import React from "react";
import { OverlayView } from "@react-google-maps/api";
import { Box, Button, List, ListItem, Popper, Typography } from "@mui/material";
import { darken, useTheme } from "@mui/material/styles";
import ArrowTooltip from "../../../ToolTip/ArrowTooltip";
import { tooltipFormatter } from "../panelUtils";
import animations from "../../../../theme/animations";
import StyledMenuWrapper from "../../../StyledMenuWrapper/StyledMenuWrapper";
import useMenu from "../../../../hooks/useMenu";
import { Divider } from "@material-ui/core";

type Props = {
	result;
	map;
	setSelected;
	disableClick: boolean;
	activeId?: number;
};

const CustomMarker = ({ result, map, setSelected, disableClick, activeId }: Props) => {
	const theme = useTheme();
	const menu = useMenu();
	const location = result["Properties.location"];
	const lat = result["Properties.geoLatitude"];
	const lng = result["Properties.geoLongitude"];
	const id = result["Properties.id"];
	const name = result["Properties.name"];
	const amount = result["RentPayments.totalAmount"];
	const avgRentPsf = result["RentPayments.avgAmountPerSf"];
	const currentAvgRentPsf = result["Properties.currentAvgRentPsf"];
	const currentTotalAnnualRent = result["Properties.sumTotalCurrentAnnualRent"];

	const active = activeId === id;

	const styles = {
		base: {
			backgroundColor: active ? "#f58737" : theme.palette["main"][400],
			position: "absolute",
			width: "20px",
			height: "20px",
			left: 0,
			top: 0,
			borderRadius: "50% 50% 50% 0",
			transform: "translate(-50%, -100%) rotate(-45deg)",
			cursor: disableClick ? "default" : "pointer",
			border: "1px solid #efefef",
			opacity: active ? 1 : 0.75,
			zIndex: active ? 3 : 1,
			pointerEvents: "auto",
		},
		hover: disableClick ? {} : {
			backgroundColor: active ? `${darken("#f58737", 0.2)}` : theme.palette["main"][600],
		},
		active: {
			border: "2px solid #efefef",
		},
	};

	if (!location) return <></>;

	const latLng = new google.maps.LatLng(lat, lng);
	return (
		<OverlayView position={latLng} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
			<Box
				sx={{
					width: "1px",
					height: "1px",
					position: "relative",
					pointerEvents: "none",
				}}
			>
				<Box
					ref={menu.anchorRef}
					onMouseEnter={menu.handleOpen}
					onMouseLeave={menu.handleClose}
					onClick={() => {
						if (disableClick) return
						if (map) {
							map.setZoom(10);
							map.setCenter(latLng);
							setSelected({
								id: result["Properties.id"],
								name: result["Properties.name"],
								attributes: [
									{ label: "Avg. Rent PSF", value: tooltipFormatter(currentAvgRentPsf, "currency") },
									{ label: "Total Annual Rent", value: tooltipFormatter(currentTotalAnnualRent, "currency") },
								],
							});
						}
					}}
					sx={{
						...styles.base,

						"&:hover": {
							...styles.hover,
						},

						...(active ? styles.active : {}),
					}}
				>
					<Popper open={menu.open} anchorEl={menu.anchorRef.current} placement="right-end">
						<StyledMenuWrapper>
							<Typography variant="subtitle2">{name}</Typography>
							<Divider />
							<List dense>
								<ListItem disableGutters disablePadding>
									<Typography
										variant="caption"
										sx={{
											marginRight: "1ch",
											fontWeight: "600",
											position: "relative",
										}}
									>
										Avg. Rent PSF
									</Typography>
									<Typography variant="caption">{tooltipFormatter(currentAvgRentPsf, "currency")}</Typography>
								</ListItem>
								<ListItem disableGutters disablePadding> 
									<Typography
										variant="caption"
										sx={{
											marginRight: "1ch",
											fontWeight: "600",
											position: "relative",
										}}
									>
										Total Annual Rent
									</Typography>
									<Typography variant="caption">{tooltipFormatter(currentTotalAnnualRent, "currency")}</Typography>
								</ListItem>
							</List>
						</StyledMenuWrapper>
					</Popper>
				</Box>
			</Box>
		</OverlayView>
	);
};

export default CustomMarker;
