import React from "react";
import {BinaryFilter, Cube, Filter, TCubeDimension, TCubeMeasure, UnaryFilter} from "@cubejs-client/core";
import {
  resolveCubeFromMember,
  resolveCubeMember,
} from "../../../utils/cube-utils";
import {
  IconButton,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { FilterOperator } from "../../../utils/utils";
import { useQueryDispatch, useQueryState } from "../../../context/query-context";
import CubeFieldLabel from "../../CubeFieldLabel/CubeFieldLabel";
import { filterOperatorsForType } from "../../../utils/filters";
import { useCubeMetaState } from "../../../context/cube-meta-context";
import QueryFilterInput from "../QueryFilterInput/QueryFilterInput";
import useStyles from "./style";
import Dropdown from "../../Dropdown/Dropdown";


interface Props {
  filterIdx: number
  filter: BinaryFilter | UnaryFilter
}

const QueryFilter: React.FunctionComponent<Props> = (
  {
    filterIdx,
    filter,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useQueryDispatch();
  const { hasQueryChanged } = useQueryState()
  const { cubesMetaLike } = useCubeMetaState()
  const [cube, setCube] = React.useState<Cube | undefined>(undefined);
  const [cubeMember, setCubeMember] = React.useState<TCubeDimension | TCubeMeasure | undefined>(undefined);
  const [availableOperators, setAvailableOperators] =  React.useState<FilterOperator[] | undefined>(undefined);
  const operatorSelectRef = React.useRef(null);

  React.useEffect(() => {
    if (filter.member && cubesMetaLike) {
      const _cubeMember = resolveCubeMember(cubesMetaLike.cubes, filter.member) as TCubeDimension | TCubeMeasure;
      const _cube = _cubeMember && resolveCubeFromMember(_cubeMember.name, cubesMetaLike.cubesMap);
      const _availableOperators = _cubeMember && filterOperatorsForType(_cubeMember.type);
      if (!_cube || !_cubeMember) {
        console.warn(`Could not resolve filter field "${filter.member}" - removing filter`);
        dispatch({ type: "removeFilter", payload: {filter: filter.member as string, runNow: true} });
      } else {
        setCube(_cube);
        setCubeMember(_cubeMember);
        setAvailableOperators(_availableOperators);
      }
    }
  }, [filter.member, cubesMetaLike]);

  if (!(cube && cubeMember)) {
    return <></>;
  }

  const handleSetValue = (newValue: string[]) => {
    // console.log(`QueryFilter > handleSetValue, newValue=`, newValue);
    let newFilter = Object.assign({}, filter);
    newFilter = Object.assign(newFilter, { values: newValue });
    dispatch({type: "updateFilter", payload: {filter: [filterIdx, newFilter]} });
    if (!hasQueryChanged) {
      dispatch({type: "setHasQueryChanged", payload: true});
    }
  }

  const handleChangeOperator = (newOperator: string): void => {
    let newFilter = Object.assign({}, filter);
    newFilter = Object.assign(newFilter, { operator: newOperator });
    if (['set', 'notSet'].includes(newOperator)) {
      Object.assign(newFilter, { values: [] });
    }
    dispatch({type: "updateFilter", payload: {filter: [filterIdx, newFilter]} });
  };

  const handleClickedDelete = () => {
    // console.log(`removeFilter - memberName=${filter.member}`);
    dispatch({ type: "removeFilter", payload: {filter: filter.member as string} });
  }

  const SelectOperator = () => {
    if (cubeMember.meta && cubeMember.meta.isBoolean) {
      return <></>
    } else {
      return <div key={'select-operator'} className={classes.filterMember + ' operator'}>
        {availableOperators
          ? <Dropdown
            value={filter.operator}
            options={availableOperators.map(op => ({ value: op.name, label: op.title }))}
            handleSetValue={(newOperator: string) => handleChangeOperator(newOperator)}
          />
          : <></>}
      </div>
    }
  }

  function ValuesInput(): React.ReactElement {
    if (cubeMember && !['set', 'notSet'].includes(filter.operator)) {
      return (
        <div
          key={'value-input-wrapper'}
          className={classes.filterMember + ' values-input' + ' ' + cubeMember.type}
        >
          <QueryFilterInput
            operator={filter.operator}
            cubeMember={cubeMember}
            values={filter.values as string[]}
            setValues={handleSetValue}
            pinnedFilter={false}
          />
        </div>
      )
    } else {
      return <></>;
    }
  }

  return <div ref={operatorSelectRef} className={classes.queryFilterContainer}>
    <FilterListIcon className={classes.blue}/>
    <div className={classes.filterMember + ' member-name'}>
      <CubeFieldLabel
        cubeTitle={cube.title}
        showCubeTitle={!(cubeMember.meta && cubeMember.meta.duplicateTitle)}
        memberTitle={cubeMember.shortTitle}
      />
    </div>
    {SelectOperator()}
    {ValuesInput()}
    <IconButton className={classes.filterMember} edge="end" aria-label="delete" onClick={handleClickedDelete}>
      <RemoveCircleOutlineIcon fontSize="small"/>
    </IconButton>
  </div>
}

export default QueryFilter;