import React from "react";
import { Query, QueryOrder, TableColumn, TQueryOrderArray } from "@cubejs-client/core";
import { TableHead, TableRow } from "@mui/material";

import { useCubeMetaState } from "../../context/cube-meta-context";
import { resolveCubeFromMember } from "../../utils/cube-utils";
import ReportHeaderCell from "./ReportHeaderCell";
import { processTimeDimensionName } from "../../utils/time-dimension-utils";

export interface Props {
	columns: TableColumn[];
	query: Query;
	isDashboardPanel: boolean;
	setQueryOrder?: (member: string, direction: QueryOrder) => void;
}

const EnhancedTableHead: React.FC<Props> = ({
	columns,
	query,
	isDashboardPanel,
	setQueryOrder,
}: Props): React.ReactElement => {
	const { cubesMetaLike } = useCubeMetaState();
	const [colWidth, setColWidth] = React.useState(getColWidth(columns));
	const [sortedColumn, setSortedColumn] = React.useState<[string, QueryOrder] | undefined>(undefined);

	React.useEffect(() => {
		setColWidth(getColWidth(columns));
	}, [columns]);

	React.useEffect(() => {
		if (query.order && query.order.length as number > 0) {
			const sortedColumn = translateColumnOrder(query.order[0]);
			setSortedColumn(sortedColumn);
		} else {
			const defaultSorting = getDefaultSorting(query);
			if (defaultSorting.length) {
				setSortedColumn(defaultSorting[0]);
			}
		}
	}, [query.order]);

	if (!cubesMetaLike) {
		return <></>;
	}

	return (
		<TableHead sx={{}}>
			<TableRow sx={{}}>
				{columns.map((column: TableColumn, id) => {
					const isMeasure = !!(query.measures && query.measures.includes(column.key));
					const title =
						column.key === "idxColumn" ? "" : resolveCubeFromMember(column.key, cubesMetaLike.cubesMap).title;

					return (
						<ReportHeaderCell
							key={column.key}
							column={column}
							title={title}
							width={colWidth}
							isMeasure={isMeasure}
							sortDirection={getSortDirection(column, sortedColumn)}
							isDashboardPanel={isDashboardPanel}
							setQueryOrder={setQueryOrder}
							index={id}
						/>
					);
				})}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;

function getColWidth(columns: TableColumn[]): string {
	const width = 100 / (columns.length - 1);
	return `${width}%`;
}

function getDefaultSorting(query: Query): TQueryOrderArray {
	if (query.measures && query.measures.length) {
		return [[query.measures[0], "desc"]];
	} else if (query.dimensions && query.dimensions.length) {
		return [[query.dimensions[0], "asc"]];
	} else {
		return [];
	}
}

function getSortDirection(column: TableColumn, sortedColumn: [string, QueryOrder] | undefined): QueryOrder | false {
	const columnKey = processTimeDimensionName(column.key);
	if (sortedColumn && sortedColumn[0] === columnKey) {
		return sortedColumn[1];
	} else {
		return false;
	}
}

function translateColumnOrder(queryOrderElement: { id: string; desc: boolean }): [string, QueryOrder] {
	return [queryOrderElement.id, queryOrderElement.desc ? "desc" : "asc"];
}
