import {createStyles, makeStyles} from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    card: {
      boxSizing: 'border-box',
      maxWidth: '25rem',
      margin: '0 auto',
      padding: '0 2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '8rem',
      background: 'transparent',
    },
    button: {
      background: 'linear-gradient(to bottom, #6371c7, #5563c1)',
      borderColor: '#3f4eae',
      borderRadius: '3px',
      padding: '1rem',
      color: 'white',
      fontWeight: 'bolder',
      width: '100%',
      marginBottom: '1rem',
      fontSize: '0.8rem',
      cursor: 'pointer',
    },
    inputField: {
      padding: '1rem',
      border: '1px solid #999',
      marginBottom: '1rem',
      fontSize: '0.8rem',
    },
    logo: {
      width: '50%',
      marginBottom: '1rem',
    },
  })
)