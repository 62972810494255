// required
export const CUBE_BACKEND = process.env.REACT_APP_CUBE_BACKEND_URL;
export const REPORTS_BACKEND = process.env.REACT_APP_REPORTS_BACKEND_URL;
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION === "false";
export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;
export const IS_LOCAL_DEV = process.env.REACT_APP_IS_LOCAL_DEV === "true";
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const APP_MAINTENANCE_MODE = false;

// optional
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || null;
export const LOGROCKET_APP_ID = process.env.REACT_APP_LOGROCKET_APP_ID || null;
