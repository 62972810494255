import {createStyles, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => (
  createStyles({
    titleRoot: {
      flex: 1,
      display: 'flex',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    actionButton: {
      // margin: '0 0.3rem',
      // minWidth: 75,
    },
  })
))