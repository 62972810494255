import React from "react";
import { AxiosError } from "axios";
import { formatRelative } from "date-fns";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { CubeContext } from "@cubejs-client/react";
import {CubejsApi, Query, ResultSet} from "@cubejs-client/core";
import {Button, IconButton, Box, Typography} from "@mui/material";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { Mixpanel, eventNames } from '../../utils/mixpanel-utils/Mixpanel';
import { deleteUserValues } from "../../utils/auth-utils";
import { exportToCSV } from "../../utils/export-utils";
import { SavedReport } from "../../utils/saved-report-utils";
import { useQueryDispatch, useQueryState } from "../../context/query-context";
import { useAlertDispatch, initiateAlert } from "../../context/alert-context";
import { useAuthDispatch, useAuthState } from "../../context/auth-context";
import SaveReportDialog from "../forms/SaveReportDialog";
import { useStyles } from "./style";
import SaveAsDialog from "../forms/SaveAsDialog";
import SaveChangesDialog from "../forms/SaveChangesDialog";
import ResetDialog from "../forms/ResetDialog";
import { ExploreQueryDefinition, SchemaContextDefinition, SchemaContextEnum } from "../../utils/cube-utils";
import { getColorFromContext } from "../../utils/dashboard-utils";
import { useTheme } from "@mui/material/styles";
import Menu from "../NestedMenu/Menu";
import { MoreVert } from "@material-ui/icons";
import useMenu from "../../hooks/useMenu";
import BreadCrumb, { path } from "../Breadcrumbs/BreadCrumb";
import { HiChevronRight } from "react-icons/hi";
import animations from "../../theme/animations";
import useContextDefinition from "../../hooks/useContextDefinition";
import PrimaryButton from "../Base/PrimaryButton";
import { FiSave, FiDownload } from "react-icons/fi";
import { BsPlayFill } from "react-icons/bs";
import SaveModal from "../Modals/SaveModal";
import ConfirmModal from "../Modals/ConfirmModal";
import SaveAsModal from "../Modals/SaveAsModal";
import RenameModal from "../Modals/RenameModal";
import SaveChangesModal from "../Modals/SaveChangesModal";
import { useFieldContext } from "../../context/providers/FieldContextProvider";


interface Props {
  report?: SavedReport,
  resultSet: ResultSet | null | undefined
  contextDefinition: SchemaContextDefinition
  resetQuery: () => void
  handleReportSaved: (report: SavedReport) => void
  executeRunQuery: (api: CubejsApi, queryToRun: Query) => void
  handleClickedExploreFromHere: () => void
}

const ReportHeader: React.FunctionComponent<Props> = (
  {
    report,
    resultSet,
    contextDefinition,
    resetQuery,
    handleReportSaved,
    executeRunQuery,
    handleClickedExploreFromHere,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate()
  const dispatchAlert = useAlertDispatch();
  const dispatchAuth = useAuthDispatch();
  const dispatchQuery = useQueryDispatch();
  const theme = useTheme();
  const fieldContext = useFieldContext()
  const [searchParams, setSearchParams] = useSearchParams();

  const { user, currentEnvironment } = useAuthState();
  const { query, hasQueryChanged, isLoading, runNow } = useQueryState();
  const { cubesMetaLike } = useCubeMetaState()
  const { env } = useParams() as Record<string, string>;
  const [isValidQuery, setIsValidQuery] = React.useState(true);
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [saveChangesDialogOpen, setSaveChangesDialogOpen] = React.useState(false);
  const [saveAsDialogOpen, setSaveAsDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
  const [pathList, setPathList] = React.useState<path[] | undefined>(undefined);
  const [titleText, setTitleText] = React.useState<string | undefined>(undefined);
  const { cubejsApi } = React.useContext(CubeContext);
  const { explore } = useContextDefinition() as { explore: ExploreQueryDefinition };

  /**
   * Set breadcrumbs & title text.
   */
  React.useEffect(() => {
    const contextColor = getColorFromContext(theme, contextDefinition.name);
    if (report) {
      setTitleText(report.name);
      setPathList([
        { label: 'Named Reports', path: `/${env}` },
        { label: report.name, color: contextColor },
      ])
    } else {
      const contextLabel = explore ? explore.label : contextDefinition.label;
      const contextColor = getColorFromContext(theme, contextDefinition.name);
      setTitleText(`Explore ${contextLabel}`);
      setPathList([
        { label: 'Explore', path: `/${env}` },
        { label: contextLabel, color: contextColor },
      ])
    }
  }, [report, explore && explore.label, hasQueryChanged]);

  /**
   * When query changes, set 'queryIsValid' in state.
   */
  React.useEffect(() => {
    const queryIsValid = !!(
      (query.dimensions && query.dimensions.length)
      || (query.measures && query.measures.length)
      || (query.timeDimensions && query.timeDimensions.length)
    );
    /** Prevent dispatching query that will be dispatched now anyway (runNow==true) **/
    if (queryIsValid && runNow === false) {
      dispatchQuery({type: "setHasQueryChanged", payload: true});
    }
    if (queryIsValid !== isValidQuery) {
      setIsValidQuery(queryIsValid);
    }
  }, [query, runNow])

  if (!cubesMetaLike) {
    return <></>
  }

  const handleClickRun = () => {
    executeRunQuery(cubejsApi, query);
  }

  const handleClickExport = () => {
    if (resultSet) {
      Mixpanel.track(eventNames.downloadCSV)
      console.log(`Exporting report '${titleText}' as CSV`);
      exportToCSV(resultSet, fieldContext, titleText || "");
    }
  }

	const handleClickSave = () => {
		Mixpanel.track(eventNames.savedReport);
		setSaveDialogOpen(true);
	};

  const handleSaveSuccess = (resp: SavedReport) => {
    initiateAlert(dispatchAlert, 'success', "Report saved");
    handleReportSaved(resp);
  }

  const handleSaveAsSuccess = (resp: SavedReport) => {
    initiateAlert(dispatchAlert, 'success', "Saved as new report");
    const newLocation = {
      pathname: `/${env}/reports/${SchemaContextEnum[resp.context_name]}/${resp.subcontext_enum}/key/${resp.key}`,
      state: {
        savedReport: resp,
        prevQuery: query,
      }
    }
    navigate(newLocation)
  }

  const handleSaveFailure = (error: AxiosError) => {
    console.error(`Failed to save report: ${error}`);
    initiateAlert(dispatchAlert, 'error', "Failed to save")
    if (error.response && error.response.status === 401) {
      console.error(`Authentication error`);
      deleteUserValues()
      dispatchAuth({ type: 'setIsNotAuthenticated'})

    }
  }

  	const lastSyncedDate = currentEnvironment?.last_synced;
	const formattedLastSynced = lastSyncedDate ? formatRelative(new Date(lastSyncedDate), new Date()) : "--";
	const reportHasChanged = report && JSON.stringify(report.query_string) !== JSON.stringify(query);
	const ownedByUser = report && user && parseInt(report.owner_id) === user.userId;
	const moreMenu = useMenu();

	return (
		<>
			<SaveModal
				report={report}
				open={saveDialogOpen}
				setOpen={setSaveDialogOpen}
				onClose={() => setSaveAsDialogOpen(false)}
				handleSuccess={handleSaveAsSuccess}
				handleFailure={handleSaveFailure}
			/>
			<SaveChangesModal
				open={saveChangesDialogOpen}
				setOpen={setSaveChangesDialogOpen}
				onClose={() => setSaveChangesDialogOpen(false)}
				handleSuccess={handleSaveSuccess}
				handleFailure={handleSaveFailure}
				report={report}
			/>
			<ConfirmModal
				open={resetDialogOpen}
				setOpen={setResetDialogOpen}
				title={"Reset Explore?"}
				description={"Resetting will discard all your changes!"}
				onAccept={resetQuery}
			/>
			<SaveAsModal
				open={saveAsDialogOpen}
				setOpen={setSaveAsDialogOpen}
				onClose={() => setSaveAsDialogOpen(false)}
				handleSuccess={handleSaveAsSuccess}
				handleFailure={handleSaveFailure}
				report={report}
			/>
			<RenameModal
				open={renameDialogOpen}
				setOpen={setRenameDialogOpen}
				onClose={() => setRenameDialogOpen(false)}
				handleSuccess={handleSaveSuccess}
				handleFailure={handleSaveFailure}
				report={report}
			/>
			<Box style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{pathList?.map((path, index) => {
						return (
							<React.Fragment key={index}>
								<BreadCrumb path={path} index={index} />
								{index !== pathList.length - 1 && (
									<Box sx={{ animation: animations.fadeRight, animationDelay: `${(index + 1) * 0.1}s` }}>
										<HiChevronRight style={{ fontSize: `25px`, transform: "translateY(5px)" }} />
									</Box>
								)}
							</React.Fragment>
						);
					})}
				</Box>
				<div style={{ marginLeft: 12, display: "flex", gap: theme.spacing(1), alignItems: "end" }}>
					<Typography variant="subtitle1" color="textSecondary" gutterBottom={false} sx={{ fontSize: "14px" }}>
						Synced {formattedLastSynced}
					</Typography>

					{/* <ResetButton onClick={() => setResetDialogOpen(true)} report={report} /> */}
					{/* {location.pathname.includes("explore") && <SelectContext />} */}
					{report ? (
						<Button
							className={classes.actionButton}
							size="medium"
							sx={{ display: "inline-block" }}
							color="secondary"
							variant="outlined"
							key="explore-from-here"
							onClick={handleClickedExploreFromHere}
						>
							Explore from here
						</Button>
					) : (
						<></>
					)}
					{/* <PrimaryButton
						startIcon={<FiSave style={{ fontSize: "16px" }} />}
						onClick={() => (report ? setSaveChangesDialogOpen(true) : handleClickSave())}
						disabled={(report && (!ownedByUser || !reportHasChanged)) || false}
						loading={saveDialogOpen}
						loadingText={"saving"}
						variant="outlined"
						shortcut="ctrl+s"
					>
						save
					</PrimaryButton> */}
					<PrimaryButton
						color="primary"
						onClick={handleClickRun}
						disabled={!(hasQueryChanged && isValidQuery)}
						loading={isLoading}
						loadingText={"running"}
					>
						run
					</PrimaryButton>
					<IconButton onClick={moreMenu.handleToggle} ref={moreMenu.anchorRef as React.RefObject<HTMLButtonElement>}>
						<MoreVert />
						<Menu
							menuItems={[
								{
									label: "Save",
									onClick: () => (report ? setSaveChangesDialogOpen(true) : handleClickSave()),
									options: {
										disabled: (report && (!ownedByUser || !reportHasChanged)) || false,
										endIcon: <FiSave style={{ fontSize: "16px" }} />,
									},
								},
								{
									label: "Save as",
									onClick: () => setSaveAsDialogOpen(true),
									options: {
										disabled: !report,
									},
								},
								{
									label: "Rename",
									onClick: () => setRenameDialogOpen(true),
									options: {
										disabled: !report || !ownedByUser,
										divider: true,
									},
								},
								// {
								//   label: 'Delete',
								//   onClick: () => setSaveDialogOpen(true),
								//   disabled: !report || !ownedByUser,
								//   visible: true,
								//   divider: true,
								//   children: [],
								// },
								{
									label: "Reset",
									onClick: () => setResetDialogOpen(true),
									options: {
										divider: !report,
									}
								},
								{
									label: "Explore from here",
									onClick: () => handleClickedExploreFromHere(),
									options: {
										divider: true,
										visible: !!report,
									},
								},

								{
									label: "Download (CSV)",
									onClick: handleClickExport,
									options: {
										endIcon: <FiDownload style={{ fontSize: "16px" }} />,
									},
								},
							]}
							open={moreMenu.open}
							setOpen={moreMenu.setOpen}
							anchorEl={moreMenu.anchorRef.current}
						/>
					</IconButton>
				</div>
			</Box>
		</>
	);
};

export default ReportHeader;
