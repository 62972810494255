import { createStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles(() =>
  createStyles({
		menuRoot: {
			marginTop: '4px',
			minWidth: "350px",
			maxWidth: '350px',
			minHeight: "300px",
			backgroundColor: grey[100],
			outline: "1px solid",
			outlineColor: grey[400],
			borderRadius: "4px",
			display: "flex",
			flexDirection: "column",
			overflow: "hidden",
			zIndex: 4,
    },
		selectContainer: {
			padding: '8px 12px 4px 12px',
		},
	})
)

export default useStyles;