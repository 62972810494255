import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => (
  createStyles({
    menuItemRoot: {
      fontSize: '0.9rem',
      // backgroundColor: 'lightblue',
    },
  })
));

export default useStyles;