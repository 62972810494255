// import React from 'react';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100%)',
      alignSelf: 'center',
      // overflow: 'hidden',
      padding: '0.5rem 2rem',
      width: '80vw',
      ['@media (min-width:1400px)']: { // eslint-disable-line no-useless-computed-key
        width: '60vw',
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    grow: {
      flexGrow: 1,
    },
    titleRowButtons: {
      alignSelf: 'center',
      margin: '0 0.5rem',
    },
  }),
);

export default useStyles