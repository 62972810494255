import React from 'react'
import StyledHeader from '../styledComponents/StyledHeader'

interface Props {
  children: React.ReactNode;
  disableBorderTop?: boolean;
  disableMarginBottom?: boolean;
}

const Header: React.FC<Props> = ({children, disableBorderTop, disableMarginBottom }: Props) => {
  return (
    <StyledHeader variant="h6" className='explore-header' disableBorderTop={disableBorderTop} disableMarginBottom={disableMarginBottom}>
      {children}
    </StyledHeader>
  )
}

Header.defaultProps = {
  disableBorderTop: false,
  disableMarginBottom: false,
}

export default Header