import { useState, useEffect } from "react";

const useTextOverflow = (textRef) => {
	const [textOverflow, setTextOverflow] = useState(false);

	useEffect(() => {
		const text = textRef.current;
		if (!text) return;
		const observer = new ResizeObserver(() => {
			if (text.offsetWidth < text.scrollWidth) {
				setTextOverflow(true);
			} else {
				setTextOverflow(false);
			}
		});

		observer.observe(text);
		return () => {
			observer.disconnect();
		};
	}, [textRef]);

	return textOverflow;
};

export default useTextOverflow;
