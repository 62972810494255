import React from "react";
import { TextField } from "@mui/material";
import Modal from "./Modal";
import { useQueryState } from "../../context/query-context";
import API from "../../api";
import { SavedReport } from "../../utils/saved-report-utils";
import { useParams } from "react-router-dom";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import { AxiosError, AxiosResponse } from "axios";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../Base/PrimaryButton";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onClose: () => void;
	handleSuccess: (responseData: SavedReport) => void;
	handleFailure: (error: AxiosError) => void;
	report?: SavedReport;
};

const SaveModal = ({ open, setOpen, onClose, handleSuccess, handleFailure, report }: Props) => {
	const theme = useTheme();
	const { query } = useQueryState();
	const { appContextName } = useCubeMetaState();
	const { env, exploreFrom } = useParams() as Record<string, string>;
	const { fields } = useFieldContext();

	const [nameInput, setNameInput] = React.useState(report ? report.name : "");

	const handleChangeName = (evt) => {
		setNameInput(evt.target.value);
	};

	const handleClose = () => {
		setNameInput("");
		setError("");
		setLoading(false);
		onClose();
		setOpen(false);
	};

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");

	const handleSave = () => {
		const payload = {
			query_string: query,
			description: "",
			name: nameInput,
			context_name: appContextName,
			subcontext_enum: parseInt(exploreFrom),
			fields_order: fields,
		};
		if (!env) {
			return;
		}

		setLoading(true);

		if (report) {
			API.put(`/api/report/env_name/${env}/key/${report.key}`, payload)
				.then((response: AxiosResponse) => {
					handleSuccess(response.data);
					handleClose();
				})
				.catch((error: AxiosError) => {
					handleFailure(error);
					setError(error.message);
                    setLoading(false);
				});
		} else {
			API.post(`/api/report/env_name/${env}`, payload)
				.then((response: AxiosResponse) => {
					handleSuccess(response.data);
					handleClose();
				})
				.catch((error: AxiosError) => {
					console.error(`SavedReportDialog > create > error=`, error);
					handleFailure(error);
					setError(error.message);
					setLoading(false);
				});
		}

	};

	const props = {
		open,
		setOpen,
		title: "Save Report",
		description: "Provide a name for the report.",
		form: (
			<>
				<TextField
					value={nameInput}
					onChange={handleChangeName}
					required
					autoFocus={true}
					id="name"
					label="Name"
					type="text"
					inputProps={{ maxLength: 50 }}
					sx={{ width: "50%", minWidth: "16vw" }}
					variant="standard"
					margin="dense"
					error={error !== ""}
					helperText={error}
				/>
			</>
		),
		actions: (
			<>
				<PrimaryButton onClick={handleClose} color="secondary" variant="text">
					Cancel
				</PrimaryButton>
				<PrimaryButton
					color="primary"
					disabled={!nameInput}
					onClick={handleSave}
					variant="text"
					loading={loading}
					loadingText="Saving"
				>
					Save
				</PrimaryButton>
			</>
		),
		onKeyPress: (e: React.KeyboardEvent) => {
			if (e.key === "Enter" && nameInput) {
				handleSave();
			}
		},
	};
	return <Modal {...props} />;
};

export default SaveModal;
