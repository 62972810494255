import { Box } from "@mui/material";
import { format } from "date-fns";
import React from "react";

type Props = {
	placeHolder: string;
	range: {
		selection: {
			startDate: Date;
			endDate: Date;
			key: string;
		};
	};
	setRange: React.Dispatch<
		React.SetStateAction<{
			selection: {
				startDate: Date;
				endDate: Date;
				key: string;
			};
		}>
	>;
};

const DateInput = ({ placeHolder, range, setRange }: Props) => {
    const [value, setValue] = React.useState("");
    const dateFormat = "MMM d, yyyy";
    React.useEffect(() => {
        const { startDate, endDate } = range.selection;
        // console.log(startDate, endDate)
        if (placeHolder === "Start Date") {
            setValue(format(startDate, dateFormat));
        } else {
            setValue(format(endDate, dateFormat));
        }
    }, [range])

    React.useEffect(() => {
        // check if string is valid date
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            setError(true);
            return;
        } else {
            setError(false);
        }
    }, [value])

    // look for enter when input is focused
    React.useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (error) return;
            if (e.key === "Enter") {
                console.log("enter", placeHolder)
                const date = new Date(value);
                if (placeHolder === "Start Date") {
                    setRange({
                        selection: {
                            startDate: date,
                            endDate: range.selection.endDate,
                            key: "selection",
                        },
                    });
                } else {
                    setRange({
                        selection: {
                            startDate: range.selection.startDate,
                            endDate: date,
                            key: "selection",
                        },
                    });
                }
            }
        }
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        }
    }, [value])

    const [error, setError] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

	return <input placeholder={placeHolder} className="dateInput" value={value} onChange={handleChange} style={{outline: error ? "1px solid red" : ""}}/>;
};

export default DateInput;
