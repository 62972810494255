import React, { useMemo } from "react";
import { ResponsiveContainer } from "recharts";
import Typography from "@mui/material/Typography";
import { tickFormatter, tooltipFormatter } from "./panelUtils";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box } from "@material-ui/core";
import { Button, Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import animations from "../../../theme/animations";
import { fontSize } from "@mui/system";
import { useTheme, alpha } from "@mui/material/styles";
import { FiArrowRight } from "react-icons/fi";
import useTextOverflow from "../../../hooks/useTextOverflow";

type Props = {
	value: string;
	data;
	seriesNames;
	format: string;
	title: string;
	granularity?: any;
};

const Figure = ({ data, seriesNames, format, title, granularity }: Props) => {
	const theme = useTheme();
	const trendIndicator = seriesNames.length > 1;
	let value = seriesNames[0].key !== "" ? data[0][seriesNames[0].key] : data[0]["x"]
	let lastValue = 0;
	if (trendIndicator) {
		lastValue = data[0][seriesNames[1].key];
	}

	const formattedValue = useMemo(() => {
		return tickFormatter(value, format);
	}, [value, format]);

	function renderChip() {
		if (!trendIndicator) {
			return <></>;
		}

		// get percentage difference between value and last value
		const percentageDiff = ((value - lastValue) / lastValue) * 100;
		let percentageStr = tickFormatter(percentageDiff, "percent");

		let color = theme.palette.info.main;
		let rotate = 0;
		if (percentageDiff >= 2) {
			color = theme.palette.success.main;
			rotate = -45;
		} else if (percentageDiff <= -2) {
			color = theme.palette.error.main;
			rotate = 45;
		}

		return (
			<ArrowTooltip
			title={
				granularity
				? `a ${percentageStr.replace("-", "")} ${
					percentageStr.includes("-") ? "decrease" : "increase"
				} since last ${granularity}`
				: ""
			}
			enterDelay={500}
			>
			<Chip
				icon={<FiArrowRight style={{ color: "inherit", rotate: `${rotate}deg`, fontSize: 'inherit' }} />}
				label={percentageStr}
				sx={{ color: color, backgroundColor: alpha(color, 0.25), fontWeight: 500, marginLeft: 1, fontSize: "0.875rem", "&:hover": {
					color: "rgba(255, 255, 255, 0.75)", backgroundColor: color
				} }}
				size="small"
				className="figure-chip"
			/>
			</ArrowTooltip>
		);
	}

	const titleRef = React.useRef(null);
	const isTextOverflowing = useTextOverflow(titleRef);
	// console.log(isTextOverflowing);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", padding: 10, }} className="figure">
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className="trend">
				<ArrowTooltip title={isTextOverflowing ? title : ""}>
					<Typography
						ref={titleRef}
						sx={{
							opacity: 0.5,
							lineHeight: 1,
							fontSize: "0.875em",
							maxHeight: "calc(clamp(12px, 3vw, 0.875em) * 2)",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: "2",
							WebkitBoxOrient: "vertical",
						}}
					>
						{title}
					</Typography>
				</ArrowTooltip>
				{renderChip()}
			</Box>
			<Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "baseline" }}>
				<Typography
					variant="h5"
					sx={{ fontWeight: "600", fontSize: "1.875rem", whiteSpace: "nowrap", marginRight: trendIndicator ? 1 : 0 }}
				>
					{formattedValue}
				</Typography>
				{trendIndicator && (
					<Typography component="span" sx={{ opacity: 0.5, fontSize: "0.875rem", whiteSpace: "nowrap" }}>
						{`from ${tickFormatter(lastValue, format)}`}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const ClipboardTooltip = ({ value }) => {
	const theme = useTheme();

	const [clicked, setClicked] = React.useState(false);

	function handleClick() {
		setClicked(true);
		navigator.clipboard.writeText(value);
	}

	return (
		<Button
			color="info"
			size="small"
			onClick={handleClick}
			sx={{
				color: theme.palette.secondary.contrastText,
				"&& .MuiTouchRipple-rippleVisible": {
					animationDuration: "250ms",
				},
			}}
		>
			{clicked ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}

			{clicked ? "copied" : value}
		</Button>
	);
};

export default Figure;
