import { keyframes } from "@mui/system";
import { theme } from "./theme";

let disable = false;
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
if (!mediaQuery || mediaQuery.matches) {
	console.log("Reduced motion detected, disabling animations");
	disable = true;
}

export function generateDelay() {
	if (disable) {
		return {};
	}
	let sx = {};
	for (let i = 1; i < 11; i++) {
		const nthChild = `&:nth-of-type(${i})`;
		const delay = i * 0.05;
		sx = { ...sx, ...{ [nthChild]: { animationDelay: `${delay.toFixed(1)}s` } } };
	}

	return sx;
}

generateDelay();

const kf = {
	fadeUp: keyframes`
    0% {
        opacity: 0;
        transform: translateY(10px);
        }
    100% {
        opacity: 1;
        transform: translateY(0);
    }`,
    fadeDown: keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }`,
    fadeRight: keyframes`
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }`,
	fadeLeft: keyframes`
    0% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
    `,
	fadeIn: keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }`,
	circle: keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }`,
    expand: keyframes`
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }`,
    expandX: keyframes`
    0% {
        transform: scaleX(0);
        opacity: 0;
    }
    100% {
        transform: scaleX(1);
        opacity: 1;
    }`,
    shrink: keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }`,
    loading: keyframes`
    0% {
        background-position: -100%;
    } 100% {
        background-position: 200%;
    }`,
    loading2: keyframes`
    0% {
        background-color: rgba(0, 0, 0, 0.05);
    } 50% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.05);
    }`,
    pulse: keyframes`
    0%{
        opacity: 0,
        // transform: scale(0),
    }
    100% {
        opacity: 1,
        // transform: scale(1),
    }`
};

const animations = {
    fadeUp: `${kf.fadeUp} backwards ${disable? 0 : 0.5}s ${theme.transitions.easing.easeIn}`,
    fadeDown: `${kf.fadeDown} backwards ${disable ? 0 : 0.5}s ${theme.transitions.easing.easeIn}`,
    fadeRight: `${kf.fadeRight} backwards ${disable ? 0 : 0.5}s ${theme.transitions.easing.easeIn}`,
    fadeIn: `${kf.fadeIn} backwards ${disable ? 0 : 1}s ${theme.transitions.easing.sharp}`,
    fadeInSlow: `${kf.fadeIn} backwards ${disable ? 0 : 5}s ${theme.transitions.easing.sharp}`,
    circle: `${kf.circle} forwards ${disable ? 0 : 1}s ${theme.transitions.easing.sharp}`,
    fadeDownSharp: `${kf.fadeUp} backwards ${disable ? 0 : 0.5}s ${theme.transitions.easing.sharp}`,
    expand: `${kf.expand} forwards ${disable ? 0 : 0.2}s ${theme.transitions.easing.sharp}`,
    expandX: `${kf.expandX} forwards ${disable ? 0 : 0.2}s ${theme.transitions.easing.sharp}`,
    shrink: `${kf.shrink} forwards ${disable ? 0 : 0.2}s ${theme.transitions.easing.sharp}`,
    loading: `${kf.loading} forwards ${disable ? 0 : 2}s ease-out infinite`,
    loading2: `${kf.loading2} forwards ${disable ? 0 : 2}s linear infinite`,
    pulse: `${kf.pulse} backwards ${disable ? 0 : 1}s linear infinite`,
}

export function reverseCubicBezier(cubicBezier: string) {
    // input is a string like cubic-bezier(0.4, 0, 0.2, 1)
    // output is a string like cubic-bezier(0.8, 1, 0.6, 0)
    const [x1, y1, x2, y2] = cubicBezier.split("(")[1].split(")")[0].split(",").map((x) => parseFloat(x))
    return `cubic-bezier(${1 - x2}, ${1 - y2}, ${1 - x1}, ${1 - y1})`
};

export default animations;
