import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Grow, DialogContentText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import ArrowTooltip from "../ToolTip/ArrowTooltip";
import { reverseCubicBezier } from "../../theme/animations";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
	description: string;
	form: React.ReactNode;
	actions: React.ReactNode;
	onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
		timeout: number;
	},
	ref: React.Ref<unknown>
) {
	const theme = useTheme();
	const { timeout, ...restProps } = props;
	return (
		<Grow
			ref={ref}
			easing={props.in ? theme.transitions.easing.sharp : reverseCubicBezier(theme.transitions.easing.sharp)}
			timeout={{ enter: 250, exit: 100 }}
			{...restProps}
		/>
	);
});

const Modal = ({ open, setOpen, title, description, form, actions, onKeyPress }: Props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	function handleClose() {
		setOpen(false);
	}

	const style = {
		backdropFilter: "blur(3px)",
		"& .MuiPaper-root": {
			minWidth: 'max(25vw, 450px)',
			boxShadow: "none",
		},
		"& .close-btn": {
			position: "absolute",
			top: 0,
			right: 0,
			margin: theme.spacing(1),
			transition: "transform 0.3s ease-in-out",
		},
	};

	return (
		<Dialog
			key={`${open}`}
			sx={style}
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			TransitionProps={{
				timeout: 1000,
			}}
			TransitionComponent={Transition}
			keepMounted
			onKeyUp={(event) => {
				if (onKeyPress) onKeyPress(event);
			}}
		>
			<ArrowTooltip title="close" enterDelay={250}>
				<IconButton className="close-btn" size="small" onClick={() => setOpen(false)}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</ArrowTooltip>
			<DialogTitle sx={{paddingBottom: 0}}>{title}</DialogTitle>
			<DialogContent sx={{ paddingBottom: description.length > 0 ? theme.spacing(1) : 0 }}>
				<DialogContentText>{description}</DialogContentText>
				{form}
			</DialogContent>
			<DialogActions>{actions}</DialogActions>
		</Dialog>
	);
};

export default Modal;
