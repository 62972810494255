import { createStyles, makeStyles } from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardFiltersRoot: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(1, 0),
      zIndex: 3,
    },
    section: {
      margin: "0 12px 12px 0",
    },
    clearButton: {
      maxHeight: "35px",
      marginTop: "20px !important",
    },
  })
);

export default useStyles;
