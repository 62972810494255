import {
	TextField,
	Box,
	IconButton,
	Button,
	InputBase,
	ButtonBase,
	Divider,
	List,
	ListItemButton,
	Popper,
	ClickAwayListener,
  Typography
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import React from "react";
import ArrowTooltip from "../../../ToolTip/ArrowTooltip";
import useMenu from "../../../../hooks/useMenu";
import { useTheme } from "@mui/material/styles";
import { ResultSet } from "@cubejs-client/core";
import { tooltipFormatter } from "../panelUtils";

type Props = {
	resultSet: ResultSet;
	map;
	setSelected;
	selected;
	mapBounds;
};

const Search = ({ resultSet, map, setSelected, selected, mapBounds }: Props) => {
	const theme = useTheme();
	const menu = useMenu();

	const [searchValue, setSearchValue] = React.useState("");
	const [searchResults, setSearchResults] = React.useState(resultSet.rawData());

	function handleChange(e) {
		setSearchValue(e.target.value);
    if (!menu.open) {
      menu.handleOpen()
    }
	}

	React.useEffect(() => {
		if (selected) {
			setSearchValue(selected.name);
		}
	}, [selected]);

	React.useEffect(() => {
		const tempResults = resultSet
			.rawData()
			.filter((r) => r["Properties.name"].toLowerCase().includes(searchValue.toLowerCase()));
		setSearchResults(tempResults);
	}, [searchValue]);

	return (
		<>
			<ClickAwayListener onClickAway={menu.handleClose}>
				<Box sx={{ zIndex: 10 }}>
					<Box
						ref={menu.anchorRef}
						sx={{
							backgroundColor: "white",
							pointerEvents: "auto",
							margin: 1,
							display: "flex",
							alignItems: "center",
							borderRadius: menu.open ? theme.spacing(1, 1, 0, 0) : theme.spacing(1),
							overflow: "hidden",
							width: "fit-content",
							padding: "0.25rem 1rem",
							position: "relative",
              "&:focus-within": {
                "& svg": {
                  color: theme.palette.primary.main,
                },
              },
						}}
					>
						<Box
							onClick={menu.handleOpen}
							sx={{
								display: "flex",
								alignItems: "center",
								
							}}
						>
							<InputBase placeholder="Search Map" value={searchValue} onChange={handleChange}/>
						</Box>
						<Divider sx={{ height: 28, mr: 1.5, ml: 1.5 }} orientation="vertical" />
						<ArrowTooltip title={selected ? "clear search" : "search"}>
							<ButtonBase
								sx={{
									opacity: 0.5,
									"&:hover": {
										opacity: 1,
									},
								}}
								disableRipple
								onClick={() => {
									menu.handleClose();
                  if (selected) {
                    if (map && mapBounds.current) {
                      map.setZoom(4);
                      map.fitBounds(mapBounds.current);
                      setSearchValue("");
                      setSelected(undefined);
                    }
                  } else if (searchResults.length > 0) {
                    const result = searchResults[0]
                    const name = result["Properties.name"];
                    const id = result["Properties.id"];
                    const lat = result["Properties.geoLatitude"];
                    const lng = result["Properties.geoLongitude"];
                    const latLng = new google.maps.LatLng(lat, lng);
                    const currentAvgRentPsf = result["Properties.currentAvgRentPsf"];
                    const currentTotalAnnualRent = result["Properties.sumTotalCurrentAnnualRent"];
                    if (map) {
                      map.setZoom(10);
                      map.setCenter(latLng);

                      setSearchValue(name);

                      setSelected({
                        id,
                        name,
                        attributes: [
                          { label: "Avg. Rent PSF", value: tooltipFormatter(currentAvgRentPsf, "currency") },
                          { label: "Total Annual Rent", value: tooltipFormatter(currentTotalAnnualRent, "currency") },
                        ],
                      });
                    }
                  }
								}}
							>
								{selected ? <CloseRoundedIcon /> : <SearchRoundedIcon />}
							</ButtonBase>
						</ArrowTooltip>
					</Box>
					<Popper open={menu.open} anchorEl={menu.anchorRef.current} placement="bottom" sx={{ zIndex: 10 }}>
						<Box
							sx={{
								backgroundColor: "white",
								width: "264px",
								borderRadius: theme.spacing(0, 0, 1, 1),
								borderTop: `1px solid ${theme.palette.divider}`,
							}}
						>
							<List sx={{ maxHeight: "200px", overflowY: "scroll" }} className="thinScrollbar">
								{searchResults.map((result, idx) => {
									const name = result["Properties.name"];
									const id = result["Properties.id"];
									const lat = result["Properties.geoLatitude"];
									const lng = result["Properties.geoLongitude"];
									const latLng = new google.maps.LatLng(lat, lng);
									const currentAvgRentPsf = result["Properties.currentAvgRentPsf"];
									const currentTotalAnnualRent = result["Properties.sumTotalCurrentAnnualRent"];
									return (
										<ListItemButton
                      // autoFocus = {idx === 0 ? true : false}
											key={idx}
                      sx={{"&:focus": {
                        backgroundColor: "lightgray"
                      }}}
											onClick={() => {
												if (map) {
													map.setZoom(10);
													map.setCenter(latLng);

													setSearchValue(name);

													setSelected({
														id,
														name,
														attributes: [
															{ label: "Avg. Rent PSF", value: tooltipFormatter(currentAvgRentPsf, "currency") },
															{ label: "Total Annual Rent", value: tooltipFormatter(currentTotalAnnualRent, "currency") },
														],
													});
												}
												menu.handleClose();
											}}
										>
											<Typography variant="body2">{name}</Typography>
										</ListItemButton>
									);
								})}
								{searchResults.length === 0 ? (
									<Typography variant="body2" sx={{opacity: 0.5, textAlign: 'center'}}>{`no results for "${searchValue}"`}</Typography>
								) : (
									<></>
								)}
							</List>
						</Box>
					</Popper>
				</Box>
			</ClickAwayListener>
		</>
	);
};

export default Search;
