import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme, alpha } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    tableContainer: {
      '&::-webkit-scrollbar:horizontal': {
        transform: 'scale(5)',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: alpha("#888", 0.5),
        borderRadius: '6px',

        "&:hover": {
          background: alpha("#888", 1),
        }
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent !important",
      },

      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          "&:hover": {
            background: alpha("#555", 0.5),
          }
        }
      }
    },
  })
));

export default useStyles;