/**
 * Utilities for query filtering
 **/

import {BinaryOperator, TCubeMemberType, UnaryOperator} from "@cubejs-client/core"
import {FilterOperator, FilterOperatorName} from "./utils"


// Which column types are supported for each operator
// export const columnTypesByOperator = {
//   equals: ['string', 'number', 'time'],
//   notEquals: ['string', 'number', 'time'],
//   contains: ['string'],
//   notContains: ['string'],
//   gt: ['number'],
//   gte: ['number'],
//   lt: ['number'],
//   lte: ['number'],
//   set: ['string', 'number', 'time'],
//   notSet: ['string', 'number', 'time'],
//   inDateRange: ['time'],
//   notInDateRange: ['time'],
//   beforeDate: ['time'],
//   afterDate: ['time'],
// }

// const filterOperators: Record<string, FilterOperator> = {
//   equals: {title: "equals", name: "equals"},
//   notEquals: {title: "does not equal", name: "notEquals"},
//   contains: {title: "contains", name: "contains"},
//   notContains: {title: "does not contain", name: "notContains"},
//   gt: {title: "greater than", name: "gt"},
//   gte: {title: "greater than or equal to", name: "gte"},
//   lt: {title: "less than", name: "lt"},
//   lte:  {title: "less than or equal to", name: "lte"},
//   set: {title: "is set", name: "set"},
//   notSet: {title: "is not set", name: "notSet"},
//   inDateRange: {title: "is in range", name: "inDateRange"},
//   notInDateRange: {title: "is not in range", name: "notInDateRange"},
//   beforeDate: {title: "is before", name: "beforeDate"},
//   afterDate: {title: "is after", name: "afterDate"},
// }

export function getOperatorByName(name: FilterOperatorName, type: TCubeMemberType): FilterOperator | null {
	const operatorsForType = operatorsByColType[type];
	return operatorsForType.find(o => o.name === name) || null;
}

const stringOperators: FilterOperator[] = [
	{title: "Is", name: "equals", multipleValuesSupported: true},
	{title: "Is not", name: "notEquals", multipleValuesSupported: true},
	{title: "Contains", name: "contains", multipleValuesSupported: true},
	{title: "Does not contain", name: "notContains", multipleValuesSupported: true},
	{title: "Has a value", name: "set", multipleValuesSupported: false},
	{title: "Has no value", name: "notSet", multipleValuesSupported: false},
]

const timeOperators: FilterOperator[] = [
	{title: "Is before", name: "beforeDate", multipleValuesSupported: false},
	{title: "Is after", name: "afterDate", multipleValuesSupported: false},
	{title: "Is in range", name: "inDateRange", multipleValuesSupported: true},
	{title: "Is not in range", name: "notInDateRange", multipleValuesSupported: true},
	{title: "Has a value", name: "set", multipleValuesSupported: false},
	{title: "Has no value", name: "notSet", multipleValuesSupported: false},
]

const numberOperators: FilterOperator[] = [
  {title: "Equals", name: "equals", multipleValuesSupported: false},
  {title: "Does not equal", name: "notEquals", multipleValuesSupported: false},
  {title: "Greater than", name: "gt", multipleValuesSupported: false},
  {title: "Greater than or equal to", name: "gte", multipleValuesSupported: false},
  {title: "Less than", name: "lt", multipleValuesSupported: false},
  {title: "Less than or equal to", name: "lte", multipleValuesSupported: false},
  {title: "Has a value", name: "set", multipleValuesSupported: false},
  {title: "Has no value", name: "notSet", multipleValuesSupported: false},
]

const booleanOperators: FilterOperator[] = numberOperators;

// const booleanOperators: FilterOperator[] = [
//   {title: "Is", name: "equals"},
//   {title: "Has a value", name: "set"},
//   {title: "Has no value", name: "notSet"},
// ]

// const F = filterOperators;
// const operatorsByColType: Record<TCubeMemberType, FilterOperator[]> = {
//   string: [ F['equals'], F['notEquals'], F['contains'], F['notContains'], F['set'], F['notSet'] ],
//   time: [ F['equals'], F['notEquals'], F['set'], F['notSet'], F['inDateRange'], F['notInDateRange'], F['beforeDate'], F['afterDate'] ],
//   number: [ F['equals'], F['notEquals'], F['gt'], F['gte'], F['lt'], F['lte'], F['set'], F['notSet'] ],
//   boolean: [],
// }

const operatorsByColType: Record<TCubeMemberType, FilterOperator[]> = {
  string: stringOperators,
  time: timeOperators,
  number: numberOperators,
  boolean: booleanOperators,
}

export const filterOperatorsForType = (cubeMemberType: TCubeMemberType): FilterOperator[] => {
  return operatorsByColType[cubeMemberType] || operatorsByColType['string']
}

export const defaultOperatorForType: Record<TCubeMemberType, BinaryOperator | UnaryOperator> = {
  time: "afterDate",
  string: "equals",
  number: "gt",
  boolean: "equals",
}

export const isUnaryOperator = (op: BinaryOperator | UnaryOperator): boolean => {
	return ["set", "notSet"].includes(op);
}

// Time Dimensions Format
//
// Since grouping and filtering by a time dimension is quite a common case, Cube.js provides a convenient
//  shortcut to pass a dimension and a filter as a timeDimension property.
//
// - dimension: Time dimension name.
// - dateRange: An array of dates with the following format YYYY-MM-DD or in YYYY-MM-DDTHH:mm:ss.SSS format.
//      Values should always be local and in query timezone. Dates in YYYY-MM-DD format are also accepted.
//      Such dates are padded to the start and end of the day if used in start and end of date range interval accordingly.
//      If only one date is specified it's equivalent to passing two of the same dates as a date range. You can also pass
//      a string instead of array with relative date range, for example: last quarter or last 360 days.
// - compareDateRange: An array of date ranges to compare a measure change over previous period
// - granularity: A granularity for a time dimension. It supports the following values second, minute, hour, day,
//      week, month, year. If you pass null to the granularity, Cube.js will only perform filtering by a specified time
//      dimension, without grouping.
//
// {
//   measures: ['Stories.count'],
//   timeDimensions: [{
//     dimension: 'Stories.time',
//     dateRange: ['2015-01-01', '2015-12-31'],
//     granularity: 'month'
//   }]
// }

// You can use compare date range queries when you want to see, for example, how a metric performed over a
//  period in the past and how it performs now. You can pass two or more date ranges where each of them
//  is in the same format as a dateRange
//
// const resultSet = cubejsApi.load({
//   measures: ['Stories.count'],
//   timeDimensions: [{
//     dimension: 'Stories.time',
//     compareDateRange: ['this week', ['2020-05-21', '2020-05-28']],
//     granularity: 'month'
//   }]
// });

// You can also set a relative dateRange, e.g. today, yesterday, last year, or last 6 months.
//
// {
//   measures: ['Stories.count'],
//   timeDimensions: [{
//     dimension: 'Stories.time',
//     dateRange: 'last week',
//     granularity: 'day'
//   }]
// }

// Be aware that e.g. Last 7 days does not include the current date. If you need the current date also you can use
//  from N days ago to now.
//
// {
//   measures: ['Stories.count'],
//   timeDimensions: [{
//     dimension: 'Stories.time',
//     dateRange: 'from 6 days ago to now',
//     granularity: 'day'
//   }]
// }