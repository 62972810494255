import {deleteUserValues, getStoredUserValues} from "../utils/auth-utils";
import cubejs, {CubejsApi} from "@cubejs-client/core";
import {CUBE_BACKEND} from "../config/frontendConfig";
import React from "react";
import {useAuthDispatch, useAuthState} from "../context/auth-context";
import {useCubeMetaDispatch, useCubeMetaState} from "../context/cube-meta-context";
import {SchemaContextName, MetaLike} from "../utils/cube-utils";


export function useSetCubejsAPI(): CubejsApi | undefined {
	const dispatchMeta = useCubeMetaDispatch();
	const dispatchAuth = useAuthDispatch();

	const { currentEnvironment, userPermissionsToken } = useAuthState();
	const { appContextName } = useCubeMetaState();
	const { jwtToken } = getStoredUserValues();

	// Hook return value
	const [cubejsApi, setCubejsApi] = React.useState<CubejsApi | undefined>(undefined);

	/**
	 * Callback for cube 'meta' API error.
	 */
	const onAuthenticationFailed = React.useCallback(
  () => {
		// console.debug(`useSetCubejsAPI > onAuthenticationFailed callback`);
    deleteUserValues();
    dispatchAuth({type: 'setIsNotAuthenticated'});
  },[]);

	/**
	 * 	1. Instantiate cubejsApi object
	 * 	2. Set cubes meta locally (dispatch setCubesMeta)
	 * 	3. Return cubejsApi
	 * 	Condition: 'currentEnvironment', 'userPermissionsToken' and 'appContextName' must all be set
	 */
	React.useEffect(() => {

		/**
		 * Call the cube 'meta' API using the new CubejsApi instance,
		 * then dispatch 'setCubesMeta' action, passing response.
		 *
		 * @param api - new CubejsApi instance
		 * @param metaContextName - SchemaContext used for CubejsApi instance
		 */
		function dispatchSetCubesMeta(api: CubejsApi, metaContextName: SchemaContextName) {
			// console.debug(`useSetCubejsAPI > dispatchSetCubesMeta > api=${api}, metaContextName=${metaContextName}`);
			api.meta().then(resp => {
				dispatchMeta({type: "setCubesMeta", payload: {
						meta: resp as MetaLike,
						metaContextName: metaContextName,
					}});
			}).catch(error => {
				console.error(`useSetCubejsAPI > dispatchSetCubesMeta > cubejsApi.meta() error, error=${error}`)
				if (error.response && error.response.status === 401) {
					onAuthenticationFailed()
				}
			});
		}

		/* main logic */
		if (currentEnvironment && userPermissionsToken && appContextName !== undefined) {
			const api = cubejs(jwtToken, {
				apiUrl: `${CUBE_BACKEND}/cubejs-api/v1`,
				headers: {
					'X-ENV-NAME': currentEnvironment['env_name'],
					'X-SCHEMA-CONTEXT': appContextName === null ? "null" : appContextName,
					'X-ENV-IS-GLOBAL': appContextName === "global" ? "true" : "false",
					'X-USER-PERMISSIONS': userPermissionsToken,
				},
			});
			if (appContextName) {
				dispatchSetCubesMeta(api, appContextName);
			} else {
				dispatchMeta({type: "clearMetaAndMetaContextName"});
			}
			setCubejsApi(api);
		}
	}, [userPermissionsToken, currentEnvironment && currentEnvironment.env_name, appContextName])

	return cubejsApi;
}