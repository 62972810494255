import React from "react";
import {useNavigate} from "react-router-dom";
import {initiateAlert, useAlertDispatch} from "../../context/alert-context";

const NotFound: React.FC = () => {
	const navigate = useNavigate();
	const dispatchAlert = useAlertDispatch();

	React.useEffect(() => {
		console.warn(`NotFound > Navigating to home`);
		initiateAlert(dispatchAlert, 'warning', "Page not found");
		navigate('/');
	});

	return <></>;
}

export default NotFound;