import { Drawer } from "@mui/material";
import { styled } from "@mui/system";
import { keyframes } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const MAX_WIDTH = '374px';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	maxWidth: MAX_WIDTH,
	"& .MuiPaper-root": {
		overflow: "hidden",
		transformOrigin: "left",
		animation: `${enterAnimation} ${300}ms cubic-bezier(0.075, 0.82, 0.165, 1) backwards`,
		display: "flex",
		flexDirection: "column",
		overflowX: "hidden",
		// height: "100%",
		"&:hover .cursor": {
			opacity: 0.1,
		},
		"& .cursor": {
			opacity: 0,
			transition: "opacity 0.2s ease",
		}
	},
	"& .MuiDrawer-paper": {
		zIndex: 1100,
		maxWidth: "var(--sidebarWidth)",
		minWidth: "var(--sidebarWidth)",
		backgroundColor: theme.palette.secondary.dark,
		backdropFilter: "blur(15px)",
	},
	"& .scroll-container": {
		"::-webkit-scrollbar": {
			width: "14px",
		},
		"::-webkit-scrollbar-thumb": {
			backgroundClip: 'context-box',
			border: '4px solid transparent',
			borderRadius: '7px',
			boxShadow: "inset 0 0 0 10px"
		},
		transition: "color .3s ease",
		color: "transparent",
	},
	"&:hover": {
		"& .cursor": {
			opacity: 0.1,
		},
		"& .scroll-container": {
			color: alpha(theme.palette.secondary.contrastText, 0.15),
			"::-webkit-scrollbar-thumb:hover": {
				color: alpha(theme.palette.secondary.contrastText, 0.25),
			}
		},
	},
}));

const enterAnimation = keyframes`
0% {
	opacity: 0;
	transform: translateX(-50%) scaleX(0.5);
}
100% {
	opacity: 1;
	transform: translateX(0) scaleX(1);
}
`;

export default StyledDrawer;
