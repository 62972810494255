import React from "react";
import { Collapse, Typography, List } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StyledFieldList from "../styledComponents/StyledFiledList";
import Member from "./Member";
import theme from "../../../theme/theme";
import { useFieldContext } from "../../../context/providers/FieldContextProvider";
import { TCubeMember } from "@cubejs-client/core";

interface Props {
	cube;
	handleClickCube: (key: string) => void;
	expanded: boolean;
	grouped: boolean;
	filterActive: (member: TCubeMember) => boolean | undefined;
}

const FieldList: React.FC<Props> = ({ cube, handleClickCube, expanded, grouped, filterActive }: Props) => {
	const { isInFields } = useFieldContext();

	return (
		<>
			<StyledFieldList
				expanded={expanded}
				grouped={grouped}
				onClick={(e) => {
					if (!grouped) return
					handleClickCube(cube.name);
				}}
			>
				<Typography sx={{ fontFamily: "Outfit", fontSize: "14px" }}>{cube.title}</Typography>
				{(expanded || !grouped) ? <ExpandMoreIcon key={"expand-icon"} /> : <ChevronRightIcon key={"collapse-icon"} />}
			</StyledFieldList>
			{grouped ? (
				<Collapse in={expanded || !grouped} unmountOnExit sx={{ paddingLeft: theme.spacing(2.5) }} easing={theme.transitions.easing.sharp} timeout={150}>
				<List component="div" disablePadding key={`cube-${cube.name}-list`} sx={{ paddingRight: "6px" }}>
					{cube.dimensions.map((dimension) => (
						<Member
							cube={cube}
							member={dimension}
							isActiveField={isInFields(dimension.name)}
							isFilterActive={filterActive(dimension)}
							memberType="dimensions"
							key={dimension.name}
						/>
					))}
					{cube.measures.map((measure) => (
						<Member
							cube={cube}
							member={measure}
							isActiveField={isInFields(measure.name)}
							isFilterActive={filterActive(measure)}
							memberType="measures"
							key={measure.name}
						/>
					))}
				</List>
			</Collapse>
			) : (
				<List component="div" disablePadding key={`cube-${cube.name}-list`} sx={{ paddingRight: "6px" }}>
					{cube.dimensions.map((dimension) => (
						<Member
							cube={cube}
							member={dimension}
							isActiveField={isInFields(dimension.name)}
							isFilterActive={filterActive(dimension)}
							memberType="dimensions"
							key={dimension.name}
						/>
					))}
					{cube.measures.map((measure) => (
						<Member
							cube={cube}
							member={measure}
							isActiveField={isInFields(measure.name)}
							isFilterActive={filterActive(measure)}
							memberType="measures"
							key={measure.name}
						/>
					))}
				</List>
			)}
			{/* <Collapse in={expanded || !grouped} unmountOnExit sx={{ paddingLeft: theme.spacing(2.5) }} easing={theme.transitions.easing.sharp} timeout={150}>
				<List component="div" disablePadding key={`cube-${cube.name}-list`} sx={{ paddingRight: "6px" }}>
					{cube.dimensions.map((dimension) => (
						<Member
							cube={cube}
							member={dimension}
							isActiveField={isInFields(dimension.name)}
							isFilterActive={filterActive(dimension)}
							memberType="dimensions"
							key={dimension.name}
						/>
					))}
					{cube.measures.map((measure) => (
						<Member
							cube={cube}
							member={measure}
							isActiveField={isInFields(measure.name)}
							isFilterActive={filterActive(measure)}
							memberType="measures"
							key={measure.name}
						/>
					))}
				</List>
			</Collapse> */}
		</>
	);
};

export default FieldList;
