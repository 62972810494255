import {BinaryFilter, Filter, PivotConfig, Query, ResultSet, UnaryFilter} from "@cubejs-client/core";
import {SchemaContextName} from "./cube-utils";
import {ReportsEnvironmentConfig} from "./auth-utils";
import {lighten, Theme} from "@mui/material/styles";
import {Format, formatString} from "../components/DashboardPanel/PanelComponents/panelUtils";
import { State } from "../context/dashboard-context"
import {format} from "date-fns";

export type DashboardDef = {
  key: string
  contextName: SchemaContextName
  title: string
  pinnedFilters: PinnedFilterDef[]
  panels: DashboardPanelDef[][]
  preconditions?: DashboardPrecondition[]
}

export type DashboardVizType = "single-figure" | "bar-graph" | "line-chart" | "table" | "scatter-chart" | "stacked-area-chart" | "pie-chart" | "map";

export type DashboardPanelDef = {
  title: string
  vizType: DashboardVizType
  baseQuery: Query
  width: number
  valueFormat?: formatString
  getPivotConfig?: (rs: ResultSet) => PivotConfig | undefined,
  vizDisplayProps?: {
    axisFormat?: Format
    dateFormatOverride?: string
    xAxisOrientation?: "top" | "bottom"
    layout?: "horizontal" | "vertical"  // bar-graph
    axisWidth?: number
    height?: number
    map?: {
      disablePanAndClick?: boolean
    }
    fieldsOrder?: string[]
  }
}

export type PinnedFilterDef = {
  member: string
  label: string
  type: "time" | "string" | "number" | "boolean"
  defaultFilter?: BinaryFilter | UnaryFilter
  visibilityCondition?: (envConfig: ReportsEnvironmentConfig) => boolean
}

export type DashboardPrecondition = {
  condition: (dashboardState: State) => boolean,
  message: string
}

export const DashboardKeys = {
  CriticalDates: "critical-dates",
  Portfolio: "portfolio",
  PortfolioRent: "portfolio-rent",
  Property: "property",
  Abstraction: "abstraction",
  DevTesting: "dev-testing",
};

export const DashboardsConfig: Record<string, DashboardDef> = {
  [DashboardKeys.CriticalDates]: {
    key: DashboardKeys.CriticalDates,
    contextName: "critical-dates",
    title: "Critical Dates",
    panels: [
      [
        {
          title: "Critical Dates by Property",
          vizType: "scatter-chart",
          width: 12,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: ["Tenants.propertyName", "Tenants.name", "CriticalDates.criticalDateType", "CriticalDates.criticalDate"],
            timeDimensions: [],
            filters: [
              // {
              //   member: "CriticalDates.criticalDate",
              //   operator: "inDateRange",
              //   values: ["2022-08-01", "2023-02-28"],
              // }
            ],
            order: [
              ["CriticalDates.criticalDate", "asc"],
            ],
            segments: [],
          },
        },
      ],
      [
        {
          title: "Upcoming Critical Dates",
          vizType: "table",
          width: 12,
          baseQuery: {
            measures: [],
            dimensions: [
              "CriticalDates.criticalDate",
              "CriticalDates.criticalDateType",
              "Tenants.name",
              "Tenants.propertyName",
              "Tenants.propertyAddressState",
              "Tenants.totalLeasableArea"
            ],
            timeDimensions: [
              // {
              //   dimension: "CriticalDates.criticalDate",
              //   dateRange: "next 12 months",
              //   granularity: "day"
              // }
            ],
            filters: [
              // {
              //   member: "CriticalDates.criticalDate",
              //   operator: "inDateRange",
              //   values: ["2022-08-01", "2023-02-28"],
              // }
            ],
            order: [
              ["CriticalDates.criticalDate", "asc"],
            ],
            segments: [],
          },
           vizDisplayProps: {
             fieldsOrder: [
               "CriticalDates.criticalDate",
               "CriticalDates.criticalDateType",
               "Tenants.name",
               "Tenants.propertyName",
               "Tenants.propertyAddressState",
               "Tenants.totalLeasableArea"
             ]
          }
          // baseQuery: {
          //   measures: [],
          //   dimensions: [
          //     "CriticalDates.criticalDateType",
          //     "Tenants.name",
          //     "Properties.name",
          //     "Tenants.propertyAddressState",
          //     "Tenants.totalLeasableArea"
          //   ],
          //   timeDimensions: [
          //     {
          //       "dimension": "CriticalDates.criticalDate",
          //       "dateRange": "next 12 months",
          //       "granularity": "day"
          //     }
          //   ],
          //   filters: [
          //     {
          //       "member": "CriticalDates.criticalDate",
          //       "operator": "afterDate",
          //       "values": ["2022-08-01"]
          //     },
          //     {
          //       "member": "Tenants.propertyName",
          //       "operator": "contains",
          //       "values": ["2020 K", "Century II"]
          //     }
          //   ],
          //   order: [
          //     ["CriticalDates.criticalDate", "asc"]
          //   ],
          //   segments: []
          // },
        },
      ]
    ],
    pinnedFilters: [
      {
        member: "CriticalDates.criticalDate",
        label: "Critical Date",
        type: "time",
        defaultFilter: {
          member: "CriticalDates.criticalDate",
          operator: "inDateRange",
          values: ["2023-05-01", "2023-11-30"],
        }
      },
      {
        member: "CriticalDates.criticalDateType",
        label: "Date Type",
        type: "string",
      },
      {
        member: "Tenants.propertyName",
        label: "Property",
        type: "string",
      },
      {
        member: "Tenants.propertyAddressState",
        label: "State",
        type: "string",
      },
      {
        member: "Tenants.leasePrimaryUse",
        label: "Primary Use",
        type: "string",
      },
      {
        member: "Tenants.totalLeasableArea",
        label: "Leasable Area",
        type: "number",
      },
      {
        member: "Projects.name",
        label: "Project Name",
        type: "string",
        visibilityCondition: envConfig => envConfig.org_ids.length > 1
      }
    ],
  },
  // [DashboardKeys.Portfolio]: {
  //   key: DashboardKeys.Portfolio,
  //   contextName: "portfolio",
  //   title: "Portfolio",
  //   panels: [
  //     [
  //       {
  //         title: "Total Leases",
  //         vizType: "single-figure",
  //         width: 2,
  //         baseQuery: {
  //           measures: ["Tenants.count"],
  //           dimensions: [],
  //           filters: [],
  //           order: [],
  //           segments: [],
  //         }
  //       },
  //       {
  //         title: "Total Properties",
  //         vizType: "single-figure",
  //         width: 2,
  //         baseQuery: {
  //           measures: ["Properties.count"],
  //           dimensions: [],
  //           filters: [],
  //           order: [],
  //           segments: [],
  //         },
  //       },
  //       {
  //         title: "Total Leasable Area",
  //         vizType: "single-figure",
  //         valueFormat: "area",
  //         width: 2,
  //         baseQuery: {
  //           measures: ["Tenants.sumTotalLeasableArea"],
  //           dimensions: [],
  //           filters: [],
  //           order: [],
  //           segments: [],
  //         },
  //       },
  //       {
  //         title: "Total Rent (2022)",
  //         vizType: "single-figure",
  //         width: 2,
  //         valueFormat: "currency",
  //         baseQuery: {
  //           measures: ["Tenants.sumTotalLeasableArea"],
  //           dimensions: [],
  //           filters: [],
  //           order: [],
  //           segments: [],
  //         },
  //       },
  //       {
  //         title: "Vacancy %",
  //         vizType: "single-figure",
  //         width: 2,
  //         valueFormat: "percent",
  //         baseQuery: {
  //           measures: [],
  //           dimensions: [],
  //           filters: [],
  //           order: [],
  //           segments: [],
  //         },
  //       },
  //       // {
  //       //   title: "Lease Commencements and Expirations",
  //       //   vizType: "line-chart",
  //       //   width: 10,
  //       //   baseQuery: [
  //       //     {
  //       //       measures: ["ValuesLeaseCommencementDate.count"],
  //       //       dimensions: [],
  //       //       timeDimensions: [
  //       //         {
  //       //           dimension: "ValuesLeaseCommencementDate.date",
  //       //           dateRange: ["2010-01-01", "2030-12-31"],
  //       //           granularity: "year"
  //       //         },
  //       //       ]
  //       //     },
  //       //     {
  //       //       measures: ["ValuesExpirationDate.count"],
  //       //       dimensions: [],
  //       //       timeDimensions: [
  //       //         {
  //       //           dimension: "ValuesExpirationDate.date",
  //       //           dateRange: ["2010-01-01", "2030-12-31"],
  //       //           granularity: "year"
  //       //         }
  //       //       ]
  //       //     },
  //       //   ]
  //       // },
  //     ],
  //   ],
  //   pinnedFilters: [
  //     {
  //       member: "Tenants.propertyAddressState",
  //       label: "State",
  //       type: "number",
  //     },
  //     {
  //       member: "Tenants.totalLeasableArea",
  //       label: "Total Leasable Area",
  //       type: "number",
  //     }
  //   ],
  // },
  [DashboardKeys.PortfolioRent]: {
    key: DashboardKeys.PortfolioRent,
    contextName: "rent",
    title: "Portfolio",
    panels: [
      [
        // row 1 - single figures
        {
          title: "Total Properties",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Properties.count"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          }
        },
        {
          title: "Total Tenants",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: "Total Leased Area",
          vizType: "single-figure",
          valueFormat: "area",
          width: 2,
          baseQuery: {
            measures: ["Tenants.sumTotalLeasableArea"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: `Payments Total - ${format(new Date(), "MMM yyyy")}`,
          vizType: "single-figure",
          width: 3,
          valueFormat: "currency",
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            dimensions: [],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "compareDateRange": ["this month", "last month"],
                "granularity": 'month',
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: `Payments Total - ${format(new Date(), "yyyy")}`,
          vizType: "single-figure",
          width: 3,
          valueFormat: "currency",
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            dimensions: [],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "compareDateRange": ["this year", "last year"],
                "granularity": "year",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
        },
        // // vacancy - future (property fields)
        // {
        //   title: "Vacancy Percentage",
        //   vizType: "single-figure",
        //   width: 2,
        //   valueFormat: "percent",
        //   baseQuery: {
        //     measures: ["Properties.vacancyPct"],
        //     dimensions: [],
        //     timeDimensions: [],
        //     filters: [],
        //     order: [],
        //     segments: [],
        //   },
        // },

        // row 2
        {
          title: "Properties Map",
          vizType: "map",
          width: 6,
          baseQuery: {
            measures: [
              "RentPayments.totalAmount",
              "RentPayments.avgAmountPerSf",
              "Properties.currentAvgRentPsf",
              "Properties.sumTotalCurrentAnnualRent",
              "Properties.sumTotalLeasableArea",
              "Tenants.count",
            ],
            dimensions: [
              "Properties.id",
              "Properties.name",
              "Properties.location",
              "Properties.geoLongitude",
              "Properties.geoLatitude",
              "Properties.address",
            ],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: `Top Earning Properties - ${format(new Date(), "yyyy")}`,
          vizType: "table",
          width: 6,
          baseQuery: {
            measures: ["RentPayments.totalAmount", "RentPayments.avgAmountPerSf"],
            dimensions: ["Properties.name", "Properties.totalLeasableArea"],
            filters: [],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "granularity": "year",
                "dateRange": "this year",
              }
            ],
            order: [
              ["RentPayments.totalAmount", "desc"],
            ],
            segments: [],
          },
          vizDisplayProps: {
            fieldsOrder: [
              "Properties.name",
              "Properties.totalLeasableArea",
              "RentPayments.totalAmount",
              "RentPayments.avgAmountPerSf"
            ],
          }
        },

        // row 3
        {
          title: "Projected Payments By Use Type",
          vizType: "stacked-area-chart",
          width: 6,
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            // dimensions: ["Tenants.propertyAddressState"],
            dimensions: ["Tenants.leasePrimaryUse"],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "granularity": "quarter",
                "dateRange": "next 5 years",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            axisFormat: { x: "date - quarter", y: "currency" },
          }
        },
        {
          title: "Projected Payments By Use Type (Avg. PSF)",
          vizType: "line-chart",
          width: 6,
          baseQuery: {
            measures: ["RentPayments.avgAmountPerSf"],
            dimensions: ["Tenants.leasePrimaryUse"],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "granularity": "quarter",
                "dateRange": "next 5 years",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
          getPivotConfig: (rs) => {
            const dimensionsNames = Object.keys(rs.annotation().dimensions);
            if (dimensionsNames.length > 1) {
              return {
                x: [dimensionsNames[0]],
                y: [...dimensionsNames.slice(1), "measures"],
              };
            }
			    	return undefined;
          },
          vizDisplayProps: {
            axisFormat: {x: "date - quarter", y: "currency"},
          },
        },

        // row 4
        {
          title: `Rent by State (${format(new Date(), "yyyy")})`,
          vizType: "bar-graph",
          width: 6,
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            dimensions: ["Tenants.propertyAddressState", "Tenants.leasePrimaryUse"],
            filters: [],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "dateRange": "this year",
              }
            ],
            order: [
              ["RentPayments.totalAmount", "desc"],
            ],
            segments: [],
          },
          getPivotConfig: (rs) => {
            const dimensionsNames = Object.keys(rs.annotation().dimensions);
            if (dimensionsNames.length > 1) {
              return {
                x: [dimensionsNames[0]],
                y: [...dimensionsNames.slice(1), "measures"],
              };
            }
            return undefined;
          },
          vizDisplayProps: {
            layout: "vertical",
            axisFormat: { x: "string", y: "currency" },
            axisWidth: 75,
          },
        },
        {
          title: `Rent By Use Type (${format(new Date(), "yyyy")})`,
          vizType: "pie-chart",
          width: 3,
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            // measures: ["RentPayments.totalAmount", "RentPeriods.avgPsf"],
            dimensions: ["Tenants.leasePrimaryUse"],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "dateRange": "this year",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            axisFormat: { x: "string", y: "currency" },
          },
        },
        {
          title: "Area By Use Type",
          vizType: "pie-chart",
          width: 3,
          baseQuery: {
            measures: ["Tenants.sumTotalLeasableArea"],
            // measures: ["RentPayments.totalAmount", "RentPeriods.avgPsf"],
            dimensions: ["Tenants.leasePrimaryUse"],
            timeDimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            axisFormat: { x: "string", y: "area" },
          },
        },
      ],
    ],
    pinnedFilters: [
      {
        member: "RentPayments.paymentCategory",
        label: "Payment Category",
        type: "string",
      },
      {
        member: "Tenants.propertyAddressState",
        label: "State",
        type: "string",
      },
      {
        member: "Tenants.leasePrimaryUse",
        label: "Primary Use",
        type: "string",
      },
      {
        member: "Properties.name",
        label: "Property",
        type: "string",
      },
    ],
  },
  [DashboardKeys.Property]: {
    key: DashboardKeys.Property,
    contextName: "rent",
    title: "Property",
    panels: [
      [
        // row 1 - single figures
        {
          title: "Property Name",
          vizType: "single-figure",
          valueFormat: "string",
          width: 5,
          baseQuery: {
            measures: [],
            dimensions: ["Properties.name"],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: "Tenants",
          vizType: "single-figure",
          valueFormat: "number",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: "Total Leased Area",
          vizType: "single-figure",
          valueFormat: "area",
          width: 2,
          baseQuery: {
            measures: ["Tenants.sumTotalLeasableArea"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: `Payments Total - ${format(new Date(), "yyyy")}`,
          vizType: "single-figure",
          width: 3,
          valueFormat: "currency",
          baseQuery: {
            measures: ["RentPayments.totalAmount"],
            dimensions: [],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "compareDateRange": ["this year", "last year"],
                "granularity": "year",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
        },
        // // vacancy - future (property fields)
        // {
        //   title: "Vacancy Percentage",
        //   vizType: "single-figure",
        //   valueFormat: "percent",
        //   width: 2,
        //   baseQuery: {
        //     measures: ["Properties.vacancyPct"],
        //     dimensions: [],
        //     filters: [],
        //     order: [],
        //     segments: [],
        //   },
        // },

        // row 2
        {
          title: "Properties Map",
          vizType: "map",
          width: 5,
          baseQuery: {
            measures: [
              "RentPayments.totalAmount",
              "RentPayments.avgAmountPerSf",
              "Properties.currentAvgRentPsf",
              "Properties.sumTotalCurrentAnnualRent",
              "Properties.sumTotalLeasableArea",
              "Tenants.count",
            ],
            dimensions: [
              "Properties.id",
              "Properties.name",
              "Properties.location",
              "Properties.geoLongitude",
              "Properties.geoLatitude",
              "Properties.address",
            ],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            map: {
              disablePanAndClick: true,
            }
          }
        },
        {
          title: `Top Earning Tenants - ${format(new Date(), "yyyy")}`,
          vizType: "table",
          width: 7,
          baseQuery: {
            dimensions: ["Tenants.name", "Tenants.totalLeasableArea"],
            measures: ["RentPayments.totalAmount", "RentPayments.avgAmountPerSf"],
            timeDimensions: [
              {
                "dimension": "RentPayments.paymentDate",
                "granularity": "year",
                "dateRange": "this year",
              }
            ],
            filters: [],
            order: [
              ["RentPayments.totalAmount", "desc"],
            ],
            segments: [],
          },
          vizDisplayProps: {
            fieldsOrder: [
              "Tenants.name",
              "Tenants.totalLeasableArea",
              "RentPayments.totalAmount",
              "RentPayments.avgAmountPerSf",
            ],
          }
        },

        // row 3
        {
          title: "Current Monthly Rent Breakdown",
          vizType: "bar-graph",
          width: 6,
          // height: 600,
          baseQuery: {
            measures: ["Tenants.totalCurrentMonthlyRent"],
            dimensions: ["Tenants.name"],
            timeDimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            layout: "vertical",
            axisFormat: { x: "string", y: "currency" },
          },
        },
        {
          title: "Current Rent PSF Breakdown",
          vizType: "bar-graph",
          width: 6,
          // height: 600,
          baseQuery: {
            measures: ["Tenants.avgCurrentPSFRent"],
            dimensions: ["Tenants.name"],
            timeDimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            axisFormat: { x: "string", y: "currency" },
            layout: "vertical",
            xAxisOrientation: "top",
          },
        },

        // // row 3+
        // {
        //   title: "Projected Payments",
        //   vizType: "stacked-area-chart",
        //   width: 6,
        //   baseQuery: {
        //     measures: ["RentPayments.totalAmount"],
        //     // dimensions: ["Tenants.propertyAddressState"],
        //     dimensions: ["Tenants.name"],
        //     timeDimensions: [
        //       {
        //         "dimension": "RentPayments.paymentDate",
        //         "granularity": "quarter",
        //         "dateRange": "next 2 years",
        //       }
        //     ],
        //     filters: [],
        //     order: [],
        //     segments: [],
        //   },
        //   vizDisplayProps: {
        //     axisFormat: { x: "date - quarter", y: "currency" },
        //   }
        // },
        // {
        //   title: "Projected Payments (Avg. PSF)",
        //   vizType: "line-chart",
        //   width: 6,
        //   baseQuery: {
        //     measures: ["RentPayments.avgAmountPerSf"],
        //     dimensions: ["Tenants.name"],
        //     timeDimensions: [
        //       {
        //         "dimension": "RentPayments.paymentDate",
        //         "granularity": "quarter",
        //         "dateRange": "next 2 years",
        //       }
        //     ],
        //     filters: [],
        //     order: [],
        //     segments: [],
        //   },
        //   getPivotConfig: (rs) => {
        //     const dimensionsNames = Object.keys(rs.annotation().dimensions);
        //     if (dimensionsNames.length > 1) {
        //       return {
        //         x: [dimensionsNames[0]],
        //         y: [...dimensionsNames.slice(1), "measures"],
        //       };
        //     }
			   //  	return undefined;
        //   },
        //   vizDisplayProps: {
        //     axisFormat: {x: "date - quarter", y: "currency"},
        //   },
        // },

        // row 4
        {
          title: "Expiring Leases",
          vizType: "bar-graph",
          width: 12,
          // height: 600,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            timeDimensions: [
              {
                dimension: "Tenants.leaseExpirationDate",
                granularity: "quarter",
                dateRange: "next 36 months",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
          getPivotConfig: (rs) => {
            // return {
            //   fillMissingDates: true
            // }

            const dimensionsNames = Object.keys(rs.annotation().dimensions);
            if (dimensionsNames.length > 1) {
              return {
                x: [dimensionsNames[0]],
                y: [...dimensionsNames.slice(1), "measures"],
                fillMissingDates: true
              };
            }
			    	return undefined;
          },
          vizDisplayProps: {
            dateFormatOverride: "MM/yy",
            xAxisOrientation: "bottom",
            layout: "horizontal",
            axisFormat: { x: "date - month", y: "number" },
          },
        },
      ],
    ],
    pinnedFilters: [
      {
        member: "Properties.name",
        label: "Property",
        type: "string",
      },
      {
        member: "RentPayments.paymentCategory",
        label: "Payment Category",
        type: "string",
      },
    ],
    preconditions: [
      {
        condition: (dashboardState: State) => Boolean(dashboardState.dashboardFilters.find(f => f.member === "Properties.name")),
        message: "Please select a property."
      },
      {
        condition: (dashboardState: State) => {
          const filter = dashboardState.dashboardFilters.find(f => f.member === "Properties.name");
          return Boolean(filter && filter.values && !(filter.values.length > 1));
        },
        message: "Please select exactly one property."
      },
    ],
  },
  [DashboardKeys.Abstraction]: {
    key: DashboardKeys.Abstraction,
    contextName: "projects",
    title: "Abstraction",
    panels: [
      [
        // row 1 - single figures
        {
          title: "Total Properties",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Properties.count"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          },
        },
        {
          title: "Total Abstracts",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [],
            order: [],
            segments: [],
          }
        },
        {
          title: "Reviewed Abstracts",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [
              {
                member: "Tenants.abstractionStatus",
                operator: "equals",
                values: ["Reviewed", "Finalized"]
              }
            ],
            order: [],
            segments: [],
          }
        },
        {
          title: "In Progress Abstracts",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [
              {
                member: "Tenants.abstractionStatus",
                operator: "equals",
                values: ["In Progress"]
              }
            ],
            order: [],
            segments: [],
          }
        },
        {
          title: "Pending Abstracts",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [
              {
                member: "Tenants.abstractionStatus",
                operator: "equals",
                values: ["Pending"]
              }
            ],
            order: [],
            segments: [],
          }
        },
        {
          title: "Blocked Abstracts",
          vizType: "single-figure",
          width: 2,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: [],
            filters: [
              {
                member: "Tenants.abstractionStatus",
                operator: "equals",
                values: ["Blocked"]
              }
            ],
            order: [],
            segments: [],
          }
        },

        // row 2
        {
          title: "Abstracts by Property",
          vizType: "bar-graph",
          width: 12,
          baseQuery: {
            measures: ["Tenants.count"],
            dimensions: ["Properties.name", "Tenants.abstractionStatus"],
            filters: [],
            order: [],
            segments: [],
          },
          getPivotConfig: (rs) => {
            const dimensionsNames = Object.keys(rs.annotation().dimensions);
            if (dimensionsNames.length > 1) {
              return {
                x: [dimensionsNames[0]],
                y: [...dimensionsNames.slice(1), "measures"],
              };
            }
            return undefined;
          },
          vizDisplayProps: {
            axisWidth: 300,
            height: 400,
            axisFormat: { x: "string", y: "number" },
            layout: "vertical",
            xAxisOrientation: "bottom",
          }
        },

        // row 3
        {
          title: "Weekly Uploads",
          vizType: "stacked-area-chart",
          width: 6,
          baseQuery: {
            measures: ["Tenants.count"],
            // dimensions: ["Tenants.insertionDate"],
            timeDimensions: [
              {
                "dimension": "Tenants.insertionDate",
                "granularity": "week",
                "dateRange": "last 12 months",
              }
            ],
            filters: [],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            dateFormatOverride: "MM/yy",
            axisFormat: { x: "date - week", y: "number" },
          },
        },
        {
          title: "Blocked Abstracts",
          vizType: "table",
          width: 6,
          baseQuery: {
            measures: ["Document.count"],
            dimensions: ["Tenants.name", "Properties.name", "Tenants.insertionDate"],
            timeDimensions: [],
            filters: [
              {
                member: "Tenants.abstractionStatus",
                operator: "equals",
                values: ["Blocked"],
              }
            ],
            order: [],
            segments: [],
          },
          vizDisplayProps: {
            fieldsOrder: [
              "Tenants.name",
              "Properties.name",
              "Tenants.insertionDate",
            ]
          }
        },
      ],
    ],
    pinnedFilters: [
      {
        member: "Tenants.abstractionStatus",
        label: "Abstraction Status",
        type: "string",
      },
      {
        member: "Properties.name",
        label: "Property Folder",
        type: "string",
      },
      {
        member: "Tenants.insertionDate",
        label: "Creation Date",
        type: "time",
      },
      {
        member: "Tenants.abstractionStatusChangedDate",
        label: "Abstraction Status Changed Date",
        type: "time",
      },
    ],
  },
  [DashboardKeys.DevTesting]: {
    key: DashboardKeys.DevTesting,
    contextName: "rent",
    title: "DEV TESTING",
    panels: [
              [
                  // Test different value formats for single-figure panels
                  {
                      title: "Test Value Format: Number",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "number",
                      baseQuery: {
                          measures: ["Properties.count"],
                          dimensions: [],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: Percent",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "percent",
                      baseQuery: {
                          measures: ["Properties.vacancyPct"],
                          dimensions: [],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: Currency",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "currency",
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: [],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: Area",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "area",
                      baseQuery: {
                          measures: ["Tenants.sumTotalLeasableArea"],
                          dimensions: [],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: date - month",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "date - month",
                      baseQuery: {
                          measures: [],
                          dimensions: [],
                          timeDimensions: [
                              {
                                  dimension: "Tenants.leaseExpirationDate",
                                  granularity: "month",
                              },
                          ],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: string",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "string",
                      baseQuery: {
                          measures: [],
                          dimensions: [],
                          timeDimensions: [
                              {
                                  dimension: "Tenants.leaseExpirationDate",
                                  granularity: "month",
                              },
                          ],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  // Single-figure panels USING TREND CHIP
                  {
                      title: "Test Value Format: Number",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "number",
                      baseQuery: {
                          measures: ["RentPayments.count"],
                          dimensions: [],
                          timeDimensions: [
                              {
                                  dimension: "RentPayments.paymentDate",
                                  compareDateRange: ["this year", "last year"],
                                  granularity: "year",
                              },
                          ],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  {
                      title: "Test Value Format: Currency",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "currency",
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: [],
                          timeDimensions: [
                              {
                                  dimension: "RentPayments.paymentDate",
                                  compareDateRange: ["this month", "last month"],
                                  granularity: "month",
                              },
                          ],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
                  // Test long title
                  {
                      title: "Test Value Format: Number Test Value Format: Number Test Value Format: Number Test Value Format: Number Test Value Format: Number",
                      vizType: "single-figure",
                      width: 2,
                      valueFormat: "number",
                      baseQuery: {
                          measures: ["Properties.count"],
                          dimensions: [],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                  },
              ],
              [
                  // Test BarChart with different layouts
                  {
                      title: "Test BarChart: Horizontal Layout",
                      vizType: "bar-graph",
                      width: 6,
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: ["Tenants.propertyAddressState"],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                      vizDisplayProps: {
                          layout: "horizontal",
                          axisFormat: { x: "string", y: "currency" },
                      },
                  },
                  {
                      title: "Test BarChart: Vertical Layout",
                      vizType: "bar-graph",
                      width: 6,
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: ["Tenants.propertyAddressState"],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                      vizDisplayProps: {
                          layout: "vertical",
                          axisFormat: { x: "string", y: "currency" },
                      },
                  },
              ],
              [
                  // Test BarChart with different layouts (overflow)
                  {
                      title: "Test BarChart: Scroll horizontal",
                      vizType: "bar-graph",
                      width: 6,
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: ["Tenants.propertyName"],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                      vizDisplayProps: {
                          layout: "horizontal",
                          axisFormat: { x: "string", y: "currency" },
                      },
                  },
                  {
                      title: "Test BarChart: Scroll vertical",
                      vizType: "bar-graph",
                      width: 6,
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: ["Tenants.propertyName"],
                          filters: [],
                          order: [],
                          segments: [],
                      },
                      vizDisplayProps: {
                          layout: "vertical",
                          axisFormat: { x: "string", y: "currency" },
                      },
                  },
              ],
              [
                  {
                      title: `Test Rent by State (${format(new Date(), "yyyy")})`,
                      vizType: "bar-graph",
                      width: 6,
                      baseQuery: {
                          measures: ["RentPayments.totalAmount"],
                          dimensions: ["Tenants.propertyAddressState", "Tenants.leasePrimaryUse"],
                          filters: [],
                          timeDimensions: [
                              {
                                  dimension: "RentPayments.paymentDate",
                                  dateRange: "this year",
                              },
                          ],
                          order: [["RentPayments.totalAmount", "desc"]],
                          segments: [],
                      },
                      getPivotConfig: (rs) => {
                          const dimensionsNames = Object.keys(rs.annotation().dimensions);
                          if (dimensionsNames.length > 1) {
                              return {
                                  x: [dimensionsNames[0]],
                                  y: [...dimensionsNames.slice(1), "measures"],
                              };
                          }
                          return undefined;
                      },
                      vizDisplayProps: {
                          layout: "horizontal",
                          axisFormat: { x: "string", y: "currency" },
                          axisWidth: 75,
                      },
                  },
              ],
          ],
          pinnedFilters: [],
      },
}


export function getColorFromContext(theme: Theme, context: SchemaContextName) {
  switch(context) {
    case 'default':
      return theme.palette.contextDefault.main
    case 'critical-dates':
      return theme.palette.contextCriticalDates.main
    case 'rent':
      return theme.palette.contextRent.main
    case 'portfolio':
      return theme.palette.contextPortfolio.main
    case 'global':
      return theme.palette.contextGlobal.main
    case 'projects':
      return theme.palette.contextProjects.main
    default:
      return "grey"
  }
}


export function stringifyFilters(filters: (BinaryFilter | UnaryFilter)[]): string {
  let retVal = "";
  filters.forEach(filter => {
    if (filter.member) {
      retVal += "-" + filter.member;
    }
    if (filter.operator) {
      retVal += "-" + filter.operator;
    }
    if (filter.values) {
      let valuesStr = "";
      filter.values.forEach((value, idx) => {
        if (idx > 0) {
          valuesStr += "-";
        }
        valuesStr += value
      })
      retVal += "-" + valuesStr;
    }
  })
  return retVal;
}

export function calculateFill(idx: number, total: number, paletteIdx: number) {
  const palette = COLOR_PALETTES[paletteIdx];
  const increase = palette.length / total;
  const colorIdx = Math.round(idx * increase);
  return palette[colorIdx % palette.length];
}

export const COLOR_PALETTES: string[][] = [
  ["#50a5dd", "#599ad1", "#638ec6", "#6c82ba", "#7577ae", "#7f6ba3", "#886097", "#91548b", "#9b4980", "#a43e74", "#ad3268", "#b7275d", "#c01b51"],  // gradient blue to red
  ["#2d83d8", "#3e86cd", "#4f89c2", "#608db7", "#7190ad", "#8293a2", "#939697", "#a4998c", "#b59c81", "#c6a077", "#d7a36c", "#e8a661", "#f9a956"],  // gradient blue to orange
  ["#1984c5", "#22a7f0", "#63bff0", "#a7d5ed", "#e2e2e2", "#e1a692", "#de6e56", "#e14b31", "#c23728"],  // ** blue to red
  ["#3d6f8e", "#4a7692", "#587d97", "#65849b", "#728b9f", "#7f92a4", "#8d98a8", "#9a9fac", "#a7a6b1", "#b4adb5", "#c1b4b9", "#cfbbbe", "#dcc2c2"],
  ["#374f81", "#405b81", "#496781", "#527380", "#5b7f80", "#648b80", "#6d9780", "#75a27f", "#7eae7f", "#87ba7f", "#90c67e", "#99d27e", "#a2de7e"],
  ["#5d39eb", "#683be0", "#733cd4", "#7e3ec8", "#893fbd", "#9441b1", "#a042a6", "#ab449b", "#b6458f", "#c14784", "#cc4878", "#d74a6d", "#e24b61"],  // purple/blue to pink/orange
  ["#a36c4e", "#b87c5c", "#c78c6a", "#d69c78", "#e5ac87", "#f4bc95", "#e8b79d", "#dcaea4", "#d0a4ac", "#c498b4", "#b88ebc", "#ac84c4", "#a07acC"],  // rustic charm
  ["#dca577", "#e3af88", "#ebc799", "#f3e0aa", "#fbe7bb", "#fde0b3", "#ffc9a3", "#ffb291", "#ff9b7f", "#ff846d", "#ff6d5b", "#ff5649", "#ff3f37"],  // desert sunset
  ["#ad8760", "#c09670", "#d1a480", "#e0b390", "#efc2a1", "#f2c6a7", "#f5caa9", "#f8ceab", "#fbd2ae", "#fdd5b0", "#ffd9b3", "#ffdcb5", "#ffdfb8"],  // earth tones
  ["#826c8e", "#8f7c94", "#9b8d9b", "#a69ea1", "#b1aea8", "#bccfb0", "#c8e0b7", "#d3f1bf", "#def3c8", "#e9f5d1", "#f5f7da", "#ffffff"], // muted purple to white
  ["#327c4c", "#3f8259", "#4c8866", "#588e72", "#65957f", "#729b8c", "#7fa199", "#8ca7a6", "#99adb3", "#a5b4c0", "#b2bacc", "#bfc0d9", "#ccc6e6"],  // green to faded purple
  ["#26b19d", "#2ea6a0", "#369ba3", "#3e91a6", "#4786a9", "#4f7bac", "#5771b0", "#5f66b3", "#675bb6", "#6f50b9", "#7845bc", "#803bbf", "#8830c2"],  // gradient green to punk
  ["#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#d7658b", "#c80064"],  // light blue to pink (pink foam)
  ["#00796b", "#00897b", "#009688", "#26a69a", "#4db6ac", "#80cbc4", "#a7ffeb", "#b2dfdb", "#c8e6c9", "#e8f5e9", "#f1f8e9", "#fce4ec", "#f5f5f5"], // teal to pink
  ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"], // spring pastels
  ["#3a3d3e", "#4a4d4e", "#5b5d5e", "#6c6e6f", "#7d7f80", "#8e9091", "#9f9fa1", "#b0b1b2", "#c1c1c2", "#d2d3d4", "#e3e4e5", "#f4f5f6", "#ffffff"], // grayscale to white
  ["#4050b5", "#754cb2", "#9b46aa", "#ba419e", "#d33f8f", "#e5447d", "#f24f6b", "#f86059", "#f97347", "#f58737"]
];
