import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '30px',
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&.numeric': {
        '& input': {
          paddingTop: '7px',
          paddingBottom: '7px',
        }
      },
    },
    input: {
      // height: '30px',
      borderRadius: 2,
      position: 'relative',
      backgroundColor: '#fff',
      border: '1px solid #d9d9d9',
      fontSize: '0.8rem',
      padding: '6px 6px 6px 12px',
      // transition: theme.transitions.create(['border-color', 'box-shadow']),
      transition: 'unset',

      '&:focus': {
        borderRadius: 2,
        backgroundColor: '#fff',
        // borderColor: '#80bdff',
        borderColor: '#40a9ff',
        boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },
    }
  })
)

export default useStyles;