import React from "react"
import API from "../api";
import {
  BinaryFilter, BinaryOperator,
  CubejsApi,
  Filter,
  LoadMethodOptions,
  ProgressResult,
  Query,
  QueryOrder,
  ResultSet,
  TableColumn,
  TCubeDimension,
  TCubeMeasure,
  TCubeMember,
  TCubeMemberType,
  TimeDimension,
  TimeDimensionGranularity,
  TQueryOrderArray, UnaryFilter,
} from "@cubejs-client/core";
import { FilterOperatorName, getNewArrayOrder, logQuery} from "../utils/utils";
import { defaultOperatorForType } from "../utils/filters";
import { dispatchLogger } from "./context-utils";
import { processTimeDimensionName } from "../utils/time-dimension-utils";
import {getStoredUserValues} from "../utils/auth-utils";

const displayName = 'query-context'

type Action =
  {type: 'setResultSet', payload: ResultSet}
  | {type: 'clearResultSet' }
  | {type: 'setOrder', payload: {order: string, runNow?: boolean, orderDirection: QueryOrder}} // member.name
  | {type: 'setDimensions', payload: {dimensions: string[], runNow?: boolean}} // member.name[]
  | {type: 'addDimensions', payload: {dimensions: string[], runNow?: boolean}} // member.name[]
  | {type: 'removeDimension', payload: {dimension: string, runNow?: boolean}} // member.name
  | {type: 'reorderDimension', payload: {dimension: string, runNow?: boolean,direction:string}} // member.name
  | {type: 'dragAndDropDimension', payload: {dimension: string, source: number, destination: number}} // member.name
  | {type: 'addTimeDimension', payload: {timeDimension, granularity, runNow?: boolean}} // member.name
  | {type: 'removeTimeDimension', payload: {timeDimension: string, runNow?: boolean}} // member.name[]
  | {type: 'setTimeDimensionGranularity', payload: {timeDimension: string, granularity: TimeDimensionGranularity | undefined, runNow?: boolean}} // member.name[]'}
  | {type: 'setTimeDimensionDateRange', payload: {timeDimension: string, range: string | [string, string], runNow?: boolean}}
  | {type: 'setMeasures', payload: {measures: string[], runNow?: boolean}} // member.name[]
  | {type: 'addMeasures', payload: {measures: string[], runNow?: boolean}} // member.name[]
  | {type: 'removeMeasure', payload: {measure: string, runNow?: boolean}}
  | {type: 'reorderMeasure', payload: {measure: string, runNow?: boolean,direction:string}}
  | {type: 'dragAndDropMeasure', payload: {measure: string, source: number, destination: number}}
  | {type: 'setQuery', payload: {query: Query, runNow?: boolean}}
  | {type: 'clearQuery'}
  | {type: 'add', payload: {member: TCubeDimension | TCubeMeasure, type: string, runNow?: boolean}}
  | {type: 'remove', payload: {member: TCubeDimension | TCubeMeasure, type: string, runNow?: boolean}}
  | {type: 'removeTimeDimensionGranularity', payload: {member: TCubeDimension, runNow?: boolean}}
  | {type: 'addFilterField', payload: { member: string, type: TCubeMemberType }}
  | {type: 'addFilter', payload: { filter: BinaryFilter | UnaryFilter, runNow?: boolean}}
  | {type: 'setRunNowTrue' }

  // | {type: 'addFilter', payload: {
  // filter: {
  //   member: (TableColumn | TCubeMember),
  //   operator?: FilterOperatorName,
  //   values?: string[]
  // },
  // runNow?: boolean}}
  | {type: 'updateFilter', payload: {filter: [number, BinaryFilter | UnaryFilter], runNow?: boolean}} // filterIdx
  | {type: 'removeFilter', payload: {filter: string, runNow?: boolean}}  // member.name
  | {type: 'setProgressResult', payload: ProgressResult}
  | {type: 'setError', payload: Error | null}
  | {type: 'startRunQuery', payload: boolean} // no payload
  | {type: 'setHasQueryChanged', payload: boolean}
  | {type: 'addSegments', payload: {segments: string[], runNow: boolean}}
  | {type: 'removeSegments', payload: {segments: string[], runNow: boolean}}

type Dispatch = (action: Action) => void

type State = {
  query: Query,
  resultSet: ResultSet | null,
  progressResult: ProgressResult | null,
  isLoading: boolean,
  error: Error | null,
  hasQueryChanged: boolean,
  runNow: boolean | undefined,
}

type QueryProviderProps = {children: React.ReactNode}

const initialState = {
  query: {
    dimensions: [],
    measures: [],
    timeDimensions: [],
    filters: [],
    order: [],
    segments: [],
  },
  resultSet: null,
  progressResult: null,
  isLoading: false,
  error: null,
  hasQueryChanged: false,
  runNow: undefined,
}

const QueryStateContext = React.createContext<State | undefined>(undefined)
const QueryDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function queryContextReducer(state: State, action: Action) {
  dispatchLogger(displayName, action, 'debug');
  return {
    query: queryReducer(state.query, action),
    resultSet: resultSetReducer(state.resultSet, action),
    progressResult: progressResultReducer(state.progressResult, action),
    isLoading: isLoadingReducer(state.isLoading, action),
    error: errorReducer(state.error, action),
    hasQueryChanged: hasQueryChangedReducer(state.hasQueryChanged, action),
    runNow: runNowReducer(state.runNow, action),
  }
}

function errorReducer(errorState: Error | null, action: Action) {
  switch (action.type) {
    case 'setError': {
      return action.payload
    }
    case 'setResultSet': {
      return initialState.error
    }
    default: {
      return errorState
    }
  }
}

function resultSetReducer(resultSetState: ResultSet | null, action: Action) {
  switch (action.type) {
    case 'setResultSet': {
      return action.payload
    }
    case 'clearResultSet': {
      return initialState.resultSet
    }
    default: {
      return resultSetState
    }
  }
}

function progressResultReducer(progressResultState: ProgressResult | null, action: Action): ProgressResult | null {
  switch (action.type) {
    case 'setProgressResult': {
      return action.payload
    }
    case 'setResultSet': {
      return null
    }
    default: {
      return progressResultState
    }
  }
}

function isLoadingReducer(isLoadingState: boolean, action: Action): boolean {
  switch (action.type) {
    case 'startRunQuery': {
      return true
    }
    case 'setError':
    case 'setResultSet': {
      return false
    }
    default: {
      return isLoadingState
    }
  }
}

function queryReducer(queryState: Query, action: Action): Query {
  switch (action.type) {
    case 'setQuery': {
      return action.payload.query;
    }
    case 'clearQuery': {
      return initialState.query;
    }
    case 'add': {
      const { member, type } = action.payload;
      if (member.type === 'time' && type === 'dimensions') {
        const newTimeDimension: TimeDimension = {
          dimension: member.name,
          granularity: 'day',
        }

        return {
          ...queryState,
          timeDimensions: [...queryState.timeDimensions ? queryState.timeDimensions.concat([newTimeDimension]) : []],
        }
      }
      const key = type;
      return {
        ...queryState,
        [key]: queryState[key] ? queryState[key].concat([member.name]) : [],
      }
    }
    case 'removeTimeDimensionGranularity': {
      const {member} = action.payload;
      return {
        ...queryState,
        timeDimensions: queryState.timeDimensions
          ? queryState.timeDimensions.map(td => (
            td.dimension === member.name
              ? Object.assign({}, td, {granularity: undefined})
              : td
          ))
          : []
      }
    }
    case 'remove': {
      const {member, type} = action.payload;
      if (member.type === 'time' && type === 'dimensions') {
        return {
          ...queryState,
          timeDimensions: queryState.timeDimensions ? queryState.timeDimensions.filter(td => td.dimension !== member.name) : [],
        }
      }

      const key = type;
      return {
        ...queryState,
        [key]: queryState[key] ? queryState[key].filter(m => m !== member.name) : [],
      }
    }
    case 'setDimensions': {
      return {
        ...queryState,
        dimensions: action.payload.dimensions
      }
    }
    case 'addDimensions': {
      return {
        ...queryState,
        dimensions: queryState.dimensions ? queryState.dimensions.concat(action.payload.dimensions) : [],
      }
    }
    case 'addSegments': {
      return {
        ...queryState,
        segments: queryState.segments ? queryState.segments.concat(action.payload.segments) : [],
      }
    }
    case 'removeSegments': {
      const newSegments = Array.from(queryState.segments || []);
      action.payload.segments.forEach(s => {
        const removeIdx = newSegments.findIndex(x => x === s);
        newSegments.splice(removeIdx, 1);
      })
      return {
        ...queryState,
        segments: newSegments,
      }
    }
    case 'addTimeDimension': {
      const newTimeDimension: TimeDimension = {
        dimension: action.payload.timeDimension,
        granularity: action.payload.granularity,
      };
      return {
        ...queryState,
        timeDimensions: queryState.timeDimensions ? queryState.timeDimensions.concat([newTimeDimension]) : [],
      }
    }
    case 'removeTimeDimension': {
      const newTimeDimensions = Array.from(queryState.timeDimensions || []);
      const removeIdx = newTimeDimensions.findIndex(x => x.dimension === action.payload.timeDimension)
      newTimeDimensions.splice(removeIdx, 1);
      return {
        ...queryState,
        timeDimensions: newTimeDimensions,
      }
    }
    case 'setTimeDimensionGranularity': {
      const {timeDimension, granularity} = action.payload;
      const dimension = processTimeDimensionName(timeDimension);

      const newTimeDimensions = Array.from(queryState.timeDimensions || []);
        const timeDimensionIdx = newTimeDimensions.findIndex(x => x.dimension === dimension)
        if (granularity) {
          newTimeDimensions[timeDimensionIdx].granularity = granularity;
        }
      return {
        ...queryState,
        timeDimensions: newTimeDimensions,
      }
    }
    case 'setTimeDimensionDateRange': {
      const {timeDimension, range} = action.payload;
      const dimension = processTimeDimensionName(timeDimension);

      const newTimeDimensions = Array.from(queryState.timeDimensions || []);
      const timeDimensionIdx = newTimeDimensions.findIndex(x => x.dimension === dimension)
      if (timeDimensionIdx > -1 && range) {
        newTimeDimensions[timeDimensionIdx].dateRange = range;
      } else if (range === "") {
        delete newTimeDimensions[timeDimensionIdx].dateRange;
      }
      return {
        ...queryState,
        timeDimensions: newTimeDimensions,
      }
    }
    case 'removeDimension': {
      const newDimensions = Array.from(queryState.dimensions || []);
      const removeIdx = newDimensions.findIndex(x => x === action.payload.dimension);
      newDimensions.splice(removeIdx, 1);
      return {
        ...queryState,
        dimensions: newDimensions,
      }
    }
    case 'reorderDimension': {
      const newDimensions = Array.from(queryState.dimensions || []);
      const fromIndex = newDimensions.indexOf(action.payload.dimension);

      if(action.payload.direction == 'up'){
        newDimensions.splice(fromIndex-1, 0, newDimensions.splice(fromIndex,1)[0]);
      }else{
        newDimensions.splice(fromIndex+1, 0, newDimensions.splice(fromIndex,1)[0]);
      }

      return {
        ...queryState,
        dimensions: newDimensions,
      }
    }
    case 'dragAndDropDimension': {
      const newDimensions = Array.from(queryState.dimensions || []);
      const {source, destination} = action.payload;
      const [removed] = newDimensions.splice(source, 1);
      newDimensions.splice(destination, 0, removed);

      return {
        ...queryState,
        dimensions: newDimensions,
      }
    }
    case 'setMeasures': {
      return {
        ...queryState,
        measures: action.payload.measures
      }
    }
    case 'addMeasures': {
      return {
        ...queryState,
        measures: queryState.measures ? queryState.measures.concat(action.payload.measures) : [],
      }
    }
    case 'removeMeasure': {
      const newMeasures = Array.from(queryState.measures || []);
      const removeIdx = newMeasures.findIndex(x => x === action.payload.measure);
      newMeasures.splice(removeIdx, 1);
      return {
        ...queryState,
        measures: newMeasures,
      }
    }
    case 'reorderMeasure':{
      const newMeasures = Array.from(queryState.measures || []);
      const fromIndex = newMeasures.indexOf(action.payload.measure);

      if(action.payload.direction == 'up'){
        newMeasures.splice(fromIndex-1, 0, newMeasures.splice(fromIndex,1)[0]);
      }else{
        newMeasures.splice(fromIndex+1, 0, newMeasures.splice(fromIndex,1)[0]);
      }

      return {
        ...queryState,
        measures: newMeasures,
      }
    }
    case 'dragAndDropMeasure': {
      const newMeasures = Array.from(queryState.measures || []);
      const {source, destination} = action.payload;
      const [removed] = newMeasures.splice(source, 1);
      newMeasures.splice(destination, 0, removed);

      return {
        ...queryState,
        measures: newMeasures,
      }

    }
    case 'addFilter': {
      return {
        ...queryState,
        filters: queryState.filters ? queryState.filters.concat([action.payload.filter]): [],
      }
    }
    case 'addFilterField': {
      const { member, type } = action.payload;
      let memberName = member;
      // const { member, operator, values } = action.payload.member;
      // const memberName = "name" in member ? member.name : member.key;
      if (type === 'time') {
        // regex remove everything after second period in memberName since time dimensions append granularity to the end of the name
        memberName = member.replace(/(.*\..*)\..*/, '$1');
      }

      const initialValues = type === 'time' ? [new Date().toString()] : [];
      const newFilter = {
        member: memberName,
        operator: defaultOperatorForType[type] as BinaryOperator,
        values: initialValues,
      }
      return {
        ...queryState,
        filters: queryState.filters ? queryState.filters.concat([newFilter]): [],
      }
    }
    case 'removeFilter': {
      const newFilters = Array.from(queryState.filters || []) as (BinaryFilter | UnaryFilter)[];
      const removeIdx = newFilters.findIndex(f => f.member === action.payload.filter)
      newFilters.splice(removeIdx, 1);
      return {
        ...queryState,
        filters: newFilters,
      }
    }
    case 'updateFilter': {
      const [idx, filter] = action.payload.filter;
      const newFilters = Array.from(queryState.filters || []);
      newFilters[idx] = filter;
      return {
        ...queryState,
        filters: Array.from(newFilters),
      }
    }
    case 'setOrder': {
      return {
        ...queryState,
        order: getNewArrayOrder(queryState.order as TQueryOrderArray, action.payload.order, action.payload.orderDirection),
      }
    }
    default: {
      return queryState
    }
  }
}

function hasQueryChangedReducer(hasQueryChangedState: boolean, action: Action): boolean {
  switch (action.type) {
    case 'setHasQueryChanged': {
      return action.payload
    }
    default: {
      return hasQueryChangedState
    }
  }
}

function runNowReducer(runNowState: boolean | undefined, action: Action): boolean | undefined {
  switch (action.type) {
    case 'setOrder':
    case 'add':
    case 'remove':
    case 'setDimensions':
    case 'addDimensions':
    case 'removeDimension':
    case 'reorderDimension':
    case 'dragAndDropDimension':
    case 'setMeasures':
    case 'addMeasures':
    case 'removeMeasure':
    case 'reorderMeasure':
    case 'addFilterField':
    case 'addFilter':
    case 'updateFilter':
    case 'removeFilter':
    case 'removeTimeDimension':
    case 'addTimeDimension':
    case 'setTimeDimensionGranularity':
    case 'setTimeDimensionDateRange':
    case 'addSegments':
    case 'removeSegments':
    case 'removeTimeDimensionGranularity':
    case 'setQuery': {
      if ("runNow" in action.payload) {
        return !!action.payload.runNow;
      } else {
        return false;
      }
    }
    case 'clearQuery':
    case 'clearResultSet':
    case 'setError':
    case 'setResultSet': {
      return undefined;
    }
    case 'setRunNowTrue': {
      return true;
    }
    default: {
      return runNowState;
    }
  }
}

function QueryProvider({children}: QueryProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(queryContextReducer, initialState)

  return (
    <QueryStateContext.Provider value={state}>
      <QueryDispatchContext.Provider value={dispatch}>
        {children}
      </QueryDispatchContext.Provider>
    </QueryStateContext.Provider>
  )
}

function useQueryState(): State {
  const context = React.useContext(QueryStateContext)
  if (context === undefined) {
    throw new Error('useQueryState must be used within a QueryProvider')
  }
  return context
}

function useQueryDispatch(): Dispatch {
  const context = React.useContext(QueryDispatchContext)
  if (context === undefined) {
    throw new Error('useQueryDispatch must be used within a QueryProvider')
  }
  return context
}

async function runQuery(
  dispatch: Dispatch,
  api: CubejsApi,
  query: Query | Query[],
  errorCallback?: (error: Error) => void
): Promise<void> {
  dispatch({type: 'startRunQuery', payload: true })

  const loadMethodOptions: LoadMethodOptions = {
    progressCallback: (result: ProgressResult) => {
      dispatch({type: 'setProgressResult', payload: result })
    }
  }

  await api.load(query, loadMethodOptions,
    (error: Error | null, resultSet: ResultSet) => {
      if (error) {
        console.error(`Cube.js API Error:`, error)
        dispatch({type: 'setError', payload: error})
        errorCallback && errorCallback(error)
      } else {
        logQuery(api, resultSet);
        dispatch({type: 'setResultSet', payload: resultSet})
      }
    }
  )
}

export { QueryProvider, useQueryState, useQueryDispatch, runQuery }