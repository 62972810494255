import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { createStyles, TextField } from "@material-ui/core";
import {format, isValid} from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { DATE_FORMAT } from "../../config/frontendConfig.js"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      '&:hover': {
        borderColor: '#40a9ff',
        boxShadow: 'unset',
        // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },

      '&.input-error': {
        '& div': {
          // '&.MuiInputBase-root': {
            '& input': {
              backgroundColor: '#f89595',
            }
          // },
        },
      },

      '&:focus': {
        border: 'none',
        boxShadow: 'unset',
      },

      '&.Mui-focused': {
        borderColor: '#40a9ff',
        boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },

      '& div': {
        '&.MuiInputBase-root': {
          borderRadius: 2,
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          height: '33px',

          '&:hover': {
            borderColor: '#40a9ff',
            boxShadow: 'unset',
            // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
          },

          '&:focus': {
            border: 'none',
            boxShadow: 'unset',
          },

          '&.Mui-focused': {
            borderColor: '#40a9ff',
            boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
          },
        },
      },

      '& input': {
        fontSize: '0.8rem',
        borderRadius: 2,
        position: 'relative',
        backgroundColor: '#fff',
        padding: '6px 6px 6px 12px',
        border: 'none',

        '&:hover': {
          border: 'none',
        },

        '&:focus': {
          border: 'none',
          // border: 'inherit',
          // borderRadius: 2,
          // backgroundColor: '#fff',
          // borderColor: '#80bdff',
          // borderColor: '#40a9ff',
          // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
        },
      },
    },
  })
)

interface Props {
  // anchorEl: Element | null
  value: string
  onValueChanged: (newValue: string) => void
  disableFuture?: boolean
}

interface InputError {
  error: React.ReactNode
  value: MaterialUiPickersDate | ParsableDate
}
const DateInput: React.FunctionComponent<Props> = (
  {
    // anchorEl,
    value,
    onValueChanged,
    disableFuture,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const [inputError, setInputError] = React.useState<InputError | undefined>(undefined);

  function handleOnChange(date: Date) {
    if (isValid(date)) {
      handleOnAccept(date);
    }
  }

  function handleOnAccept(date: Date): void {
    if (inputError) {
      setInputError(undefined);
    }

    if (isValid(date)) {
      const dateFormatted = format(date, 'yyyy-MM-dd');
      console.log(`dateFormatted=`, dateFormatted);
      onValueChanged(dateFormatted);
    }
  }

  function handleOnError(error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate): void {
    if (!inputError && error) {
      setInputError({error, value});
    }
  }

  const clsName = inputError ? 'input-error' : '';


  return <KeyboardDatePicker
    autoOk
    className={clsName}
    variant="inline"
    inputVariant="standard"
    InputProps={{disableUnderline: true}}
    format={DATE_FORMAT}
    value={new Date(value)}
    onChange={handleOnChange}
    onAccept={handleOnAccept}
    onError={handleOnError}
    // minDate={new Date("01/01/1900")}
    // maxDate={new Date("12/31/2200")}
    invalidDateMessage=''
    disableFuture={disableFuture || false}
    TextFieldComponent={(props) => (
      <TextField
        {...props}
        className={inputError ? 'input-error' : ''}
        classes={{
          root: classes.root,
        }}
      />
    )}
  />
}

export default DateInput;