import { ListItem } from "@mui/material";
import { styled } from "@mui/system";
import { CSSObject } from "@mui/material/styles";
import { alpha } from "@mui/system";

interface Props {
	expanded: boolean;
	grouped: boolean;
}

const activeMixin = (theme): CSSObject => ({
	color: alpha(theme.palette.secondary.contrastText, 1),
	background: `linear-gradient(90deg, ${alpha(theme.palette.primary.contrastText, 0.15)} 0%, ${alpha(theme.palette.secondary.main, 0.75)} 100%)`,
	"& .MuiSvgIcon-root": {
		opacity: 0.5,
	},
});

const StyledFieldList = styled(ListItem, { shouldForwardProp: (prop) => prop !== "expanded" && prop !== "grouped" })<Props>(({ theme, expanded, grouped }) => ({
	background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
	pointerEvents: grouped ? "auto" : "none",
	cursor: "pointer",
	borderRadius: 3,
	display: "flex",
	fontFamily: "Outfit",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	padding: theme.spacing(0.5, 0, 0.5, 2),
	marginBottom: theme.spacing(0.5),
	color: alpha(theme.palette.secondary.contrastText, 0.75),
	"& .MuiSvgIcon-root": {
		opacity: 0.25,
		transition: "opacity 0.2s ease-in-out",
	},
	"&:hover": {
		...activeMixin(theme),
	},
	...((expanded || !grouped) && {
		...activeMixin(theme),
		background: `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.75)} 0%, ${alpha(theme.palette.secondary.main, 0.75)} 100%)`,
		"&:hover": {
			background: `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.5)} 0%, ${alpha(theme.palette.secondary.main, 0.75)} 100%)`,
		},
		"& .MuiSvgIcon-root": {
			opacity: 1,
		},
	}),
}));

export default StyledFieldList;