/* eslint-disable no-use-before-define */

import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { CubeContext } from "@cubejs-client/react";
import { CircularProgress } from "@material-ui/core";
import MultipleTextInput from "../MultipleTextInput/MultipleTextInput";
import {TCubeDimension} from "@cubejs-client/core";


interface Props {
  cubeDimension: TCubeDimension
  values: string[]
  handleSetValue: (x: string[]) => void
  isDropdown: boolean
}

const MultiSelectDropdown: React.FunctionComponent<Props> = (
  {
    cubeDimension,
    values,
    handleSetValue,
    isDropdown,
  }: Props): React.ReactElement => {
  const { cubejsApi } = React.useContext(CubeContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>("");
  const loading = isDropdown ? (open && options.length === 0) : false;

  React.useEffect(() => {
    if (isDropdown) {
      let active = true;

      if (!loading) {
        return undefined;
      }

      (async () => {
        const query = {
          dimensions: [cubeDimension.name],
          // limit: 500,
        };
        const resultSet = await cubejsApi.load(query);
        const values = await resultSet.rawData()
            .filter(row => !!(row[cubeDimension.name]))
            .map(row => row[cubeDimension.name]);

        if (active) {
          setOptions(values as string[]);
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return <MultipleTextInput
    id="multi-select-dropdown"
    multiple
    freeSolo
    value={values}
    onChange={(event: ChangeEvent, newValue: (string | string[])[]) => {
      handleSetValue(newValue as string[]);
    }}
    inputValue={inputValue}
    onInputChange={(event, newInputValue) => {setInputValue(newInputValue)}}
    open={open}
    onOpen={() => {setOpen(true)}}
    onClose={() => {setOpen(false)}}
    getOptionLabel={(option: string) => option}
    getOptionSelected={(option, value) => option === value}
    loading={loading}
    options={options}
    renderInput={(params: AutocompleteRenderInputParams) => {
      return <TextField
        {...params}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress size={10}/> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          )}}
      />
    }}
  />
}

export default MultiSelectDropdown;
