import React from "react";
import { deleteUserValues } from "../utils/auth-utils";
import API from "../api";
import { useAuthDispatch, useAuthState} from "../context/auth-context";
import { useAlertDispatch } from "../context/alert-context";
import {SavedReport} from "../utils/saved-report-utils";

export function useSavedReports() {
	const [savedReports, setSavedReports] = React.useState<SavedReport[] | undefined>(undefined);
	const dispatchAuth = useAuthDispatch();
	const dispatchAlert = useAlertDispatch();
	const {currentEnvironment} = useAuthState();

	React.useEffect(() => {
		if (!currentEnvironment) {
			return;
		}

		API.get(`/api/reports/env_name/${currentEnvironment["env_name"]}`)
			.then((response) => {
				setSavedReports(response.data);
			})
			.catch((error) => {
				console.error(`LandingPage.tsx > useEffect > get reports API error=${error}`);
				if (error.response && error.response.status === 401) {
					deleteUserValues();
					dispatchAuth({ type: "setIsNotAuthenticated" });
					dispatchAlert({
						type: "setAlert",
						payload: {
							alert: { message: "Authentication failed", severity: "error" },
						},
					});
				} else {
					dispatchAlert({
						type: "setAlert",
						payload: {
							alert: { message: "Failed to load data, try reloading page", severity: "warning" },
						},
					});
				}
				// dispatchAuth({type: 'setIsNotAuthenticated'})
			});
	}, [currentEnvironment])

	return savedReports
}