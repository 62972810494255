import {resolveCubeMember, resolveCubeFromMember} from "../cube-utils"
import { Mixpanel, eventNames } from "./Mixpanel"

export default function trackQuery(query, currentEnvironment, cubes, cubesMap) {
    try {
        const membersList = query.dimensions.map((dimensionName) => {
            const member = resolveCubeMember(cubes, dimensionName)
            return {name: member.name, title: member.title, shortTitle: member.shortTitle, type: member.type}
        })
        
        const cubesSet = new Set()
        for (const member of membersList) {
            const cube = resolveCubeFromMember(member.name, cubesMap)
            if (!cubesSet.has(cube)) {
                cubesSet.add({name: cube.name, title: cube.title})
            }
        }
        
        const cubesList = Array.from(cubesSet)

        const memberObject = {names: [], titles: [], shortTitles: [], types: []}
        for (let i = 0; i < membersList.length; i++) {
            const member = membersList[i]
            memberObject.names.push(member.name)
            memberObject.titles.push(member.title)
            memberObject.shortTitles.push(member.shortTitle)
            memberObject.types.push(member.type)
        }
      
        const cubeObject = {names: [], titles: []}
        for (let i = 0; i < cubesList.length; i++) {
            const cube = cubesList[i]
            cubeObject.names.push(cube.name)
            cubeObject.titles.push(cube.title)
        }
        
        Mixpanel.track(
            eventNames.ranQuery,
            {
                queryCubes: cubeObject, 
                queryMembers: memberObject, 
                query, 
                env: {
                    name: currentEnvironment.display_name,
                    key: currentEnvironment.env_name
                    }
            }
            );
    } catch (error) {
        console.warn(error)
        return
    }
}
