import React from 'react';
import {formatRelative} from 'date-fns';
import { useNavigate } from 'react-router-dom';
import API from "../../api";
import { useAuthDispatch, useAuthState } from "../../context/auth-context";
import { ReportsEnvironmentConfig } from "../../utils/auth-utils";
import useStyles from "./style"
import { AxiosError } from "axios";
import { useCubeMetaDispatch } from "../../context/cube-meta-context";
import { deleteUserValues } from "../../utils/auth-utils";
import {
  Typography,
  CircularProgress,
  Button
} from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';

import { green, grey, blue } from "@mui/material/colors";
import { Mixpanel, eventNames } from '../../utils/mixpanel-utils/Mixpanel';


const EnvironmentSelectPage: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const { user, allowedEnvironments } = useAuthState();
  const dispatchAuth = useAuthDispatch()
  const dispatchMeta = useCubeMetaDispatch();

  /**
   * Clear cubes meta context.
   */
  React.useEffect(() => {
    // console.debug(`EnvironmentSelectPage > useEffect > dispatch clearCubesMeta`);
    dispatchMeta({type: 'clearCubesMeta'});
    dispatchAuth({type: 'clearCurrentEnvironment' })
  }, [])

  function logOut() {
    Mixpanel.track(eventNames.loggedOut)
    deleteUserValues()
    dispatchAuth({type: 'setIsNotAuthenticated'});
    dispatchMeta({type: 'clearCubesMeta'});
    navigate("/login");
  }

  /**
   * Execute the actions needed for environment navigation, namely:
   *    - Call the 'setLastEnvironment' function which calls API of same name
   *    - Trigger setCubesMeta ????
   *    - Navigate to correct URL
   */
  function navigateToEnvironment(envName: string) {
    // console.debug(`Navigate to environment: ${envName}`);
    Mixpanel.track(eventNames.selectedEnv, {envName})
    setLastEnvironment(envName);
    navigate(`/${envName}`);
  }

  /**
   * Call the set_last_environment API for the user,
   * using currentEnvironment for environmentName.
   */
  function setLastEnvironment(lastEnvironment: string) {
    const payload = { last_env_name: lastEnvironment };
    const userId = user && user.userId;
    API.put(`/api/user/${userId}/update`, payload)
      .then(() => {
        // console.info(`navigateToEnvironment > update user success, response.data`, response.data)
      })
      .catch((error: AxiosError) => {
        console.error(`navigateToEnvironment > update user error, error=`, error);
      })
  }

  function getLastSyncedDisplay(env: ReportsEnvironmentConfig) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <SyncIcon fontSize="small" sx={{ color: env['last_synced'] ? blue[200] : grey[400] }}/>
        <Typography variant="body2" sx={{ fontSize: 12, marginLeft: '4px', paddingTop: '0px' }}
                    color={env['last_synced'] ? grey[500] : grey[500]}>
          {env['last_synced']
            ? `${formatRelative(new Date(env['last_synced']), new Date())}`
            : `Not synced`}
        </Typography>
      </div>
    )
  }

  React.useEffect(() => {
    const currentEnvironment = localStorage.getItem('currentEnvironment');
    if (allowedEnvironments && allowedEnvironments.filter((env) => env['env_name'] === currentEnvironment).length > 0) {
      navigate(`/${currentEnvironment}`);
    } else if (allowedEnvironments && allowedEnvironments.length > 0) {
      navigate(`/${allowedEnvironments[0]['env_name']}`);
    }
  }, [allowedEnvironments])

  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        {
          allowedEnvironments ?
            (
              allowedEnvironments.length > 0 ?
                <div>
                  <CircularProgress size='2rem'/>
                  <Typography>Loading Environment</Typography>
                </div>
                :
                <div>
                  <Typography>
                    Sorry! You do not have access to any Reports Pro environments.<br/>
                    To gain access to Reports Pro, please contact your account admin or DealSumm Support at support@dealsumm.com
                  </Typography>
                  <br/>
                  <Button size="small" variant='outlined' onClick={logOut}>
                    Log Out
                  </Button>
                </div>
            )
          :
          <div>
            <CircularProgress size='2rem'/>
            <Typography>Loading Environment</Typography>
          </div>
        }
      </div>
    </div>
  );
}

export default EnvironmentSelectPage;