import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { CubeProvider } from "@cubejs-client/react";
import { CircularProgress, Toolbar } from '@material-ui/core';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { deleteUserValues } from "../../utils/auth-utils";
import { useAlertState } from "../../context/alert-context";
import {useCubeMetaDispatch, useCubeMetaState} from "../../context/cube-meta-context";
import { QueryProvider } from "../../context/query-context";
import { useAuthDispatch, useAuthState } from "../../context/auth-context";
import BasicSnackbar from "../../components/alerts/BasicSnackbar/BasicSnackbar";
import useStyles from "./style";
import useSetUserPermissions from "../../hooks/useSetUserPermissions";
import {useSetCubejsAPI} from "../../hooks/useSetCubejsAPI";
import useSetCurrentEnvironment from "../../hooks/useSetCurrentEnvironment";
import useSetSchemaContext from "../../hooks/useSetSchemaContext";
import {CubejsApi} from "@cubejs-client/core";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import FieldContextProvider from "../../context/providers/FieldContextProvider";
import {useTheme} from "@mui/material/styles";
import MaintenancePopup from '../../components/alerts/MaintenancePopup';

interface Props {
  MainComponent: React.ElementType,
}

const LayoutWrapper: React.FunctionComponent<Props> = (
  {
    MainComponent,
  }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate()
  const { alert } = useAlertState();
  const dispatchAuth = useAuthDispatch()
  const dispatchMeta = useCubeMetaDispatch();
  const location = useLocation();

  useSetCurrentEnvironment();
  useSetUserPermissions();
  useSetSchemaContext();

  const cubejsApi: CubejsApi | undefined = useSetCubejsAPI();
  const { appContextName, schemaContextDefinitions, metaContextName } = useCubeMetaState();
  const { currentEnvironment, userPermissionsToken } = useAuthState();

  if (!currentEnvironment) {
    // console.warn(`LayoutWrapper > No currentEnvironment! Returning loader`);
    return <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        <CircularProgress size='2rem'/>
        <Typography>Loading environment</Typography>
      </div>
    </div>
  }

  if (!userPermissionsToken) {
    // console.warn(`LayoutWrapper > No userPermissionsToken! Returning loader`);
    return <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        <CircularProgress size='2rem'/>
        <Typography>Loading permissions</Typography>
      </div>
    </div>
  }

  if (!schemaContextDefinitions) {
    // console.warn(`LayoutWrapper > No contextConfigs! Returning loader`);
    return <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        <CircularProgress size='2rem'/>
        <Typography>Loading contexts</Typography>
      </div>
    </div>
  }

  if (!cubejsApi) {
    // console.warn(`LayoutWrapper > No cubejsApi! Returning loader`);
    return <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        <CircularProgress size='2rem'/>
        <Typography>Loading query engine</Typography>
      </div>
    </div>
  }

  if (appContextName !== null && appContextName !== metaContextName) {
    // console.warn(`LayoutWrapper > metaContextName (${metaContextName}) and appContextName (${appContextName}) not synced.  Returning loader`);
    return <div className={classes.loadingContainer}>
      <div className={classes.loader}>
        <CircularProgress size='2rem'/>
        <Typography>Loading context</Typography>
      </div>
    </div>
  }

  function logOut() {
    // console.debug(`LayoutWrapper > logout`);
    deleteUserValues()
    dispatchAuth({type: 'setIsNotAuthenticated'});
    dispatchMeta({type: 'clearCubesMeta'});
    navigate("/login"); // todo - check if navigation necessary
  }

  function Alert() {
    if (!alert) {
      return null
    }
    return <BasicSnackbar
      message={alert.message}
      severity={alert.severity}
      onClose={alert.onClose}
      autoHideDuration={alert.autoHideDuration}
    />
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <QueryProvider>
          <FieldContextProvider>
            <div className={classes.root}>
              {Alert()}
              <AppBar envName={currentEnvironment.display_name} />
              <main className={classes.content} style={{
                background: (location.pathname.includes('explore') || location.pathname.includes('report')) ? `linear-gradient(180deg, ${theme.palette.background.paper} 0%, #D9D9D9 100%)` : '#FFFFFF'
              }}>
                <MaintenancePopup />
                {/*<Toolbar />*/}
                <MainComponent
                  currentEnvironment={currentEnvironment}
                  metaContextName={metaContextName}
                  appContextName={appContextName}
                />
              </main>
            </div>
          </FieldContextProvider>
        </QueryProvider>
      </MuiPickersUtilsProvider>
    </CubeProvider>
  )
}

export default LayoutWrapper;


interface AppBarProps {
  envName: string
}

const AppBar: React.FC<AppBarProps> = (
  {
    envName
  }: AppBarProps) => {
  const classes = useStyles();
  return <Box
    id={"app-bar-fixed"}
    sx={{
      position: 'fixed',
      display: 'flex',
      left: 0,
      top: 0,
      width: '100%',
      height: '32px'
    }}
  >
    <div className={classes.grow} />
    {/*<Typography variant="subtitle1" sx={{ padding: '12px 72px', cursor: 'default' }}>*/}
    <Typography variant="subtitle1" sx={{ padding: '12px 48px', cursor: 'default' }}>
      {envName}
    </Typography>
  </Box>
}