import LandingPage from "../pages/LandingPage/LandingPage";
import NamedReportPage from "../pages/NamedReportPage";
import DashboardsPage from "../pages/DashboardsPage/DashboardsPage";

/*
 ** routes inside LayoutWrapper component only
 */
const routes = [
  {
    path: "/:env",
    exact: true,
    key: "landing-page",
    main: LandingPage,
    sidebar: false,
  },
  {
    path: `/:env/reports/:contextNumeric/:exploreFrom/key/:key`,
    key: "named-reports",
    exact: true,
    main: NamedReportPage,
    sidebar: true,
  },
  {
    path: `/:env/explore/:contextNumeric`,
    key: "explore",
    exact: true,
    main: NamedReportPage,
    sidebar: true,
  },
  {
    path: `/:env/explore/:contextNumeric/:exploreFrom`,
    key: "exploreFrom",
    exact: true,
    main: NamedReportPage,
    sidebar: true,
  },
  {
    path: `/:env/dashboards/:contextNumeric/:dashboardKey`,
    key: "dashboard",
    exact: true,
    main: DashboardsPage,
    sidebar: false,
  },
]

export default routes