import React from "react";
import { Box, ClickAwayListener, PopperPlacementType } from "@mui/material";
import NestedMenu from "./NestedMenu";
import { menuItemType } from "./menuItemType";

type Props = {
	menuItems: menuItemType[]; // The items to display in the menu
	open: boolean; // Whether the menu is open
	setOpen: React.Dispatch<React.SetStateAction<boolean>>; // The function to call to set the open state
	anchorEl: HTMLElement | null | undefined; // The element to anchor the menu to
	placement?: PopperPlacementType; // The placement of the menu
	arrow?: boolean; // Whether to show the arrow
	color?: string; // The color of the menu
	canHide?: boolean; // Whether the menu can be hidden by clicking away
};

const Menu = ({
	menuItems,
	open,
	setOpen,
	anchorEl,
	placement = "bottom-start",
	arrow = true,
	color="primary",
	canHide = true,
}: Props) => {
	const [activeDepth, setActiveDepth] = React.useState(0);

	const nestedMenuProps = { open, setOpen, menuItems, activeDepth, setActiveDepth, anchorEl, placement, arrow, color, canHide };

	return (
		<>
			{open && (
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<Box>
						<NestedMenu {...nestedMenuProps} />
					</Box>
				</ClickAwayListener>
			)}
		</>
	);
};

export default Menu;
