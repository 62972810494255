import React from "react";
import { dispatchLogger } from "./context-utils";
import {BinaryFilter, UnaryFilter} from "@cubejs-client/core";
const displayName = 'dashboard-context'

type Action = {type: 'addDashboardFilter', payload: { filter: BinaryFilter | UnaryFilter }}
| {type: 'updateDashboardFilter', payload: { filter: BinaryFilter | UnaryFilter}}
| {type: 'removeDashboardFilter', payload: { filterMember: string }}
| {type: 'setDashboardFilters', payload: { filters: (BinaryFilter | UnaryFilter)[] }}
| {type: 'setColorPaletteIdx', payload: { colorPaletteIdx: number }}

type Dispatch = (action: Action) => void

export type State = {
  dashboardFilters: (BinaryFilter | UnaryFilter)[]
  colorPaletteIdx: number
}

type DashboardProviderProps = {initialDashboardFilters: (BinaryFilter | UnaryFilter)[], children: React.ReactNode}

const initialState = {
  dashboardFilters: [],
  colorPaletteIdx: 16,
}

const DashboardStateContext = React.createContext<State | undefined>(undefined)
const DashboardDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function dashboardContextReducer(state: State, action: Action): State {
  dispatchLogger(displayName, action, 'debug');
  switch (action.type) {
    case "addDashboardFilter": {
      return {
        ...state,
        dashboardFilters: [...state.dashboardFilters, action.payload.filter],
      }
    }
    case "updateDashboardFilter": {
      const filterIdx = state.dashboardFilters.findIndex(f => f.member === action.payload.filter.member);
      const newFilters = Array.from(state.dashboardFilters);
      newFilters.splice(filterIdx, 1, action.payload.filter);
      return {
        ...state,
        dashboardFilters: newFilters,
      }
    }
    case "removeDashboardFilter": {
      const newFilters = state.dashboardFilters.filter(f => f.member !== action.payload.filterMember);
      return {
        ...state,
        dashboardFilters: newFilters,
      }
    }
    case "setDashboardFilters": {
      return {
        ...state,
        dashboardFilters: action.payload.filters,
      }
    }
    case "setColorPaletteIdx": {
      return {
        ...state,
        colorPaletteIdx: action.payload.colorPaletteIdx,
      }
    }
    default: {
      return state
    }
  }
}

function DashboardProvider({initialDashboardFilters, children}: DashboardProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(dashboardContextReducer, {
    ...initialState,
    dashboardFilters: initialDashboardFilters,
  })

  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  )
}

function useDashboardState(): State {
  const context = React.useContext(DashboardStateContext)
  if (context === undefined) {
    throw new Error('useDashboardState must be used within a DashboardProvider')
  }
  return context
}

function useDashboardDispatch(): Dispatch {
  const context = React.useContext(DashboardDispatchContext)
  if (context === undefined) {
    throw new Error('useDashboardDispatch must be used within a DashboardProvider')
  }
  return context
}


export { DashboardProvider, useDashboardDispatch, useDashboardState }