import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {ExploreQueryDefinition, SchemaContextDefinition, SchemaContextName} from "../utils/cube-utils";
import { useCubeMetaState } from "../context/cube-meta-context";
import { useAuthState } from "../context/auth-context";

const useContextDefinition = () => {
	const cubeMetaState = useCubeMetaState();
	const contextDefinitions = cubeMetaState.schemaContextDefinitions as SchemaContextDefinition[];
	const appContextName = cubeMetaState.appContextName as SchemaContextName;
	const { key, exploreFrom } = useParams() as Record<string, string>;
	const { currentEnvironment } = useAuthState();

	const contextDefinition = useMemo<SchemaContextDefinition | undefined>(() => {
		return contextDefinitions.find((c) => c.name === appContextName);
	}, [appContextName, contextDefinitions]);

	const explore = useMemo<ExploreQueryDefinition | undefined>(() => {
		if (currentEnvironment && appContextName && contextDefinition) {
			return exploreFrom
				? contextDefinition.explore_queries.find((x) => x.enumInt === parseInt(exploreFrom))
				: contextDefinition.explore_queries[0];
		}
		return undefined;
	}, [exploreFrom, contextDefinition, key]);

	return { contextDefinition, explore };
};

export default useContextDefinition;
