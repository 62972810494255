import { ResponsiveContainer, Tooltip, BarChart as ReBarChart, XAxis, YAxis, CartesianGrid, Bar, Text } from "recharts";
import { useTheme } from "@mui/material/styles";
import CustomTooltip, { reTooltipProps } from "./CustomTooltip";
import { PanelProps, getTypeFromFormat, tickFormatter } from "./panelUtils";
import { calculateFill } from "../../../utils/dashboard-utils";
import React from "react";
import { Box } from "@mui/material";

interface Props extends PanelProps {
	layout?: "horizontal" | "vertical";
}

const CustomXAxisTick = props => {
	const ref = React.useRef(null)
	const labelText = props.payload.value.length > 15
    ? `${props.payload.value.substring(0, 15)}...`
    : props.payload.value;
	return <Text ref={ref} {...props} >{props.tickFormatter(props.payload.value)}</Text>;
  };

const BarChart = (
	{
		seriesNames,
		data,
		format,
		colorPaletteIdx,
		height,
		axisWidth,
		xAxisOrientation,
		layout="vertical",
		dateFormatOverride,
	}: Props) => {
	const theme = useTheme();

	function getXAxisProps() {
		return {
			dataKey: "x",
			type: getTypeFromFormat(format.x),
			tickFormatter: (value) => tickFormatter(value, format.x, dateFormatOverride),
			interval: format.x == "string" ? 0 : undefined
		};
	}

	function getYAxisProps() {
		return {
			dataKey: "",
			type: getTypeFromFormat(format.y),
			tickFormatter: (value) => tickFormatter(value, format.y, dateFormatOverride),
			interval: format.y == "string" ? 0 : undefined
		};
	}

	function getContainerHeight() {
		const calculatedHeight = data.length * 25
		const minHeight = height || 400
		
		return calculatedHeight < minHeight ? minHeight : calculatedHeight
	}

	function getFontSize() {
		if (data.length === 0 || layout === "horizontal") return 14
		const heightToLengthRatio = (height || 400) / data.length
		if (heightToLengthRatio > 30) return 14
		else if (heightToLengthRatio > 20) return 13
		return 12
	}


	return (
		<Box sx={{maxHeight: height || 400, overflow: layout === "horizontal" ? 'none' :  getContainerHeight() > (height || 400) ? 'auto' : 'none'}} className="thinScrollbar">
			<ResponsiveContainer width="95%" height={layout === "horizontal" ? height || 400 : getContainerHeight()}>
				<ReBarChart data={data} layout={layout}>
					<XAxis
						{...(layout === "horizontal" ? getXAxisProps() : getYAxisProps())}
						orientation={xAxisOrientation || "top"}
					/>
					<YAxis
						{...(layout === "horizontal" ? getYAxisProps() : getXAxisProps())}
						width={axisWidth || 150}
						tick={<CustomXAxisTick fontFamily="Outfit, Arial, sans-serif" fontSize={getFontSize()} whiteSpace="nowrap" style={{whiteSpace: 'nowrap'}}/>}
						minTickGap={1}
						textAnchor="end"
					/>
					<Tooltip
						content={<CustomTooltip
							payload={data.map(d => Object.assign(d, { seriesNames }))}
							format={format}
							dateFormatOverride={dateFormatOverride}
							/>}
							{...reTooltipProps}
							animationDuration={0}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					{seriesNames.map(({ key, title, yValues }, idx) => {
						return (
							<Bar
								key={idx}
								stackId="a"
								dataKey={key}
								barSize={30}
								fill={calculateFill(idx, seriesNames.length, colorPaletteIdx)}
								name={yValues[0]}
							/>
						);
					})}
				</ReBarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default BarChart;
