import { Box, Button, Divider, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import { alpha, useTheme } from "@mui/material/styles";
import useMenu from "../../../hooks/useMenu";
import Menu from "../../NestedMenu/Menu";
import { menuItemType } from "../../NestedMenu/menuItemType";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import { HiArrowUp, HiArrowDown } from "react-icons/hi";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { Fullscreen } from "@mui/icons-material";

type Props = {
	page: number;
	rowsPerPage: number;
	count: number;
	setPage: (page: number) => void;
	setRowsPerPage: (rowsPerPage: number) => void;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
	isDashboardPanel: boolean;
};

const Pagination = (props: Props) => {
	const theme = useTheme();

	const lastPage = Math.ceil(props.count / props.rowsPerPage);

	const [fullScreen, setFullScreen] = React.useState(false);

	React.useEffect(() => {
		props.setPage(0);
	}, [props.rowsPerPage]);

	const moreMenu = useMenu();

	React.useEffect(() => {
		moreMenu.handleClose();
	}, [props.page]);

	const moreMenuItems: menuItemType[] = React.useMemo(() => {
		const items: menuItemType[] = [];
		for (let i = 0; i < lastPage; i++) {
			items.push({
				label: `Page ${i + 1}`,
				onClick: () => {
					console.log(`Page ${i + 1} clicked`);
					props.setPage(i);
				},
				options: {
					active: props.page === i,
				}
			});
		}

		return items;
	}, [props.page, props.rowsPerPage]);

	const rowsPerPageMenu = useMenu();

	React.useEffect(() => {
		rowsPerPageMenu.handleClose();
	}, [props.rowsPerPage]);

	const rowsPerPageMenuItems: menuItemType[] = [
		{
			label: "100",
			onClick: () => {
				rowsPerPageMenu.handleClose();
				props.setRowsPerPage(100);
			},
			options: {
				active: props.rowsPerPage === 100,
			}
		},
		{
			label: "50",
			onClick: () => {
				rowsPerPageMenu.handleClose();
				props.setRowsPerPage(50);
			},
			options: {
				active: props.rowsPerPage === 50,
			}
		},
		{
			label: "20",
			onClick: () => {
				rowsPerPageMenu.handleClose();
				props.setRowsPerPage(20);
			},
			options: {
				active: props.rowsPerPage === 20,
			}
		},
	];

	if (props.isDashboardPanel) {
		rowsPerPageMenuItems.push({
			label: "10",
			onClick: () => {
				rowsPerPageMenu.handleClose();
				props.setRowsPerPage(10);
			},
			options: {
				active: props.rowsPerPage === 10,
			}
		})
	}

	function handleChangePage(page) {
		if (page < 0 || page > lastPage) return;
		props.setPage(page);
	}

	const defaultPageButtonProps = { handleChangePage, currentPage: props.page, lastPage };

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				gap: theme.spacing(4),
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<IconButton
					size="small"
					onClick={() => handleChangePage(0)}
					disabled={props.page === 0}
					sx={{ color: theme.palette.secondary.dark }}
				>
					<KeyboardDoubleArrowLeftRoundedIcon fontSize="small" />
				</IconButton>
				<IconButton
					size="small"
					onClick={() => handleChangePage(props.page - 1)}
					disabled={props.page === 0}
					sx={{ color: theme.palette.secondary.dark }}
				>
					<KeyboardArrowLeftRoundedIcon fontSize="small" />
				</IconButton>
				<Box sx={{ display: "flex", alignItems: "center", gap: "1ch", marginX: theme.spacing(1) }}>
					<Button
						ref={moreMenu.anchorRef as React.RefObject<HTMLButtonElement>}
						onClick={moreMenu.handleOpen}
						color="primary"
						variant="contained"
						disableElevation
						sx={{
							padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
							minWidth: '10px',
						}}
					>
						{props.page + 1}
						{/* <ArrowDropDownIcon
							style={{
								transform: `rotate(${moreMenu.open ? 180 : 0}deg)`,
								position: "absolute",
								right: 0,
								color: 'inherit',
							}}
						/> */}
						<Menu
							menuItems={moreMenuItems}
							anchorEl={moreMenu.anchorRef.current}
							open={moreMenu.open}
							setOpen={moreMenu.setOpen}
							placement="top-start"
							arrow={false}
						/>
					</Button>
					<Typography>
						of <b>{lastPage}</b>
					</Typography>
				</Box>
				<IconButton
					size="small"
					onClick={() => handleChangePage(props.page + 1)}
					disabled={props.page + 1 === lastPage}
					sx={{ color: theme.palette.secondary.dark }}
				>
					<KeyboardArrowRightRoundedIcon fontSize="small" />
				</IconButton>
				<IconButton
					size="small"
					onClick={() => handleChangePage(lastPage - 1)}
					disabled={props.page + 1 === lastPage}
					sx={{ color: theme.palette.secondary.dark }}
				>
					<KeyboardDoubleArrowRightRoundedIcon fontSize="small" />
				</IconButton>
			</Box>
			<Typography variant="subtitle2" sx={{ opacity: 0.5, fontWeight: "200", b: { fontWeight: "400" } }}>
				<b>
					{props.rowsPerPage * props.page + 1} -{" "}
					{props.rowsPerPage * (props.page + 1) > props.count
						? props.count
						: props.rowsPerPage * (props.page + 1) + " "}
				</b>
				of {props.count}
			</Typography>
			<Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
				{!props.isDashboardPanel && (
					<ArrowTooltip title={fullScreen ? "exit fullscreen" : "fullscreen"}>
						<Button color="secondary" sx={{
							backgroundColor: alpha(theme.palette.secondary.main, 0.05),
							borderRadius: 1,
						}} onClick={() => {
							if (!document.fullscreenElement) {
								document.documentElement.requestFullscreen();
								setFullScreen(true);
							} else {
								if (document.exitFullscreen) {
									document.exitFullscreen();
									setFullScreen(false);
								}
							}
						}}>
							{!fullScreen ? <AiOutlineFullscreen /> : <AiOutlineFullscreenExit />}
						</Button>
					</ArrowTooltip>
				)}
				<ArrowTooltip title={props.isExpanded ? "table rows are expanded" : "table rows are collapsed"}>
					<Button
						size="small"
						disableRipple
						color="secondary"
						onClick={() => props.setIsExpanded(!props.isExpanded)}
						sx={{
							"&:hover .icon": {
								backgroundColor: alpha(theme.palette.secondary.main, 0.75),
							},
							display: "flex", flexDirection: "column", px: "4px", alignItems: "center", width: "30px", backgroundColor: alpha(theme.palette.secondary.main, 0.05), height: props.isExpanded ? "" : "20px", borderRadius: 1, maxHeight: "26px"
						}}
					>
						<HiArrowDown style={{ transform: props.isExpanded ? "rotate(0deg)" : "rotate(180deg)" }} />
						<HiArrowUp style={{ transform: props.isExpanded ? "rotate(0deg)" : "rotate(180deg)" }} />
					</Button>
				</ArrowTooltip>
				<Button
					ref={rowsPerPageMenu.anchorRef as React.RefObject<HTMLButtonElement>}
					onClick={rowsPerPageMenu.handleOpen}
					color="secondary"
					sx={{
						backgroundColor: alpha(theme.palette.secondary.main, 0.05),
						borderRadius: 1,
						justifyContent: "left",
						padding: "4px 8px",
						paddingRight: "25px",
						minWidth: "0px",
					}}
				>
					{props.rowsPerPage}
					<ArrowDropDownIcon
						style={{
							transform: `rotate(${rowsPerPageMenu.open ? 180 : 0}deg)`,
							position: "absolute",
							right: 0,
							color: theme.palette.grey[500],
						}}
					/>
					<Menu
						menuItems={rowsPerPageMenuItems}
						anchorEl={rowsPerPageMenu.anchorRef.current}
						open={rowsPerPageMenu.open}
						setOpen={rowsPerPageMenu.setOpen}
						placement="top-start"
						arrow={false}
					/>
				</Button>
				<Typography sx={{ opacity: 0.5, fontWeight: "200", b: { fontWeight: "400" } }} variant="subtitle2">
					Rows / <b>Page</b>
				</Typography>
			</Box>
		</Box>
	);
};

export default Pagination;
