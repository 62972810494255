import React, {useLayoutEffect} from 'react'

export default function useShortcut(shortcut: string | undefined, callback: (keyboardEvent: KeyboardEvent) => void, dependencies) {
    useLayoutEffect(() => {
        if (!shortcut) return;

        const {modifiers, key} = parseShortcut(shortcut)

        const handler = (keyboardEvent: KeyboardEvent) => {
            if (keyboardEvent.key !== key) return;
            if (modifiers.alt !== keyboardEvent.altKey) return;
            if (modifiers.ctrl !== keyboardEvent.ctrlKey) return;
            if (modifiers.shift !== keyboardEvent.shiftKey) return;
            keyboardEvent.preventDefault()

            callback(keyboardEvent)
        }

        document.addEventListener('keydown', handler)

        return () => {
            document.removeEventListener('keydown', handler)
        }
    }, dependencies)
}

function parseShortcut(shortcut: string) {
    const keys = shortcut.split('+')
    const modifiers = {
        alt: keys.includes('alt'),
        ctrl: keys.includes('ctrl') || keys.includes('control'),
        shift: keys.includes('shift'),
    }
    // get the last key
    const key = keys[keys.length - 1]

    return {modifiers, key}
}
