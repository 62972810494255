import API from "../api";
import {AxiosError} from "axios";
import {useAuthDispatch, useAuthState} from "../context/auth-context";
import React from "react";

export default function useSetUserPermissions(): void {
  const dispatchAuth = useAuthDispatch();
  const { user, currentEnvironment } = useAuthState();

  React.useEffect(() => {
    // console.debug(`useSetUserPermissions > useEffect > currentEnvironment changed (env_name=${currentEnvironment && currentEnvironment.env_name})`);
    if (user && currentEnvironment) {
      API.get(`/api/user/${user.userId}/env_name/${currentEnvironment['env_name']}/user_permissions`).then(response => {
        dispatchAuth({ type: 'setUserPermissionsToken', payload: {
          userPermissionsToken: response.data.jwt_permissions_token,
        }});
      })
        .catch((error: AxiosError) => {
          console.error(`useSetUserPermissions > user_permissions error, error=`, error);
        })
    }
  }, [currentEnvironment && currentEnvironment.env_name])
}