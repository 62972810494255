import { createStyles, makeStyles } from "@material-ui/core/styles";
// import blue from "@mui/material/colors/blue"
// import purple from "@mui/material/colors/purple"
import {deepPurple} from "@mui/material/colors";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) =>
  createStyles({
		buttonRoot: {
			'&.MuiButtonBase-root': {
				minWidth: "100px",
				height: '28px',
				fontSize: "12px",
				padding: '4px 12px 4px 8px',
				borderRadius: '20px',
				textTransform: "none",
				justifyContent: "start",
				color: grey[600],
				// backgroundColor: '#eee',
				backgroundColor: grey[200],
				outline: '1px solid',
				outlineColor: grey[300],
				'&.activeFilter': {
					color: grey[800],
					backgroundColor: 'white',
					// outline: '2px solid',
					// outlineColor: deepPurple[100],
					outlineColor: theme.palette.primary.main
					// outline: '1px solid lightblue',
				},
				'&.menuOpen': {
					// backgroundColor: deepPurple[100],
					// color: deepPurple[500],
					// outlineColor: deepPurple[200],
					color: theme.palette.primary.main,
					outlineColor: theme.palette.primary.main
					// outline: '1px solid lightblue',
				},
			}
		},
		iconButtonRoot: {
			color: '#bbb',
			// height: '0.875rem',
			// width: '0.875rem',
			padding: '12px',
			marginLeft: '12px',
		},
	})
)

export default useStyles;