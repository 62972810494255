/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { AutocompleteProps } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./style";


interface TagProps {
  label: string
  onDelete: () => void
}

const Tag = (
  {
    label,
    onDelete,
    ...props
  }: TagProps) => {
  const classes = useStyles();
  return <div className={classes.tagDiv} {...props}>
    <span className={classes.tagSpan}>{label}</span>
    <CloseIcon className={classes.tagSvg} onClick={onDelete}/>
  </div>
}

export default function MultipleTextInput(props: AutocompleteProps<any, any, any, any>): React.ReactElement {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete(props);
  const classes = useStyles();

  return (
    <div style={{ flexGrow: 1 }}>
      <div {...getRootProps()}>
        {/*<DSInputBase className={focused ? ' focused' : ''} ref={setAnchorEl}>*/}
        <div className={classes.inputWrapper + (focused ? ' focused' : '')} ref={setAnchorEl}>
          {value.map((option: string, index: number) => (
            <Tag key={`${index}-${option}`} label={option} {...getTagProps({ index }) as {onDelete: () => void }} />
          ))}
           <input className={classes.inputRoot + ' multi-value'} {...getInputProps()} />
          {props.loading ? <CircularProgress className={classes.inputAdornment} color="primary" size={15} /> : null}
        </div>
        {/*</DSInputBase>*/}
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listBox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li className={classes.listBoxLi} key={`${index}-${option}`} {...getOptionProps({ option, index })}>
              <span className={classes.listBoxSpan}>{option}</span>
              <CheckIcon className={classes.listBoxSvg} fontSize="small" />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
