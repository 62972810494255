import React from "react";
import Header from "../Header";
import SelectContext from "../../../SelectContext/SelectContext";
import { useAuthState } from "../../../../context/auth-context";
import { Box } from "@mui/material";
import Wrapper from "./Wrapper";
import { useTheme } from "@mui/material/styles";
import {ExploreQueryDefinition, SchemaContextDefinition} from "../../../../utils/cube-utils";

interface Props {
	contextDefinition: SchemaContextDefinition
	explore: ExploreQueryDefinition
}

const ContextHeader: React.FC<Props> = (
	{
		contextDefinition,
		explore
	}: Props): React.ReactElement => {
	const theme = useTheme();
	const { currentEnvironment } = useAuthState();

	if (!currentEnvironment) {
		console.warn(`ContextHeader.tsx > no currentEnvironment, returning <></>`);
		return <></>;
	}

	return (
		<>
			<Wrapper>
				<Box sx={{ marginLeft: "64px", mt: theme.spacing(2), px: theme.spacing(2), mb: theme.spacing(1) }}>
					<Header disableBorderTop>
						{currentEnvironment["display_name"]}
					</Header>
					<SelectContext contextDefinition={contextDefinition} explore={explore} />
				</Box>
			</Wrapper>
		</>
	);
};

export default ContextHeader;
