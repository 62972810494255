const palette = {
	main: {
		100: "#d9dcf0",
		200: "#b3b9e1",
		300: "#8c96d3",
		400: "#6673c4",
		500: "#4050b5",
		600: "#334091",
		700: "#26306d",
		800: "#1a2048",
		900: "#0d1024",
	},
	second: {
		100: "#d3d3d3",
		200: "#a7a7a7",
		300: "#7a7a7a",
		400: "#4e4e4e",
		500: "#222222",
		600: "#1b1b1b",
		700: "#141414",
		800: "#0e0e0e",
		900: "#070707",
	},
	primary: {
		main: "#4050B5",
		light: "#5b6dde",
		dark: "#424D96",
	},
	secondary: {
		main: "#222222",
		dark: "#171717",
		contrastText: "#EEEEEE",
	},
	contextDefault: {
		main: "#1686B6",
		light: "#2ca0d0",
		dark: "#116c94",
		contrastText: "#EAEAEA",
	},
	contextCriticalDates: {
		main: "#ec5c18",
		light: "#f9844a",
		dark: "#ac3e0a",
		contrastText: "#EAEAEA",
	},
	contextRent: {
		main: "#4d6f32",
		light: "#90be6d",
		dark: "#374f20",
		contrastText: "#EAEAEA",
	},
	contextPortfolio: {
		main: "#B8336A",
		light: "#ca447c",
		dark: "#8d1d4b",
		contrastText: "#EAEAEA",
	},
	contextGlobal: {
		main: "#781a29",
		light: "#650D1B",
		dark: "#410610",
		contrastText: "#EAEAEA",
	},
	contextProjects: {
		main: "#B8336A",
		light: "#ca447c",
		dark: "#8d1d4b",
		contrastText: "#EAEAEA",
	},
	// #870058 - pink
	// #6E0D25 - red
	// #96031A - red
	// #B8336A - magenta
	// #653239 - burgundy
	// #F9C74F - yellow
	// #FFBA08 - strong yellow
	// #5E0B15 - dark red
};

// overrides for MUI components

interface basicPaletteOptions {
	main: string;
	light: string;
	dark: string;
	contrastText: string;
}

declare module "@mui/material/styles/createPalette" {
	export interface PaletteOptions {
		contextDefault: basicPaletteOptions;
		contextCriticalDates: basicPaletteOptions;
		contextRent: basicPaletteOptions;
		contextPortfolio: basicPaletteOptions;
		contextGlobal: basicPaletteOptions;
		contextProjects: basicPaletteOptions;
	}
}

declare module "@mui/material/styles" {
	interface Palette {
		contextDefault: Palette["primary"];
		contextCriticalDates: Palette["primary"];
		contextRent: Palette["primary"];
		contextPortfolio: Palette["primary"];
		contextGlobal: Palette["primary"];
		contextProjects: Palette["primary"];
	}
}

declare module "@mui/material" {
	interface ButtonPropsColorOverrides {
		contextRent: true;
		contextDefault: true;
		contextCriticalDates: true;
		contextGlobal: true;
		contextPortfolio: true;
		contextProjects: true;
	}

	interface ButtonGroupPropsColorOverrides {
		contextRent: true;
		contextDefault: true;
		contextCriticalDates: true;
		contextGlobal: true;
		contextPortfolio: true;
		contextProjects: true;
	}
}

export default palette;
