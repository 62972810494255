import React from "react";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import StyledInput from "../styledComponents/StyledInput";

interface Props {
	handleChangeInput: (input: string) => void;
	filterText: string;
}

const SearchFields: React.FC<Props> = ({ handleChangeInput, filterText }: Props) => {
	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		handleChangeInput(event.target.value);
	}

	return (
		<StyledInput
			value={filterText}
			onChange={handleChange}
			variant="outlined"
			placeholder="Search"
			InputProps={{
				startAdornment: <SearchIcon fontSize="small" />,
				endAdornment: (
					<IconButton onClick={() => handleChangeInput("")}>
						<CloseIcon fontSize="small" />
					</IconButton>
				),
			}}
		/>
	);
};

export default SearchFields;
