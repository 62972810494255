import * as React from "react";
import useStyles from "./style";
import {BinaryFilter, UnaryFilter} from "@cubejs-client/core";
import Button from "@mui/material/Button";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {PinnedFilterDef} from "../../../utils/dashboard-utils";
// import purple from "@mui/material/colors/purple"
import grey from "@material-ui/core/colors/grey";
import {getOperatorByName, isUnaryOperator} from "../../../utils/filters";
import {FilterOperator} from "../../../utils/utils";
import deepPurple from "@mui/material/colors/deepPurple";

interface Props {
	pinnedFilter: PinnedFilterDef
	activeFilter?: BinaryFilter | UnaryFilter
	setAnchorEl: (target) => void
	handleClickedRemoveFilter: () => void
	anchorEl: HTMLElement | null
	popperId: string | undefined
	menuOpen: boolean
}

const MAX_CHARS = 70;

export const PinnedFilterMenuButton: React.FunctionComponent<Props> = (
	{
		pinnedFilter,
		activeFilter,
		setAnchorEl,
		handleClickedRemoveFilter,
		anchorEl,
		popperId,
		menuOpen,
	}: Props) => {
	const classes = useStyles();

	function getFilterText() {
		if (activeFilter) {
			const operator = getOperatorByName(activeFilter.operator, pinnedFilter.type) as FilterOperator;
			const operatorLabel = operator.title.toLowerCase();

			if (isUnaryOperator(activeFilter.operator)) {
				return operatorLabel;
			} else {
				let formattedValues = ``;
				let numFormattedValues = 0;
				(activeFilter as BinaryFilter).values.slice(0, 3).forEach(v => {
					const valueText = `${formattedValues.length > 1 ? ', ' : ''}${v}`;
					if (formattedValues.length + valueText.length <= MAX_CHARS) {
						formattedValues += valueText;
						numFormattedValues += 1;
					}
				});
				if ((activeFilter as BinaryFilter).values.length > numFormattedValues) {
					formattedValues += ` or ${(activeFilter as BinaryFilter).values.length - numFormattedValues} more`;
				}
				return `${operatorLabel} ${formattedValues}`;
			}
		} else {
			return "";
		}
	}

	function renderRemoveButton() {
		if (!activeFilter) {
			return null;
		}

		return <IconButton
			aria-label="delete"
			size="small"
			edge="end"
			sx={{ color: menuOpen ? grey[600] : grey[400], padding: '5px', marginLeft: '12px' }}
			onClick={(event) => {
				event.stopPropagation();
				handleClickedRemoveFilter();
			}}
		>
			<HighlightOffIcon
				fontSize="small"
				sx={{ fontSize: '1rem', verticalAlign: 'middle' }}
			/>
		</IconButton>
	}

	let btnCls = '';
	if (menuOpen) {
		btnCls += ' menuOpen';
	} else if (activeFilter) {
		btnCls += ' activeFilter';
	}

	return (
		<Button
			id={`pinned-filter-btn-${pinnedFilter.member}`}
			aria-describedby={popperId}
			className={btnCls}
			classes={{ root: classes.buttonRoot }}
			onClick={(event) =>
				setAnchorEl(anchorEl ? null : event.currentTarget)}
		>
			<FilterAltIcon
				fontSize="small"
				sx={{
					fontSize: '1rem',
					marginRight: '4px',
					color: activeFilter ? deepPurple[menuOpen ? 400 : 200] : grey[menuOpen ? 500 : 400]
				}}
			/>
			<span style={{ marginRight: '2px' }}>{pinnedFilter.label}</span>
			<span style={{ /* fontWeight: 'bold', */ /* color: '#555' */ }}>{getFilterText()}</span>
			{renderRemoveButton()}
		</Button>
	)
}