import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 'inherit',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(6, 6, 3, 6),
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    contentRoot: {
      alignSelf: 'center',
      overflow: 'hidden',
      padding: '0.5rem 2rem',
      width: '80vw',
      ['@media (min-width:1400px)']: { // eslint-disable-line no-useless-computed-key
        width: '60vw',
      },
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    gridRoot: {
      padding: theme.spacing(0, 3, 0, 0),
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    headerText: {
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    loadingContainer: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    loader: {
      position: 'absolute',
      top: '20%'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      height: '6rem',
      cursor: 'pointer',
    },
    labelText: {
      textAlign: 'center',
      display: 'flex',
      fontSize: '1.5rem',
    },
    grow: {
      flexGrow: 1,
    },
  }),
);

export default useStyles