import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => (
  createStyles({
    label: {
      'padding': '0 0 4px',
      'lineHeight': '1.5',
      'display': 'block',
    },

    listBox: {
      'width': '300px',
      // 'width': '100%',
      'margin': '2px 0 0',
      'padding': '0',
      'position': 'absolute',
      'list-style': 'none',
      'background-color': '#fff',
      'overflow': 'auto',
      'max-height': '250px',
      'border-radius': '4px',
      'box-shadow': '0 2px 8px rgba(0, 0, 0, 0.15)',
      'z-index': 1,

      '& li': {
        'padding': '5px 12px',
        'display': 'flex',

        '& span': {
          'flex-grow': 1,
        },

        '& svg': {
          color: 'transparent',
        },
      },

      '& li[aria-selected="true"]': {
        'background-color': '#fafafa',
        'font-weight': 600,

        '& svg': {
          'color': '#1890ff',
        },
      },

      '& li[data-focus="true"]': {
        'background-color': '#e6f7ff',
        'cursor': 'pointer',

        '& svg': {
          'color': '#000',
        },
      },
    },

    listBoxLi: {
      'padding': '5px 12px',
      'display': 'flex',

      '&:selected': {
        'background-color': '#fafafa',
        'font-weight': '600',

        '.svg': {
          'color': '#1890ff',
        },
      },

      '&:focus': {
        'background-color': '#e6f7ff',
        'cursor': 'pointer',

        '.svg': {
          'color': '#000',
        },
      },
    // },

      '& li[aria-selected=\'true\']': {
        'background-color': '#fafafa',
        'font-weight': '600',
        '& svg': {
          'color': '#1890ff',
        },
      },

      '& li[data-focus=\'true\']': {
        'background-color': '#e6f7ff',
        'cursor': 'pointer',

        '& svg': {
          'color': '#000',
        }
      },
    },

    listBoxSpan: {
      'flex-grow': '1',
    },

    listBoxSvg: {
      'color': 'transparent',
    },

    tagDiv: {
      'font-size': '0.8rem',
      'display': 'flex',
      'align-items': 'center',
      'height': '24px',
      'margin': '2px',
      'line-height': '22px',
      'background-color': '#fafafa',
      'border': '1px solid #e8e8e8',
      'border-radius': '2px',
      'box-sizing': 'content-box',
      'padding': '0 4px 0 10px',
      'outline': '0',
      'overflow': 'hidden',

      '&:focus': {
        borderColor: '#40a9ff',
        backgroundColor: '#e6f7ff',
      },
    },

    tagSpan: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    tagSvg: {
      fontSize: '12px',
      // 'font-size': '0.8rem',
      cursor: 'pointer',
      padding: '4px',
    },

    muiOutlinedInputRoot: {
      width: '100%',
      backgroundColor: '#ffffff',
      '&:focus': {
        backgroundColor: '#ffffff',
      }
    },

    inputWrapper: {
      // 'width': '300px',
      border: '1px solid #d9d9d9',
      backgroundColor: '#ffffff',
      borderRadius: 2,
      padding: '1px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',

      // '&:hover': {
      //   'border-color': '#40a9ff',
      // },

      '&.focused': {
        'border-color': '#40a9ff',
        'box-shadow': '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },

      // '& div': {
      //   'width': '100%',
      // }
    },

    inputRoot: {
      // 'font-size': '14px',
      fontSize: '0.8rem',
      height: '30px',
      boxSizing: 'border-box',
      padding: '4px 6px',
      // 'width': '0',
      width: '100%',
      minWidth: '30px',
      flexGrow: 1,
      border: '0',
      margin: '0',
      outline: '0',
      backgroundColor: '#ffffff',

      '&.multi-value': {
        'width': '0',
        // 'border': '0',
        // 'margin': '0',
        // 'outline': '0',
      }
    },

    inputAdornment: {
      marginRight: '6px',
    }
  })
));

export default useStyles;