import React from "react";
import { getNewArrayOrder } from "../../../utils/utils";
import { ProgressResult, Query, QueryOrder, ResultSet, TQueryOrderArray } from "@cubejs-client/core";
import { ResponsiveContainer } from "recharts";
import TableRenderer from "../../ReportTable/TableRenderer/TableRenderer";
import {useFieldContext} from "../../../context/providers/FieldContextProvider";

type Props = {
    runPanelQuery: (query: Query) => Promise<void>;
	vizDisplayProps;
	rs: ResultSet;
	query?: Query;
	progress?: ProgressResult;
	fieldsOrder?: string[]
};

const Table = ({ query, runPanelQuery, vizDisplayProps, rs, progress, fieldsOrder }: Props) => {
	const fieldContext = useFieldContext();
	React.useEffect(() => {
		if (fieldsOrder) {
			fieldContext.initializeFieldsFromMemberList(fieldsOrder);
		}
	}, [fieldsOrder]);

	function handleSetQueryOrder(member: string, direction: QueryOrder) {
		if (query) {
			const newOrderArray = getNewArrayOrder(query.order as TQueryOrderArray, member, direction);
			runPanelQuery({
				...query,
				order: newOrderArray,
			});
		} else {
			console.warn(`No query, can't update order`);
		}
	}

	return (
		<ResponsiveContainer width="100%" height={vizDisplayProps.height || 400}>
			<div style={{ display: "flex", height: "100%", maxHeight: 400 }}>
				<TableRenderer
					query={query}
					resultSet={rs}
					isLoading={undefined}
					progress={progress}
					error={undefined}
					isDashboardPanel={true}
					setQueryOrder={handleSetQueryOrder}
				/>
			</div>
		</ResponsiveContainer>
	);
};

export default Table;
