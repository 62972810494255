import { Box, List, Typography } from "@mui/material";
import React from "react";
import Header from "../Header";
import SearchFields from "../SearchFields";
import Wrapper from "./Wrapper";
import { useTheme } from "@mui/material/styles";
import { useCubeMetaState } from "../../../../context/cube-meta-context";
import FieldList from "../FieldList";
import { Set } from "immutable";
import {BinaryFilter, Cube, TCubeMember, UnaryFilter} from "@cubejs-client/core";
import { useQueryState } from "../../../../context/query-context";
import useDebounce from "../../../../hooks/useDebounce";

interface Props {
	visibleCubes: Cube[]
}

const AllFields: React.FC<Props> = (
	{
		visibleCubes
	}: Props) => {
	const cubeMetaState = useCubeMetaState()
	const theme = useTheme();
	const [allFieldsFilterText, setAllFieldsFilterText] = React.useState("");
	const debouncedAllFieldsFilterText = useDebounce(allFieldsFilterText, 250)
	const { query } = useQueryState();

	const filterActive = (member: TCubeMember) => {
		return query.filters && query.filters.map((f: BinaryFilter | UnaryFilter) => f.member).includes(member.name);
	};

	const cubesMetaLike = cubeMetaState.cubesMetaLike;
	if (!cubesMetaLike) {
		return <></>;
	}

	const filteredCubes = filterCubes(visibleCubes, debouncedAllFieldsFilterText);

	const [open, setOpen] = React.useState(Set());

	const handleClickCube = (key: string) => {
		// const element = event.target
		// const y = element.getBoundingClientRect().top
		// event.target.scrollIntoView( { behavior: 'smooth', top: y } );
		if (open.has(key)) {
			setOpen(open.remove(key));
		} else {
			setOpen(Set([key]));
		}
		return;
	};

	const [grouped, setGrouped] = React.useState(true);

	React.useEffect(() => {
		if (debouncedAllFieldsFilterText.length > 0) {
			setGrouped(false);
		} else {
			setGrouped(true);
		}
	}, [debouncedAllFieldsFilterText])

	return (
		<>
			<Wrapper>
				<Box sx={{ marginLeft: "64px", px: theme.spacing(2) }}>
					<Header>
						<div>
							all <span>fields</span>
						</div>
					</Header>
					<SearchFields handleChangeInput={setAllFieldsFilterText} filterText={allFieldsFilterText} />
				</Box>
			</Wrapper>
			<Box
				sx={{
					marginLeft: "64px",
					px: theme.spacing(2),
					position: "relative",
					flex: 1,
					marginRight: theme.spacing(1),
				}}
			>
				<List
					className="scroll-container"
					sx={{
						position: "absolute",
						maxHeight: "calc(100% - 20px)",
						width: "94%",
						mb: theme.spacing(2),
						overflowY: "scroll",
						paddingRight: theme.spacing(0),
					}}
				>
					{filteredCubes.map((cube, idx) => (
						<FieldList
							key={idx + '-' + cube.name}
							cube={cube}
							handleClickCube={handleClickCube}
							expanded={open.has(cube.name)}
							grouped={grouped}
							filterActive={filterActive}
						/>
					))}
				</List>
			</Box>
		</>
	);
};

export default AllFields;

function filterCubes(cubes: Cube[], filterText: string) {
	const normFilterText = normalizeText(filterText);
	const data = JSON.parse(JSON.stringify(cubes));
	return data.filter((cube) => {
		if (normalizeText(cube.title).includes(normFilterText)) {
			return true;
		} else {
			cube.dimensions = cube.dimensions.filter((dimension) => {
				return normalizeText(dimension.shortTitle).includes(normFilterText);
			});
			cube.measures = cube.measures.filter((measure) => {
				return normalizeText(measure.shortTitle).includes(normFilterText);
			});

			return !(cube.dimensions.length === 0 && cube.measures.length === 0);
		}
	});
}

function normalizeText(text: string): string {
	return text.replace(/\s/g, "").toLowerCase();
}
