import React, { MouseEvent } from "react";
import StyledDrawer from "./styledComponents/StyledDrawer";
import ContextHeader from "./components/layout/ContextHeader";
import ActiveFields from "./components/layout/ActiveFields";
import AllFields from "./components/layout/AllFields";
import { ExploreQueryDefinition, SchemaContextDefinition, VisibleCube } from "../../utils/cube-utils";
import { Cube } from "@cubejs-client/core";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { Box } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import animations from "../../theme/animations";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import useContextDefinition from "../../hooks/useContextDefinition";

type Props = {
	namedReportPageRef: React.RefObject<HTMLDivElement>;
};

const ExploreSideBar = ({ namedReportPageRef }: Props) => {
	const theme = useTheme();
	const cubeMetaState = useCubeMetaState();
	const fieldContext = useFieldContext();

	const { contextDefinition, explore } = useContextDefinition() as {
		contextDefinition: SchemaContextDefinition;
		explore: ExploreQueryDefinition;
	};

	// resize information
	const [isResizing, setIsResizing] = React.useState(false);
	const sidebarRef = React.useRef<HTMLDivElement>(null);

	const cursorRef = React.useRef<HTMLDivElement>(null);

	const drawerRef = React.useRef<HTMLDivElement>(null);

	const [collapsed, setCollapsed] = React.useState(false);

	React.useEffect(() => {
		// check if local storage has a saved sidebar width
		const savedSidebarWidth = localStorage.getItem("sidebarWidth");
		if (savedSidebarWidth) {
			if (!namedReportPageRef.current || !drawerRef.current) {
				return;
			}

			namedReportPageRef.current.style.marginLeft = parseInt(savedSidebarWidth) - 80 + "px";
			drawerRef.current.style.setProperty("--sidebarWidth", savedSidebarWidth);
		}
	}, []);
	
	if (!cubeMetaState.cubesMetaLike) {
		return <></>;
	}

	const dimensions = fieldContext.getDimensionFields();
	const measures = fieldContext.getMeasureFields();

	if (!explore) {
		console.log(`ExploreSideBar > no explore, returning <></>`);
		return <></>;
	}

	const { cubes } = cubeMetaState.cubesMetaLike;
	const visibleCubes = filterVisibleCubes(cubes, explore.visibleCubes, contextDefinition['hidden_cubes']);

	const updateCircleGlowMousePosition = (e: MouseEvent) => {
		if (cursorRef.current) {
			cursorRef.current.style.top = e.clientY - 50 + "px";
			cursorRef.current.style.left = e.clientX - 50 + "px";
		}
	};

	// handle resize actions
	// disable selection when resizing
	function disableSelect(event) {
		event.preventDefault();
	}

	// when the resize handle is clicked we listen for mouse move events, disable selection,
	// and wait till the mouse is released at which point we are no longer resizing
	const handleResizeMouseDown = (e) => {
		setIsResizing(true);
		document.addEventListener("mousemove", handleResizeMouseMove);
		document.addEventListener("mouseup", handleResizeMouseUp);
		document.addEventListener("selectstart", disableSelect);
	};

	const handleResizeMouseUp = () => {
		setIsResizing(false);
		document.removeEventListener("mousemove", handleResizeMouseMove);
		document.removeEventListener("mouseup", handleResizeMouseUp);
		document.removeEventListener("selectstart", disableSelect);

		if (drawerRef.current) {
			localStorage.setItem("sidebarWidth", drawerRef.current.style.getPropertyValue("--sidebarWidth"));
		}
	};


	const handleResizeMouseMove = (e) => {
		if (!namedReportPageRef.current || !drawerRef.current) return;
		// we need to get the offset of the sidebar from the left of the page
		const offsetRight = e.clientX - document.body.offsetLeft;

		const max = document.body.offsetWidth * 0.33;
		const min = 324;
		if (offsetRight <= min / 2 && drawerRef.current.style.getPropertyValue("--sidebarWidth") === min + "px" && !collapsed) {
			namedReportPageRef.current.style.marginLeft = 75 - 80 + "px";
			drawerRef.current.style.setProperty("--sidebarWidth", 75 + "px");
			handleResizeMouseUp();
			setCollapsed(true);
			return;
		}
		if (collapsed) {
			if (offsetRight > min / 2) {
				namedReportPageRef.current.style.marginLeft = min - 80 + "px";
				drawerRef.current.style.setProperty("--sidebarWidth", min + "px");
				setCollapsed(false);
			}
			return;
		}
		let newWidth = offsetRight;
		if (newWidth > max) newWidth = max;
		if (newWidth < min) newWidth = min;

		// namedReportPageRef.current.style.setProperty("--sidebarWidth", offsetRight + "px");
		namedReportPageRef.current.style.marginLeft = newWidth - 80 + "px";
		drawerRef.current.style.setProperty("--sidebarWidth", newWidth + "px");

	};


	return (
		<StyledDrawer
			variant="permanent"
			anchor="left"
			onMouseMove={(e) => updateCircleGlowMousePosition(e)}
			ref={drawerRef}
		>
			<Box
				onMouseDown={handleResizeMouseDown}
				sx={{
					backgroundColor: isResizing ? theme.palette.primary.light : "rgba(255, 255, 255, 0.2)",
					width: "5px",
					height: "100%",
					position: "absolute",
					right: 0,
					cursor: "ew-resize",
					opacity: isResizing ? 1 : 0,
					transition: "opacity 0.2s ease-in-out",
					"&:hover": {
						opacity: 1,
					},
				}}
			/>
			<Box
				sx={{
					width: "100%",
					height: "20vh",
					backgroundImage: `linear-gradient(${alpha(contextDefinition.color, 0.15)}, transparent 75%)`,
					position: "absolute",
					zIndex: -1,
					animation: animations.fadeInSlow,
					animationDelay: "0.5s",
				}}
			></Box>
			{!collapsed && (
				<>
					<ContextHeader contextDefinition={contextDefinition} explore={explore!} />
					<ActiveFields dimensions={dimensions} measures={measures} />
					<AllFields visibleCubes={visibleCubes} />
				</>
			)}
			<div
				ref={cursorRef}
				className="cursor"
				style={{
					pointerEvents: "none",
					width: "100px",
					height: "100px",
					borderRadius: "100%",
					backgroundColor: "white",
					filter: "blur(50px)",
					position: "absolute",
					mixBlendMode: "exclusion",
					// opacity: 0,
					left: 0,
					top: 0,
					zIndex: -1,
				}}
			/>
		</StyledDrawer>
	);
};

export default ExploreSideBar;

interface VisibleCubeWithOrder extends VisibleCube {
	order: number
}

function filterVisibleCubes(cubes: Cube[], visibleCubes: VisibleCube[], hiddenCubes: string[]): Cube[] {
	const filterHidden = (cube: Cube) => !hiddenCubes.includes(cube.name);

	if (!visibleCubes.length) {
		return cubes.filter(filterHidden);
	}

	const filteredCubes = cubes
		.filter(filterHidden)
		.filter(cube => visibleCubes.map(vc => vc.name).includes(cube.name))
		.map(cube => {
			const visibleCube = visibleCubes
				.map((_, order) => Object.assign(_, { order }))
				.find(vc => vc.name === cube.name) as VisibleCubeWithOrder;

			const filteredCube = Object.assign({}, cube);
			return Object.assign(filteredCube, {
				dimensions: visibleCube.dimensions === true ? filteredCube.dimensions
					: visibleCube.dimensions === false ? []
						: filteredCube.dimensions.filter(dimension => (visibleCube.dimensions as string[]).includes(dimension.name)),
				measures: visibleCube.measures === true ? filteredCube.measures
					: visibleCube.measures === false ? []
						: filteredCube.measures.filter(dimension => (visibleCube.measures as string[]).includes(dimension.name)),
				order: visibleCube.order,
			})
		});

	filteredCubes.sort((cube1, cube2) =>
		cube1.order > cube2.order ? 1 : -1
	);
	return filteredCubes;
}