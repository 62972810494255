import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import blueGrey from "@material-ui/core/colors/blueGrey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '0.5rem',
      borderRadius: '3px',
      display: 'flex',  // side by side boxes for fields + filters
      zIndex: 1,
    },

    subRoot: {
      flex: 1,
      margin: 0,
      backgroundColor: blueGrey[50],
    },

    title: {
      margin: theme.spacing(4, 0, 2),
    },

    listRoot: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },

    listItemRoot: {
      padding: '0 1rem',
      borderBottom: '1px solid #e0e0e0',
      "&:last-child": {
        borderBottom: 'none',
      },
    },

    iconSmall: {
      width: '1rem',
      height: '1rem',
    },

    filtersAreaPlaceholderText: {
      padding: '0.8rem',
      fontSize: '0.8rem',
      color: 'grey',
    },
  }),
);

export default useStyles;