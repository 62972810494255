import { styled } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";
import { alpha } from "@mui/material/styles";

const StyledNavigationButton = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== "open" && prop !== "active" && prop !== "expandable" && prop !== "expanded",
})<{ open?: boolean; active?: boolean; expandable: boolean | undefined; expanded: boolean | undefined }>(
	({ theme, open, active, expandable, expanded }) => ({
		color: theme.palette.secondary.contrastText,
		borderRadius: open ? (expanded ? "25px 0 0 0px" : "50px 0 0 50px") : "50px",
		backgroundColor: active
			? theme.palette.primary.main
			: open
			? alpha(theme.palette.secondary.contrastText, 0.15)
			: "transparent",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginTop: theme.spacing(2),
		height: "50px",
		paddingLeft: "13px",
		outline: active ? `3px solid ${alpha(theme.palette.primary.main, 0.25)}` : "",
		"& .expand-btn": {
			display: "none !important",
		},
		"&:hover": {
			color: active
				? alpha(theme.palette.getContrastText(theme.palette.primary.main), 0.5)
				: theme.palette.getContrastText(theme.palette.primary.main),
			backgroundColor: active ? theme.palette.primary.dark : alpha(theme.palette.secondary.contrastText, 0.25),
			"& .expand-btn": {
				display: expandable ? "block !important" : "none",
			},
			"& .MuiSvgIcon-root": {
				display: expandable ? "none" : "block",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "inherit",
			transition: "inherit",
			marginRight: open ? "12px" : "0px",
			justifyContent: "center",
			display: "block",
		},
		"& .MuiListItemText-root": {
			fontFamily: "Outfit",
			width: open ? "auto" : 0,
			opacity: open ? 1 : 0,
			maxWidth: open ? "auto" : 0,
			transition: "inherit",
			overflow: "hidden",
			textTransform: "capitalize",
		},
	})
);

export default StyledNavigationButton;
