import React  from "react";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import DateInput from "../../DateInput/DateInput";
import DSInputBase from "../../DSInputBase/DSInputBase";
import Dropdown from "../../Dropdown/Dropdown";
import DateRangeInput from "../../DateInput/DateRangeInput";
import {FilterOperatorName} from "../../../utils/utils";
import MultipleSelectCheckmarks from "../../PinnedFilters/MultipleSelectCheckmarks/MultipleSelectCheckmarks";
import {TCubeDimension, TCubeMeasure} from "@cubejs-client/core";

interface Props {
  operator: FilterOperatorName
  cubeMember: TCubeDimension | TCubeMeasure
  values: string[]
  setValues: (newValue: string[]) => void
  pinnedFilter: boolean
}

const QueryFilterInput: React.FunctionComponent<Props> = (
  {
    operator,
    cubeMember,
    values,
    setValues,
    pinnedFilter,
  }: Props): React.ReactElement => {

  const handleChangeDropdownValue = (newValue: string[] | string) => {
    // console.log(`QueryFilterInput > handleChangeDropdownValue > newValue=`, newValue);
    if (Array.isArray(newValue)) {
      setValues(newValue);
    } else {
      setValues([newValue]);
    }
  }

  if (["set", "notSet"].includes(operator)) {
    return <></>
  }

  switch (cubeMember.type) {
    case 'time': {
      if (operator === "inDateRange" || operator === "notInDateRange") {
        return <div
          id={'query-filter-input-datepicker-container'}
          style={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <DateRangeInput
            value={values}
            onValueChanged={(newValue: string[]) => setValues(newValue)}
          />
        </div>
      }

      return <div className={'input-container date'}>
        <DateInput
          value={values[0]}
          onValueChanged={(newValue: string) => setValues([newValue])}
        />
      </div>
    }
    case 'number': {
      if (cubeMember.meta && cubeMember.meta.isBoolean) {
        const boolOptions = [{ value: "1", label: "False"}, { value: "0", label: "True"}]
        return <div className={'input-container boolean'}>
          <Dropdown
            value={values[0]}
            options={boolOptions}
            handleSetValue={handleChangeDropdownValue}
          />
        </div>
      } else {
        return <div className={'input-container numeric'}>
          <DSInputBase
            className={'numeric'}
            value={values[0]}
            type="number"
            onChange={(event)=> setValues([event.target.value])}
          />
        </div>
      }
    }
    case 'string': {
      if (cubeMember.meta && cubeMember.meta.isBoolean) {
        const boolOptions = [{ value: "Yes", label: "Yes"}, { value: "No", label: "No"}]
        return <div className={'input-container boolean'}>
          <Dropdown
            value={values[0] || ""}
            options={boolOptions}
            handleSetValue={handleChangeDropdownValue}
          />
        </div>
      } else {
        return <div className={'input-container text'}>
          {pinnedFilter
            ? <MultipleSelectCheckmarks
              cubeDimension={cubeMember as TCubeDimension}
              values={values}
              handleSetValue={handleChangeDropdownValue}
            />
            : <MultiSelectDropdown
              cubeDimension={cubeMember as TCubeDimension}
              values={values}
              handleSetValue={handleChangeDropdownValue}
              isDropdown={!!(cubeMember.meta && cubeMember.meta.isDropdown)}
            />
          }
        </div>
      }
    }
    default: {
      console.warn(`QueryFilterInput > Non-implemented filter for member type=${cubeMember.type}`);
      return <div className={'input-container text'}>
        <MultiSelectDropdown
          cubeDimension={cubeMember as TCubeDimension}
          values={values}
          handleSetValue={handleChangeDropdownValue}
          isDropdown={false}
        />
      </div>
    }
  }
}

export default QueryFilterInput;