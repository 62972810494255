import React from "react"
import {Box, Typography} from "@mui/material";


interface Props {
  cubeTitle: string
  showCubeTitle: boolean
  memberTitle: string
  isDisabled?: boolean
}

const style1 = { fontSize: '13px', display: 'inline', fontWeight: 'normal', lineHeight: "1px" };
const style2 = { fontSize: '13px', display: 'inline', fontWeight: '600', lineHeight: "1px" };

const CubeFieldLabel: React.FC<Props> = ({ cubeTitle, showCubeTitle, memberTitle, isDisabled }: Props) => (
  <Box sx={{lineHeight: "95%"}}>
    {showCubeTitle
      ? <Typography variant='subtitle1' sx={{...style1, color: isDisabled ? '#b1b1b1' : undefined }} gutterBottom={false}>{cubeTitle} </Typography>
      : <></>}
    <Typography variant='subtitle1' sx={{...style2, color: isDisabled ? '#b1b1b1' : undefined }} gutterBottom={false}>{memberTitle}</Typography>
  </Box>
)

export default CubeFieldLabel