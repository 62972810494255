import { ArrowRight } from "@mui/icons-material";
import { alpha, Box, MenuItem, useTheme, Typography } from "@mui/material";
import React from "react";
import NestedMenu from "./NestedMenu";
import { menuItemOptions, menuItemType } from "./menuItemType";
import ArrowTooltip from "../ToolTip/ArrowTooltip";

type Props = {
	menuOpen: boolean;
	item: menuItemType;
	options: menuItemOptions;
	index: number;
	depth: number;
	activeDepth: number;
	activeIndex: number;
	setActiveIndex: (number) => void;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveDepth: (number) => void;
	color?: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NestedMenuItem = ({
	menuOpen,
	item,
	index,
	depth,
	activeIndex,
	setActiveIndex,
	setMenuOpen,
	activeDepth,
	setActiveDepth,
	options,
	color,
	setOpen,
}: Props) => {
	const theme = useTheme();
	const menuItemRef = React.useRef<HTMLLIElement>(null);
	const hovering = React.useRef(false);

	const textRef = React.useRef<HTMLParagraphElement>(null);
	const [textOverflow, setTextOverflow] = React.useState(false);
	// recalculate text overflow on resize
	React.useEffect(() => {
		const text = textRef.current;
		if (!text) return;
		const observer = new ResizeObserver(() => {
			if (text.offsetWidth < text.scrollWidth) {
				setTextOverflow(true);
			} else {
				setTextOverflow(false);
			}
		});
		
		observer.observe(text);
		return () => observer.unobserve(text);
	}, []);

	const children = item.children ? item.children : [];

	const style = {
		base: {
			backgroundColor:
				activeIndex === index && children.length > 0
					? "rgba(255, 255, 255, 0.2)"
					: options.active
					? theme.palette.primary.main
					: "transparent !important",
			padding: "0px 8px",
			paddingRight: children.length > 0 ? "0px" : "8px",
			borderRadius: 1,
			"& .content-wrapper": {
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				
				"& .label-container": {
					display: "flex",
					alignItems: "center",
					marginRight: "1ch",
				},
				"& .label": {
					overflow: "hidden",
					maxWidth: '150px',
					textOverflow: "ellipsis",
					fontSize: "11px",
					margin: 0,
					fontFamily: "Roboto",
					fontWeight: "bold",
				},
				"& .secondary-label": {
					fontSize: "11px",
					margin: 0,
					fontFamily: "Roboto",
					fontWeight: "normal",
					opacity: 0.5,
				},
			},
			overflow: "visible",
			"&:hover": {
				backgroundColor:
				(options.color ? options.color : color ? theme.palette[color].main : theme.palette.primary.light) +
				" !important",
			},
			marginBottom: options.divider ? "4px" : "0px",
			"&:before": {
				content: '""',
				display: "block",
				position: "absolute",
				bottom: "-2px",
				left: "0px",
				width: "100%",
				height: options.divider ? "1px" : "0px",
				backgroundColor: "rgba(255, 255, 255, 0.2)",
			},
		},
	};

	function handleClick() {
		setOpen(false);
		item.onClick();
	}

	function handleMouseEnter() {
		hovering.current = true;
		setActiveDepth(() => depth);
		setTimeout(() => {
			if (hovering.current) {
				setActiveIndex(() => index);
			}
		}, 100);
	}

	function handleMouseLeave() {
		hovering.current = false;
	}

	return (
		<>
			<MenuItem
				ref={menuItemRef}
				onClick={handleClick}
				disabled={options.disabled}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				disableGutters
				sx={{ ...style.base }}
			>
				<Box className="content-wrapper">
					<Box className="label-container">
						{options.startIcon}
						<ArrowTooltip title={textOverflow ? item.label : ""} placement="right">
							<Typography className="label" variant="subtitle1" ref={textRef}>
								{item.label}
							</Typography>
						</ArrowTooltip>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
						{!options.disabled && (
							<Typography className="secondary-label" variant="subtitle1">
								{/* shortcut text coming soon */}
								{options.shortcut}
							</Typography>
						)}
						{options.endIcon}
						{children.length > 0 && <ArrowRight />}
					</Box>
				</Box>
			</MenuItem>
			{children.length > 0 && (
				<NestedMenu
					open={menuOpen}
					menuItems={children}
					activeDepth={activeDepth}
					setActiveDepth={setActiveDepth}
					setOpen={setMenuOpen}
					isChild={true}
					parentOpen={index === activeIndex}
					anchorEl={menuItemRef.current}
					depth={depth + 1}
				/>
			)}
		</>
	);
};

export default NestedMenuItem;
