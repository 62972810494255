import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formCtrlRoot: {
      width: '-webkit-fill-available',
    },

    queryFilterContainer: {
      fontSize: '0.8rem',
      display: 'flex',
      position: 'relative',
      boxSizing: 'border-box',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      flexGrow: 1,
    },

    outlinedInput: {
      paddingTop: '9px',
      paddingBottom: '8px',
      fontSize: '0.8rem',
      height: '1.5rem',
    },

    blue: {
      color: blue[500],
      marginRight: theme.spacing(1),
      fontSize: '1.2rem',
    },

    grey: {
      color: grey[500],
      marginRight: theme.spacing(1),
      fontSize: '1.2rem',
    },

    filterMember: {
      // fontSize: '0.8rem',
      margin: theme.spacing(0, 0.5),
      '&.member-name': {
        width: 200,
      },
      '&.operator': {
        width: 200,
      },
      '&.values-input': {
        '& .input-container': {
          '&.text': {
            width: '25vw',
          },
          '&.numeric': {
            width: 200,
          },
          '&.date': {
            minWidth: 200,
          },
          '&.boolean': {
            width: 100,
          },
        },
      },

    },

    listItemTextPrimary: {
      fontSize: '0.8rem',
      fontWeight: 'bolder',
    },
  }),
);

export default useStyles;