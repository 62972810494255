import {
	add,
	sub,
	startOfDay,
	endOfDay,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	startOfYear,
	startOfQuarter,
	endOfMonth,
	endOfQuarter,
	endOfYear,
	getDay,
	getWeek,
	getMonth,
	getQuarter,
	getYear,
	format,
} from "date-fns";

interface unitFunctions {
	start: (date: Date) => Date;
	end: (date: Date) => Date;
	get: (date: Date) => number;
}

class RelativeDate {
	relativeString: string;
	now: Date;
	unit: string;
	units: number;
	direction: number;
	functions: {
		days: unitFunctions;
		weeks: unitFunctions;
		months: unitFunctions;
		quarters: unitFunctions;
		years: unitFunctions;
	};

	constructor(relativeString: string) {
		this.relativeString = relativeString.toLowerCase();
		this.now = new Date();

		// get the unit from the relative string
		if (relativeString.includes("day")) {
			this.unit = "days";
		} else if (relativeString.includes("week")) {
			this.unit = "weeks";
		} else if (relativeString.includes("month")) {
			this.unit = "months";
		} else if (relativeString.includes("quarter")) {
			this.unit = "quarters";
		} else if (relativeString.includes("year")) {
			this.unit = "years";
		}


		// get the direction from the relative string
		this.direction = 0; // 0 is current day, week, month, ..., 1 is forward, -1 is backward
		if (relativeString.includes("next")) {
			this.direction = 1;
		} else if (relativeString.includes("last")) {
			this.direction = -1;
		} else {
			switch (relativeString) {
				case "yesterday":
					this.direction = -1;
					break;
				case "tomorrow":
					this.direction = 1;
					break;
			}
		}

		// determine how many units to go
		this.units = 0;

		// get the number of units to go using regex
		const getIntRegex = /\d+/g;
		const matches = relativeString.match(getIntRegex);
		if (matches) {
			this.units = parseInt(matches[0]);
		}

		// if regex didn't find any number then check for edge cases
		if (this.units === 0) {
			if (relativeString === "yesterday" || relativeString === "tomorrow") {
				this.units = 1;
			} else {
				if (relativeString.includes("next") || relativeString.includes("last")) {
					this.units = 1;
				}
			}
		}

		// if the unit is plural then add an s to the unit
		if (this.unit[this.unit.length - 1] !== "s") {
			this.unit = this.unit + "s"; // date fns takes plural
		}

		this.functions = {
			days: {
				start: startOfDay,
				end: endOfDay,
				get: getDay,
			},
			weeks: {
				start: startOfWeek,
				end: endOfWeek,
				get: getWeek,
			},
			months: {
				start: startOfMonth,
				end: endOfMonth,
				get: getMonth,
			},
			quarters: {
				start: startOfQuarter,
				end: endOfQuarter,
				get: getQuarter,
			},
			years: {
				start: startOfYear,
				end: endOfYear,
				get: getYear,
			},
		};
	}

	subtraction(date: Date, unit: string, units: number) {
		switch (unit) {
			case "days":
				return sub(date, { days: units });
			case "weeks":
				return sub(date, { weeks: units });
			case "months":
				return sub(date, { months: units });
			case "quarters":
				return sub(date, { months: units * 3 }); // a quarter is 3 months
			case "years":
				return sub(date, { years: units });
		}
		return date;
	}

	addition(date: Date, unit: string, units: number) {
		switch (unit) {
			case "days":
				return add(date, { days: units });
			case "weeks":
				return add(date, { weeks: units });
			case "months":
				return add(date, { months: units });
			case "quarters":
				return add(date, { months: units * 3 }); // a quarter is 3 months
			case "years":
				return add(date, { years: units });
		}
		return date;
	}

	getUtc(date: Date): Date {
		return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	}

	getRange() {
		let startDay = this.now;
		let endDay = this.now;

		if (this.direction === -1) {
			startDay = this.subtraction(startDay, this.unit, this.units);
			startDay = this.functions[this.unit].start(startDay);
			endDay = this.subtraction(this.now, this.unit, 1);
			endDay = this.functions[this.unit].end(endDay);
		} else if (this.direction === 1) {
			endDay = this.addition(this.now, this.unit, this.units);
			endDay = this.functions[this.unit].end(endDay);
			startDay = this.addition(this.now, this.unit, 1);
			startDay = this.functions[this.unit].start(startDay);
		} else {
			startDay = this.functions[this.unit].start(this.now);
			endDay = this.functions[this.unit].end(this.now);
		}
		
		startDay = this.getUtc(startDay);
		// startDay = zonedTimeToUtc(startDay, "US/Western");
		// endDay = zonedTimeToUtc(endDay, "US/Western");
		endDay = this.getUtc(endDay);
		// if (this.direction === -1) {
		// 	startDay = this.addition(startDay, "days", 1);
		// } else {
		// 	endDay = this.subtraction(endDay, "days", 1);
		// }
		startDay = endOfDay(startDay);
		startDay = add(startDay, { hours: 1 });

		return { startDay, endDay };
	}

	getRangeString() {
		const { startDay, endDay } = this.getRange();

		function getDateFormat() {
			return "MMM dd, yyyy";
		}
		const dateFormat = getDateFormat();
		const startDayString = format(startDay, dateFormat);
		const endDayString = format(endDay, dateFormat);

        return `${startDayString} - ${endDayString}`;
	}

	getEndDayString() {
		const { endDay } = this.getRange();

		function getDateFormat() {
			return "MMM dd, yyyy";
		}
		const dateFormat = getDateFormat();
		const endDayString = format(endDay, dateFormat);

		return endDayString;
	}
}

export default RelativeDate;
