import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles(() => (
  createStyles({
    cellText: {
      '&.clickable': {
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
          textDecorationLine: '',
          textDecoration: "underline",
        }
      }
    }
  })
));

export default useStyles;