import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from "../../config/frontendConfig";
mixpanel.init(MIXPANEL_TOKEN);

let env_check = !!MIXPANEL_TOKEN;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;


export const eventNames = {
  exitedPage: "Exit page",
  loginSuccessful: "Login success",
  loginFailed: "Login failure",
  loggedOut: "Logout",
  selectedEnv: "Select environment",
  ranQuery: "Run query",
  savedReport: "Save report",
  deletedReport: "Delete report",
  downloadCSV: "Download CSV",
  logRocket: "Log Rocket",
}