import { createStyles, makeStyles } from "@material-ui/core/styles";
import { grey, red } from "@material-ui/core/colors";

const useStyles = makeStyles(() =>
  createStyles({
    // form control label
    formControlRoot: {
      float: 'right',
    },
    label: {
      fontSize: '0.8rem',
      color: '#777',
    },

    // switch
    sizeSmall: {
      padding: 9,
      '& .MuiSwitch-thumb': {
        width: 13,
        height: 13,
      },
      '& .MuiSwitch-switchBase': {
        padding: 5,
      },
    },
    switchBase: {
      color: grey[400],
      '&.Mui-checked': {
        color: red[500],
      },
      '&.Mui-checked + .MuiSwitch-track': {
      // '&$checked + $track': {
        backgroundColor: red[500],
      },
    },
  })
)

// export const CustomSwitch = withStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: 28,
//       height: 16,
//       padding: 0,
//       display: 'flex',
//     },
//     switchBase: {
//       padding: 2,
//       color: theme.palette.grey[500],
//       '&$checked': {
//         transform: 'translateX(12px)',
//         color: theme.palette.common.white,
//         '& + $track': {
//           opacity: 1,
//           backgroundColor: theme.palette.primary.main,
//           borderColor: theme.palette.primary.main,
//         },
//       },
//     },
//     thumb: {
//       width: 12,
//       height: 12,
//       boxShadow: 'none',
//     },
//     track: {
//       border: `1px solid ${theme.palette.grey[500]}`,
//       borderRadius: 16 / 2,
//       opacity: 1,
//       backgroundColor: theme.palette.common.white,
//     },
//     checked: {},
//   }),
// )(Switch);

export default useStyles;