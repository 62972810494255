import React from "react";
import { Button, Theme, Typography } from "@mui/material";
import logoImg from "../../../img/logo-reversed.png";
import logoIcon from "../../../img/logo-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "../../../context/auth-context";

interface Props {
	open: boolean;
	theme: Theme;
}

const Logo: React.FC<Props> = ({ open, theme }: Props) => {
	const { currentEnvironment } = useAuthState();

	const transition = theme.transitions.create("all", {
		easing: theme.transitions.easing.sharp,
		duration: "150ms",
	});

	return (
		<Button
			href={currentEnvironment ? `/${currentEnvironment["env_name"]}` : "/"}
			sx={{
				marginTop: theme.spacing(2),
				transition,
				padding: 0,
				borderRadius: 0,
				opacity: 0.5,
				"&:hover": {
					opacity: 1,
				},
			}}
			disableRipple
		>
			<img src={logoIcon} style={{ maxHeight: "32px" }} />
			<img
				src={logoImg}
				style={{
					height: "32px",
					maxWidth: open ? "106.53px" : "0",
					objectFit: "cover",
					objectPosition: "120.5%",
					marginLeft: open ? "10px" : "0",
					transformOrigin: "left",
					transform: open ? "scale(0.9) translateY(-5px)" : "scaleX(0)",
					transition,
				}}
			/>
			<Typography
				sx={{
					opacity: open ? 1 : 0,
					position: "absolute",
					color: theme.palette.secondary.contrastText,
					bottom: "-5px",
					transformOrigin: "left",
					transform: open ? "scale(0.8) translateX(-7px)" : "scaleX(0)",
					textTransform: "capitalize",
					transitionDelay: "150ms",
					transition,
				}}
			>
				Reports
			</Typography>
		</Button>
	);
};

export default Logo;
