import React from "react";
import {Box, Grid, Stack} from "@mui/material";
import DashboardPanel from "../DashboardPanel/DashboardPanel";
import useStyles from "./style";
import {COLOR_PALETTES, DashboardDef} from "../../utils/dashboard-utils";
import {Filter} from "@cubejs-client/core";
import {useDashboardDispatch, useDashboardState} from "../../context/dashboard-context";
import {IS_LOCAL_DEV} from "../../config/frontendConfig";

interface Props {
  dashboardKey: string
  dashboardDef: DashboardDef
  // dashboardFilters: Filter[]
}

const DashboardMain: React.FunctionComponent<Props> = (
  {
    dashboardKey,
    dashboardDef,
    // dashboardFilters,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const dashboardState = useDashboardState();
  const dispatchDashboard = useDashboardDispatch();
  const [preconditionsMet, setPreconditionsMet] = React.useState<boolean | undefined>(undefined);
  const [preconditionsMessage, setPreconditionsMessage] = React.useState<string | undefined>(undefined);


  React.useEffect(() => {
    if (dashboardDef.preconditions) {
      const failedCondition = dashboardDef.preconditions.find(pc => !pc.condition(dashboardState));
      if (failedCondition) {
        setPreconditionsMet(false);
        setPreconditionsMessage(failedCondition.message);
        return;
      }
    }

    setPreconditionsMet(true);
    setPreconditionsMessage(undefined);
  }, [dashboardDef.preconditions, dashboardState.dashboardFilters]);

  function renderColorPalettePicker() {
    if (!IS_LOCAL_DEV) {
      return <></>
    }

    const { colorPaletteIdx } = dashboardState;

    return <Box sx={{display: 'block', flex: 1, position: 'fixed', top: 16, left: 140, color: '#bbb' }}>
      <div style={{display: 'inline', fontSize: '14px', fontFamily: 'Outfit'}}>Color palette:</div>
      {COLOR_PALETTES.map((palette, idx) => (
        <div
          key={idx}
          onClick={() => dispatchDashboard({
            type: 'setColorPaletteIdx',
            payload: {colorPaletteIdx: idx}
          })}
          style={{
            backgroundColor: idx === colorPaletteIdx ? "#5a6bc1" : "white",
            color: idx === colorPaletteIdx ? "white" : "#bbb",
            display: 'inline',
            padding: '1px 4px',
            margin: '1px',
            cursor: "pointer",
            border: '1px solid #bbb',
            borderRadius: '3px',
            fontSize: '14px',
            fontFamily: 'Outfit',
          }}>
          {idx + 1}
        </div>
      ))}
    </Box>
  }

  return (
    <div className={classes.root}>
      {preconditionsMet
        ? <>
          {/*{renderColorPalettePicker()}*/}
          {dashboardDef.panels.map((rowPanels, rowIdx) => (
            <Grid key={'row' + rowIdx} container spacing={{ xs: 1, md: 1 }} columns={12} sx={{ margin: '12px', padding: 0 }}>
              {rowPanels.map((panel, idx) => (
                <DashboardPanel
                  panel={panel}
                  key={dashboardKey+"-panel-"+idx+1}
                />
              ))}
            </Grid>
          ))}
        </>
        : (
          <Box sx={{ alignContent: 'middle', justifyContent: 'middle' }}>
            {preconditionsMessage}
          </Box>
        )
      }
    </div>
  );
};

export default DashboardMain;
