import React, { useMemo } from "react";
import { ResponsiveContainer, PieChart as RePieChart, Cell, Pie, Tooltip, Legend } from "recharts";
import { PanelProps, tickFormatter } from "./panelUtils";
import { useTheme, darken } from "@mui/material/styles";
import CustomTooltip, { reTooltipProps } from "./CustomTooltip";
import {calculateFill} from "../../../utils/dashboard-utils";
import "./style.css"

const PieChart = ({ seriesNames, data, format, colorPaletteIdx, height, dateFormatOverride }: PanelProps) => {

  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, value, format
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    if (percent < 0.1) {
      return null
    }
    
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${tickFormatter(value, format, dateFormatOverride)}`}
      </text>
    );
  };
  
	const theme = useTheme();
  const [hoveredIndex, setHoveredIndex] = React.useState(-1);
  
  // calculate the total
  const total = useMemo(() => {
    return data.reduce((acc, item) => {
      if (seriesNames.length < 1) return
      const value = item[seriesNames[0].key]
      if (!value) return
      return acc + value
    }, 0)
  }, [data])

  if (seriesNames.length < 1) {
    return null
  }

	return (
		<ResponsiveContainer width="100%" height={400}>
			<RePieChart width={400} height={400}>
				<Pie data={data} dataKey={seriesNames[0].key} nameKey="x" label={renderCustomizedLabel} labelLine={false} format={format.y} legendType="line">
					{data.map((entry, idx) => {
            const fill = calculateFill(idx, data.length, colorPaletteIdx)
						return (
              <Cell key={idx} fill={hoveredIndex === idx ? darken(fill, 0.2): fill} className={"cell"}/>
              )
					})}
				</Pie>
				<Tooltip content={<CustomTooltip format={format} dateFormatOverride={dateFormatOverride} pieChartTotal={total}/>} {...reTooltipProps} animationDuration={500} animationEasing="ease-out" position={{x: 0}}/>
        <Legend/>
			</RePieChart>
		</ResponsiveContainer>
	);
};

const PieCell = ({ ...otherProps }) => {

  return (
    <Cell
    // fill={}
      // onMouseOver={() => setIsHovered(true)}
      // onMouseOut={() => setIsHovered(false)}
      // {...otherProps}
      fill="#ab2020"
    />
  );
};


// function calculateFill(theme, idx, length) {
// 	return lighten(theme.palette.primary.main, (idx / (length - 1)) * 0.75);
// }

export default PieChart;
