import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width:"100%",
      height: 'calc(100%)',
      overflow: "hidden",
      padding: '0 0 0 24px',
      // border: '1px dashed blue',
    },
    titleRoot: {
      flex: 1,
      display: 'flex',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    filters: {
      height:"10%"
    },
    mainContent: {
      height:"90%"
    },
  }),
);

export default useStyles