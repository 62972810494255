import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// import {Snackbar} from "@mui/material";
import MuiAlert, {AlertProps} from "@mui/material/Alert"
import {DSAlert, useAlertDispatch} from "../../../context/alert-context";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const BasicSnackbar: React.FC<DSAlert> = (
  {
    message,
    severity,
    onClose,
    autoHideDuration,
  }: DSAlert) => {

  const dispatchAlert = useAlertDispatch();
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (!alert) {
      setOpen(true)
    }
  }, [alert])

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    dispatchAlert({type: "clearAlert"})
    onClose && onClose()
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={autoHideDuration || 2500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}


export default BasicSnackbar;