import { format } from 'date-fns';
import { Parser } from "json2csv";
import { ResultSet, TableColumn } from "@cubejs-client/core";
import { TableColsGenerator } from './utils';
import { currencyOptions } from '../components/ReportTable/TableRenderer/TableRenderer';

function downloadCsv(data: unknown, reportTitle: string) {
  console.info(`Downloading CSV`)
  const blob = new Blob([data as BlobPart], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  const filename = `DealSumm-${reportTitle}-${format(new Date(), 'dd-MM-yyyy_HH-mm')}.csv`
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function exportToCSV(resultSet: ResultSet, fieldContext, reportName: string): void {
  const columns = TableColsGenerator(fieldContext.orderColumns(resultSet.tableColumns())).filter(c => c['key'] !== "idxColumn")
  const fields = columns.map(c => ({
    value: c['key'],
    label: c['title'],
  }));
  const formattedResultSet = resultSet.rawData().map((row) => {
    return columns.reduce((obj, column) => {
      obj[column.key] = csvCellFormat(row[column.key], column);
      return obj;
    }, {});
  });

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(formattedResultSet);

  downloadCsv(csv, reportName);
}

function csvCellFormat(val: string, column: TableColumn) {
  if (val === undefined) {
    return val;
  } else {
    let columnType = column.type;
    if (column.meta && column.meta.format) {
      columnType = column.meta.format;
    }
    switch (columnType) {
      case 'string':
        if(column.title?.toLowerCase().includes('date') && canBeConvertedToDate(val))  
          return format(new Date(val), 'MM-dd-yyyy');  
        return val;
      case 'time':
        return format(new Date(val), 'MM-dd-yyyy')
      case 'number':
        if (column.format === 'percent') {
          return new Intl.NumberFormat('en-US', currencyOptions(1, 2, true)).format(parseFloat(val)) + '%';
        } else if (column.format === 'currency') {
          return new Intl.NumberFormat('en-US', currencyOptions(2, 2, true)).format(parseFloat(val));
        } else if (column.format === 'id') {
          return val;
        } else {
          return new Intl.NumberFormat('en-US', { 'useGrouping': true }).format(parseFloat(val));
        }
      default:
        if (column.format === 'percent') {
          return val + '%';
        } else if (column.format === 'currency') {
          return new Intl.NumberFormat('en-US', currencyOptions(2, 2, true)).format(parseFloat(val));
        }
        return val;
    }
  }
}

const canBeConvertedToDate = (val:string): boolean => {
  const date = new Date(val);
  return !isNaN(date.getTime());
}

export { exportToCSV };