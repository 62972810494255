import { Button } from "@mui/material";
import React from "react";
import { useTheme, alpha } from "@mui/material/styles";
import ArrowTooltip from "../ToolTip/ArrowTooltip";
import RelativeDate from "../../utils/relative-dates";

type Props = {
	children: React.ReactNode;
	onClick?: () => void;
	selected: string;
	option: string;
	highlighterRef: React.RefObject<HTMLDivElement>;
	pickerRef: React.RefObject<HTMLDivElement>;
	highlighted: string;
	sx?: any;
};

// eslint-disable-next-line react/display-name
const DateButton = ({
	children,
	onClick,
	selected,
	option,
	highlighterRef,
	pickerRef,
	highlighted,
	sx,
}: Props) => {
	const theme = useTheme();
	const buttonRef = React.useRef<HTMLButtonElement | null>(null);

	React.useEffect(() => {
		if (highlighted !== option) return;
		if (highlighterRef.current === null || pickerRef.current === null || buttonRef.current === null) return;
		// get rect of pickerRef
		const pickerRect = pickerRef.current.getBoundingClientRect();
		// get rect of button
		const buttonRect = buttonRef.current.getBoundingClientRect();

		// set highlighterRef width to button width
		highlighterRef.current.style.width = `${buttonRect.width}px`;
		// set highlighterRef left to button left - picker left
		highlighterRef.current.style.left = `${buttonRect.left - pickerRect.left}px`;
	}, [selected, option, onClick]);

	const tooltip = React.useMemo(() => {
		if (option === "all") return "";
		if (option === "custom" && highlighted === "custom") {
			try {
				return new RelativeDate(selected).getRangeString();
			} catch (e) {
				return "";
			}
		}
		try {
			return new RelativeDate(option).getRangeString();
		} catch (e) {
			return "";
		}
	}, [option, selected, highlighted]);

	return (
		<ArrowTooltip title={tooltip}>
			<Button
				ref={buttonRef}
				onClick={onClick}
				variant="outlined"
				color="secondary"
				sx={{
					border: "none",
					borderRight: "1px solid " + theme.palette.secondary.main,
					...sx,
					// borderRight: `1px solid ${alpha(theme.palette.secondary.contrastText, 0.25)}`,
					textTransform: "uppercase",
					fontSize: "12px",
					backgroundColor: "transparent",
					// color: "white",
					// color: theme.palette.secondary.contrastText,
					// borderRadius: "0px",
					transition: "background-color 0.1s cubic-bezier(0.075, 0.82, 0.165, 1) !important",
					minHeight: "33px !important",

					svg: {
						marginRight: theme.spacing(0.5),
					},

					"&:hover": {
						backgroundColor: `${alpha(theme.palette.secondary.light, 0.1)} !important`,
						border: "none",
						borderRight: "1px solid " + theme.palette.secondary.main,
					},
					
					"&:last-of-type": {
						borderRight: "none",
					},

					"&& .MuiTouchRipple-rippleVisible": {
						animationDuration: "250ms",
					},
				}}
			>
				{children}
			</Button>
		</ArrowTooltip>
	);
};

export default DateButton;
