import * as React from "react";
import useStyles from "./style";
import {
	BinaryFilter,
	BinaryOperator,
	TCubeDimension,
	TCubeMeasure,
	UnaryFilter,
	UnaryOperator
} from "@cubejs-client/core";
import {PinnedFilterDef} from "../../../utils/dashboard-utils";
import QueryFilterInput from "../../Filters/QueryFilterInput/QueryFilterInput";
import Button from "@mui/material/Button";
import {FilterOperatorSelect} from "../../DashboardSelect/FilterOperatorSelect";
import {defaultOperatorForType, getOperatorByName, isUnaryOperator} from "../../../utils/filters";
import {FilterOperator, FilterOperatorName} from "../../../utils/utils";
import {useDashboardDispatch} from "../../../context/dashboard-context";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

interface Props {
	pinnedFilter: PinnedFilterDef
	activeFilter?: BinaryFilter | UnaryFilter,
	setAnchorEl: (target) => void
	anchorEl: HTMLElement | null
	cubeMember: TCubeDimension | TCubeMeasure
}

interface FilterInput {
  operator: BinaryOperator | UnaryOperator
  values: string[]
}

export const PinnedFilterMenu: React.FunctionComponent<Props> = (
	{
		pinnedFilter,
		activeFilter,
		setAnchorEl,
		anchorEl,
		cubeMember
	}: Props) => {
	const classes = useStyles();
	const dispatchDashboard = useDashboardDispatch();
	const [applyButtonEnabled, setApplyButtonEnabled] = React.useState<boolean>(false);
	const [inputState, setInputState] = React.useState<FilterInput>({
    operator: activeFilter && activeFilter.operator || defaultOperatorForType[pinnedFilter.type],
    values: activeFilter && activeFilter.values || [],
  });

	React.useEffect(() => {
		if (!activeFilter) {
			setInputState({ operator: defaultOperatorForType[pinnedFilter.type], values: [] });
		}
	}, [!!activeFilter]);

	React.useEffect(() => {
    setApplyButtonEnabled(
      isInputValid(inputState.operator, inputState.values)
      && inputDifferentFromActive())
  }, [inputState.operator, inputState.values]);

	function isInputValid(op: FilterOperatorName, values: string[]): boolean {
    return isUnaryOperator(op) || !!(values.length && values[0].length);
  }

  function inputDifferentFromActive(): boolean {
		if (!activeFilter) {
			return true;
		}
		return Boolean(activeFilter.operator !== inputState.operator
			|| (activeFilter.values && !arraysEqual(activeFilter.values, inputState.values))
		);
  }

	function handleValuesChanged(newValues: string[]): void {
    setInputState({
      ...inputState,
      values: newValues,
    });
    if (activeFilter && activeFilter.values && !arraysEqual(activeFilter.values, newValues)) {
      setApplyButtonEnabled(true);
    }
  }

	function handleClickedApply(): void {
    const newFilter: BinaryFilter | UnaryFilter = isUnaryOperator(inputState.operator)
      ? {
        member: pinnedFilter.member,
        operator: inputState.operator as UnaryOperator,
      }
      : {
        member: pinnedFilter.member,
        operator: inputState.operator as BinaryOperator,
        values: inputState.values,
      };
    if (activeFilter) {
      dispatchDashboard({ type: 'updateDashboardFilter', payload: { filter: newFilter }});
    } else {
      dispatchDashboard({type: 'addDashboardFilter', payload: {filter: newFilter}});
    }
  }

	function handleOperatorChanged(newOperator: BinaryOperator | UnaryOperator): void {
		// console.log(`handleOperatorChanged > newOperator=${newOperator}`);
		let inputValues = inputState.values;
		const prevOperatorObj = inputState.operator && getOperatorByName(inputState.operator, pinnedFilter.type);
		const newOperatorObj = getOperatorByName(newOperator, pinnedFilter.type) as FilterOperator;

		if (prevOperatorObj && prevOperatorObj.multipleValuesSupported && !newOperatorObj.multipleValuesSupported) {
			inputValues = inputValues.slice(0, 1);
		}

		if (['set', 'notSet'].includes(newOperator)) {
			inputValues = [];
		}

		setInputState({
      values: inputValues,
      operator: newOperator,
    });

    if (newOperator !== (activeFilter && activeFilter.operator) || isUnaryOperator(newOperator)) {
      setApplyButtonEnabled(true);
    }
  }

	const renderApplyButton = (): React.ReactElement => (
    <Button
			fullWidth
			sx={{
				color: "white",
				marginTop: '12px',
				borderRadius: '0 0 4px 4px',
			}}
      onClick={(event) => {
        handleClickedApply();
        setAnchorEl(anchorEl ? null : event.currentTarget);
      }}
      variant="contained"
      disabled={!applyButtonEnabled}
    >
      Apply
    </Button>
  )

	return (
		<Box className={classes.menuRoot} /* ref={valueInputRef} */>
			<Typography variant='h6' sx={{ fontSize: '14px', textAlign: 'center', padding: '10px 12px 4px 12px' }}>
				{pinnedFilter.label}
			</Typography>
			<hr style={{width: "100%", margin: '2px' }}/>
			<div className={classes.selectContainer}>
				<FilterOperatorSelect
					handleChange={(event) => handleOperatorChanged(event.target.value)}
					memberType={pinnedFilter.type}
					value={inputState.operator}
				/>
			</div>
			<Box sx={{ padding: '4px 12px', flex: 1, /* maxHeight: 250 */ }}>
				<QueryFilterInput
					operator={inputState.operator}
					cubeMember={cubeMember}
					values={inputState.values}
					setValues={handleValuesChanged}
					pinnedFilter={true}
				/>
			</Box>
			{renderApplyButton()}
		</Box>
	)
}

function arraysEqual(array1: string[], array2: string[]) {
	if (array1.length !== array2.length) {
		return false;
	}
	return array1.every((v, idx) => array2[idx] === v);
}