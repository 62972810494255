import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";

interface Props {
	children: React.ReactNode;
}

const Wrapper: React.FC<Props> = ({ children }: Props) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				// maxHeight: "100%",

				"& .explore-header": {
					"& .MuiButtonBase-root": {
						transition: "all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0ms",
						opacity: 0,
					},
				},
				"&:hover": {
					"& .header-action": {
						opacity: 1,
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export default Wrapper;
