import React from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import API from "../api";
import { useAuthDispatch, useAuthState } from "../context/auth-context";
import { useAlertDispatch, useAlertState } from "../context/alert-context";
import { deleteUserValues, getStoredUserValues, setUserValues, UserAuthenticationDetails } from "../utils/auth-utils";
import LoginForm from "../components/forms/LoginForm"
import BasicSnackbar from "../components/alerts/BasicSnackbar/BasicSnackbar";
import { Mixpanel, eventNames } from "../utils/mixpanel-utils/Mixpanel";


const LoginPage = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate()
  const { alert } = useAlertState();
  const dispatchAlert = useAlertDispatch();
  const dispatch = useAuthDispatch();
  const { isAuthenticated } = useAuthState();
  const [isValidating, setIsValidating] = React.useState(false)
  const apiUrl = `/api/login`;

  function handleLoginSuccess(userAuth: UserAuthenticationDetails): void {
    Mixpanel.track(eventNames.loginSuccessful);
    setUserValues(userAuth);
    dispatch({ type: 'setIsAuthenticated', payload: {
        user: userAuth,
    }})
    navigate("/");
  }

  function handleLoginFailure() {
    Mixpanel.track(eventNames.loginFailed);
    return;
  }
  
  function handleSubmitForm(username: string, password: string) {
    setIsValidating(true)
    validateCredentials(username, password)
      .then(response => {
        if (response.status === 200) {
          handleLoginSuccess(response.data.userAuthenticationDetails)
        }
      })
      .catch(error => {
        console.error(`LoginPage > handleSubmitForm() > validateCredentials.catch > ${error}`)
        setIsValidating(false)
        handleLoginFailure()
        dispatchAlert({
          type: 'setAlert',
          payload: { alert: { message: "Login failed", severity: 'error', }}
        })
      })
  }

  function validateCredentials(userName: string, password: string) {
    const payload = {
      'username': userName,
      'password': password,
    }
    return API.post(apiUrl + `/credentials`, payload);
  }

  function Alert(){
    if (!alert) {
      return null
    }
    return <BasicSnackbar
      message={alert.message}
      severity={alert.severity}
      onClose={alert.onClose}
      autoHideDuration={alert.autoHideDuration}
    />
  }

  if (isAuthenticated) {
    const locationState = location.state;
    let redirectLocation = { pathname: '/' }
    if (locationState && locationState.referer) {
      redirectLocation = locationState.referer
    }
    return <Navigate to={redirectLocation} replace={true}/>
  }

  return (
    <div>
      {Alert()}
      <LoginForm
        handleSubmit={handleSubmitForm}
        isValidating={isValidating}
      />
    </div>
  );
}

export default LoginPage