import React from "react";
import { dispatchLogger } from "./context-utils";
import {
  createCubesMap,
  SchemaContextName,
  SchemaContextDefinition,
  MetaLike,
  resolveCubeMember,
} from "../utils/cube-utils";
const displayName = 'cube-meta-context';

type Action = {type: 'setCubesMeta', payload: { meta: MetaLike, metaContextName: SchemaContextName }}
| {type: 'clearCubesMeta'}
| {type: 'clearMetaAndMetaContextName'}
| {type: 'setAppContextName', payload: { appContextName: SchemaContextName | null }}
| {type: 'setSchemaContextDefinitions', payload: { schemaContextDefinitions: SchemaContextDefinition[] }}
| {type: 'setVisibleContextNames', payload: { visibleContextNames: SchemaContextName[] }}

type Dispatch = (action: Action) => void

type State = {
  cubesMetaLike: MetaLike | undefined
  metaContextName: SchemaContextName | undefined
  appContextName: SchemaContextName | null | undefined
  schemaContextDefinitions: SchemaContextDefinition[] | undefined
  visibleContextNames: SchemaContextName[] | undefined
}

type CubeMetaProviderProps = {children: React.ReactNode}

const initialState: State = {
  cubesMetaLike: undefined,
  metaContextName: undefined,
  appContextName: undefined,
  schemaContextDefinitions: undefined,
  visibleContextNames: undefined,
}

const CubeMetaStateContext = React.createContext<State | undefined>(undefined)
const CubeMetaDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function cubeMetaReducer(state: State, action: Action): State {
  dispatchLogger(displayName, action, 'debug');
  switch(action.type) {
    case "setCubesMeta": {
      const { meta, metaContextName } = action.payload;
      return {
        ...state,
        cubesMetaLike: {
          cubes: meta.cubes,
          cubesMap: createCubesMap(meta.cubes),
          resolveCubeMember: resolveCubeMember,
          membersForQuery: meta.membersForQuery,  // copied from @cubejs-client core
          resolveMember: meta.resolveMember,  // copied from @cubejs-client core
          defaultTimeDimensionNameFor: meta.defaultTimeDimensionNameFor,  // copied from @cubejs-client core
          filterOperatorsForMember: meta.filterOperatorsForMember,  // copied from @cubejs-client core
          meta: meta,
          membersGroupedByCube: meta.membersGroupedByCube,
        },
        metaContextName: metaContextName,
      }
    }
    case "clearCubesMeta": {
      return {
        ...initialState,
        schemaContextDefinitions: state.schemaContextDefinitions,
      };
    }
    case "clearMetaAndMetaContextName": {
      return {
        ...state,
        cubesMetaLike: undefined,
        metaContextName: undefined,
      }
    }
    case 'setSchemaContextDefinitions': {
      return {
        ...state,
        schemaContextDefinitions: action.payload.schemaContextDefinitions
      };
    }
    case 'setAppContextName': {
      return {
        ...state,
        appContextName: action.payload.appContextName,
      };
    }
    case 'setVisibleContextNames': {
      return {
        ...state,
        visibleContextNames: action.payload.visibleContextNames
      }
    }
    default: {
      return state;
    }
  }
}

function CubeMetaProvider({children}: CubeMetaProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(cubeMetaReducer, initialState)

  return (
    <CubeMetaStateContext.Provider value={state}>
      <CubeMetaDispatchContext.Provider value={dispatch}>
        {children}
      </CubeMetaDispatchContext.Provider>
    </CubeMetaStateContext.Provider>
  )
}

function useCubeMetaState(): State {
  const context = React.useContext(CubeMetaStateContext)
  if (context === undefined) {
    throw new Error('useCubeMetaState must be used within a CubeMetaProvider')
  }
  return context
}

function useCubeMetaDispatch(): Dispatch {
  const context = React.useContext(CubeMetaDispatchContext)
  if (context === undefined) {
    throw new Error('useCubeMetaDispatch must be used within a CubeMetaProvider')
  }
  return context
}


export { CubeMetaProvider, useCubeMetaDispatch, useCubeMetaState}