import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { createStyles, TextField } from "@material-ui/core";
import {format, isValid} from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { DATE_FORMAT } from "../../config/frontendConfig.js"
import {Typography} from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      '&:hover': {
        borderColor: '#40a9ff',
        boxShadow: 'unset',
        // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },

      '&.input-error': {
        '& div': {
          // '&.MuiInputBase-root': {
            '& input': {
              backgroundColor: '#f89595',
            }
          // },
        },
      },

      '&:focus': {
        border: 'none',
        boxShadow: 'unset',
      },

      '&.Mui-focused': {
        borderColor: '#40a9ff',
        boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
      },

      '& div': {
        '&.MuiInputBase-root': {
          borderRadius: 2,
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          height: '33px',

          '&:hover': {
            borderColor: '#40a9ff',
            boxShadow: 'unset',
            // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
          },

          '&:focus': {
            border: 'none',
            boxShadow: 'unset',
          },

          '&.Mui-focused': {
            borderColor: '#40a9ff',
            boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
          },
        },
      },

      '& input': {
        fontSize: '0.8rem',
        borderRadius: 2,
        position: 'relative',
        backgroundColor: '#fff',
        padding: '6px 6px 6px 12px',
        border: 'none',

        '&:hover': {
          border: 'none',
        },

        '&:focus': {
          border: 'none',
          // border: 'inherit',
          // borderRadius: 2,
          // backgroundColor: '#fff',
          // borderColor: '#80bdff',
          // borderColor: '#40a9ff',
          // boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
        },
      },
    },
  })
)

interface Props {
  value: string[]
  onValueChanged: (newValue: string[]) => void
  disableFuture?: boolean
}

interface InputError {
  error: React.ReactNode
  value: MaterialUiPickersDate | ParsableDate
}
const DateRangeInput: React.FunctionComponent<Props> = (
  {
    value,
    onValueChanged,
    disableFuture,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const [inputError, setInputError] = React.useState<InputError | undefined>(undefined);

  function handleChangedValue(date: Date, valueIdx: number) {
    // console.debug(`DateRangeInput > handleChangedValue: date=${date}, valueIdx=${valueIdx}`);
    if (isValid(date)) {
      const dateFormatted = format(date, 'yyyy-MM-dd');
      console.log(`dateFormatted=`, dateFormatted);
      const newValue = valueIdx === 0
        ? [dateFormatted, value[1]]
        : [value[0], dateFormatted];
      onValueChanged(newValue);
    }
  }

  function handleOnError(error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate): void {
    if (!inputError && error) {
      setInputError({error, value});
    }
  }

  const clsName = inputError ? 'input-error' : '';

  return <>
    <KeyboardDatePicker
      autoOk
      className={clsName}
      variant="inline"
      inputVariant="standard"
      InputProps={{disableUnderline: true}}
      format={DATE_FORMAT}
      value={new Date(value[0])}
      onChange={(date: Date) => handleChangedValue(date, 0)}
      onAccept={(date: Date) => handleChangedValue(date, 0)}
      onError={handleOnError}
      // minDate={new Date("01/01/1900")}
      // maxDate={isValid(new Date(value[1])) ? new Date(value[1]) : null}
      invalidDateMessage=''
      disableFuture={disableFuture || false}
      TextFieldComponent={(props) => (
        <TextField
          {...props}
          className={inputError ? 'input-error' : ''}
          classes={{
            root: classes.root,
          }}
        />
      )}
    />
    <Typography
      variant="caption"
      component={"div"}
      sx={{alignSelf: 'center', display: 'inline', margin: '0 4px'}}>
      to
    </Typography>
    <KeyboardDatePicker
      autoOk
      className={clsName}
      variant="inline"
      inputVariant="standard"
      InputProps={{disableUnderline: true}}
      format={DATE_FORMAT}
      value={new Date(value[1])}
      onChange={(date: Date) => handleChangedValue(date, 1)}
      onAccept={(date: Date) => handleChangedValue(date, 1)}
      onError={handleOnError}
      // minDate={isValid(new Date(value[0])) ? new Date(value[0]) : null}
      invalidDateMessage=''
      disableFuture={disableFuture || false}
      TextFieldComponent={(props) => (
        <TextField
          {...props}
          className={inputError ? 'input-error' : ''}
          classes={{
            root: classes.root,
          }}
        />
      )}
    />
  </>
}

export default DateRangeInput;