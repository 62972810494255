import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import useShortcut from "../../hooks/useShortcut";
import ArrowTooltip from "../ToolTip/ArrowTooltip";

type Props = {
	children: React.ReactNode;
	onClick?: () => void;
	disabled?: boolean;
	loading?: boolean;
	loadingText?: string;
	color?: "secondary" | "inherit" | "primary" | "error" | "info" | "success" | "warning" | undefined;
	variant?: "text" | "outlined" | "contained" | undefined;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	sx?: React.CSSProperties;
	shortcut?: string;
};

const defaultSx = {
	"&& .MuiTouchRipple-rippleVisible": {
		animationDuration: "250ms",
	},
};

const disabledSx = {
	// color: "#ffffff42",
	// filter: "saturate(75%)",
	// opacity: 0.75,
};

const PrimaryButton = ({
	children,
	onClick,
	disabled,
	loading,
	loadingText,
	color = "secondary",
	variant = "contained",
	startIcon,
	endIcon,
	sx,
	shortcut,
}: Props) => {
	const theme = useTheme();
	const hoverSx = {
		color: disabled || loading ? (variant === "contained" ? "#ffffff42" : "") : "",
		filter: disabled || loading ? (variant === "contained" ? "saturate(75%)" : "saturate(0%)") : "",
		opacity: disabled || loading ? (variant === "contained" ? 0.75 : 0.5) : 1,
		"&:hover": {
			backgroundColor: variant === "contained" ? theme.palette[color].light : alpha(theme.palette[color].main, 0.1),
		},
		"&:focus-visible": {
			outline: `1px solid ${theme.palette[color].main}`,
		},
	};

	useShortcut(shortcut, !disabled ? onClick ? onClick : () => {} : () => {}, [onClick])

	sx = { ...defaultSx, ...sx, ...hoverSx };

	if (disabled || loading) {
		sx = { ...defaultSx, ...sx, ...disabledSx };
		if (loading) {
			startIcon = <CircularProgress size={16} color="inherit" />;
			if (loadingText) {
				children = loadingText;
			} else if (children) {
				console.warn("loadingText is not provided");
			}
		}
	}

	const muiProps = {
		onClick,
		color,
		variant,
		startIcon,
		endIcon,
		sx,
		className: "primary-button",
		disableElevation: true,
		tabIndex: disabled ? -1 : 1,
	};

	return (
		<div style={{ pointerEvents: disabled || loading ? "none" : "auto" }}>
			<ArrowTooltip title={shortcut ? shortcut : ""}>
			<Button {...muiProps}>{children}</Button>
			</ArrowTooltip>
		</div>
	);
};

export default PrimaryButton;
