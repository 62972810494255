import React from "react";
import useStyles from "./style";
import {PinnedFilterDef} from "../../utils/dashboard-utils";
import {BinaryFilter, TCubeDimension, TCubeMeasure, UnaryFilter} from "@cubejs-client/core";
import {useDashboardDispatch} from "../../context/dashboard-context";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import {ClickAwayListener} from "@mui/base";
import {resolveCubeMember} from "../../utils/cube-utils";
import {useCubeMetaState} from "../../context/cube-meta-context";
import {PinnedFilterMenuButton} from "./PinnedFilterMenuButton/PinnedFilterMenuButton";
import {PinnedFilterMenu} from "./PinnedFilterMenu/PinnedFilterMenu";
import { Popper } from "@mui/material";


interface Props {
  pinnedFilter: PinnedFilterDef
  activeFilter?: BinaryFilter | UnaryFilter,
}

const PinnedFilter: React.FunctionComponent<Props> = ({
  pinnedFilter,
  activeFilter,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const dispatchDashboard = useDashboardDispatch();
  const { cubesMetaLike } = useCubeMetaState();
  const [cubeMember, setCubeMember] = React.useState<TCubeDimension | TCubeMeasure | undefined>(undefined);

  React.useEffect(() => {
    if (pinnedFilter.member && cubesMetaLike) {
      const _cubeMember = resolveCubeMember(cubesMetaLike.cubes, pinnedFilter.member) as TCubeDimension | TCubeMeasure;
      setCubeMember(_cubeMember);
    }
  }, [pinnedFilter.member, cubesMetaLike]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? `filter-menu-popper-${pinnedFilter.member}` : undefined;

  if (!cubeMember) {
    // console.warn(`PinnedFilter > no cubeMember for ${pinnedFilter.member}, cannot render...`);
    return <></>;
  }

  function handleClickedRemoveFilter(): void {
    // console.log(`handleClickedRemoveFilter, pinnedFilter.member=${pinnedFilter.member}`);
    dispatchDashboard({ type: 'removeDashboardFilter', payload: { filterMember: pinnedFilter.member }});
  }

  return (
    <div className={classes.root}>
      <PinnedFilterMenuButton
        pinnedFilter={pinnedFilter}
        activeFilter={activeFilter}
        handleClickedRemoveFilter={handleClickedRemoveFilter}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        popperId={popperId}
        menuOpen={open}
      />

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setAnchorEl(null)}
      >
        <Popper
          id={popperId}
          open={open}
          anchorEl={anchorEl}
          placement='bottom-start'
          sx={{zIndex: 40}}
        >
          <PinnedFilterMenu
            pinnedFilter={pinnedFilter}
            activeFilter={activeFilter}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            cubeMember={cubeMember}
          />
        </Popper>
      </ClickAwayListener>
    </div>
   )
};

export default PinnedFilter;
