import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LOGROCKET_APP_ID } from "./config/frontendConfig.js";
import { AuthProvider } from "./context/auth-context";
import { AlertProvider } from "./context/alert-context";
import App from "./App";
import LandingPage from "./pages/LandingPage/LandingPage";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

if (LOGROCKET_APP_ID) {
	LogRocket.init(LOGROCKET_APP_ID);
	setupLogRocketReact(LogRocket);
}

ReactDOM.render(
	<Router>
		<QueryParamProvider adapter={ReactRouter6Adapter}>
			<AuthProvider>
				<AlertProvider>
					<App>
						<Route key="index" path="/" element={<LandingPage />} />
					</App>
				</AlertProvider>
			</AuthProvider>
		</QueryParamProvider>
	</Router>,

	document.getElementById("root")
);
