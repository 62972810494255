import React from "react";
import {DashboardDef, getColorFromContext} from "../../utils/dashboard-utils";
import { Card, Grid, CardMedia, CardContent, CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import { /* useHistory */ Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import useStyles from "./style"
import {SchemaContextEnum} from "../../utils/cube-utils";
import {useTheme} from "@mui/material/styles";
import animations from "../../theme/animations";

interface Props {
  dashboard: DashboardDef
}

const dashboardCard: React.FunctionComponent<Props> = ({
  dashboard,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate();
  const { env } = useParams() as Record<string, string>;
  const theme = useTheme();
  const contextNumeric = SchemaContextEnum[dashboard.contextName];

  function getPreviewImage() {
    const previewImage = {
      src: "",
      alt: "",
    }

    switch(dashboard.key) {
      case "critical-dates":
        previewImage.src = "/img/dashboardPreviews/criticalDates.webp"
        previewImage.alt = "critical dates dashboard preview"
        break
      case "portfolio-rent":
        previewImage.src = "/img/dashboardPreviews/portfolioRent.webp"
        previewImage.alt = "portfolio rent dashboard preview"
        break
      case "property":
        previewImage.src = "/img/dashboardPreviews/property.webp"
        previewImage.alt = "property dashboard preview"
        break
      case "abstraction":
        previewImage.src = "/img/dashboardPreviews/abstractionOverview.webp"
        previewImage.alt = "abstraction overview dashboard preview"
        break
      default:
        previewImage.src = ""
        previewImage.alt = "image not found"
    }

    return previewImage
  }

  const previewImage = React.useMemo(getPreviewImage, [dashboard.key])

  return (
    <Grid item>
      <Link to={`/${env}/dashboards/${contextNumeric}/${dashboard.key}`} style={{textDecoration: "none"}}>
        <Card
          classes={{root: classes.card}}
          sx={{
            width: 233,
            boxShadow: 0,
            borderRadius: 2,
            animation: animations.fadeRight,
            animationDelay: `250ms`,
          }}
          tabIndex={1}
        >
            <CardMedia
              component="img"
              height="100"
              src={previewImage.src}
              alt={previewImage.alt}
            />
            <CardContent sx={{ borderTop: "1px solid #dadce0", px: 2, py: 1, '&:last-child': { pb: 1 } }}>
              <Typography
                classes={{root: classes.cardTitle}}
                color="text.primary"
                sx={{ fontWeight: 500, fontSize: "16px" }}
                variant="h6"
              >
                <Tooltip title={dashboard.contextName}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: getColorFromContext(theme, dashboard.contextName),
                      borderRadius: "100%",
                      display: "inline-block",
                      marginRight: "1ch",
                    }}
                  />
                </Tooltip>
                {dashboard.title}
              </Typography>
              <Typography
                classes={{root: classes.cardSubtitle}}
                variant="body2"
                color="text.secondary"
              >
                dashboard
              </Typography>
            </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default dashboardCard;