import { AiOutlineNumber, AiOutlinePercentage } from "react-icons/ai";
import { TbLetterT } from "react-icons/tb";
import { BsCalendar, BsCurrencyDollar } from "react-icons/bs";

export const dataIcons = {
	text: <TbLetterT fontSize='small'/>,
	number: <AiOutlineNumber fontSize='small'/>,
	date: <BsCalendar fontSize='small'/>,
	percentage: <AiOutlinePercentage fontSize='small'/>,
	currency: <BsCurrencyDollar fontSize='small'/>,
};

export function getDataType(type: string, name: string) {
	if (type === "time") return "date";
	if (type === "number") {
		if (name.includes("$")) {
			return "currency";
		} else if (name.includes("%")) {
			return "percentage";
		}
		return "number";
	}
	if (type === "string") {
		return "text";
	}
	if (type === "boolean") return "boolean";
}
