import { Button, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { useQueryDispatch } from "../../../context/query-context";
import { MemberType, TCubeDimension, TCubeMeasure, Cube } from "@cubejs-client/core";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { useFieldContext } from "../../../context/providers/FieldContextProvider";
import { Box } from "@mui/material";
import { dataIcons, getDataType } from "../dataIconsUtil";
import { BiFilterAlt } from "react-icons/bi";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";

interface Props {
	cube: Cube;
	member: TCubeDimension | TCubeMeasure;
	memberType: MemberType;
	isActiveField: boolean;
	isFilterActive?: boolean;
	grouped?: boolean;
}

const Member: React.FunctionComponent<Props> = ({ cube, member, memberType, isActiveField, isFilterActive, grouped }: Props) => {
	const theme = useTheme();
	const fieldContext = useFieldContext();
	const dispatch = useQueryDispatch();

	const textRef = React.useRef<HTMLDivElement>(null);
	const [textOverflow, setTextOverflow] = React.useState(false);
	React.useEffect(() => {
		const text = textRef.current;
		if (!text) return;
		const observer = new ResizeObserver(() => {
			if (text.offsetWidth < text.scrollWidth) {
				setTextOverflow(true);
			} else {
				setTextOverflow(false);
			}
		});

		observer.observe(text);
		return () => observer.unobserve(text);
	}, []);

	const handleClickMember = (e) => {
		console.log(e)
		fieldContext.addField(member.name, memberType);
	};

	const dataType = React.useMemo(() => {
		return getDataType(member.type, member.shortTitle);
	}, [member.name]);

	return (
		<Box
			sx={{
				display: "flex",
				pointerEvents: isActiveField ? "none" : "auto",
				background: `linear-gradient(90deg, transparent 25%, ${alpha(
					theme.palette.secondary.contrastText,
					0.15
				)} 100%)`,
				backgroundSize: "400%",
				transition: `background-size ${theme.transitions.duration.short}ms ${theme.transitions.easing.sharp}`,
				borderRadius: 1,
				overflow: "hidden",

				".member": {
					color:
					memberType === "measures"
						? alpha("#F58737", 0.75)
						: alpha(theme.palette.secondary.contrastText, 0.75),
				},
			
				".filter-btn": {
					zIndex: 2,
					minWidth: "unset",
					width: "34px",
					height: "34px",
					aspectRatio: 1,
					color: isFilterActive ? theme.palette.primary.light : theme.palette.secondary.contrastText,
					backgroundColor: isFilterActive ? alpha(theme.palette.primary.light, 0.05) : "transparent",
					borderRadius: 0,
					borderLeft: `1px solid transparent`,
					
					"& svg": {
						opacity: isFilterActive ? "0.75 !important" : "0.25 !important",
					},
					
					"&:hover": {
						backgroundColor: alpha(theme.palette.secondary.contrastText, 0.1),
						opacity: 1,
						"& svg": {
							opacity: "1 !important",
						},
					},
				},

				"&:hover": {
					backgroundSize: "100%",

					".filter-btn": {
						"svg": {
							opacity: 0.5,
						},
						borderLeft: `1px solid ${theme.palette.secondary.main}`
					},
					
					".member": {
						color: memberType === "measures" ? "#F58737" : theme.palette.secondary.contrastText,
						backgroundSize: "100%",
					}
				},
			}}
		>
			<ListItemButton
				className="member"
				onClick={handleClickMember}
				draggable={false}
				disabled={isActiveField}
				sx={{
					paddingLeft: theme.spacing(1),
					paddingTop: 0,
					paddingBottom: 0,
					minHeight: "34px",
				}}
				disableGutters
			>
				<ArrowTooltip title={textOverflow ? !grouped ? `${cube.title} ${member.shortTitle}` : member.shortTitle : ""}>
					<Box ref={textRef} sx={{ display: "flex", width: "100%", WebkitMaskMode: "alpha", WebkitMaskImage: textOverflow ? `linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)` : "unset", }}>
						<Box
							sx={{
								color:
									memberType === "dimensions"
										? alpha(theme.palette.secondary.contrastText, 0.45)
										: alpha("#F58737", 0.45),
								marginRight: theme.spacing(1),
							}}
						>
							{dataIcons[dataType || "string"]}
						</Box>
						<Typography sx={{ fontSize: "12px", fontFamily: "Outfit", fontWeight: "bold", whiteSpace: "nowrap" }}>
							{member.shortTitle}
						</Typography>
					</Box>
				</ArrowTooltip>
			</ListItemButton>
				{!isActiveField && 
				<Button className="filter-btn" onClick={()  => {
					if (isFilterActive) {
						dispatch({ type: "removeFilter", payload: { filter: member.name, runNow: false } });
					} else {
						dispatch({ type: "addFilterField", payload: { member: member.name, type: member.type } })
					}
					}}>
						<BiFilterAlt fontSize="small"/>
				</Button>
				}
		</Box>
	);
};

export default Member;
