export function dispatchLogger(
  sourceName: string,
  action: Record<string, unknown>,
  severity: 'info' | 'warning' | 'error' | 'debug'
): void {
  if (severity === 'warning') {
    console.warn(`${sourceName}: dispatched '${action.type}', payload=`, action.payload)
  } else if (severity === 'error') {
    console.error(`${sourceName}: dispatched '${action.type}', payload=`, action.payload)
    } else if (severity === 'debug') {
    console.debug(`${sourceName}: dispatched '${action.type}', payload=`, action.payload)
  } else {
    console.log(`${sourceName}: dispatched '${action.type}', payload=`, action.payload)
  }
}