import axios from 'axios';
import { REPORTS_BACKEND } from "./config/frontendConfig.js"

const instance = axios.create({
  baseURL: REPORTS_BACKEND,
})


export function setApiAuthHeader(jwtToken: string): void {
  // console.log(`api > setApiAuthHeader > setting jwtToken in api headers`)
  instance.defaults.headers.common['Authorization'] = jwtToken;
}

export function removeApiAuthHeader(): void {
  // console.log(`api > removeApiAuthHeader`)
  delete instance.defaults.headers.common['Authorization'];
}

export default instance