// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell:hover {
    filter: brightness(120%);
}

.cell:focus {
    outline: none;
    filter: saturate(0%)
}

.trend {
    container-type: inline-size;
}

@container (max-width: 160px) {
    .figure .figure-chip {
      font-size: 12px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardPanel/PanelComponents/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb;AACJ;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI;MACE;IACF;AACJ","sourcesContent":[".cell:hover {\n    filter: brightness(120%);\n}\n\n.cell:focus {\n    outline: none;\n    filter: saturate(0%)\n}\n\n.trend {\n    container-type: inline-size;\n}\n\n@container (max-width: 160px) {\n    .figure .figure-chip {\n      font-size: 12px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
