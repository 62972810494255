import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import {menuItemType} from "../NestedMenu/menuItemType";
import Menu from '../NestedMenu/Menu';
import animations, {generateDelay} from '../../theme/animations';
import useMenu from '../../hooks/useMenu';


export interface SplitButtonOption {
	label: string
  onClick: () => void
	key: string
}

interface Props {
  label: string
	options: SplitButtonOption[]
  bgColor?: string
}

const SplitButton: React.FC<Props> = (
  {
    label,
    options,
    bgColor
  }: Props): React.ReactElement => {
  const menu = useMenu();
  const theme = useTheme();

  const handleButtonClick = () => {
    options[0].onClick();
  }

  const handleMenuItemClick = (option: SplitButtonOption) => {
    option.onClick();
  };

  const menuItems = React.useMemo(() => {
    const menuItems: menuItemType[] = [];
    for (let i = 0; i < options.length; i++) {
      menuItems.push({
        label: options[i].label,
        onClick: () => {handleMenuItemClick(options[i])},
      })
    }
    return menuItems;
  }, [options])

  return (
    <>
      <ButtonGroup
        color={bgColor as 'contextRent' | 'contextDefault' | 'contextCriticalDates' | 'contextPortfolio' | 'contextGlobal' | 'contextProjects' }  // use correct interface for type
        variant="contained"
        ref={menu.anchorRef as React.RefObject<HTMLDivElement>}
        aria-label="split button"
        disableElevation
        sx={{animation: animations.fadeDown, color: theme.palette.secondary.contrastText}}
      >
        <Button onClick={handleButtonClick} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: 'inherit' }}>{label}</Button>
        <Button
          size="small"
          aria-controls={menu.open ? 'split-button-menu' : undefined}
          aria-expanded={menu.open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={menu.handleToggle}
          sx={{color: 'inherit'}}
        >
          <ArrowDropDownIcon style={{
            transform: menu.open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}/>
        </Button>
        <Menu menuItems={menuItems} open={menu.open} setOpen={menu.setOpen} anchorEl={menu.anchorRef.current} placement={"bottom-end"} arrow={false} color={bgColor}/>
      </ButtonGroup>
      </>
  );
}

export default SplitButton;