import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      // maxWidth: "215px",
      // backgroundColor: "#eee !important",
    },
    menuItem: {
      display: "flex !important",
      flexDirection: "column",
    },
  })
);

export default useStyles;
