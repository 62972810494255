import { Query, TableColumn, TCubeDimension, TCubeMeasure } from "@cubejs-client/core";
import React from "react";
import {resolveCubeMember, resolveMemberAndType} from "../../utils/cube-utils";
import { useCubeMetaState } from "../cube-meta-context";
import { useQueryDispatch } from "../query-context";
import { processTimeDimensionName } from "../../utils/time-dimension-utils";
import { MemberType } from "@cubejs-client/core";
import useContextDefinition from "../../hooks/useContextDefinition";

export interface Field {
	memberName: string;
	type: MemberType;
}

interface Props {
	children: React.ReactElement;
}

interface State {
	fields: Field[];
	getDimensionFields: () => Field[];
	getMeasureFields: () => Field[];
	initializeFields: (fields_order: Field[]) => void;
	initializeFieldsFromMemberList: (memberList: string[]) => void;
	initializeFieldsFromQuery: (query: Query) => Field[] | null;
	addField: (memberName: string, type: MemberType) => void;
	removeField: (memberName: string, memberType: string, type: MemberType, runNow?: boolean) => void;
	validateReorder: (source: number, destination: number, type?: MemberType) => boolean;
	reorderField: (source: number, destination: number, type?: MemberType, runNow?: boolean) => void;
	isInFields: (memberName: string) => boolean;
	getMember: (memberName: string) => TCubeDimension | TCubeMeasure | undefined;
	orderColumns: (columns: TableColumn[]) => TableColumn[];
}

export const FieldContext = React.createContext<State | undefined>(undefined);

export function useFieldContext() {
	const fieldContext = React.useContext(FieldContext);
	if (fieldContext === undefined) {
		throw new Error("useFieldContext must be used within a FieldContextProvider");
	}
	return fieldContext;
}

const FieldContextProvider: React.FC<Props> = (props: Props) => {
	const dispatch = useQueryDispatch();
	const { cubesMetaLike } = useCubeMetaState();
	const { explore } = useContextDefinition();

	const [fields, setFields] = React.useState<Field[]>([]);
	const dimension_idx = React.useRef(0); // index to push dimensions to
	const measure_idx = React.useRef(0); // index to push measures to

	/**
	 * Initialize fields from Field array
	 * Used when initializing fields from saved reports.
	 */
	function initializeFields(fieldsOrder: Field[]) {
		dimension_idx.current = 0;
		measure_idx.current = 0;
		const tempFields: Field[] = [];
		if (fieldsOrder !== undefined) {
			fieldsOrder.forEach((f: Field) => {
				tempFields.push(f);
				if (f.type === "dimensions") {
					dimension_idx.current = dimension_idx.current + 1;
					measure_idx.current = measure_idx.current + 1;
				} else {
					measure_idx.current = measure_idx.current + 1;
				}
			});
			setFields(tempFields);
		}
	}

	/**
	 * Initialize fields from list of member names.
	 * Used when initializing fields from explore definition.
	 */
	function initializeFieldsFromMemberList(memberList: string[]) {
		if (!cubesMetaLike) {
			console.warn(`initializeFieldsFromMemberList > cubesMetaLike is undefined, returning`);
			return;
		}

		dimension_idx.current = 0;
		measure_idx.current = 0;

		const fields: Field[] = [];
		memberList.forEach((memberName: string) => {
			const { member, memberType } = resolveMemberAndType(cubesMetaLike.cubes, memberName);
			if (!member || !memberType) {
				console.warn(`"${memberName}" is not in dimensions or measures, cannot initialize fields order`);
				return;
			}
			measure_idx.current = measure_idx.current + 1;
			if (memberType == "dimensions") {
				dimension_idx.current = dimension_idx.current + 1;
			}
			fields.push({memberName, type: memberType});
		});
		setFields(fields);
	}

	/**
	 * Initialize fields from Query object.
	 * Used when there is no fields order array available, e.g. drill-down queries.
	 */
	function initializeFieldsFromQuery(query: Query): Field[] | null {
		dimension_idx.current = 0;
		measure_idx.current = 0;
		const tempFields: Field[] = [];
		if (query.dimensions !== undefined && query.measures !== undefined && query.timeDimensions !== undefined) {
			query.dimensions.forEach((d: string) => {
				tempFields.push({ memberName: d, type: "dimensions" });
				dimension_idx.current = dimension_idx.current + 1;
				measure_idx.current = measure_idx.current + 1;
			});
			query.timeDimensions.forEach((t) => {
				tempFields.push({ memberName: t.dimension, type: "dimensions" });
				dimension_idx.current = dimension_idx.current + 1;
				measure_idx.current = measure_idx.current + 1;
			});
			query.measures.forEach((m: string) => {
				tempFields.push({ memberName: m, type: "measures" });
				measure_idx.current = measure_idx.current + 1;
			});
			setFields(tempFields);
			return tempFields;
		}
		return null;
	}

	function addField(memberName: string, type) {
		const field: Field = { memberName, type };

		// check if field already exists
		if (fields.some((f) => f.memberName === memberName)) {
			console.error(`Field ${memberName} already exists`);
			return;
		}

		field.memberName = processTimeDimensionName(memberName);

		// resolve member
		const cubeMember = getMember(field.memberName);
		if (cubeMember === undefined) {
			console.error(`Could not resolve member "${field.memberName}"`);
			return;
		}

		// add to query and state
		const member = cubeMember as TCubeDimension | TCubeMeasure;
		if (explore && explore.fixedTimeDimension && explore.fixedTimeDimension.dimension === memberName) {
			dispatch({ type: "setTimeDimensionGranularity", payload: {
					timeDimension: member.name,
					granularity: "day",
					runNow: false }
			});
		} else {
			dispatch({ type: "add", payload: { member, type: field.type, runNow: false } });
		}

		// if dimension add to dimension section of fields array
		if (type === "dimensions") {
			setFields((fields) => [...fields.slice(0, dimension_idx.current), field, ...fields.slice(dimension_idx.current)]);
			dimension_idx.current = dimension_idx.current + 1;
			measure_idx.current = measure_idx.current + 1;
		} else {
			setFields((fields) => [...fields.slice(0, measure_idx.current), field, ...fields.slice(measure_idx.current)]);
			measure_idx.current = measure_idx.current + 1;
		}
	}

	function removeField(memberName, memberType, type, runNow) {
		const field: Field = { memberName, type };

		// preprocess time dimension
		if (memberType === "time" && type === "dimensions") {
			field.memberName = processTimeDimensionName(memberName);
		}

		// resolve member
		const cubeMember = getMember(field.memberName);
		if (cubeMember === undefined) {
			console.error(`Could not resolve member "${field.memberName}"`);
			return;
		}

		// remove from query and state
		const member = cubeMember as TCubeDimension | TCubeMeasure;
		if (explore && explore.fixedTimeDimension && explore.fixedTimeDimension.dimension === member.name) {
			dispatch({
				type: "removeTimeDimensionGranularity",
				payload: { member, runNow: Boolean(runNow) } as { member: TCubeDimension, runNow: boolean }
			});
		} else {
			dispatch({ type: "remove", payload: { member, type: field.type, runNow: Boolean(runNow) }});
		}
		if (type === "dimensions") {
			dimension_idx.current = dimension_idx.current - 1;
			measure_idx.current = measure_idx.current - 1;
		} else {
			measure_idx.current = measure_idx.current - 1;
		}
		setFields(fields.filter((f) => f.memberName !== field.memberName));
	}

	function validateReorder(source: number, destination: number) {
		// if measure is dragged to dimensions its a invalid reorder
		if (source >= dimension_idx.current && destination < dimension_idx.current) {
			return false;
		}

		// if dimension is dragged to measures its a invalid reorder
		if (source < dimension_idx.current && destination >= dimension_idx.current) {
			return false;
		}

		return true;
	}

	function reorderField(source: number, destination: number, type?: MemberType, runNow?: boolean) {
		if (!cubesMetaLike) {
			console.warn(`No cubesMetaLike, cannot reorder fields`);
			return;
		}

		if (type === "measures") {
			source += dimension_idx.current;
			destination += dimension_idx.current;
		}

		if (!validateReorder(source, destination)) {
			return;
		}

		// Get member object
		const { member, memberType } = resolveMemberAndType(cubesMetaLike.cubes, fields[source].memberName);
		const isTimeDimension = member && member.type === "time";

		// Calculate new context fields
		const newFields = [...fields];
		const [removed] = newFields.splice(source, 1);
		newFields.splice(destination, 0, removed);

		// Update query dimensions/measures if field is not a time dimension.
		// Otherwise indicate that query has changed / should rerender
		if (isTimeDimension) {
			if (runNow) {
				dispatch({ type: 'setRunNowTrue' });
			} else {
				dispatch({type: 'setHasQueryChanged', payload: true});
			}
		} else {
			const membersOfType = newFields.filter(f => f.type === memberType).map(f => f.memberName as string);
			if (memberType === "dimensions") {
				dispatch({type: 'setDimensions', payload: {dimensions: membersOfType, runNow: Boolean(runNow)}});
			}
			if (memberType === "measures") {
				dispatch({type: 'setMeasures', payload: {measures: membersOfType, runNow: Boolean(runNow)}});
			}
		}
		setFields(newFields);
		dispatch({ type: "setHasQueryChanged", payload: true });
	}

	function getMember(memberName: string) {
		if (cubesMetaLike) {
			return resolveCubeMember(cubesMetaLike.cubes, memberName);
		} else {
			console.warn("cubesMetaLike is undefined");
		}
	}

	function isInFields(memberName: string) {
		return fields.some((f) => f.memberName === memberName);
	}

	function getDimensionFields() {
		// return all dimension fields
		return fields.slice(0, dimension_idx.current);
	}

	function getMeasureFields() {
		// return all measure fields
		return fields.slice(dimension_idx.current);
	}

	function orderColumns(columns: TableColumn[]) {
		// order columns array based on fields array
		const orderedColumns: TableColumn[] = [];
		const missingColumns: string[] = [];
		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];
			const column = columns.find((c) => {
				let name = c.dataIndex
				name = processTimeDimensionName(name);
				return name === field.memberName
			});
			if (column) {
				orderedColumns.push(column);
			} else {
				missingColumns.push(field.memberName);
			}
		}
		if (missingColumns.length) {
			console.debug(`orderColumns > Could not find ${missingColumns.length} of ${columns.length} columns. Maybe the query has not been executed yet?`, missingColumns);
		}

		return orderedColumns;
	}

	const fieldContextInitial = {
		fields,
		getDimensionFields,
		getMeasureFields,
		initializeFields,
		initializeFieldsFromMemberList,
		initializeFieldsFromQuery,
		addField,
		removeField,
		validateReorder,
		reorderField,
		isInFields,
		getMember,
		orderColumns
	};
	// return the context provider
	return <FieldContext.Provider value={fieldContextInitial}>{props.children}</FieldContext.Provider>;
};

export default FieldContextProvider;
