import React from "react";
import {useCubeMetaDispatch} from "../context/cube-meta-context";
import API from "../api";


/**
 * First time component renders, get the context config definitions from backend.
 */
export default function useSetSchemaContextDefinitions(): void {
	const dispatchMeta = useCubeMetaDispatch();
  React.useEffect(() => {
    API.get(`/api/get_context_definitions`).then(resp => {
      dispatchMeta({ type: "setSchemaContextDefinitions", payload: { schemaContextDefinitions: resp.data }})
    })
  }, []);
}
