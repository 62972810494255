import React from "react";
import { BinaryFilter, Filter, Query, QueryAnnotations, TableColumn, TCubeMeasure } from "@cubejs-client/core";
import { TableCell, Box } from "@mui/material";
import { useQueryDispatch } from "../../../context/query-context";
import useStyles from "./style";
import { useFieldContext } from "../../../context/providers/FieldContextProvider";
import { useTheme } from "@mui/material/styles";
import { menuItemType } from "../../NestedMenu/menuItemType";
import Menu from "../../NestedMenu/Menu";
import useMenu from "../../../hooks/useMenu";
import { getDataType } from "../../SidebarExplore/dataIconsUtil";
// import {resolvePath, useResolvedPath} from "react-router-dom";
import {useLocation} from "react-router-dom";
// import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import lzString from "lz-string";


interface Props {
	row: Record<string, string>;
	column: TableColumn;
	annotation: QueryAnnotations;
	query: Query;
	isMeasure: boolean;
	isOddRow: boolean;
	value: string;
	isDashboardPanel: boolean;
	isExpanded: boolean;
	isRowExpanded: boolean;
}

const ReportBodyCell: React.FC<Props> = ({
	row,
	column,
	annotation,
	query,
	isMeasure,
	isOddRow,
	value,
	isDashboardPanel,
	isExpanded,
	isRowExpanded,
}: Props): React.ReactElement => {
	const theme = useTheme();
	const navigate = useNavigate();
  	const location = useLocation();
	const classes = useStyles();
	const dispatchQuery = useQueryDispatch();
	const fieldContext = useFieldContext();
	const [isDrillable, setIsDrillable] = React.useState(getIsDrillable());
	const [isClickable, setIsClickable] = React.useState(getIsClickable());

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!isClickable) {
			return null;
		}
		event.preventDefault();
		if (!isDrillable && isMeasure) {
			menu.handleClose();
		} else {
			menu.handleOpen();
		}
	};

	const dataType = React.useMemo(() => {
		return getDataType(column.type as string, column.shortTitle);
	}, [column.shortTitle])

	React.useEffect(() => {
		setIsDrillable(getIsDrillable());
		setIsClickable(getIsClickable());
	}, [column.key]);

	function getMeasureMember() {
		return annotation && annotation["measures"][column.key] && (annotation["measures"][column.key] as TCubeMeasure);
	}

	function getIsDrillable(): boolean {
		const measure = getMeasureMember();
		return measure && measure.drillMembers.length > 0;
	}

	function getIsClickable(): boolean {
		return getIsDrillable() || (!isMeasure && column.key !== "idxColumn");
	}

	const menuItems: menuItemType[] = [
		{
			label: "Drill down",
			onClick: handleClickedDrillDown,
			options: {
				visible: (isDrillable && !isDashboardPanel) ? true : false,
			}
		},
		{
			label: `Filter on "${value}"`,
			onClick: handleClickedFilter,
			options: {
				visible: !(isMeasure || isDashboardPanel) ? true : false,
				divider: true,
			}
		},
		{
			label: `Exclude "${value}"`,
			onClick: () => handleClickedFilter(true),
			options: {
				visible: !(isMeasure || isDashboardPanel) ? true : false,
				divider: true,
			}
		},
		{
			label: "Copy value",
			onClick: () => navigator.clipboard.writeText(value),
		}
	];

  function getDrillDownFilters(): Filter[] {
    // Skip tree node filters
    const queryFilters = query.filters || [];
    if (queryFilters) {
      queryFilters.splice(-1, 1);
    }

    const newFilters = Object.keys(annotation.dimensions)
      .filter(key => annotation.dimensions[key].type !== 'time')
      .map(key => {
      const filter: BinaryFilter = {
        member: key,
        operator: "equals",
        values: [row[key]]
      };
      return filter;
    });
    return queryFilters.concat(newFilters);
  }

	function handleClickedFilter(not = false) {
		const newFilter: BinaryFilter = {
			member: column.key,
			operator: not ? "notEquals" : "equals",
			values: [column.type == "number" ? Number.parseFloat(row[column.key]).toString() : value],
		};
		dispatchQuery({
			type: "addFilter",
			payload: { filter: newFilter, runNow: true },
		});
	}

  function handleClickedDrillDown() {
    // console.log(`ReportBodyCell > handleClickedDrillDown`);
    if (isDrillable) {
      const drillMembersGrouped = (annotation['measures'][column.key] as TCubeMeasure).drillMembersGrouped;
      if (drillMembersGrouped) {
        const newQuery = {
          segments: query.segments,
          measures: drillMembersGrouped.measures,
          dimensions: drillMembersGrouped.dimensions,
          timeDimensions: [],
          order: [],
          filters: getDrillDownFilters(),
        };

        dispatchQuery({type: "setQuery", payload: {query: newQuery, runNow: true}});

        // Initialize fields context for new query, generate query URL
        const fieldsOrder = fieldContext.initializeFieldsFromQuery(newQuery);

        // Replace current explore enum with '0' (default)
        const newUrlBase = location.pathname.replace(/(explore\/\d+)\/\d+/g, `$1/0`);

        // Get query as URL search param
        const json = JSON.stringify({query: newQuery, fieldsOrder: fieldsOrder});
        const compressed = lzString.compressToEncodedURIComponent(json);

        // Navigate to new query & explore
        const newUrl = `${newUrlBase}?q=${compressed}`;
        navigate(newUrl);
      }
    }
    menu.handleClose()
  }

	const menu = useMenu();

	function renderContextMenu() {
		return (
			<Menu
				open={menu.open}
				setOpen={menu.setOpen}
				anchorEl={menu.anchorRef.current}
				menuItems={menuItems}
				arrow={false}
			/>
		);
	}

	let clsName = column.key === "idxColumn" ? "idxCell" : "";

	if (isMeasure) {
		clsName += " is-measure";
	}

	if (isOddRow) {
		clsName += " is-odd-row";
	}


	return (
		<TableCell
			sx={{
				fontSize: "0.8rem",
				position: "relative",
				paddingLeft: column.key === 'idxColumn' ? 0 : 2,
				paddingRight: column.key === 'idxColumn' ? 0 : 2,
				whiteSpace: isExpanded ? "unset" : "nowrap",
				width: column.key === 'idxColumn' ? "25px" : "unset",
				minWidth: dataType === "text" ? "120px" : "20px",
				maxWidth: dataType === "text" ? "120px" : "20px",
				textAlign: column.key === "idxColumn" ? "center" : ["number", "time"].includes(column.type as string) ? "right" : "left",
				"&:before": {
					content: "''",
					position: "absolute",
					left: 0,
					top: "0",
					height: "100%",
					width: "1px",
					backgroundColor: isMeasure ? "#fee1ce" : theme.palette.divider,
				},
				"&:first-of-type:before": {
					height: "100%",
					top: 0,
				},
				"&:last-of-type:after": {
					content: "''",
					position: "absolute",
					right: 0,
					top: 0,
					height: "100%",
					width: "1px",
					backgroundColor: theme.palette.divider,
				},
				borderColor: isMeasure ? "#fee1ce" : theme.palette.divider,
				transition: `background-color 0.2s ${theme.transitions.easing.sharp}`,
				boxShadow: isRowExpanded ? `inset 0 0 0 1px ${theme.palette.divider}` : "",
				backgroundColor: isOddRow
					? isMeasure
						? "#fffbf8"
						: theme.palette.background.paper
					: isMeasure
					? "#fef6f0"
					: theme.palette.grey[50],
			}}
			key={column.key}
			className={clsName}
		>
			<Box
			className="hover-overlay"
			sx={{
				position: "absolute",
				backgroundColor: theme.palette.primary.light,
				mixBlendMode: 'overlay',
				left: 0,
				top: 0,
				height: "100%",
				width: "100%",
				zIndex: 1,
				opacity: 0,
				pointerEvents: "none",
			}}/>
			<div
				style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				title={value}
			>
				<Box
					component="span"
					sx={{opacity: column.key === "idxColumn" ? 0.5 : 1, "&:after": {
						transition: `width 0s ${theme.transitions.easing.sharp}`,
					} }}
					className={`${classes.cellText} ${isClickable ? "clickable" : ""}`}
					onClick={handleClick}
					ref={menu.anchorRef}
				>
					{value}
				</Box>
				{renderContextMenu()}
			</div>
		</TableCell>
	);
};

export default ReportBodyCell;
