import React from "react";
import 'date-fns';
import {BinaryFilter, UnaryFilter} from "@cubejs-client/core";
import {
  Box,
  Collapse,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";

import QueryFilter from "../QueryFilter/QueryFilter";
import useStyles from "./style";
import QueryFiltersAreaTitle from "../QueryFiltersAreaTitle/QueryFiltersAreaTitle";


interface Props {
  filters: (BinaryFilter | UnaryFilter)[]
}

const QueryFiltersArea: React.FC<Props> = (
  {
    filters,
  }: Props) => {
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = React.useState(false);

  React.useEffect(() => {
    if (!filtersOpen) {
      if (filters.length) {
        setFiltersOpen(true); // expand filters area when filters change from outside
      }
    }
  }, [filters.length])

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.subRoot} elevation={0}>
        <QueryFiltersAreaTitle
          toggleFiltersOpen={toggleFiltersOpen}
          filtersOpen={filtersOpen}
          numFilters={filters.length}
        />
        <Collapse in={filtersOpen} >
          <Box>
            <List dense={false} className={classes.listRoot}>
              {filters.length
                ? filters.map((f, filterIdx) => {
                  return <ListItem key={`filter-${filterIdx}-${(f as BinaryFilter | UnaryFilter).member || ''}`} className={classes.listItemRoot}>
                      <QueryFilter
                        filterIdx={filterIdx}
                        filter={f}
                      />
                  </ListItem>
                })
                : <Typography className={classes.filtersAreaPlaceholderText}>
                  No filters yet...
                </Typography>}
            </List>
          </Box>
        </Collapse>
      </Paper>
    </div>
  )
}

export default QueryFiltersArea;