import * as React from "react";
import { CircularProgress, Grid, Paper, Typography, Box } from "@mui/material";
import { DashboardPanelDef, stringifyFilters } from "../../utils/dashboard-utils";
import { CubeContext } from "@cubejs-client/react";
import {
	BinaryFilter,
	ProgressResult,
	Query,
	ResultSet,
	UnaryFilter,
} from "@cubejs-client/core";
import BarChart from "./PanelComponents/BarChart";
import PieChart from "./PanelComponents/PieChart";
import { ResponsiveContainer } from "recharts";
import CriticalDatesScatterChart from "./ScatterChart/CriticalDatesScatterChart";
import { useDashboardState } from "../../context/dashboard-context";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import { alpha, useTheme } from "@mui/material/styles";
import StackedAreaChart from "./PanelComponents/StackedAreaChart";
import LineChart from "./PanelComponents/LineChart";
import Figure from "./PanelComponents/Figure";
import Map from "./PanelComponents/Map/Map";
import {AiOutlineWarning} from "react-icons/ai"
import Table from "./PanelComponents/Table";
import { IS_LOCAL_DEV } from "../../config/frontendConfig";

interface Props {
	panel: DashboardPanelDef;
}

const DashboardPanel: React.FunctionComponent<Props> = ({ panel }: Props) => {
	const theme = useTheme();
	const fields = useFieldContext();
	const [query, setQuery] = React.useState<Query | undefined>(undefined);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [resultSet, setResultSet] = React.useState<ResultSet | undefined>(undefined);
	const [progressResult, setProgressResult] = React.useState<ProgressResult | undefined>(undefined);
	const { dashboardFilters, colorPaletteIdx } = useDashboardState();
	const { cubejsApi } = React.useContext(CubeContext);

	const { title, baseQuery, vizType, width } = panel;
	const vizDisplayProps = panel.vizDisplayProps || {};

	const [error, setError] = React.useState<string | undefined>(undefined)
	async function runPanelQuery(query: Query): Promise<void> {
		// console.log(`runPanelQuery > panel=${title}, query=`, query);
		setLoading(true);
		const progressCallback = (progressResult: ProgressResult) => setProgressResult(progressResult);
		const resultsCallback = (error: Error | null, resultSet: ResultSet) => {
			if (error) {
				console.error(error);
				setError(error.message)
			} else {
        // logQuery(cubejsApi, resultSet);
				setLoading(false);
				fields.initializeFieldsFromQuery(query);
				setQuery(query);
				setProgressResult(undefined);
				setResultSet(resultSet);
			}
		};
		await cubejsApi.load(query, { progressCallback }, resultsCallback);
	}

	React.useEffect(() => {
		if (
			!query ||
			(query.filters &&
				stringifyFilters(dashboardFilters) !== stringifyFilters(query.filters as (BinaryFilter | UnaryFilter)[]))
		) {
			const newQuery = {
				...baseQuery,
				filters: (baseQuery.filters || []).concat(dashboardFilters),
			};
			runPanelQuery(newQuery);
		}
	}, [stringifyFilters(dashboardFilters)]);

	const renderScatterPlot = (rs: ResultSet, progressResult: ProgressResult | undefined) => {
		if (progressResult && progressResult.stage() === "Loading") {
			return <CircularProgress />;
		}
		return <CriticalDatesScatterChart resultSet={rs} styles={{ maxHeight: 275 }} />;
	};

	function renderProgressLoader(text?: string) {
		return (
			<>
				<div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress size="1rem" />
					{(text && <Typography variant="body1">{text}</Typography>) || null}
				</div>
			</>
		);
	}

	function renderError() {
		return (
			<Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
				<Typography sx={{ color: theme.palette.error.main, fontWeight: 700 }}>
				<AiOutlineWarning  style={{verticalAlign: "text-bottom", transform: 'translateY(-2px)'}}/>
				</Typography>
				<Typography sx={{lineHeight: 1.2}}>
					Unable to show <b>{title}</b> data
				</Typography>
				{IS_LOCAL_DEV && (
					<Typography
						variant="subtitle1"
						sx={{ fontSize: "12px", lineHeight: 1, maxWidth: "90%", overflowWrap: "anywhere", opacity: 0.5 }}
					>
						{error}
					</Typography>
				)}
			</Box>
		);
	}

	function renderViz() {
		if (error) {
			return renderError()
		}

		if (loading) {
			return renderProgressLoader();
		}

		if (!resultSet) {
			return renderProgressLoader();
		}

		const pivotConfig = panel && panel.getPivotConfig ? panel.getPivotConfig(resultSet) : undefined;
		const seriesNames = resultSet.seriesNames(pivotConfig);
		const data = resultSet.chartPivot(pivotConfig);
		const format = vizDisplayProps.axisFormat || { x: "string", y: "string" };

		const vizProps = {
			seriesNames,
			data,
			format,
			colorPaletteIdx,
			...vizDisplayProps,
		};

		// Define the render functions for each visualization type
		const renderFunctions = {
			table: () => <Table runPanelQuery={runPanelQuery} vizDisplayProps={vizDisplayProps} rs={resultSet} query={query} progress={progressResult} fieldsOrder={panel.vizDisplayProps && panel.vizDisplayProps.fieldsOrder}/>,
			"single-figure": () => {
				if (resultSet.series().length < 1) {
					return null
				}

				return (
					<Figure
						value={resultSet.series()[0].key ? resultSet.chartPivot()[0][resultSet.series()[0].key] : resultSet.chartPivot()[0].x}
						data={data}
						seriesNames={seriesNames}
						format={panel.valueFormat || "number"}
						title={title}
						granularity={baseQuery.timeDimensions && baseQuery.timeDimensions.length > 0 && baseQuery.timeDimensions[0]?.granularity || undefined}
					/>
				)
			},
			"bar-graph": () => <BarChart {...vizProps} />,
			"line-chart": () => <LineChart {...vizProps} />,
			"stacked-area-chart": () => <StackedAreaChart {...vizProps} />,
			"scatter-chart": () => renderScatterPlot(resultSet, progressResult),
			"pie-chart": () => <PieChart {...vizProps} />,
			map: () => (
				<ResponsiveContainer width="100%" height={vizDisplayProps.height || 400}>
					<Map rs={resultSet} disablePanAndClick={vizDisplayProps?.map?.disablePanAndClick || false}/>
				</ResponsiveContainer>
			),
		};

		// Call the appropriate render function based on the visualization type
		if (renderFunctions[vizType]) {
			return renderFunctions[vizType]();
		} else {
			console.error("Unknown viz type", vizType);
			return <div>Not available ({vizType})</div>;
		}
	}

	return (
		<Grid
			item
			xs={width}
			sx={{ padding: 1, position: "relative", boxSizing: "border-box", display: "grid", gridTemplateRows: "auto 1fr auto" }}
		>
			<Paper
				sx={{
					border: `1px solid ${alpha(theme.palette.secondary.main, 0.25)}`,
					boxShadow: "none",
					borderRadius: 2,
					gridRow: "2 / 3",
					display: 'flex',
					overflow: 'hidden',
					position: 'relative',
					flexDirection: 'column',
					justifyContent: 'center',
					backgroundColor: '#fff',
					...theme.typography.body2,
					padding: theme.spacing(0.5),
					textAlign: 'center',
					color: theme.palette.text.secondary,
					minHeight: vizDisplayProps.height,
				}}
			>
				{vizType !== "single-figure" && !error ? (
					<div style={{ paddingBottom: 6 }}>
						<Typography variant="h6" sx={{ fontSize: 16 }}>
							{title}
						</Typography>
					</div>
				) : (
					<></>
				)}
				{renderViz()}
			</Paper>
		</Grid>
	);
};

export default DashboardPanel;
