import { ResultSet, TableColumn } from "@cubejs-client/core";
import { Box, TableRow } from "@mui/material";
import React from "react";
import ReportBodyCell from "./ReportBodyCell/ReportBodyCell";
import { useTheme } from "@mui/material/styles";

type Props = {
	columns: TableColumn[];
	resultSet: ResultSet<any>;
	getCellValueFormatted: (
		row: Record<string, string>,
		column: TableColumn,
		rowIdx: number,
		page: number,
		rowsPerPage: number
	) => string;
	row: Record<string, string>;
	rowIdx: number;
	page: number;
	rowsPerPage: number;
	isDashboardPanel: boolean;
	isExpanded: boolean;
};
const sx = {
	"&:hover": {
		"& .idxCell  .MuiBox-root": {
			opacity: 1,
		},
		"& .hover-overlay": {
			opacity: 1,
		}
	},
};

const EnhancedTableRow = ({
	columns,
	resultSet,
	getCellValueFormatted,
	row,
	rowIdx,
	page,
	rowsPerPage,
	isDashboardPanel,
	isExpanded,
}: Props) => {
	const theme = useTheme();
	const [rowExpand, setRowExpand] = React.useState(false);

	React.useEffect(() => {
		if (!isExpanded) {
			setRowExpand(false);
		}
	}, [isExpanded])

	return (
		<>
		<TableRow sx={sx}
			onDoubleClick={(e) => {
				if (isExpanded) return;
				e.preventDefault();
				setRowExpand(!rowExpand)
			}}>
			{columns.map((column: TableColumn) => {
				const isMeasure =
					resultSet.annotation() && Object.keys(resultSet.annotation().measures).includes(column.key);
				const cellValue = getCellValueFormatted(row, column, rowIdx, page, rowsPerPage);
				return (
					<ReportBodyCell
						key={column.key}
						row={row}
						column={column}
						isMeasure={isMeasure}
						isOddRow={rowIdx % 2 === 0}
						annotation={resultSet.annotation()}
						query={resultSet.query()}
						value={cellValue}
						isDashboardPanel={isDashboardPanel}
						isExpanded={rowExpand || isExpanded}
						isRowExpanded={rowExpand}
					/>
				);
			})}
		</TableRow>
		</>
	);
};

export default EnhancedTableRow;
