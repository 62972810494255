import { makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

const useStyles = makeStyles(() => ({
  card: {
    cursor: "pointer",
    border: "1px solid #dadce0",
    "&:hover": {
      outline: "none",
      backgroundColor: blue[50],
      "& $cardTitle": {
        color: blue[800],
      },
    },
  },
  cardContent: {},
  cardTitle: {
    textTransform: "capitalize",
  },
  cardSubtitle: {
    textTransform: "capitalize",
  },
}));

export default useStyles;