import React, {ReactElement} from "react";
import {format, fromUnixTime, getUnixTime, differenceInMonths, differenceInWeeks, startOfMonth} from "date-fns";
import {startOfWeek, addMonths, addWeeks, addDays, differenceInDays, differenceInYears, addYears} from "date-fns";
import {ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
import {ResultSet} from "@cubejs-client/core";
import CircleIcon from '@mui/icons-material/Circle';

// color
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import useStyles from "./style";
import {Box, Typography} from "@mui/material";

interface TooltipProps {
  active: boolean
  payload: Record<string, Record<string, string | number>>[]
}

interface Props {
  resultSet: ResultSet
  styles?: React.CSSProperties
}

enum FieldName {
  CriticalDateField = "CriticalDates.criticalDate",
  CriticalDateTypeField = "CriticalDates.criticalDateType",
  TenantsNameField = "Tenants.name",
  PropertyNameField = "Tenants.propertyName",
  // CriticalDateDayField = "CriticalDates.criticalDate.day",
  // CriticalDateWeekField = "CriticalDates.criticalDate.week",
  // CriticalDateMonthField = "CriticalDates.criticalDate.month",
  // CriticalDateYearField = "CriticalDates.criticalDate.year",
}

const color = scaleOrdinal(schemeCategory10);

function setColorDomain(colorDomain: string[]) {
  color.domain(colorDomain);
}

const MAX_NUM_X_AXIS_TICKS = 10;
const MIN_NUM_X_AXIS_TICKS = 4;
const VERTICAL_LABEL_INTERVAL = 3;

function getXAxisTicks(domain: number[]) {
  const firstDate = fromUnixTime(domain[0]);
  const secondDate = fromUnixTime(domain[1]);
  const yearsDiff = differenceInYears(secondDate, firstDate);
  const monthsDiff = differenceInMonths(secondDate, firstDate);
  const monthDiff3 = monthsDiff/3;
  const monthDiff6 = monthsDiff/6;
  const weeksDiff = differenceInWeeks(secondDate, firstDate);
  const daysDiff = differenceInDays(secondDate, firstDate);
  const ticks: number[] = [];
  let currentVal = firstDate;
  let intervalFnc: (date: Date) => Date;
  if (monthsDiff >= MIN_NUM_X_AXIS_TICKS && monthsDiff <= MAX_NUM_X_AXIS_TICKS){
    currentVal = startOfMonth(firstDate);
    intervalFnc = (date: Date) => addMonths(date, 1);
  } else if (monthsDiff < MIN_NUM_X_AXIS_TICKS && weeksDiff > MAX_NUM_X_AXIS_TICKS) {
    currentVal = startOfWeek(firstDate, {weekStartsOn: 1});
    intervalFnc = (date: Date) => addWeeks(date, 2);
  } else if (weeksDiff >= MIN_NUM_X_AXIS_TICKS && weeksDiff <= MAX_NUM_X_AXIS_TICKS){
    currentVal = startOfWeek(firstDate, {weekStartsOn: 1});
    intervalFnc = (date: Date) => addWeeks(date, 1);
  } else if (monthsDiff > MAX_NUM_X_AXIS_TICKS && monthDiff3 <= MAX_NUM_X_AXIS_TICKS) {
      currentVal = startOfMonth(firstDate);
      intervalFnc = (date => addMonths(date, 3));
  } else if (monthDiff3 > MAX_NUM_X_AXIS_TICKS && monthDiff6 <= MAX_NUM_X_AXIS_TICKS) {
      currentVal = startOfMonth(firstDate);
      intervalFnc = (date => addMonths(date, 6));
  } else if (monthDiff6 > MAX_NUM_X_AXIS_TICKS && yearsDiff <= MAX_NUM_X_AXIS_TICKS) {
      currentVal = startOfMonth(firstDate);
      intervalFnc = (date => addYears(date, 1));
  } else {
    intervalFnc = (date: Date) => addDays(date, Math.floor(daysDiff / MAX_NUM_X_AXIS_TICKS));
  }
  while (currentVal < secondDate) {
    ticks.push(getUnixTime(currentVal))
    currentVal = intervalFnc(currentVal);
  }
  return ticks;
}

function getYAxisTicks(maxYVal){
  let ticks: number[] = [];
  let currentVal = 0;
  while (currentVal <= maxYVal){
    ticks = ticks.concat([currentVal]);
    currentVal += 1;
  }
  return ticks;
}

const zSizeRange = [200, 400];  // min/max size of scatter bubbles

type DatasetEntry = {
    [FieldName.CriticalDateField]: number,
    [FieldName.CriticalDateTypeField]: string,
    [FieldName.TenantsNameField]: string[],
    index?: number,
    value?: number
};

type Dataset = { category: string, entries: DatasetEntry[] };

/**
 * Scatter chart visualization for dates on a timeline.
 * @param resultSet - Query data returned from Cube.js API
 * @constructor
 */
const CriticalDatesScatterChart: React.FunctionComponent<Props> = (
  {
    resultSet,
    styles,
  }: Props) => {
  const classes = useStyles();
  const [chartWidth, setChartWidth] = React.useState<number>(0);

  const divRef = React.useRef<HTMLDivElement>(null);

  const getChartWidth = () => {
    if (divRef.current) {
      const newWidth = (divRef.current as HTMLDivElement).offsetWidth * 5 / 6;
      return newWidth;
    } else {
      return 0;
    }
  }

  /* Set chart width on initial render and on window resize */
  React.useLayoutEffect(() => {
    const resizeListener = () => {
      setChartWidth(getChartWidth());
    };
    setChartWidth(getChartWidth());

    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  /**
   * Custom formatting of on-hover tooltip.
   * @param props
   */
  function renderTooltip(props: TooltipProps, category): React.ReactElement | null {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;
      const dateType = data[FieldName.CriticalDateTypeField]
      const dateValue = format(fromUnixTime(data[FieldName.CriticalDateField] as number), 'MMM dd, yyyy');
      const dateTypeColor = color(dateType as string);
      const dataset = datasets.filter(ds => ds.category === category);
      const tenants: string[] = dataset ? dataset[0].entries
          .filter(e => e[FieldName.CriticalDateField] === data[FieldName.CriticalDateField] && e.index == data.index)[0][FieldName.TenantsNameField]: [];
      let tenantsString = "";
      if (tenants){
          if (tenants.length == 1) {
              tenantsString = tenants[0];
          } else if (tenants.length > 1 && tenants.length < 3){
              tenantsString = tenants.join(', ');
          } else if (tenants.length >= 3){
              tenantsString = tenants.slice(0, 3).join(', ') + ` & ${tenants.slice(3).length} more`
          }
      }

      const rowStyle: React.CSSProperties = {
        display: "table-row",
      };
      const labelStyle: React.CSSProperties = {
        display: "table-cell",
        textAlign: "left",
      };
      const boldStyle = { fontWeight: 600 };

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid',
            borderColor: dateTypeColor as string,
            borderRadius: 4,
            margin: 0,
            padding: 8,
            display: 'table',
            fontSize: 10,
            minWidth: 200,
          }}
        >
          <div style={rowStyle}>
            <span style={labelStyle}>Type: <span style={boldStyle}>{dateType}</span></span>
          </div>
          <div style={rowStyle}>
            <span style={labelStyle}>Date: <span style={boldStyle}> {dateValue}</span></span>
          </div>
          <div style={rowStyle}>
            <span style={labelStyle}>Tenants count: <span style={boldStyle}> {data.value}</span></span>
          </div>
          <div>
            <span style={labelStyle}>Tenants: <span style={boldStyle}> {tenantsString}</span></span>
          </div>
        </div>
      );
    }

    return null;
  }

  let maxYVal = 0;
  const datasets = Object.values(resultSet.rawData().reduce((datasets, row) => {
    const category = row[FieldName.PropertyNameField];
    datasets[category] = datasets[category] ?? {category, entries: []};
    const entry = {
        [FieldName.CriticalDateField]: getUnixTime(new Date(row[FieldName.CriticalDateField])),
        [FieldName.CriticalDateTypeField]: row[FieldName.CriticalDateTypeField],
        [FieldName.TenantsNameField]: row[FieldName.TenantsNameField]
    };
    datasets[category].entries.push(entry);
    return datasets;
  }, {})).map((dataset: Dataset) => {
    const entriesPerDate: Record<number, number> = {};
    const entries = Object.values(dataset.entries.reduce((newEntries, entry) => {
      const dateIdentifier = `${entry[FieldName.CriticalDateField]},${entry[FieldName.CriticalDateTypeField]}`;
      newEntries[dateIdentifier] = newEntries[dateIdentifier] ?? {
        [FieldName.CriticalDateField]: entry[FieldName.CriticalDateField],
        [FieldName.CriticalDateTypeField]: entry[FieldName.CriticalDateTypeField],
        [FieldName.TenantsNameField]: [],
        "index": 1
      };
      newEntries[dateIdentifier][FieldName.TenantsNameField].push(entry[FieldName.TenantsNameField]);
      return newEntries;
    }, {})).map((entry: DatasetEntry) => {
      const indexVal = entriesPerDate[entry[FieldName.CriticalDateField]] || 0;
      entriesPerDate[entry[FieldName.CriticalDateField]] = indexVal + 1;
        if (indexVal > maxYVal) {
          maxYVal = indexVal;
        }
        entry.index = indexVal;
        entry.value = entry[FieldName.TenantsNameField].length
        return entry;
    });
    dataset.entries = entries as DatasetEntry[];
    return dataset;
  });

  const dateValues = resultSet.rawData().map(d => getUnixTime(new Date(d[FieldName.CriticalDateField])));
  const dateValuesDomain = [Math.min(...dateValues), Math.max(...dateValues)];
  const xTicks = getXAxisTicks(dateValuesDomain);

  const yTicks = getYAxisTicks(maxYVal);

  const zLabels = resultSet.seriesNames({
    x: [],
    y: [FieldName.CriticalDateTypeField]
  }).map((column) => column.yValues[0]);

  setColorDomain(zLabels);

  const zValues = datasets.reduce((values :number[], dataset) => {
      const numbers = dataset.entries.reduce((innerValues: number[], entry) => {
          if (entry.value) innerValues.push(entry.value);
          return innerValues
      }, []);
      values.push(...numbers);
      return values;
  }, [])
  const zDomain = [Math.min(...zValues), Math.max(...zValues)];

  const maxHeight = styles && styles.maxHeight || "300px";
  return (
    <div style={{ width: '100%', height: '100%', maxHeight: maxHeight }}>
      <div
        style={{
          display: 'flex',
          padding: 12,
          paddingTop: 0,
          position: 'relative',
          maxHeight: 'inherit',
          overflow: 'hidden'
        }}
      >
        <div
          id={'scatter-chart-container'}
          style={{
            flex: 7,
            minHeight: '150px',
            maxHeight: maxHeight,
            // overflow: 'auto',
            paddingTop: '12px',
            paddingBottom: '12px',
            margin: 10,
            marginLeft: 0,
            marginTop: 0,
            borderRadius: 4,
            marginBottom: 0,
            backgroundColor: '#fbfbfb',
            border: '1px solid #ddd',
          }}
        >
          <div
            className={classes.chartContainer}
            ref={divRef}
            style={{
              direction: 'ltr',
              padding: '20px',
              paddingTop: '12px',
              paddingBottom: '34px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {resultSet.tablePivot().length === 0
              ? <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>No matching results</Typography>
              </div>
              : datasets.map((dataset, datasetIdx) => {
                const data = dataset.entries;
                return <ResponsiveContainer key={datasetIdx + '-' + dataset.category} width="100%" height={55}>
                  <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1, alignSelf: 'center', paddingBottom: '3px', marginRight: '16px' }}>
                      <YAxisLabel title={dataset.category}/>
                    </div>
                    <div style={{ flex: 5 }}>
                      <ScatterChart
                        width={chartWidth}
                        height={55}
                        margin={{
                          top: 10,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <XAxis
                          type="number"
                          scale="time"
                          domain={[
                            dateValuesDomain[0] - Math.round((dateValuesDomain[1] - dateValuesDomain[0]) / MAX_NUM_X_AXIS_TICKS),
                            dateValuesDomain[1] + Math.round((dateValuesDomain[1] - dateValuesDomain[0]) / MAX_NUM_X_AXIS_TICKS)
                          ]}
                          dataKey={FieldName.CriticalDateField}
                          axisLine={{ stroke: 'brown', strokeWidth: 0.5 }}
                          ticks={xTicks}
                          tick={{stroke: 'brown', strokeWidth: 0, fontSize: 11 }}
                          tickLine={{ stroke: 'brown', strokeWidth: 0.5 }}
                          tickFormatter={(value: number) => (
                            (datasets.length < VERTICAL_LABEL_INTERVAL && datasetIdx + 1 == datasets.length) ||
                            (datasetIdx + 1) % VERTICAL_LABEL_INTERVAL === 0
                              ? format(fromUnixTime(value), 'M/d/yy')
                              : ""
                          )}
                          minTickGap={2}
                        />
                        {
                          <YAxis
                            type="number"
                            dataKey="index"
                            name="count"
                            height={10}
                            width={0}
                            tick={false}
                            // ticks={yTicks}
                            tickLine={false}
                            axisLine={false}
                          />
                          // </YAxis>
                        }
                        <ZAxis type="number" dataKey="value" domain={zDomain} range={zSizeRange} />
                        <Tooltip
                          cursor={{strokeDasharray: '3 3'}}
                          wrapperStyle={{zIndex: 100, outline: 'none' }}
                          content={(props: TooltipProps) => renderTooltip(props, dataset.category)}/>
                        <Scatter data={data}>
                          {data.map((entry, entryIdx) => {
                            return <Cell key={`cell-${entryIdx}`} fill={color(entry[FieldName.CriticalDateTypeField]) as string}/>
                          })}
                        </Scatter>
                      </ScatterChart>
                    </div>
                  </Box>
                </ResponsiveContainer>
              })}
          </div>
        </div>
        <div style={{ flex: 2, position: 'relative', display: 'flex', borderRadius: 4 }}>
          <Legend
            wrapperStyle={{
              flex: 1,
              backgroundColor: '#fbfbfb',
              position: 'relative',
              padding: '16px',
              border: '1px solid #ddd',
            }}
            content={() => (
              <Box sx={{ display: 'block', height: 'calc(100%)' }}>
                <Typography variant="h6" sx={{ height: '24px', fontSize: 12, fontWeight: 'bolder', color: '#333' }}>
                  Legend
                </Typography>
                <Box className={classes.legendContainer} sx={{ display: 'block', height: 'calc(100% - 24px)', overflow: 'auto', overflowX: 'none' }}>
                  {zLabels
                    .sort((a, b) => a < b ? -1 : 1)
                    .map((label, idx) => {
                      return (
                        <Box key={`item-${idx}`} sx={{ whiteSpace: 'nowrap', textAlign: 'initial' }}>
                          <CircleIcon sx={{ color: color(label) as string, height: 12 }}/>
                          <span style={{
                            fontSize: 12,
                            color: '#666',
                            paddingLeft: 6,
                            paddingRight: 18,
                          }}>
                            {label}
                          </span>
                        </Box>
                      )})}
                </Box>
              </Box>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default CriticalDatesScatterChart;

interface YAxisLabelProps {
  title: string
}
function YAxisLabel({ title }: YAxisLabelProps): React.ReactElement {
  return (
    <Typography
      variant='subtitle1'
      sx={{
        fontSize: '12px',
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'left',
      }}
    >
      {title}
    </Typography>
  )
}
