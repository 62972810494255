import React from "react";
import { MetaLike, resolveCubeFromMember, resolveCubeMember } from "../../utils/cube-utils";

export const baseRelativeFilterOptions = ["day", "week", "month", "quarter", "year"];

export function getDescription(timeDimension: string, cubesMetaLike: MetaLike | undefined ): React.ReactNode {
	if (!cubesMetaLike) return <></>
	const cube = resolveCubeFromMember(timeDimension, cubesMetaLike.cubesMap)
	const member = resolveCubeMember(cubesMetaLike.cubes, timeDimension)
	return <>{cube.title} <b>{member?.shortTitle}</b></>
	
}
