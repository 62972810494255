import { ResultSet } from "@cubejs-client/core";
import React, { useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Box, Divider, IconButton, List, ListItem, Slider, Stack, Typography } from "@mui/material";
import { style } from "../../../../pages/DashboardsPage/GoogleMapsStyleArray";
import { useTheme } from "@mui/material/styles";
import CustomMarker from "./CustomMarker";
import StyledMenuWrapper from "../../../StyledMenuWrapper/StyledMenuWrapper";
import Search from "./MapSearch";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { GOOGLE_MAPS_API_KEY } from "../../../../config/frontendConfig";

type Props = {
	rs: ResultSet;
	disablePanAndClick: boolean;
};

const containerStyle = {
	width: "100%",
	height: "100%",
	borderRadius: "4px",
};

const Map = ({ rs, disablePanAndClick }: Props) => {
	const theme = useTheme();

	const defaultZoom = 4;
	const [zoom, setZoom] = useState(defaultZoom);
	const [center, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>(undefined);
	const mapBounds = React.useRef<google.maps.LatLngBounds | undefined>(undefined);
	const [selected, setSelected] = useState<Record<string, number | [Record<string, string>]> | undefined>(undefined);
	
	function getSelectedLocation() {
		const result = rs.rawData().filter((r) => selected && r["Properties.id"] === selected.id)[0]
		const lat = result["Properties.geoLatitude"];
		const lng = result["Properties.geoLongitude"];

		return `${lat},${lng}`
	}

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: GOOGLE_MAPS_API_KEY as string,
	});

	const [map, setMap] = React.useState<google.maps.Map | null>(null);

	const onLoad = React.useCallback(function callback(map) {
		const bounds = new google.maps.LatLngBounds();
		if (rs.rawData().length > 1) {
			rs.rawData().forEach((result) => {
				const location = result["Properties.location"];
				const lat = result["Properties.geoLatitude"];
				const lng = result["Properties.geoLongitude"];

				if (location) {
					const latLng = new google.maps.LatLng(lat, lng);
					bounds.extend(latLng);
				}
			});

			map.fitBounds(bounds);
			mapBounds.current = bounds;
			setMap(map);
		} else if (rs.rawData().length === 1) {
			const result = rs.rawData()[0];
			const location = result["Properties.location"];
			const lat = result["Properties.geoLatitude"];
			const lng = result["Properties.geoLongitude"];

			if (location) {
				const latLng = new google.maps.LatLng(lat, lng);
				setCenter(latLng);
				setZoom(6);
			}

			setMap(map)
		}
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	function debounce(cb, delay = 500) {
		return (...args) => {
			setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	return isLoaded ? (
		<Box sx={{ width: "100%", height: "100%", borderRadius: 1, overflow: "hidden", position: "relative" }}>
			<Box
				sx={{
					position: "absolute",
					width: "100%",
					height: "96%",
					zIndex: 2,
					pointerEvents: "none",
				}}
			>
				<Box sx={{position: 'absolute', right: 0, bottom: 0, pointerEvents: "auto", display: 'flex', flexDirection: 'column', grow: 1}}>
					<IconButton onClick={() => {
						if (map) {
							const curZoom = map.getZoom()

							if (curZoom) {
								map.setZoom(curZoom + 1)
							}
						}
					}}>
						<AddRoundedIcon/>
					</IconButton>
					<IconButton onClick={() => {
						if (map) {
							const curZoom = map.getZoom()
							if (curZoom) {
								map.setZoom(curZoom - 1)
							}
						}
					}}>
						<RemoveRoundedIcon/>
					</IconButton>
				</Box>
				{rs.rawData().length > 1 && <Search resultSet={rs} map={map} setSelected={setSelected} selected={selected} mapBounds={mapBounds} />}
				{selected && (
					<StyledMenuWrapper
						id="StyledMenuWrapper-123"
						sx={{
							fontSize: "0.8rem",
							pointerEvents: "auto",
							width: "280px",
							position: "absolute",
							top: 0,
							zIndex: -1,
						}}
					>
						<Box sx={{marginTop: 6}}>
							{/* <Box
								sx={{
									height: "160px",
									width: "100%",
									background:
										`url(https://maps.googleapis.com/maps/api/streetview?size=280x200&location=${getSelectedLocation()}&fov=100&heading=0&pitch=0&key=)`,
									backgroundSize: "cover",
									backgroundPosition: "center center",
								}}
							/> */}
							<Box sx={{ padding: 1 }}>
								<Typography variant="h6" sx={{ textAlign: "left" }}>
									{selected.name}
								</Typography>
								<Divider />
								<List dense>
									{(selected.attributes as [Record<string, string>]).map(({ label, value }) => (
										<ListItem sx={{paddingLeft: 0}}>
											<Typography
												variant="caption"
												sx={{
													marginRight: "1ch",
													fontWeight: "600",
													position: "relative",
												}}
											>
												{`${label}: `}
											</Typography>
											<Typography variant="caption">{value}</Typography>
										</ListItem>
									))}
								</List>
								{/* <Divider/> */}
								{/* <Button variant="contained" disableElevation>
									View in dashboard
								</Button> */}
							</Box>
						</Box>
					</StyledMenuWrapper>
				)}
			</Box>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={zoom}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{
					disableDefaultUI: true,
					gestureHandling: disablePanAndClick ? "none" : "cooperative",
					streetViewControl: false,
					keyboardShortcuts: false,
					styles: style,
				}}
			>
				{rs.rawData().map((result, idx) => (
					<CustomMarker
						key={idx}
						result={result}
						map={map}
						setSelected={setSelected}
						activeId={selected ? (selected.id as number) : undefined}
						disableClick={disablePanAndClick}
					/>
				))}
				<></>
			</GoogleMap>
		</Box>
	) : (
		<></>
	);
};

export default Map;
