import { useRef } from "react";

const useFadedScroll = (basePercentage: number) => {
	const ref = useRef<HTMLDivElement>(null);

	function onScroll(e: React.UIEvent<HTMLDivElement>) {
		if (!ref.current) return;
		const target = e.target as HTMLDivElement;
		const percentage = (
			basePercentage +
				(100 - basePercentage) * (((target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100) / 100)
		);

		// if the rounded percentage is the same as the current percentage, don't update
        console.log("onScroll")
		// if (percentage + "%" === ref.current.style.getPropertyValue("--scrollPercentage")) return;
		ref.current.style.setProperty("--scrollPercentage", `${percentage}%`);
	}

	// define the fadeStyles object
	const fadeStyles = {
		"--scrollPercentage": `${basePercentage}%`,
		WebkitMaskImage: `linear-gradient(black var(--scrollPercentage), transparent 100%)`,
		WebkitMaskMode: "alpha",
		WebkitMaskPositionY: "top",
	};

	return { ref, onScroll, fadeStyles, basePercentage };
};

export default useFadedScroll;
