import React, { useEffect, useState } from "react";
import { format, startOfWeek } from "date-fns";
import { ProgressResult, QueryOrder, ResultSet, TableColumn, Query } from "@cubejs-client/core";
import { CircularProgress, Typography } from "@material-ui/core";
import { Table, TableBody, TableRow, Box } from "@mui/material";
import { TableColsGenerator } from "../../../utils/utils";
import EnhancedTableHead from "../EnhancedTableHead";
import useStyles from "./style";
import { DATE_FORMAT } from "../../../config/frontendConfig.js";
import { useFieldContext } from "../../../context/providers/FieldContextProvider";
import useFadedScroll from "../../../hooks/useFadedScroll";
import Pagination from "../Pagination/Pagination";
import { useTheme } from "@mui/material/styles";
import EnhancedTableRow from "../EnhancedTableRow";
import animations from "../../../theme/animations";

interface Props {
	query?: Query;
	resultSet: ResultSet | null | undefined;
	isLoading: boolean | undefined;
	error: Error | null | undefined;
	progress?: ProgressResult | null | undefined;
	isDashboardPanel: boolean;
	setQueryOrder?: (member: string, direction: QueryOrder) => void;
}

const TableRenderer: React.FunctionComponent<Props> = ({
	query,
	resultSet,
	isLoading,
	error,
	progress,
	isDashboardPanel,
	setQueryOrder,
}: Props): React.ReactElement => {
	const theme = useTheme();

	const fieldContext = useFieldContext();
	const classes = useStyles({});

	/* Table Controls */
	// pagination
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const [isExpanded, setIsExpanded] = useState(true);

	useEffect(() => {
		setPage(0);
	}, [resultSet]);

	/* Handle rows per page */
	function localStorageGetRPS() {
		const rowsPerPage = localStorage.getItem("rowsPerPage");
		if (isDashboardPanel) {
			setRowsPerPage(10);
			return;
		}
		if (rowsPerPage) {
			let parsedRowsPerPage = parseInt(rowsPerPage);
			if (parsedRowsPerPage > 100) parsedRowsPerPage = 100;
			else if (parsedRowsPerPage < 1) parsedRowsPerPage = 20;
			setRowsPerPage(parseInt(rowsPerPage));
		}
	}

	function localStorageSetRPS() {
		localStorage.setItem("rowsPerPage", rowsPerPage.toString());
	}

	// get preferred rows per page from local storage
	useEffect(localStorageGetRPS, []);

	// update local storage rows per page
	useEffect(localStorageSetRPS, [rowsPerPage]);

	/* Fade effect & table header background */

	/* Table component Refs */
	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const tableRef = React.useRef<HTMLTableElement>(null);

	const fade = useFadedScroll(95); // init the fade effect

	/* handles the variation of the fade based on scroll position
	 * and whether the header is sticky or not
	 */
	function handleScroll(event) {
		const target = event.target as HTMLDivElement;
		if (!target || isLoading) return;

		// fade effect
		const percentage =
			fade.basePercentage +
			(100 - fade.basePercentage) * (((target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100) / 100);

		target.style.webkitMaskImage = `linear-gradient(black ${percentage}%, rgba(0, 0, 0, 0.15) calc(100% - 6px), black calc(100% - 6px)`;

		// detect if header is sticky
		if (target.scrollTop > 0) {
			target.classList.add("sticky-header");
		} else {
			target.classList.remove("sticky-header");
		}
	}

	// resize observer for table container
	const resizeTimeout = React.useRef<NodeJS.Timeout>();
	function handleResize() {
		const tableContainer = tableContainerRef.current;
		if (!tableContainer || isLoading) return;
		clearTimeout(resizeTimeout.current);
		resizeTimeout.current = setTimeout(() => {
			const height = tableContainer.clientHeight;
			tableContainer.style.setProperty("--tableContainerHeight", `${height}px`);
			tableContainer.style.setProperty("--windowHeight", `${window.innerHeight}`);
		}, 100);
		getTableOverflow();
	}
	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [handleResize]);

	React.useEffect(() => {
		handleResize();
	}, [isExpanded, handleResize]);

	const getTableOverflow = () => {
		const table = tableRef.current;
		const tableContainer = tableContainerRef.current;
		if (table && tableContainer) {
			const overflowX = table.clientWidth > tableContainer.clientWidth;
			const overflowY = table.clientHeight > tableContainer.clientHeight;

			if (!overflowY && fade.ref.current) {
				// if no overflowY remove fade effect
				fade.ref.current.style.webkitMaskImage = `linear-gradient(black ${100}%, rgba(0, 0, 0, 0.15) calc(100% - 6px), black calc(100% - 6px)`;
			} else if (fade.ref.current) {
				// if overflowY add fade effect
				fade.ref.current.style.webkitMaskImage = `linear-gradient(black ${fade.basePercentage}%, rgba(0, 0, 0, 0.15) calc(100% - 6px), black calc(100% - 6px)`;
			}
		}
	};

	/* Table data */

	const columns = React.useMemo(() => {
		// return resultSet?.tableColumns()
		if (!resultSet) return [];
		return TableColsGenerator(fieldContext.orderColumns(resultSet.tableColumns()));
	}, [resultSet]);

	const rows = React.useMemo(() => {
		if (!resultSet) return [];
		const rawData = resultSet.rawData();
		const result = rawData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		return result;
	}, [resultSet, page, rowsPerPage]);

	const skeletonSx = {
		backgroundSize: "50%",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "0px",
		animation: `${animations.loading2}`,
	}

	/* Handle loading state or no results */
	if (isLoading) {
		return (
			<Box sx={{position: 'relative', width: '100%', height: '100%'}} id="loader">
				<Box sx={{overflow: 'hidden', width: '100%', maxHeight: 'calc(100% - 40px)'}} key="tableLoader">
					<Box sx={{ width: '100%', height: '44px', marginTop: "1px", ...skeletonSx }} />
					{
						new Array(100).fill(0).map((_, i) => (
							<Box key={i} sx={{ width: '100%', height: '30px', marginTop: "1px", ...skeletonSx }} />
						))
					}
				</Box>
			<Box sx={{width: '100%', height: "32.5px", display: 'flex', justifyContent: 'space-between', marginTop: "2px"}}>
				<Box sx={{ width: '220px', height: '32.5px', ...skeletonSx }} />
				<Box sx={{ width: '85px', height: '32.5px', ...skeletonSx }} />
				<Box sx={{ width: '200px', height: '32.5px', ...skeletonSx}} />
			</Box>
			</Box>
		);
	}

	if (error) {
		console.warn(`TableRenderer > error! > rendering previous resultSet...`);
	}

	if (resultSet) {
		/* Handle results exist */
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					overflow: "auto",
					"& .sticky-header thead tr th::before": {
						opacity: isDashboardPanel ? 0 : 1,
						boxShadow: `inset 0px -1px 0px ${theme.palette.divider}`,
					},
					marginBottom: isDashboardPanel ? 0 : theme.spacing(1),
					animation: animations.fadeIn,
				}}
				ref={tableContainerRef}
			>
				<Box
					className={classes.tableContainer}
					ref={fade.ref}
					onScroll={handleScroll}
					sx={{
						position: "relative",
						WebkitMaskMode: "alpha",
						WebkitMaskImage: `linear-gradient(black ${fade.basePercentage}%, rgba(0, 0, 0, 0.15) calc(100% - 6px), black calc(100% - 6px))`,
						transition: "all 0.3s ease-in-out",
						overflow: "scroll",
						marginBottom: theme.spacing(1),
					}}
				>
					<Table
						ref={tableRef}
						stickyHeader
						size="small"
						style={{ zIndex: 0, borderCollapse: "collapse", position: "relative" }}
					>
						<EnhancedTableHead
							columns={columns}
							query={resultSet.query()}
							isDashboardPanel={isDashboardPanel}
							setQueryOrder={setQueryOrder}
						/>
						<TableBody sx={{ overflow: "hidden" }}>
							{rows.map((row: Record<string, string>, rowIdx) => (
								<EnhancedTableRow
									key={rowIdx}
									columns={columns}
									resultSet={resultSet}
									getCellValueFormatted={getCellValueFormatted}
									row={row}
									rowIdx={rowIdx}
									page={page}
									rowsPerPage={rowsPerPage}
									isDashboardPanel={isDashboardPanel}
									isExpanded={isExpanded}
								/>
							))}
						</TableBody>
					</Table>
				</Box>
				<Pagination
					count={resultSet.rawData().length}
					page={page}
					rowsPerPage={rowsPerPage}
					setPage={setPage}
					setRowsPerPage={setRowsPerPage}
					isExpanded={isExpanded}
					setIsExpanded={setIsExpanded}
					isDashboardPanel={isDashboardPanel}
				/>
			</Box>
		);
	} else {
		return (
			<Typography component="span" style={{ alignSelf: "center" }}>
				There was was a problem with your query. Please modify fields or filters and try again.
			</Typography>
		);
	}
};

export default React.memo(TableRenderer);

const dateFormatter = (item: string, column: TableColumn) => {
	try {
		if (item) {
			const granularity = column.key.replace(/.*\./, ""); // column key membername.granularity so grab granularity
			let date = new Date(item);
			let dateFormat = DATE_FORMAT as string;
			switch (granularity) {
				case "day":
					break;
				case "week":
					date = startOfWeek(date);
					break;
				case "month":
					dateFormat = "MMM yyyy";
					break;
				case "quarter":
					dateFormat = "QQQ yyyy";
					break;
				case "year":
					dateFormat = "yyyy";
					break;
			}

			return format(date, dateFormat);
		} else {
			return "";
		}
	} catch (error) {
		console.warn("Date formatter passed invalid date: " + item);
		return item;
	}
};

function formatValue(val: string, column: TableColumn) {
	if (val === undefined) {
		return val;
	} else {
		let columnType = column.type;
		if (column.meta && column.meta.format) {
			columnType = column.meta.format;
		}
		switch (columnType) {
			case "string":
				return val;
			case "time":
				return dateFormatter(val, column);
			case "number":
				if (column.format === "percent") {
					return new Intl.NumberFormat("en-US", currencyOptions(1, 2, true)).format(parseFloat(val)) + "%";
				} else if (column.format === "currency") {
					return new Intl.NumberFormat("en-US", currencyOptions(2, 2, true)).format(parseFloat(val));
				} else if (column.format === "id") {
					return val;
				} else {
					// return val;
					return new Intl.NumberFormat("en-US", { useGrouping: true }).format(parseFloat(val));
				}
			default:
				if (column.format === "percent") {
					return val + "%";
				} else if (column.format === "currency") {
					return new Intl.NumberFormat("en-US", currencyOptions(2, 2, true)).format(parseFloat(val));
				}
				return val;
		}
	}
}

export const currencyOptions = (minDigits: number, maxDigits: number, useGrouping: boolean) => ({
	minimumFractionDigits: minDigits,
	maximumFractionDigits: maxDigits,
	useGrouping: useGrouping,
});

function getCellValueFormatted(
	row: Record<string, string>,
	column: TableColumn,
	rowIdx: number,
	page: number,
	rowsPerPage: number
) {
	if (column.key === "idxColumn") {
		return (page * rowsPerPage + rowIdx + 1).toString();
	} else {
		return formatValue(row[column.key], column);
	}
}
