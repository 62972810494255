import React, { Fragment, useEffect, useState } from "react";
import { /* useHistory */ useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../../../context/auth-context";
import { Collapse, List, ListItem, ListItemText, ListItemButton, Typography } from "@mui/material";
import { alpha, Theme } from "@mui/material/styles";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import StyledNavigationButton from "../styledComponents/StyledNavigationButton";
import {DashboardDef, DashboardsConfig, getColorFromContext} from "../../../utils/dashboard-utils";
import { SchemaContextName, SchemaContextEnum, SchemaContextDefinition } from "../../../utils/cube-utils";
import { useSavedReports } from "../../../hooks/useSavedReports";
import {USER_ROLE_NAME_ADMIN} from "../../../utils/auth-utils";
import { useCubeMetaState } from "../../../context/cube-meta-context";

interface NavigationButton {
	text: string;
	icon: {
		active: React.ReactNode;
		inactive: React.ReactNode;
	};
	expandable: boolean;
	subTitle?: string;
}

interface NavigationStateObject {
	visible: boolean;
	open: boolean;
	list: SubNav[];
}

interface NavigationState {
	[key: string]: NavigationStateObject;
}
interface SubNav {
	text: string;
	context: string;
	match: (location) => boolean;
	to: string;
}

const navigationButtons: NavigationButton[] = [
	{
		text: "home",
		// icon: <HomeRoundedIcon />,
		icon: {
			active: <HomeRoundedIcon />,
			inactive: <HomeOutlinedIcon />
		},
		expandable: false,
	},
	{
		text: "dashboards",
		icon: {
			active: <PieChartRoundedIcon />,
			inactive: <PieChartOutlineOutlinedIcon />
		},
		expandable: true,
	},
	{
		text: "explore",
		icon: {
			active: <ExploreIcon />,
			inactive: <ExploreOutlinedIcon />
		},
		expandable: true,
	},
	{
		text: "named reports",
		icon: {
			active: <AssignmentRoundedIcon />,
			inactive: <AssignmentOutlinedIcon />
		},
		expandable: true,
		subTitle: 'Recently modified',
	},
];

interface Props {
	open: boolean;
	openMenu: () => void;
	closeMenu: () => void;
	theme: Theme;
}

const Navigation = ({ open, openMenu, theme }: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { currentEnvironment, user } = useAuthState();
	const cubeMetaState = useCubeMetaState();
	const schemaContextDefinitions = cubeMetaState.schemaContextDefinitions as SchemaContextDefinition[];

	// navigation state
	const navigationActions = {
		home: () => {
			if (currentEnvironment) {
				navigate(`/${currentEnvironment["env_name"]}`);
			}
		},
		dashboards: () => {
			if (!open) {
				openMenu();
				if (!navigationState.dashboards.open) {
					setNavigationState((navigationState) => ({
						...navigationState,
						dashboards: { ...navigationState.dashboards, open: true },
					}));
				}
			} else {
				setNavigationState((navigationState) => ({
					...navigationState,
					dashboards: { ...navigationState.dashboards, open: !navigationState.dashboards.open },
				}));
			}
		},
		"explore": () => {
			if (!open) {
				openMenu();
				if (!navigationState["explore"].open) {
					setNavigationState((navigationState) => ({
						...navigationState,
						"explore": {
							...navigationState["explore"],
							open: true,
						},
					}));
				}
			} else {
				setNavigationState((navigationState) => ({
					...navigationState,
					"explore": {
						...navigationState["explore"],
						open: !navigationState["explore"].open,
					},
				}));
			}
		},
		"named reports": () => {
			if (!open) {
				openMenu();
				if (!navigationState["named reports"].open) {
					setNavigationState((navigationState) => ({
						...navigationState,
						"named reports": {
							...navigationState["named reports"],
							open: true,
						},
					}));
				}
			} else {
				setNavigationState((navigationState) => ({
					...navigationState,
					"named reports": {
						...navigationState["named reports"],
						open: !navigationState["named reports"].open,
					},
				}));
			}
		},
	}

	const navigationMatches = {
		home: location.pathname === (currentEnvironment && `/${currentEnvironment["env_name"]}`),
		dashboards: location.pathname.includes("dashboards"),
		"explore": location.pathname.includes("explore"),
		"named reports": location.pathname.includes("reports"),
	};

	const [navigationState, setNavigationState] = useState<NavigationState>({
		home: { visible: true, open: false, list: [] },
		dashboards: { visible: true, open: false, list: [] },
		"explore": { visible: true, open: false, list: [] },
		"named reports": { visible: true, open: false, list: [] },
	});

	// when sidebar is collapsed close all sub navigation
	useEffect(() => {
		if (!open) {
			setNavigationState((navigationState) => ({
				...navigationState,
				dashboards: { ...navigationState["dashboards"], open: false },
				"explore": { ...navigationState["explore"], open: false },
				"named reports": { ...navigationState["named reports"], open: false },
			}));
		}
	}, [open]);

	const savedReports = useSavedReports();

	function isDashboardVisible(dashboardDef: DashboardDef): boolean {
		const dashboardContextConfig = currentEnvironment && currentEnvironment.contexts_config[dashboardDef.contextName];
		const dashboardConfig = currentEnvironment && currentEnvironment.dashboards_config[dashboardDef.key];
		return dashboardConfig
			&& user
			&& (dashboardConfig.visible || user.roleName === USER_ROLE_NAME_ADMIN)
			&& dashboardContextConfig
			&& dashboardContextConfig.enabled
			&& (dashboardContextConfig.visible || user.roleName === USER_ROLE_NAME_ADMIN)
			&& dashboardContextConfig.dependencies_met;
	}

	function updateDashboardButton() {
		const dashboardKeys = Object.keys(DashboardsConfig);
		const dashboardsSubNav: SubNav[] = [];
		for (let i = 0; i < dashboardKeys.length; i++) {
			const key = dashboardKeys[i];
			const dashboard = DashboardsConfig[key];
			const isVisible = isDashboardVisible(dashboard);

			if (isVisible) {
				const contextNumeric = SchemaContextEnum[dashboard.contextName];
				dashboardsSubNav.push({
					text: key.replace("-", " "),
					context: dashboard.contextName,
					match: (location) => {
						return location.pathname.includes(key);
					},
					to: `/${currentEnvironment && currentEnvironment["env_name"]}/dashboards/${contextNumeric}/${key}`,
				});
			}
		}

		if (dashboardsSubNav.length === 0) {
			setNavigationState((navigationState) => ({
				...navigationState,
				dashboards: { ...navigationState.dashboards, visible: false },
			}));
			return;
		}

		setNavigationState((navigationState) => ({
			...navigationState,
			dashboards: { ...navigationState.dashboards, list: dashboardsSubNav },
		}));

		return;
	}

	React.useEffect(() => {
		updateDashboardButton();
	}, [currentEnvironment]);

	function updateNamedReportsButton() {
		if (!savedReports) {
			return;
		}
		const namedReportsSubNav: SubNav[] = [];
		const length = savedReports.length <= 5 ? savedReports.length : 5;
		for (let i = 0; i < length; i++) {
			const contextNumeric = SchemaContextEnum[savedReports[i]["context_name"]];
			namedReportsSubNav.push({
				text: savedReports[i]["name"],
				context: savedReports[i]["context_name"],
				match: (location) => {
					return location.pathname.includes(savedReports[i]["key"]);
				},
				to: currentEnvironment ? `/${currentEnvironment["env_name"]}/reports/${contextNumeric}/${savedReports[i].subcontext_enum}/key/${savedReports[i]["key"]}` : "/"
			});
		}
		if (namedReportsSubNav.length === 0) {
			setNavigationState((navigationState) => ({
				...navigationState,
				"named reports": { ...navigationState["named reports"], visible: false },
			}));
		} else {
			setNavigationState((navigationState) => ({
				...navigationState,
				["named reports"]: { ...navigationState["named reports"], list: namedReportsSubNav, visible: true },
			}));
		}
	}

	React.useEffect(() => {
		updateNamedReportsButton();
	}, [savedReports]);

	function updateExploreButton() {
		if (!currentEnvironment || !user) return;
		const visibleContextNames = Object.entries(currentEnvironment.contexts_config)
			.filter(
				([key, context]) =>
					context.enabled &&
					context.dependencies_met &&
					(context.visible || user.roleName === USER_ROLE_NAME_ADMIN)
			)
			.map(([key, context]) => key);
		const exploreSubNav: SubNav[] = [];
		const visibleContext = schemaContextDefinitions.filter((context) => visibleContextNames.includes(context.name));
		for (let i = 0; i < visibleContext.length; i++) {
			const cur = visibleContext[i];
			exploreSubNav.push({
				text: cur.label,
				context: cur.name,
				match: (location) => {
					return location.pathname.includes(cur.name);
				},
				to: `/${currentEnvironment["env_name"]}/explore/${SchemaContextEnum[cur.name]}/${0}`
			});
		}
		if (exploreSubNav.length === 0) {
			setNavigationState((navigationState) => ({
				...navigationState,
				explore: { ...navigationState.explore, visible: false },
			}));
		} else {
			setNavigationState((navigationState) => ({
				...navigationState,
				explore: { ...navigationState.explore, list: exploreSubNav, visible: true },
			}));
		}
	}

	React.useEffect(() => {
		updateExploreButton();
	}, [currentEnvironment, user]);




	return (
		<List
			sx={{
				marginTop: theme.spacing(4),
				padding: "0",
				height: "100%",
				display: "block",
				pl: "7px",
				pr: open ? "0" : "7px",
			}}
		>
			{navigationButtons.map((button, index) => {
				const { expandable, text, icon, subTitle } = button;
				const state = navigationState[text];

				if (state.visible) {
					return (
						<Fragment key={index}>
							<ListItem
								sx={{
									padding: "0",
									transition: "all 225ms cubic-bezier(.075, .82, .165, 1) 0ms",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}
							>
								<ArrowTooltip title={open ? "" : text} disableInteractive enterDelay={500}>
									<StyledNavigationButton
										open={open}
										active={navigationMatches[text]}
										expandable={expandable && state.list.length > 0}
										expanded={expandable && navigationState[text].open}
										onClick={navigationActions[text]}
										disableGutters
									>
										{expandable &&
											state.list.length > 0 &&
											(!state.open ? (
												<ExpandLess className={"expand-btn"} />
											) : (
												<ExpandMore className={"expand-btn"} />
											))}
										{navigationMatches[text] ? icon.active : icon.inactive}
										<ListItemText primary={text} />
									</StyledNavigationButton>
								</ArrowTooltip>
							</ListItem>
							{expandable && state.list.length > 0 ? (
								<Collapse
									key={"collapse" + index}
									in={state.open && open}
									timeout="auto"
									unmountOnExit
									easing={theme.transitions.easing.easeIn}
								>
									<List
										disablePadding
										sx={{
											backgroundColor: alpha(theme.palette.secondary.contrastText, 0.05),
											borderRadius: "0 0 0px 25px",
											borderLeft: `2px solid ${alpha(theme.palette.secondary.contrastText, 0.05)}`,
											borderBottom: `2px solid ${alpha(theme.palette.secondary.contrastText, 0.05)}`,
										}}
									>
										{subTitle
											? (
												<ListItem
													key={'subtitle-' + subTitle}
													disablePadding
													disableGutters
													sx={{ borderBottom: "1px solid rgba(5, 5, 5, 0.1)", padding: 0, overflow: "hidden" }}
												>
													<ListItemButton
														sx={{
															padding: theme.spacing(0.5, 1, 0.5, 2.5),
															// backgroundColor: alpha(theme.palette.primary.light, 0.35),
															backgroundColor: "rgba(238, 238, 238, 0.05)",
															cursor: "default",
														}}
														disableRipple
														onClick={() => { /* do nothing */ }}
													>
														<Typography
															variant='subtitle1'
															sx={{
																color: "#BBBBBB",
																fontSize: "10px",
																fontWeight: "bold",
																textTransform: "uppercase",
															}}
														>
															{subTitle}
														</Typography>
													</ListItemButton>
												</ListItem>
											)
											: <></>
										}
										{state.list.map((subButton, index) => (
											<Link key={index + subButton.text} to={subButton.to} style={{textDecoration: 'none'}}>
												<ListItem
													disablePadding
													disableGutters
													sx={{ borderBottom: "1px solid rgba(5, 5, 5, 0.1)", padding: 0, overflow: "hidden" }}
													>
													<ListItemButton
														disableRipple
														sx={{
															padding: theme.spacing(0.5, 1, 0.5, 2.5),
															// backgroundColor: subButton.match(location)
															backgroundColor: navigationMatches[text] && subButton.match(location)
															? alpha(theme.palette.primary.light, 0.35)
															: "transparent",
															// overflow: "",
															"&:hover": {
																// backgroundColor: subButton.match(location)
																backgroundColor: navigationMatches[text] && subButton.match(location)
																? alpha(theme.palette.primary.light, 0.25)
																: "rgba(238, 238, 238, 0.05)",
															},
														}}
														>
														<span
															style={{
																minWidth: "7px",
																height: "7px",
																backgroundColor: getColorFromContext(theme, subButton.context as SchemaContextName),
																borderRadius: "100%",
																display: "inline-block",
																marginRight: "1ch",
																textOverflow: "ellipsis",
															}}
															/>
														<ListItemText
															primary={subButton.text}
															sx={{
																color: theme.palette.secondary.contrastText,
																textTransform: "capitalize",
																"& .MuiTypography-body1": {
																	fontSize: "12px",
																	fontFamily: "Outfit",
																	fontWeight: "bold",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																},
															}}
															/>
													</ListItemButton>
												</ListItem>
											</Link>
										))}
										<ListItem key="spacer" sx={{ minHeight: 5 }}></ListItem>
									</List>
								</Collapse>
              ) : (
                <></>
              )}
            </Fragment>
					);
				}
				return <Fragment key={index}></Fragment>;
			})}
		</List>
	);
};

export default Navigation;
