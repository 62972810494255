import React from "react";
import { Box, Fade, MenuList, Popper, PopperPlacementType } from "@mui/material";
import NestedMenuItem from "./NestedMenuItem";
import { menuItemType } from "./menuItemType";
import StyledMenuWrapper from "../StyledMenuWrapper/StyledMenuWrapper";

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isChild?: boolean;
	parentOpen?: boolean;
	menuItems: menuItemType[];
	anchorEl?: HTMLElement | null;
	depth?: number;
	activeDepth: number;
	setActiveDepth: React.Dispatch<React.SetStateAction<number>>;
	placement?: PopperPlacementType;
	arrow?: boolean;
	color?: string;
	canHide?: boolean;
};

const NestedMenu = ({
	open: menuOpen,
	setOpen: setMenuOpen,
	isChild,
	parentOpen,
	menuItems,
	anchorEl,
	depth,
	activeDepth,
	setActiveDepth,
	placement,
	arrow,
	color,
	canHide,
}: Props) => {
	const [open, setOpen] = React.useState(!isChild); // the open sate of this menu

	const [activeIndex, setActiveIndex] = React.useState(-1); // the index of the active menu item
	const [arrowRef, setArrowRef] = React.useState(null); // the ref to the arrow element

	React.useEffect(() => {
		if (parentOpen !== undefined) {
			const isOpen = parentOpen && activeDepth < (depth ? depth : 0);
			setOpen(() => isOpen);
		}
	}, [parentOpen]);

	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement={isChild ? "right-start" : placement}
			sx={{
				zIndex: 1200,
				...popperArrowSx,
				...(canHide
					? {
							"&[data-popper-reference-hidden]": {
								display: "none",
							},
					  }
					: {}),
			}}
			modifiers={
				isChild
					? undefined
					: [
							{
								name: "arrow",
								enabled: true,
								options: {
									element: arrowRef,
								},
							},
					  ]
			}
		>
			{!isChild && (
				<Box
					component="span"
					className="arrow"
					sx={{
						position: "absolute",
						fontSize: 7,
						width: "3em",
						height: "3em",

						"&::before": {
							opacity: arrow ? 1 : 0,
							content: '""',
							margin: "auto",
							display: "block",
							width: 0,
							height: 0,
							borderStyle: "solid",
						},
					}}
					ref={setArrowRef}
				/>
			)}
			<Fade in timeout={100} mountOnEnter unmountOnExit>
				<StyledMenuWrapper style={{ transformOrigin: placement?.includes("top") ? "bottom" : "top" }}>
					<MenuList dense disablePadding>
						{menuItems.map((item, index) => {
							const options = {
								active: item.options && item.options.active !== undefined ? item.options.active : false,
								disabled: item.options && item.options.disabled !== undefined ? item.options.disabled : false,
								visible: item.options && item.options.visible !== undefined ? item.options.visible : true,
								divider: item.options && item.options.divider !== undefined ? item.options.divider : false,
								color: item.options && item.options.color !== undefined ? item.options.color : undefined,
								startIcon: item.options && item.options.startIcon !== undefined ? item.options.startIcon : <></>,
								endIcon: item.options && item.options.endIcon !== undefined ? item.options.endIcon : <></>,
								shortcut: item.options && item.options.shortcut !== undefined ? item.options.shortcut : undefined,
							};

							if (options.visible) {
								return (
									<NestedMenuItem
										menuOpen={menuOpen}
										setMenuOpen={setMenuOpen}
										setOpen={setOpen}
										item={item}
										index={index}
										depth={depth ? depth : 0}
										activeIndex={activeIndex}
										setActiveIndex={setActiveIndex}
										activeDepth={activeDepth}
										setActiveDepth={setActiveDepth}
										options={options}
										color={color}
										key={index}
									/>
								);
							} else {
								<></>;
							}
						})}
					</MenuList>
				</StyledMenuWrapper>
			</Fade>
		</Popper>
	);
};

const popperArrowSx = {
	'&[data-popper-placement*="bottom"] .arrow': {
		top: 0,
		left: 0,
		marginTop: "-0.9em",
		width: "3em",
		height: "1em",
		"&::before": {
			borderWidth: "0 1em 1em 1em",
			borderColor: `transparent transparent rgba(80, 80, 80, 1) transparent`,
		},
	},
	'&[data-popper-placement*="top"] .arrow': {
		bottom: 0,
		left: 0,
		marginBottom: "-0.9em",
		width: "3em",
		height: "1em",
		"&::before": {
			borderWidth: "1em 1em 0 1em",
			borderColor: `rgba(80, 80, 80, 1) transparent transparent transparent`,
		},
	},
	'&[data-popper-placement*="right"] .arrow': {
		left: 0,
		marginLeft: "-0.9em",
		height: "3em",
		width: "1em",
		"&::before": {
			borderWidth: "1em 1em 1em 0",
			borderColor: `transparent rgba(80, 80, 80, 1) transparent transparent`,
		},
	},
	'&[data-popper-placement*="left"] .arrow': {
		right: 0,
		marginRight: "-0.9em",
		height: "3em",
		width: "1em",
		"&::before": {
			borderWidth: "1em 0 1em 1em",
			borderColor: `transparent transparent transparent rgba(80, 80, 80, 1)`,
		},
	},
};

export default NestedMenu;
