import { setApiAuthHeader, removeApiAuthHeader } from "../api";
import {SchemaContextName} from "./cube-utils";

export const USER_ROLE_NAME_ADMIN = "ds_admin";

export type ReportsEnvironmentContextsConfig = Record<SchemaContextName, {
  enabled: boolean
  visible: boolean
  dependencies_met: boolean
}>

export type ReportsEnvironmentDashboardsConfig = Record<SchemaContextName, {
  visible: boolean
}>

export type ReportsEnvironmentConfig = {
  env_name: string
  display_name: string
  template_id: number
  org_ids: number[]
  visible_to_end_users: boolean
  included_in_daily_stream: boolean
  is_global: boolean
  last_synced: Date
  creation_time: Date
  contexts_config: ReportsEnvironmentContextsConfig
  dashboards_config: ReportsEnvironmentDashboardsConfig
}

export type UserAuthenticationDetails = {
  jwtToken: string
  firstName: string
  lastName: string
  email: string
  username: string
  userId: number
  account: number
  lastEnvName: string
  roleName: string
  team: number
  // allowedEnvNames: string
}


// new
function getStoredUserValues(): UserAuthenticationDetails {
  const jwtToken = window.localStorage.getItem('jwtToken') as string;
  const firstName = window.localStorage.getItem('firstName') as string;
  const lastName = window.localStorage.getItem('lastName') as string;
  const email = window.localStorage.getItem('email') as string;
  const username = window.localStorage.getItem('username') as string;
  const userId = parseInt(window.localStorage.getItem('userId') as string) as number;
  const account = parseInt(window.localStorage.getItem('account') as string) as number;
  const lastEnvName = window.localStorage.getItem('lastEnvName') as string;
  const team = parseInt(window.localStorage.getItem('team') as string) as number;
  const roleName = window.localStorage.getItem('roleName') as string;
  return { jwtToken, firstName, lastName, email, username, userId, account, lastEnvName, roleName, team }
}

function setUserValues(userAuthDetails: UserAuthenticationDetails): void {
  // console.log(`auth-utils > setUserValues()`);
  setApiAuthHeader(userAuthDetails.jwtToken)
  window.localStorage.setItem('jwtToken', userAuthDetails.jwtToken);
  window.localStorage.setItem('firstName', userAuthDetails.firstName);
  window.localStorage.setItem('lastName', userAuthDetails.lastName);
  window.localStorage.setItem('email', userAuthDetails.email);
  window.localStorage.setItem('username', userAuthDetails.username);
  window.localStorage.setItem('userId', userAuthDetails.userId.toString());
  window.localStorage.setItem('account', userAuthDetails.account.toString());
  window.localStorage.setItem('lastEnvName', userAuthDetails.lastEnvName);
  window.localStorage.setItem('team', userAuthDetails.team.toString());
  window.localStorage.setItem('roleName', userAuthDetails.roleName);
  // window.localStorage.setItem('allowedEnvNames', userAuthDetails.allowedEnvNames);
}


function deleteUserValues(): void {
  // console.log(`deleteUserValues`);
  window.localStorage.removeItem('jwtToken');
  window.localStorage.removeItem('firstName');
  window.localStorage.removeItem('lastName');
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('username');
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('account');
  window.localStorage.removeItem('lastEnvName');
  window.localStorage.removeItem('team');
  window.localStorage.removeItem('roleName');
  removeApiAuthHeader()
}

export { getStoredUserValues, setUserValues, deleteUserValues }