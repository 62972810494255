/* eslint-disable no-use-before-define */
import React from 'react';
import { MenuItem, Select } from "@material-ui/core";
import DSInputBase from '../DSInputBase/DSInputBase';
import useStyles from "./style";
import {Typography} from "@mui/material";


interface Props {
  value: string,
  options: {
    value: string,
    label: string,
  }[]
  handleSetValue: (x: string) => void
}

const Dropdown: React.FunctionComponent<Props> = (
  {
    value,
    options,
    handleSetValue,
  }: Props): React.ReactElement => {
  const classes = useStyles();

  return <Select
    id="ds-select"
    className={'dropdown'}
    onChange={(event: React.ChangeEvent<{value: string}>) => handleSetValue(event.target.value)}
    value={value}
    variant="outlined"
    input={<DSInputBase/>}
  >
    {options.map((option) => (
      <MenuItem classes={{ root: classes.menuItemRoot }} key={option.value} value={option.value}>
        <Typography sx={{
          fontSize: "13px",
          fontFamily: "Outfit",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}>
          {option.label}
        </Typography>
      </MenuItem>
    ))}
  </Select>

  {/*return <Autocomplete
    id="highlights-demo"
    classes={{
      root: classes.root,
    }}
    options={options}
    getOptionLabel={(option) => option.title}
    onChange={(event: ChangeEvent, option: {title: string, name: string}) => handleSetValue(option.name)}
    renderInput={(params) => (
      <TextField
        {...params}
        classes={{
          // root: classes.textFieldRoot,
        }}
        // InputProps={{
          // classes: {
            // root: classes.inputRoot,
            // input: classes.input,
          // },
        // }}
        variant="outlined"
        margin="none"
      />
    )}
    disableClearable
    renderOption={(option, { inputValue }) => {
      const matches = match(option.title, inputValue);
      const parts = parse(option.title, matches);

      return (
        <div>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      );
    }}
  />
  */}
}


export default Dropdown;