import {useAuthDispatch, useAuthState} from "../context/auth-context";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCubeMetaDispatch} from "../context/cube-meta-context";
import {USER_ROLE_NAME_ADMIN} from "../utils/auth-utils";
import {SchemaContextName} from "../utils/cube-utils";


/**
 * Listens for changes to:
 * 	- "env", the URL param
 * 	- "allowedEnvironments", the list of allowed envs retrieved from the backend
 *
 *  Finds the EnvConfig whose key matches "env" in the "allowedEnvironments" array
 *  Then dispatches "setCurrentEnvironment" to set the EnvConfig object as "currentEnvironment" globally
 */
export default function useSetCurrentEnvironment(): void {
	const navigate = useNavigate();
	const dispatchAuth = useAuthDispatch();
	const dispatchMeta = useCubeMetaDispatch();
	const { allowedEnvironments, user } = useAuthState();
	const { env } = useParams() as Record<string, string>;

  React.useEffect(() => {
    // console.debug(`useSetCurrentEnvironment > useEffect > env changed (env=${env})`);
    if (allowedEnvironments) {
			const currentEnvironment = allowedEnvironments.find((e) => e['env_name'] == env);
			if (currentEnvironment) {
				dispatchAuth({type: 'setCurrentEnvironment', payload: { currentEnvironment }});
				const visibleContextNames = Object.entries(currentEnvironment.contexts_config)
        .filter(([key, context]) => (
          context.enabled
          && context.dependencies_met
          && (context.visible || (user && user.roleName === USER_ROLE_NAME_ADMIN))
        ))
        .map(([key, context]) => key);

				dispatchMeta({type: 'setVisibleContextNames', payload: {
					visibleContextNames: visibleContextNames as SchemaContextName[] }})
			} else {
				console.warn(`Illegal environment "${env}"`);
				navigate("/");
			}
		}
  }, [env, reduceAllowedEnvironments(allowedEnvironments) ])
}


function reduceAllowedEnvironments(allowedEnvironments) {
	return allowedEnvironments && allowedEnvironments.reduce((a, b) => a + "-" + b.env_name, "");
}
