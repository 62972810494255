import React from "react";
import useStyles from "./style";
import {COLOR_PALETTES, PinnedFilterDef} from "../../utils/dashboard-utils";
import PinnedFilter from "../PinnedFilters/PinnedFilter";
import {useDashboardDispatch, useDashboardState} from "../../context/dashboard-context";
import Box from "@mui/material/Box";
import {IS_LOCAL_DEV} from "../../config/frontendConfig";

interface Props {
  pinnedFilters: PinnedFilterDef[]
}

const DashboardFilters: React.FunctionComponent<Props> = ({
  pinnedFilters,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const { dashboardFilters } = useDashboardState();

  return (
    <div className={classes.dashboardFiltersRoot}>
      {pinnedFilters.map((pinnedFilter, idx) => {
        return <div key={idx} className={classes.section}>
          <PinnedFilter
            pinnedFilter={pinnedFilter}
            activeFilter={dashboardFilters.find(f => f.member === pinnedFilter.member)}
          />
        </div>
      })}
    </div>
  );
};

export default DashboardFilters;
