import { SeriesNamesColumn } from "@cubejs-client/core";
import { format, startOfWeek } from "date-fns";
import { DATE_FORMAT } from "../../../config/frontendConfig";
import numeral from "numeral";

export type formatString = "string" | "percent" | "number" | "currency" | "area" | `date - ${'day' | 'week' | 'month' | 'quarter' | 'year'}`

export interface Format {
    x: formatString;
    y: formatString;
}

export interface PanelProps {
    seriesNames: SeriesNamesColumn[];
    data;
    format: Format;
    colorPaletteIdx: number
	dateFormatOverride?: string
    axisWidth?: number
    height?: number
		xAxisOrientation?: "top" | "bottom"
}

export const numberFormatter = (item, valueFormat) => {
	switch (valueFormat) {
		case "number":
			return numeral(item).format("0,0");
		case "percent":
			return numeral(item / 100).format("0.0%");
		case "currency":
			return numeral(item).format("($0.00a)");
		case "area":
			return numeral(item).format("0.0a") + " SF";
		default:
			return numeral(item).format("0,0");
	}
};

const dateFormatter = (item: string, granularity, dateFormatterOverride?: string) => {
	try {
		if (item) {
			let date = new Date(item);
			let dateFormat = DATE_FORMAT as string;
			
			if (dateFormatterOverride) {
				return format(date, dateFormatterOverride)
			}

			switch (granularity) {
				case "day":
					dateFormat = "MM/dd/yyyy";
					break;
				case "week":
					date = startOfWeek(date);
					dateFormat = "MM/dd/yyyy";
					break;
				case "month":
					dateFormat = "MMM yyyy";
					break;
				case "quarter":
					dateFormat = "QQQ yyyy";
					break;
				case "year":
					dateFormat = "yyyy";
					break;
			}
			return format(date, dateFormat);
		} else {
			return "";
		}
	} catch (error) {
		console.warn("Date formatter passed invalid date: " + item)
		return item;
	}
};


export function getTypeFromFormat(format: string): "category" | "number" {
	return format === "string" || format.includes("date") ? "category" : "number";
}

export function tickFormatter(value, format: string, dateFormatterOverride?: string) {
    if (format.includes("date")) {
        // parse the format to get the granularity
        const granularity = format.split("-")[1].trim();
        return dateFormatter(value, granularity, dateFormatterOverride);
    }

	switch (format) {
      case "string":
          return value;
      case "currency":
      case "number":
      case "percent":
      case "area":
          return numberFormatter(value, format);
      default:
          return value;
  }
}

export function tooltipFormatter(value, format: string) {
    switch (format) {
        case "currency":
            return `${numberFormatter(value, "currency")}`
        case "number":
            return numberFormatter(value, "number")
        case "area":
            return `${numberFormatter(value, "number")} sq ft`
        case "percent":
            return `${numberFormatter(value, "percent")}%`
        default:
            return value
    }
}