import {
  BinaryOperator,
  CubejsApi,
  QueryOrder,
  ResultSet,
  TableColumn,
  TCubeMeasure,
  TCubeDimension,
  TQueryOrderArray,
  UnaryOperator,
  TCubeSegment
} from "@cubejs-client/core";
import {getStoredUserValues} from "./auth-utils";
import API from "../api";

export type CubeMeta = {
  name: string
  title: string
  dimensions: TCubeDimension[]
  measures: TCubeMeasure[]
  segments: TCubeSegment[]
  connectedComponent: number // don't know what it means
}

export type FilterOperatorName = BinaryOperator | UnaryOperator

export type FilterOperator = {
  name: FilterOperatorName
  title: string
  multipleValuesSupported: boolean
}

/* Column for row number (i.e. 1, 2, ... 20) */
export const IndexTableColumn: TableColumn = {
  key: "idxColumn",
  dataIndex: "idxColumn",
  meta: "",
  type: "number",
  title: "",
  shortTitle: "",
};

export function TableColsGenerator(columns: TableColumn[]): TableColumn[] {
  return [IndexTableColumn].concat(columns)
}/* types */

export type ColumnOrder = 'asc' | 'desc';

export function toggleDirection(order: QueryOrder): QueryOrder {
  return order === 'asc' ? 'desc' : 'asc';
}

export function getNewArrayOrder(
  orderArray: TQueryOrderArray,
  member: string,
  sortDirection: QueryOrder
): TQueryOrderArray {
  const resArray = Array.from(orderArray)
  resArray.unshift([member, sortDirection]);
  return resArray;
}

export const templateSections = [
  {
    title: "Lease Data Fields",
    cubes: [
      "ValuesLeaseId",
      "ValuesLeaseVersion",
      "ValuesLeaseStatus",
      "ValuesLeaseType",
      "ValuesCountry",
      "ValuesCurrency",
      "ValuesAreaUnits",
    ]
  },
  {
    title: "Parties",
    cubes: [
      "ValuesTenantName",
      "ValuesTenantEntity",
      "ValuesParentId",
      "ValuesTenantConcentrate",
      "ValuesTenantDba",
      "ValuesLandlordName",
      "ValuesGuarantor",
    ]
  }
]

export function logQuery(api: CubejsApi, resultSet: ResultSet) {
  try {
    let envType = 'athena';
    if (process.env.REACT_APP_REPORTS_BACKEND_URL && process.env.REACT_APP_REPORTS_BACKEND_URL.includes('mongo')) {
      envType = 'mongo';
    }
    const headers = (api as Record<string, any>).headers;
    const {jwtToken} = getStoredUserValues();
    API.post('/api/store_query', {query: resultSet.query(), results: resultSet.rawData(), envType, headers, jwtToken})
      .catch(error => {
        console.warn('logQuery: ' + error);
      })
  } catch (error) {
      console.warn('logQuery: ' + error);
      return
  }
}

export function getDealthingUrl() {
  const currentUrl = new URL(window.location.href);
  let dealthingUrl = "";

  if (currentUrl.hostname.includes("localhost")) {
      dealthingUrl = "http://localhost:8000";
  } else {
      let subdomains = currentUrl.hostname.split('.');
      if (subdomains[0] === "reports") {
          subdomains[0] = "app";
      }
      dealthingUrl = currentUrl.protocol + "//" + subdomains.join('.');
  }
  return dealthingUrl
}