import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReportsUser, useAuthState } from "../../context/auth-context";
import { useCubeMetaState } from "../../context/cube-meta-context";
import {
	ExploreQueryDefinition,
	SchemaContextDefinition,
	SchemaContextEnum,
} from "../../utils/cube-utils";
import { ReportsEnvironmentConfig, USER_ROLE_NAME_ADMIN } from "../../utils/auth-utils";
import useMenu from "../../hooks/useMenu";
import Menu from "../NestedMenu/Menu";
import { menuItemType } from "../NestedMenu/menuItemType";
import { SplitButtonOption } from "../SplitButton/SplitButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { alpha } from "@mui/material/styles";
import {useQueryDispatch} from "../../context/query-context";


interface Props {
	contextDefinition: SchemaContextDefinition
	explore: ExploreQueryDefinition
}

const SelectContext: React.FC<Props> = (
	{
		contextDefinition,
		explore
	}: Props): React.ReactElement => {
	const theme = useTheme();
	const navigate = useNavigate();
	const authState = useAuthState();
	const currentEnvironment = authState.currentEnvironment as ReportsEnvironmentConfig;
	const user = authState.user as ReportsUser;
	const { schemaContextDefinitions } = useCubeMetaState();
	const { env } = useParams() as Record<string, string>;
	const menu = useMenu();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatchQuery = useQueryDispatch();

	const visibleContextNames = Object.entries(currentEnvironment.contexts_config)
		.filter(
			([key, context]) =>
				context.enabled && context.dependencies_met && (context.visible || user.roleName === USER_ROLE_NAME_ADMIN)
		)
		.map(([key, context]) => key);

		React.useEffect(() => {
			menu.handleClose();
		}, [searchParams])

	const menuItems = React.useMemo(() => {
		const items: menuItemType[] = [];
		if (!schemaContextDefinitions) return items;
		schemaContextDefinitions
			.filter((contextDef) => visibleContextNames.includes(contextDef.name))
			.map((contextDef) => {
				const children: menuItemType[] = [];
				const options = contextDef.explore_queries.map((queryDef) =>
					Object.assign(queryDef, {
						onClick: () => {
							setSearchParams({ q: "" });
							dispatchQuery({ type: "clearResultSet" });
							navigate(`/${env}/explore/${SchemaContextEnum[contextDef.name]}/${queryDef.enumInt}`)
					},
					})
				);
				for (let i = 0; i < options.length; i++) {
					const option: SplitButtonOption = options[i];
					children.push({
						label: option.label,
						onClick: () => {
							menu.handleClose();
							option.onClick();
						},
						options: {
							color:
								theme.palette[
									contextDef.colorPalette as "contextRent" | "contextDefault" | "contextCriticalDates" | "contextPortfolio" | "contextGlobal" | "contextProjects"
								].main,
						},
					});
				}
				items.push({
					label: contextDef.label,
					onClick: () => {
						menu.handleClose();
						options[0].onClick();
					},
					children,
					options: {
						color:
							theme.palette[contextDef.colorPalette as "contextRent" | "contextDefault" | "contextCriticalDates" | "contextPortfolio" | "contextGlobal" | "contextProjects"]
								.main,
						startIcon: (
							<div
								style={{
									backgroundColor:
										theme.palette[
											contextDef.colorPalette as "contextRent" | "contextDefault" | "contextCriticalDates" | "contextPortfolio" | "contextGlobal" | "contextProjects"
										].main,
									minWidth: "11px",
									aspectRatio: "1",
									height: "100%",
									borderRadius: "100%",
									display: "inline-block",
									marginRight: "1ch",
								}}
							/>
						),
					}
				});
			});
		return items;
	}, []);

	if (!schemaContextDefinitions) return <></>;

	return (
		<>
			<Button
				ref={menu.anchorRef as React.RefObject<HTMLButtonElement>}
				onClick={menu.handleOpen}
				color="secondary"
				sx={{
					backgroundColor: alpha(theme.palette["second"][100], 0.1),
					"&:hover": {
						backgroundColor: alpha(theme.palette["second"][100], 0.2),
					},
					color: theme.palette.secondary.contrastText,
					width: "100%",
					display: "flex",
					justifyContent: "start",
				}}
			>
				<div
					style={{
						backgroundColor:
							theme.palette[
								contextDefinition.colorPalette as
									| "contextRent"
									| "contextDefault"
									| "contextCriticalDates"
							].main,
						width: "12px",
						height: "12px",
						borderRadius: "100%",
						display: "inline-block",
						marginRight: "1ch",
					}}
				/>
				{explore.label}
				<ArrowDropDownIcon
					style={{ transform: menu.open ? "rotate(180deg)" : "rotate(0deg)", color: theme.palette["second"][300] }}
				/>
				<Menu
					open={menu.open}
					setOpen={menu.setOpen}
					anchorEl={menu.anchorRef.current}
					menuItems={menuItems}
					arrow={false}
				/>
			</Button>
		</>
	);
};

export default SelectContext;
