import React, { RefObject } from "react";
import { Button } from "@mui/material";
import Menu from "../../NestedMenu/Menu";
import { menuItemType } from "../../NestedMenu/menuItemType";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface menuType {
	anchorRef: React.RefObject<HTMLButtonElement | HTMLDivElement>;
	handleClose: () => void;
	handleOpen: () => void;
	handleToggle: () => void;
	open: boolean;
	setOpen: React.Dispatch<boolean>;
}

type Props = {
	menu: menuType;
	menuItems: menuItemType[];
	selected: string;
};
const Select = ({ menu, menuItems, selected }: Props) => {
	return (
		<>
			<Button
				onClick={menu.handleToggle}
				variant="contained"
				color="secondary"
				disableElevation
				disableRipple
				ref={menu.anchorRef as RefObject<HTMLButtonElement>}
                sx={{flexGrow: 1}}
			>
				{selected} <ArrowDropDownIcon style={{ transform: menu.open ? "rotate(180deg)" : "rotate(0deg)" }} />

			<Menu
				menuItems={menuItems}
				open={menu.open}
				setOpen={menu.setOpen}
				anchorEl={menu.anchorRef.current}
				arrow={false}
				placement="bottom"
			/>
			</Button>
		</>
	);
};

export default Select;
