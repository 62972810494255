import React from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CircularProgress, Divider, Typography} from "@material-ui/core";
import API from "../api";
import { initiateAlert, useAlertDispatch } from "../context/alert-context";
import { runQuery, useQueryDispatch, useQueryState } from "../context/query-context";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import TableRenderer from "../components/ReportTable/TableRenderer/TableRenderer";
import QueryFiltersArea from "../components/Filters/QueryFiltersArea/QueryFiltersArea";
import QueryToggleControls from "../components/QueryToggleControls/QueryToggleControls";
import {useCubeMetaState} from "../context/cube-meta-context";
import {CubeContext} from "@cubejs-client/react";
import {
  SchemaContextName,
  SchemaContextDefinition,
  validateQuery,
  MetaLike,
  ExploreQueryDefinition, SchemaContextEnum
} from "../utils/cube-utils";
import ExploreSideBar from '../components/SidebarExplore/ExploreSideBar';
import { useFieldContext } from '../context/providers/FieldContextProvider';
import DateFilter from '../components/FixedDateFilter/DateFilter';
import {BinaryFilter, CubejsApi, Query, UnaryFilter} from "@cubejs-client/core";
import lzString from "lz-string";
import useContextDefinition from '../hooks/useContextDefinition';
import { Box } from '@mui/material';
import {SavedReport} from "../utils/saved-report-utils";
import trackQuery from "../utils/mixpanel-utils/track-query";
import {ReportsEnvironmentConfig} from "../utils/auth-utils";

interface Props {
  currentEnvironment: ReportsEnvironmentConfig
  appContextName: SchemaContextName
  metaContextName: SchemaContextName
}
const NamedReportPage: React.FC<Props> = (
  {
    currentEnvironment,
    appContextName,
    metaContextName,
  }: Props): React.ReactElement => {
  const dispatch = useQueryDispatch()
  const fieldContext = useFieldContext();
  const dispatchAlert = useAlertDispatch()
  const navigate = useNavigate();


  const { key } = useParams() as Record<string, string>;
  const [report, setReport] = React.useState<SavedReport | undefined>(undefined);
  /**
   * Handle change to report 'key' URL param.
   * If 'key' exists, fetch report from server and set report in component state.
   */
  React.useEffect(() => {
    if (!key && !report) {
      return;
    }

    if (!key && report) {
      setReport(undefined);
      return;
    }

    API.get(`/api/report/env_name/${currentEnvironment.env_name}/key/${key}`).then(resp => {
      setReport(resp.data);
    }).catch(error => {
      const errorMsg = error.response
        ? `Error ${error.response.status} (${error.response.statusText}): ${error.response.data}`
        : `Error: ${error}`;
      console.error(errorMsg);
      initiateAlert(dispatchAlert, 'error', `Oops... Couldn't load saved report."`);
      navigate(`/${currentEnvironment.env_name}`);
    });

  }, [key])


  const initialSidebarWidth = '324px'

  const [searchParams, setSearchParams] = useSearchParams();
  const urlQuery = React.useMemo(() => searchParams.get("q"), [searchParams]);

  const { query, resultSet, progressResult, error, isLoading, runNow, hasQueryChanged } = useQueryState();
  const { contextDefinition, explore } = useContextDefinition();
  const { cubesMetaLike } = useCubeMetaState() as { cubesMetaLike: MetaLike };


  /**
   * Handle init saved report.
   *  1. Determine whether page is explore or saved report.
   *  2. Determine query from saved report, explore def or URL search params; validate query.
   *  3. Dispatch query.
   */
  React.useEffect(() => {
    if (urlQuery) {
      return;
    }
    if (!report) {
      return;
    }

    // Use report fields_order (if not empty) or query_string to initialize fields.
    if (Object.keys(report.fields_order).length > 0) {
      fieldContext.initializeFields(report.fields_order);
    } else {
      if (report.query_string.dimensions !== undefined && report.query_string.measures !== undefined) {
        fieldContext.initializeFieldsFromQuery(report.query_string)
      } else {
        console.warn(`NamedReportPage > useEffect > no fields_order or query_string found in report`);
      }
    }
    dispatch({ type: 'setQuery', payload: {
        query: report.query_string,
        runNow: true
      }});
  }, [report]);


  /**
   * Handle init explore where there is NO url query.
   */
  React.useEffect(() => {
    if (urlQuery) {
      return;
    }
    if (key) {
      return;
    }
    if (!explore || !cubesMetaLike) {
      return;
    }
    // Validate and dispatch explore query
    const parsedUrlQuery = urlQuery && JSON.parse(lzString.decompressFromEncodedURIComponent(urlQuery) as string);
    const validatedQuery = validateQuery(parsedUrlQuery ? parsedUrlQuery.query : explore.query, cubesMetaLike);
    fieldContext.initializeFieldsFromMemberList(parsedUrlQuery ? parsedUrlQuery.fieldsOrder : explore.fieldsOrder);
    dispatch({ type: "setQuery", payload: {
        query: validatedQuery,
        runNow: true
      }});
  }, [key, explore, urlQuery, cubesMetaLike]);


  /**
   * Check if query has changed in URL. If so, run the new query.
   */
  React.useEffect(() => {
    if (!urlQuery) {
      return
    }
    const currentQueryJson = JSON.stringify({query: query, fieldsOrder: fieldContext.fields});
    const currentQueryCompressed = lzString.compressToEncodedURIComponent(currentQueryJson);
    if (currentQueryCompressed === urlQuery) {
      return;
    }

    const decompressed = lzString.decompressFromEncodedURIComponent(urlQuery)
    if (!decompressed) {
      console.error("Failed to decompress query from search param")
      return
    }
    const parsed = JSON.parse(decompressed)

    if (fieldContext.fields !== parsed.fieldsOrder) {
      fieldContext.initializeFields(parsed.fieldsOrder);
    }

    dispatch({
      type: "setQuery",
      payload: {
        query: parsed.query,
        runNow: true,
      },
    });
  }, [urlQuery, explore && explore.enumInt])


  const { cubejsApi } = React.useContext(CubeContext);
  /**
   * Check if query has changed.  If so, run query.
   */
  React.useEffect(() => {
    if (runNow && cubejsApi && cubesMetaLike) {
      if (appContextName === metaContextName) {
        executeRunQuery(cubejsApi, query);
      }
    }
  }, [query && JSON.stringify(query), cubesMetaLike, runNow]);

  // for sidebar resize
  const pageRef = React.useRef<HTMLDivElement>(null);

  /**
   * Return loader if context is not set.
   */
  if (!appContextName || !metaContextName || appContextName !== metaContextName) {
    return <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size='1rem'/>
    </div>
  }

  /**
   * Central function to handle calling 'runQuery'.
   * @param api
   * @param queryToRun
   */
  function executeRunQuery(api: CubejsApi, queryToRun: Query): void {
    // console.log(`executeRunQuery`, queryToRun);
    trackQuery(queryToRun, currentEnvironment, cubesMetaLike.cubes, cubesMetaLike.cubesMap);
    runQuery(dispatch, api, queryToRun);
    dispatch({type: "setHasQueryChanged", payload: false});
    setQuerySearchParameter(queryToRun);
  }

  function handleClickedExploreFromHere(): void {
    const queryJson = JSON.stringify({query: query, fieldsOrder: fieldContext.fields});
    const compressed = lzString.compressToEncodedURIComponent(queryJson);
    const contextEnum = SchemaContextEnum[(contextDefinition as SchemaContextDefinition).name];
    const exploreEnum = (explore as ExploreQueryDefinition).enumInt;
    const url = `/${currentEnvironment.env_name}/explore/${contextEnum}/${exploreEnum}/?q=${compressed}`;
    navigate(url);
  }

  function resetQuery() {
    const exploreQuery = explore!.query;
    const fieldsOrder = explore!.fieldsOrder;
    const validatedQuery = validateQuery(exploreQuery, cubesMetaLike);
    if (validatedQuery.dimensions !== undefined && validatedQuery.measures !== undefined) {
      if (fieldsOrder !== undefined) {
        fieldContext.initializeFieldsFromMemberList(fieldsOrder);
      } else {
        fieldContext.initializeFieldsFromQuery(validatedQuery)
      }
    }
    dispatch({ type: "setQuery", payload: {
        query: validatedQuery,
        runNow: true
      }});
  }

  /**
   * Set the query search parameter in the URL.
   * Cases:
   *  1. Post-query execution -> set URL retroactively, don't run query again
   *  2. Page initial load -> set URL to trigger query execution
   *
   * @param queryToSet
   */
  function setQuerySearchParameter(queryToSet: Query): void {
    const keys = Object.keys(queryToSet)
    let count = 0
    keys.forEach(key => count += queryToSet[key]?.length || 0);

    // Parsed empty query
    if (count == 0) {
      return;
    }

    // Encode query using lz-string
    const json = JSON.stringify({query: queryToSet, fieldsOrder: fieldContext.fields});
    const compressed = lzString.compressToEncodedURIComponent(json);

    // Query hasn't changed
    if (compressed === urlQuery) {
      return;
    }

    // Replace in browser history if no search params exist
    const navOptions = {
      replace: !urlQuery
    };
    setSearchParams({q: compressed}, navOptions);
  }

  return (
    <Box sx={{"--sidebarWidth": initialSidebarWidth, height: "100%"}}>
      <ExploreSideBar namedReportPageRef={pageRef}/>
      <div ref={pageRef} style={{ display: 'flex', flexDirection: 'column', height: 'calc(100%)', marginLeft: "calc(var(--sidebarWidth) - 80px)" }}>
        <ReportHeader
          report={report}
          resultSet={resultSet}
          contextDefinition={contextDefinition as SchemaContextDefinition}
          resetQuery={resetQuery}
          handleReportSaved={setReport}
          executeRunQuery={executeRunQuery}
          handleClickedExploreFromHere={handleClickedExploreFromHere}
        />
        <Divider style={{ margin: '0.5rem 0' }}/>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <DateFilter timeDimension={explore?.fixedTimeDimension?.dimension} explore={explore} fixed={true}/>
        </Box>
        {
          appContextName === "global"
            ? <QueryToggleControls
              query={resultSet ? resultSet.query() : undefined}
              queryExists={!!(resultSet && resultSet.query())}
            />
            : null
        }
        {
          query && (resultSet || progressResult || isLoading)
            ? (
              <>
                <div key='filters-area-container' style={{flex: 0, marginBottom: 16}}>
                  <QueryFiltersArea filters={query && query.filters as (BinaryFilter | UnaryFilter)[] || []}/>
                </div>
                <div style={{flex: 1, overflow: 'hidden', display: 'flex', justifyContent: 'center', zIndex: 0}}>
                  <TableRenderer
                    query={query}
                    resultSet={resultSet}
                    isLoading={isLoading}
                    error={error}
                    progress={progressResult}
                    isDashboardPanel={false}
                  />
                </div>
              </>
            )
            : (
              <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <CircularProgress size='2rem' />
                  <Typography>Loading</Typography>
                </div>
              </div>
            )
        }
      </div>
    </Box>
  );
};

export default NamedReportPage;