import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";

interface Props {
	disableBorderTop?: boolean;
	disableMarginBottom?: boolean;
}

const StyledHeader = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "disableBorderTop" && prop !== "disableMarginBottom",
})<Props>(({ theme, disableBorderTop, disableMarginBottom }) => ({
	textTransform: "uppercase",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	fontSize: "14px",
	color: alpha(theme.palette.secondary.contrastText, 0.75),
	transition: "all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1)",
	"& span": {
		transition: "inherit",
		color: alpha(theme.palette.secondary.contrastText, 0.5),
	},
	marginBottom: "0.25rem",
	...(!disableMarginBottom && {
		marginBottom: theme.spacing(1),
	}),
	...(!disableBorderTop && {
		paddingTop: "1rem",
		borderTop: "1px solid " + alpha(theme.palette.secondary.contrastText, 0.1),
	}),
	"& .MuiButtonBase-root": {
		padding: "0",
		color: alpha(theme.palette.secondary.contrastText, 0.5),

		"&:hover": {
			color: alpha(theme.palette.secondary.contrastText, 1),
		},
	},
	"& .run-query": {
		backgroundColor: theme.palette.primary.main,
		"& .MuiSvgIcon-root": {
			transform: "scale(0.7)",
		},
	},
}));

export default StyledHeader;
