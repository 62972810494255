import { AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Area } from "recharts";
import CustomTooltip, { reTooltipProps } from "./CustomTooltip";
import { PanelProps, tickFormatter } from "./panelUtils";
import { useTheme } from "@mui/material/styles";
import { calculateFill } from "../../../utils/dashboard-utils";
// import useChartZoom from "./hooks/useChartZoom";

const StackedAreaChart = ({ seriesNames, data, format, colorPaletteIdx, height, dateFormatOverride }: PanelProps) => {
	const theme = useTheme();
	// const zoom = useChartZoom(data);

	const seriesNamesSorted = seriesNames.sort((a, b) => (data[0][a.key] < data[0][b.key] ? 1 : -1));

	return (
		<>
			{/*{zoom.renderButton()}*/}
			<div onScrollCapture={(e) => console.log(e)}>
				<ResponsiveContainer width="100%" height={400}>
					{/*<AreaChart data={zoom.data} {...zoom.chartEventProps}>*/}
					<AreaChart data={data}>
						<defs>
							{seriesNamesSorted.map(({ key, title, yValues }, idx) => {
								return (
									<linearGradient key={idx} id={`color${idx}`} x1="0" y1="0" x2="0" y2="1">
										<stop
											offset="5%"
											stopColor={calculateFill(idx, seriesNames.length, colorPaletteIdx)}
											stopOpacity={0.8}
										/>
										<stop offset="95%" stopColor={calculateFill(idx, seriesNames.length, colorPaletteIdx)} stopOpacity={0} />
									</linearGradient>
								);
							})}
						</defs>
						<XAxis dataKey="x" tickFormatter={(value) => tickFormatter(value, format.x, dateFormatOverride)} />
						<YAxis tickFormatter={(value) => tickFormatter(value, format.y, dateFormatOverride)} />
						<Tooltip
							content={
								<CustomTooltip
									payload={data.map((d) => Object.assign(d, { seriesNames }))}
									format={format}
									dateFormatOverride={dateFormatOverride}
								/>
							}
							{...reTooltipProps}
							position={{ y: 0 }}
							animationDuration={0}
						/>
						<CartesianGrid strokeDasharray="3 3" />
						{seriesNamesSorted.map(({ key, title, yValues }, idx) => {
							return (
								<Area
									key={idx}
									dataKey={key}
									stroke={calculateFill(idx, seriesNames.length, colorPaletteIdx)}
									fill={`url(#color${idx})`}
									type="monotone"
									stackId="a"
									animationDuration={300}
									animationEasing="ease-in-out"
								/>
							);
						})}
						{/*{zoom.renderReferenceArea()}*/}
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</>
	);
};

export default StackedAreaChart;
