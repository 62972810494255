import React from "react";
import { TextField } from "@mui/material";
import Modal from "./Modal";
import { useQueryState } from "../../context/query-context";
import API from "../../api";
import { SavedReport } from "../../utils/saved-report-utils";
import { useParams } from "react-router-dom";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import { AxiosError, AxiosResponse } from "axios";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../Base/PrimaryButton";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onClose: () => void;
	handleSuccess: (responseData: SavedReport) => void;
	handleFailure: (error: AxiosError) => void;
	report?: SavedReport;
};

const RenameModal = ({ open, setOpen, onClose, handleSuccess, handleFailure, report }: Props) => {
	const theme = useTheme();
	const { query } = useQueryState();
	const { appContextName } = useCubeMetaState();
	const { env } = useParams() as Record<string, string>;
	const { fields } = useFieldContext();
	const [nameInput, setNameInput] = React.useState(report !== undefined ? report.name : "");

	const handleChangeName = (evt) => {
		setNameInput(evt.target.value);
	};

	const handleClose = () => {
		setNameInput("");
		setError("");
		setLoading(false);
		onClose();
	};

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");

	const handleRename = () => {
    
    const payload = {
      query_string: query,
			description: "",
			name: nameInput,
			context_name: appContextName,
			fields_order: fields,
		};
		if (!env) {
      return;
		}
		if (!report) {
      return;
		}
    
		setLoading(true);
    
		API.put(`/api/report/env_name/${env}/key/${report.key}`, payload)
    .then((response: AxiosResponse) => {
      handleSuccess(response.data);
      handleClose();
			})
			.catch((error: AxiosError) => {
			handleFailure(error);
        	setLoading(false);
			});
	};

	const props = {
		open,
		setOpen,
		title: "Rename Report",
		description: `Please provide a new name. To create a new report, use "Save As" instead.`,
		form: (
			<>
				<TextField
					value={nameInput}
					onChange={handleChangeName}
					required
					autoFocus
					id="name"
					label="Name"
					type="text"
					inputProps={{ maxLength: 50 }}
					sx={{ width: "50%", minWidth: "16vw" }}
					variant="standard"
					margin="dense"
					error={error !== ""}
					helperText={error}
				/>
			</>
		),
		actions: (
			<>
				<PrimaryButton onClick={() => setOpen(false)} color="secondary" variant="text">
					Cancel
				</PrimaryButton>
				<PrimaryButton
					color="primary"
					disabled={!nameInput}
					onClick={handleRename}
					variant="text"
					loading={loading}
					loadingText="Renaming"
				>
					Rename
				</PrimaryButton>
			</>
		),
		onKeyPress: (e: React.KeyboardEvent) => {
			if (e.key === "Enter" && nameInput) {
				handleRename();
			}
		},
	};
	return <Modal {...props} />;
};

export default RenameModal;
