import { styled } from "@mui/system";
import {Box, Card, Container, Grid, IconButton, Modal, Typography} from "@mui/material";
import { useTheme, alpha, keyframes } from "@mui/material/styles";
import React from "react";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import GridViewRounded from "@mui/icons-material/GridViewRounded";
import { useAuthState } from "../../../context/auth-context";
import SelectEnvironmentCard from "./SelectEnvironmentCard";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { ReportsEnvironmentConfig } from "../../../utils/auth-utils";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryParam, NumberParam } from 'use-query-params';
import PrimaryButton from "../../Base/PrimaryButton";

interface Props {
	open: boolean;
	setOpen: (boolean) => void;
	setEditEnv: (env: ReportsEnvironmentConfig | null) => void;
}

const SelectEnvironment: React.FC<Props> = ({ open, setOpen, setEditEnv }) => {
	const theme = useTheme();
	const gridRef = React.useRef<HTMLDivElement>(null);
	const { allowedEnvironments } = useAuthState();

	const [filterText, setFilterText] = React.useState("");
	const [isModalOpen, setIsModalOpen] = useQueryParam("select-env", NumberParam);

	if (!allowedEnvironments) {
		return <></>;
	}

	return (
		<>
			<ArrowTooltip title="select environment" disableInteractive enterDelay={500}>
				<IconButton
					onClick={() => {
						setIsModalOpen(isModalOpen === 1 ? 0 : 1);
						setOpen(false)
					}}
					sx={{
						width: "100%",
						borderRadius: 0,
						color: alpha(theme.palette.secondary.contrastText, 0.5),
						"&:hover": {
							color: theme.palette.secondary.contrastText,
						},
						mb: 3,
					}}
				>
					<GridViewRounded />
					<Typography
						sx={{
							width: open ? "auto" : "0",
							opacity: open ? 1 : 0,
							marginLeft: open ? "1ch" : 0,
							transition: theme.transitions.create("all", {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.short,
							}),
						}}
						variant="body2"
					>
						Select Environment
					</Typography>
				</IconButton>
			</ArrowTooltip>
			<Modal
				open={isModalOpen === 1}
				onClose={() => setIsModalOpen(0)}
				BackdropComponent={() => (
					<Box onClick={() => setIsModalOpen(0)} sx={{
						backgroundColor: alpha(theme.palette.secondary.main, 0.95),
						width: "100%",
						height: "100%",
						zIndex: "-1",
						position: "fixed",
					}}/>
				)}
			>
				<Box sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					padding: theme.spacing(4),
					transition: 'all 0.3s ease',
				}}>
					<Box>
						<div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center",  paddingLeft: "5rem", paddingRight: "5rem"}}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography variant="h4" sx={{
									verticalAlign: 'middle',
									display: 'inline-block',
									color: theme.palette.background.paper,
								}}>
									Select Environment
								</Typography>
								<CssTextField
									value={filterText}
									onChange={(e) => setFilterText(e.target.value)}
									variant="outlined"
									placeholder="Search"
									size="small"
									sx={{ marginLeft: '4ch', verticalAlign: 'middle' }}
									InputProps={{
										startAdornment: <SearchIcon fontSize="small" sx={{ color: '#aaa', fontSize: '18px', marginRight: '4px' }}/>,
										endAdornment: (
											<IconButton onClick={() => setFilterText("")}>
												<ClearIcon fontSize="small" sx={{ color: '#aaa', fontSize: '18px'  }}/>
											</IconButton>
										),
									}}
								/>
							</Box>
							<IconButton className="close-btn" size="small" sx={{color: theme.palette.secondary.contrastText}} onClick={() => setIsModalOpen(0)}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</div>
						<Box sx={{ width: 'calc(100%-5rem)', paddingLeft: "5rem"}}>
							<Grid container rowSpacing={2} columnSpacing={2} ref={gridRef} sx={{
								flexGrow: 1
							}}>
								{allowedEnvironments
									.filter(e => e.display_name.toLowerCase().includes(filterText.toLowerCase()) || e.env_name.toLowerCase().includes(filterText.toLowerCase()))
									.map((env, index) => (
									<SelectEnvironmentCard gridRef={gridRef} env={env} setIsModalOpen={setIsModalOpen} setEditEnv={setEditEnv} key={index} />
								))}
							</Grid>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

const animateModalBackdrop = keyframes`
0% {
	opacity: 0;
	backdrop-filter: blur(0);
}
100% {
	opacity: 1;
	backdrop-filter: blur(10px);
}`;

const animateTitle = keyframes`
0% {
	opacity: 0;
	transform: translateX(5rem);
}
100% {
	opacity: 0.75;
	transform: translateX(0rem);
}`;

const animateModalContainer = keyframes`
0% {
	opacity: 0;
	transform:scale(0.8);
}
100% {
	opacity: 1;
	transform: scale(1);
}`;

export default SelectEnvironment;


const CssTextField = styled(TextField)({
	'& .MuiInputBase-input': {
		color: '#ccc',
		fontSize: '14px',
	},

  '& .MuiOutlinedInput-root': {
		backgroundColor: "rgba(0,0,0,0.15)",
		'&.Mui-focused': {
			backgroundColor: "rgba(0,0,0,0.3)",
		},

    '& fieldset': {
      borderColor: '#aaa',
    },
    '&:hover fieldset': {
      borderColor: '#aaa',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ddd',
			borderWidth: '1px',
    },
  },
});
