import React from "react";
import { useQueryDispatch } from "../../context/query-context";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Query } from "@cubejs-client/core";
import useStyles from "./style";


interface Props {
  query: Query | undefined
  queryExists: boolean
}

const QueryToggleControls: React.FunctionComponent<Props> = (
  {
    query,
    queryExists,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const dispatchQuery = useQueryDispatch()
  const [showDeleted, setShowDeleted] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    // console.log(`QTC > effect > queryExists == ${queryExists}`);
    if (query && query.segments) {
      if (showDeleted === undefined) {
        const queryIncludesDeleted = !(query.segments && query.segments.includes("Tenants.nonDeleted"));
        if (queryIncludesDeleted !== showDeleted) {
          setShowDeleted(queryIncludesDeleted);
        }
      }
    }
  }, [queryExists])

  React.useEffect(() => {
    if (showDeleted) {
      dispatchQuery({
        type: "removeSegments",
        payload: { segments: ["Tenants.nonDeleted", "Properties.nonDeleted"], runNow: false } });
    } else {
      if (query && !(query.segments && query.segments.includes("Tenants.nonDeleted"))) {
        dispatchQuery({
          type: "addSegments",
          payload: { segments: ["Tenants.nonDeleted", "Properties.nonDeleted"], runNow: false }
        });
      }
    }
  }, [showDeleted])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(`handleChange > setShowDeleted = ${event.target.checked}`)
    setShowDeleted(event.target.checked);
  };

  return <div key='controls-area-container'>
    <FormControlLabel
      control={
        <Switch
          checked={!!(showDeleted)}
          onChange={handleChange}
          name="showDeleted"
          color="primary"
          size="small"
          classes={{
            sizeSmall: classes.sizeSmall,
            switchBase: classes.switchBase,
          }}
        />
      }
      label="Include Deleted"
      classes={{
        root: classes.formControlRoot,
        label: classes.label,
      }}
    />
  </div>
}

export default QueryToggleControls;