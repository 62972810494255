import React from "react";
import { Routes, Route } from "react-router-dom";
import * as LogRocket from 'logrocket';
import {Mixpanel, eventNames} from "./utils/mixpanel-utils/Mixpanel"
import { useAuthState } from "./context/auth-context";
import { CubeMetaProvider } from "./context/cube-meta-context";
import { getStoredUserValues } from "./utils/auth-utils";
import LayoutWrapper from "./containers/LayoutWrapper/LayoutWrapper";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./auth/PrivateRoute";
import routes from "./routing/routes"
import EnvironmentSelectPage from "./pages/EnvironmentSelectPage/EnvironmentSelectPage";
import Theme from "./theme/theme"
import { ThemeProvider } from "@mui/material";
import "./App.css"
import Sidebar from "./components/SidebarNavigation/Sidebar";
import NotFound from "./pages/NotFound/NotFound";
import {StylesProvider} from "@material-ui/core/styles";
import UnifiedLoginPage from "./pages/UnifiedLoginPage";


const App: React.FC = () => {
  const { isAuthenticated } = useAuthState();

  React.useEffect(() => {
    if (isAuthenticated) {
      const { firstName, lastName, email, username, userId, account } = getStoredUserValues();
      const traits = { name: firstName, lastName, email, username, account }
      LogRocket.identify(userId.toString(), traits);
      Mixpanel.identify(userId);
      Mixpanel.people.set({"$email": email, userId, username, account, name: `${firstName} ${lastName}`});
      LogRocket.getSessionURL(function (sessionURL) {
        Mixpanel.track(eventNames.logRocket, { sessionURL: sessionURL });
      });
    }
  }, [isAuthenticated])
  
  return (
    <StylesProvider injectFirst>
      <CubeMetaProvider>
        <ThemeProvider theme={Theme}>
          <Sidebar />
          <Routes>
            {/* public routes */}
            <Route path="/auth" element={<UnifiedLoginPage />} />
            <Route path="/login" element={<LoginPage />} />

            {/* private routes */}
            <Route path="" element={<PrivateRoute />}>
              <Route path="/" element={<EnvironmentSelectPage />} />
              {routes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={
                      <LayoutWrapper
                        MainComponent={route.main}
                      />
                    }
                    key={index}
                  />
                );
              })}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </CubeMetaProvider>
    </StylesProvider>
  );
};

export default App;
