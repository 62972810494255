import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
		chartContainer: {
			'&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      }
		},
		legendContainer: {
			'&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      }
		},
  })
);

export default useStyles;
