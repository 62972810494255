import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { keyframes } from "@mui/material/styles";

const openedMixin = (theme: Theme): CSSObject => ({
	width: 220,
	transition: theme.transitions.create("all", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("all", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shortest,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(8)} + 1px)`,
});

const StyledSideBar = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiPaper-root": {
		backgroundColor: open ? alpha(theme.palette.secondary.main, 0.9) : theme.palette.secondary.main,
		backdropFilter: open ? "blur(15px)" : "",
		animation: `${enterAnimation} ${500}ms ${theme.transitions.easing.sharp} backwards 500ms`,
		width: "64px",
	},
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	zIndex: 0,
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const enterAnimation = keyframes`
0% {
	opacity: 0;
	transform: translateX(-100%);
}
100% {
	opacity: 1;
	transform: translateX(0);
}
`;

export default StyledSideBar;
