import React from "react";
// import { dispatchLogger } from "./context-utils";
import { ReportsEnvironmentConfig, UserAuthenticationDetails } from "../utils/auth-utils";
// const displayName = 'auth-context'

type Action = {type: 'setIsAuthenticated', payload: { user: UserAuthenticationDetails }}
| {type: 'setIsNotAuthenticated'}
| {type: 'setCurrentEnvironment', payload: { currentEnvironment: ReportsEnvironmentConfig }}
| {type: 'setAllowedEnvironments', payload: { allowedEnvironments: ReportsEnvironmentConfig[] }}
| {type: 'clearCurrentEnvironment'}
| {type: 'clearAllowedEnvironments'}
| {type: 'setUserPermissionsToken', payload: { userPermissionsToken: string }}
| {type: 'clearUserPermissionsToken'}

type Dispatch = (action: Action) => void

export type ReportsUser = {
  account: number
  email: string
  firstName: string
  lastName: string
  userId: number
  username: string
  lastEnvName: string | null
  roleName: string
  team: number
}

type State = {
  isAuthenticated: boolean
  user: ReportsUser | undefined
  currentEnvironment: ReportsEnvironmentConfig | undefined
  allowedEnvironments: ReportsEnvironmentConfig[] | undefined
  userPermissionsToken: string | undefined
}

type AuthProviderProps = {children: React.ReactNode}

const initialState = {
  isAuthenticated: false,
  user: undefined,
  currentEnvironment: undefined,
  allowedEnvironments: undefined,
  userPermissionsToken: undefined,
}

const AuthStateContext = React.createContext<State | undefined>(undefined)
const AuthDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function authContextReducer(state: State, action: Action): State {
  // dispatchLogger(displayName, action, 'debug');
  switch (action.type) {
    case "setIsAuthenticated": {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          account: action.payload.user.account,
          email: action.payload.user.email,
          firstName: action.payload.user.firstName,
          lastName: action.payload.user.lastName,
          userId: action.payload.user.userId,
          username: action.payload.user.username,
          lastEnvName: action.payload.user.lastEnvName,
          roleName: action.payload.user.roleName,
          team: action.payload.user.team,
        }
      }
    }
    case "setIsNotAuthenticated": {
      return initialState;
    }
    case "setAllowedEnvironments": {
      return {
        ...state,
        allowedEnvironments: action.payload.allowedEnvironments,
      }
    }
    case "clearAllowedEnvironments": {
      return {
        ...state,
        allowedEnvironments: initialState.allowedEnvironments,
      }
    }
    case "setCurrentEnvironment": {
      return {
        ...state,
        currentEnvironment: action.payload.currentEnvironment,
      }
    }
    case "clearCurrentEnvironment": {
      return {
        ...state,
        currentEnvironment: initialState.currentEnvironment,
      }
    }
    case "setUserPermissionsToken": {
      return {
        ...state,
        userPermissionsToken: action.payload.userPermissionsToken,
      }
    }
    default: {
      return state
    }
  }
}

function AuthProvider({children}: AuthProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(authContextReducer, initialState)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function useAuthState(): State {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }
  return context
}

function useAuthDispatch(): Dispatch {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }
  return context
}


export { AuthProvider, useAuthDispatch, useAuthState }