import { createStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles(() =>
  createStyles({
		formControlLabelRoot: {
			width: '100%',
      '&:hover': {
        backgroundColor: grey[300],
        borderRadius: '4px',
        transition: '0.3s',
      }
    }
	})
)

export default useStyles;