import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleRow: {
      cursor: 'pointer',
      backgroundColor: 'white',
      // backgroundColor: '#333333',
      // color: 'white',
      padding: theme.spacing(0.6, 1.5, 0.6, 0.2),
      borderRadius: '3px 3px 0 0',
      display: 'flex',
      '& .MuiSvgIcon-root': {
        // color: 'white',
      }
    },
    heading: {
      fontSize: '13px',
      alignSelf: 'center',
    },
    numFiltersSuperText: {
      color: '#f254ff',
      paddingLeft: '3px'
    },
  })
);

export default useStyles;