import React from "react";
import { useNavigate} from "react-router-dom";
import API from "../api";
import { useAuthDispatch} from "../context/auth-context";
import { setUserValues, UserAuthenticationDetails } from "../utils/auth-utils";
import { Mixpanel, eventNames } from "../utils/mixpanel-utils/Mixpanel";
import { useSearchParams } from "react-router-dom";
import { IS_LOCAL_DEV } from "../config/frontendConfig";
import { getDealthingUrl } from "../utils/utils";


const UnifiedLoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate()

  const jwt = React.useMemo(() => {
    return searchParams.get("jwt")
  }, [searchParams])

  console.log(jwt)

  function handleLoginSuccess(userAuth: UserAuthenticationDetails): void {
    Mixpanel.track(eventNames.loginSuccessful);
    setUserValues(userAuth);
    dispatch({ type: 'setIsAuthenticated', payload: {
        user: userAuth,
    }})
    navigate("/");
  }

  function handleLoginFailure() {
    Mixpanel.track(eventNames.loginFailed);
    navigate("/login");
    return;
  }

  React.useEffect(() => {
    if (IS_LOCAL_DEV) {
      navigate("/login");
      return;
    }

    if (jwt) {
      // remove the jwt from the url
      searchParams.delete("jwt")
      
      // send request to backend to validate jwt
      API.get("/api/login/unified/" + jwt).then(response => {
        if (response.status === 200) {
          handleLoginSuccess(response.data.userAuthenticationDetails)
        }
      }).catch(error => {
        console.error(`LoginPage > handleSubmitForm() > validateCredentials.catch > ${error}`)
        handleLoginFailure()
      })
      return
    }

    // resolve dealthing url
    window.location.href = getDealthingUrl() + "/accounts/login/?next=" + window.location.origin + "/auth?jwt=";
  }, [jwt]);

  return null;
}

export default UnifiedLoginPage