import React from "react";
import { darken, alpha, useTheme, lighten } from "@mui/material/styles";
import { Button, Card, Grid, Typography, keyframes, IconButton, Box } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CardActionArea } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Mixpanel, eventNames } from "../../../utils/mixpanel-utils/Mixpanel";
import API from "../../../api";
import { AxiosError } from "axios";
import { useAuthState, useAuthDispatch } from "../../../context/auth-context";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import {format, formatRelative} from "date-fns";
import {ReportsEnvironmentConfig, USER_ROLE_NAME_ADMIN} from "../../../utils/auth-utils";
import { Settings } from "@mui/icons-material";

type Props = {
	gridRef: React.RefObject<HTMLDivElement>
	env: ReportsEnvironmentConfig
	setIsModalOpen: (boolean) => void
	setEditEnv: (env: ReportsEnvironmentConfig | null) => void;
};

const SelectEnvironmentCard = ({ gridRef, env, setIsModalOpen, setEditEnv }: Props) => {
	const navigate = useNavigate();
	const { user, currentEnvironment } = useAuthState();
	const dispatchAuth = useAuthDispatch();
	const theme = useTheme();

	const ref = React.useRef<HTMLDivElement>(null);

	const [offset, setOffset] = React.useState({ x: 0, y: 0 });
	const [distance, setDistance] = React.useState(1);
	React.useEffect(() => {
		if (!gridRef.current || !ref.current) {
			return;
		}

		const boundingBox = gridRef.current.getBoundingClientRect();
		const center = {
			x: boundingBox.left + boundingBox.width / 2,
			y: boundingBox.top + boundingBox.height / 2,
		};
		const boundBoxCard = ref.current.getBoundingClientRect();
		const centerCard = { x: boundBoxCard.left + boundBoxCard.width / 2, y: boundBoxCard.top + boundBoxCard.height / 2 };
		//distance from center to centerCard
		setDistance(
			Math.log(Math.sqrt(Math.pow(center.x - centerCard.x, 2) + Math.pow(center.y - centerCard.y, 2))) / Math.log(1.4)
		);

		setOffset({ x: center.x - boundBoxCard.x - boundBoxCard.width / 2, y: center.y - boundBoxCard.y - 25 });
	}, [gridRef]);

	/**
	 * Execute the actions needed for environment navigation, namely:
	 *    - Call the 'setLastEnvironment' function which calls API of same name
	 *    - Trigger setCubesMeta ????
	 *    - Navigate to correct URL
	 */
	function navigateToEnvironment(envName: string) {
		// console.debug(`Navigate to environment: ${envName}`);
		Mixpanel.track(eventNames.selectedEnv, { envName });
		setLastEnvironment(envName);
		dispatchAuth({ type: "clearCurrentEnvironment" });
		localStorage.setItem("currentEnvironment", envName);
		navigate(`/${envName}`);
	}

	/**
	 * Call the set_last_environment API for the user,
	 * using currentEnvironment for environmentName.
	 */
	function setLastEnvironment(lastEnvironment: string) {
		// console.log(`setLastEnvironment, lastEnvironment=${lastEnvironment}`);
		const payload = { last_env_name: lastEnvironment };
		const userId = user && user.userId;
		API.put(`/api/user/${userId}/update`, payload)
			.then(() => {
				// console.info(`navigateToEnvironment > update user success, response.data`, response.data)
			})
			.catch((error: AxiosError) => {
				console.error(`navigateToEnvironment > update user error, error=`, error);
			});
	}

	function handleEnvSelect() {
		if (!user || user.roleName !== USER_ROLE_NAME_ADMIN) return
		setIsModalOpen(0);
		setEditEnv(env);
	}

	if (!currentEnvironment) {
		return <></>;
	}

	const isCurrentEnvironment = currentEnvironment["env_name"] === env["env_name"];
	const isVisibleToEndUsers = env["visible_to_end_users"];

	function getTooltipRow(itemLabel: string, enabled: boolean, visible: boolean) {
		const style = {
			fontWeight: 'bold',
			color: enabled ? (visible ? "lightgreen" : "yellow") : "orangered",
		}
		const itemText = enabled ? (visible ? "Visible to client" : "Visible internally") : "Not configured";
		return <div><Typography variant="body1" sx={{ fontSize: '11px', color: 'whitesmoke' }}>{itemLabel}: <span style={style}>{itemText}</span></Typography></div>;
	}

	return (
		<ArrowTooltip title={user && user.roleName === USER_ROLE_NAME_ADMIN
			? (
				<div>
					<Typography variant="subtitle2" sx={{fontSize: '14px', fontWeight: 'bold'}}>{env.display_name}</Typography>
					<Typography variant="subtitle2" sx={{ fontSize: '10px', marginTop: '-4px', color: '#ddd', fontStyle: 'italic'}}>{env.env_name}</Typography>
					<br />
					<TooltipContentRow
						label={"Visible"}
						value={env.visible_to_end_users ? "Yes" : "No"}
						valueColor={"whitesmoke"}
					/>
					<TooltipContentRow
						label={"Daily sync"}
						value={env.included_in_daily_stream ? "Yes" : "No"}
						valueColor={"whitesmoke"}
					/>
					<TooltipContentRow
						label={"Last synced"}
						value={env.last_synced ? format(new Date(env.last_synced), 'Pp') : "Not synced"}
						valueColor={"whitesmoke"}
					/>
					{/*<br />*/}
					<TooltipContentRow label={""} value={""} valueColor={"black"}/>
					<TooltipContentRow
						label={"CD Dashboard"}
						value={env.contexts_config["critical-dates"].dependencies_met && env.contexts_config["critical-dates"].enabled
								? (env.dashboards_config["critical-dates"].visible ? "Visible to client" : "Visible internally")
								: "Not configured"}
						valueColor={env.contexts_config["critical-dates"].dependencies_met && env.contexts_config["critical-dates"].enabled
								? (env.dashboards_config["critical-dates"].visible ? "lightgreen" : "yellow")
								: "orangered"}
					/>
					<TooltipContentRow
						label={"CD Explore"}
						value={env.contexts_config["critical-dates"].dependencies_met && env.contexts_config["critical-dates"].enabled
								? (env.contexts_config["critical-dates"].visible ? "Visible to client" : "Visible internally")
								: "Not configured"}
						valueColor={env.contexts_config["critical-dates"].dependencies_met && env.contexts_config["critical-dates"].enabled
								? (env.contexts_config["critical-dates"].visible ? "lightgreen" : "yellow")
								: "orangered"}
					/>
				</div>
			)
			: <Typography variant="subtitle2">{env.display_name}</Typography>
		}>
		<Grid
			item
			xs="auto"
			sx={{
				transition: `all 0.2s ${theme.transitions.easing.sharp}`,
				"&:hover": {
					transform: isCurrentEnvironment ? "" : "translateY(-3px)",
				},
				"&:active": {
					transform: isCurrentEnvironment ? "" : "translateY(1px)",
				},
			}}
		>
			<Card
				sx={{
					backgroundColor: isCurrentEnvironment
						? lighten(theme.palette.primary.light, 0.65)
						: isVisibleToEndUsers
						? theme.palette.background.paper
						: theme.palette.grey[400],
					color: isCurrentEnvironment
						? theme.palette.primary.light
						: isVisibleToEndUsers
						? "black"
						: theme.palette.grey[800],
					boxShadow: isCurrentEnvironment
						? `0 1px 1px ${darken(theme.palette.primary.main, 0.2)}`
						: `0 1px 1px ${theme.palette.grey[500]}`,
					cursor: isCurrentEnvironment ? "default" : "pointer",
					whiteSpace: "nowrap",
					"&:active": {
						color: isCurrentEnvironment ? theme.palette.primary.main : theme.palette.primary.light,
						boxShadow: isCurrentEnvironment
							? `0 1px 1px ${darken(theme.palette.primary.main, 0.2)}`
							: `0 1px 1px ${theme.palette.primary.dark}`,
					},
					"& .MuiTouchRipple-root": {
						color: theme.palette.primary.light,
					},
				}}
				ref={ref}
				onContextMenu={(e) => {
					e.preventDefault();
					handleEnvSelect();
				}}
			>
				<CardActionArea
					onClick={() => {
						if (isCurrentEnvironment) {
							setIsModalOpen(0);
							return;
						}
						navigateToEnvironment(env["env_name"]);
					}}
					disableRipple={isCurrentEnvironment}
				>
					<div style={{ display: 'flex', paddingLeft: theme.spacing(1) }}>
						<div style={{ alignSelf: 'center' }}>
							{isVisibleToEndUsers ? <></> : <VisibilityOffIcon sx={{ fontSize: '14px' }}/>}
						</div>
						<Box sx={{display: "flex", gap: 1, alignItems: "center", padding: theme.spacing(1, 2, 1, 0.5)}}>
							<Typography
								variant="h6"
								sx={{
									fontSize: "16px",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{env["display_name"]}
							</Typography>
							{!user || user.roleName !== USER_ROLE_NAME_ADMIN ? <></> : (
								<IconButton sx={{aspectRatio: "1:1"}} size="small" onClick={(e) => {
									e.stopPropagation();
									handleEnvSelect();
								}}>
									<Settings sx={{ fontSize: '14px' }}/>
								</IconButton>
							)}
						</Box>
					</div>
					<Typography
							variant="caption"
							color="#777"
							sx={{
								fontSize: "12px",
								padding: theme.spacing(1, 2),
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{
								env["last_synced"]
									? <>Synced {formatRelative(new Date(env["last_synced"]), new Date())}</>
										: <>Not synced</>
							}
						</Typography>
				</CardActionArea>
			</Card>
		</Grid>
		</ArrowTooltip>
	);
};

export default SelectEnvironmentCard;

const gridExpandAnimation = keyframes`
0% {
    // maxWidth: "50px";
    opacity: 0.5;
}
100% {
    // maxWidth: "50px";
    opacity: 1;
	transform: translate(0, 0) scale(1);
}`;


interface TooltipContentRowProps {
	label: string
	value: string
	valueColor: string
}

const TooltipContentRow = (
	{
		label,
		value,
		valueColor
	}: TooltipContentRowProps) => (
		<div style={{ display: 'table-row', fontSize: '11px' }}>
			<Typography variant="body1" sx={{
				fontSize: '11px',
				color: 'whitesmoke',
				display: 'table-cell'
			}}>
				{label && label + ":" || "---"}
			</Typography>
			<Typography variant="body1" sx={{
				fontSize: '11px',
				fontWeight: 'bold',
				color: valueColor,
				display: 'table-cell',
				paddingLeft: '8px',
			}}>
				{value}
			</Typography>
		</div>
)