import React from "react";
import { dispatchLogger } from "./context-utils";
const displayName = 'alert-context'

type Action = {type: 'setAlert', payload: State}
| {type: 'clearAlert' }

type Dispatch = (action: Action) => void

type State = {
  alert: DSAlert | null
}

type AlertProviderProps = {children: React.ReactNode}

export type AlertSeverity = 'success' | 'error' | 'info' | 'warning'

export type DSAlert = {
  message: string
  severity: 'success' | 'error' | 'info' | 'warning'
  onClose?: () => void
  autoHideDuration?: number
}

const initialState = {
  alert: null,
}

const AlertStateContext = React.createContext<State | undefined>(undefined)
const AlertDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function alertContextReducer(state: State, action: Action): State {
  dispatchLogger(displayName, action, 'debug');
  switch (action.type) {
    case "setAlert": {
      return action.payload
    }
    case "clearAlert": {
      return initialState
    }
    default: {
      return state
    }
  }
}

function AlertProvider({children}: AlertProviderProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(alertContextReducer, initialState)

  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  )
}

function useAlertState(): State {
  const context = React.useContext(AlertStateContext)
  if (context === undefined) {
    throw new Error('useAlertState must be used within a AlertProvider')
  }
  return context
}

function useAlertDispatch(): Dispatch {
  const context = React.useContext(AlertDispatchContext)
  if (context === undefined) {
    throw new Error('useAlertDispatch must be used within a AlertProvider')
  }
  return context
}

function initiateAlert(dispatch: Dispatch, severity: AlertSeverity, message: string, autoHideDuration?: number): void {
  // console.log(`initiateAlert > severity=${severity}, message=${message}, autoHideDuration=${autoHideDuration}`);
  dispatch({type: 'setAlert',
    payload: {
      alert: { severity, message, autoHideDuration }
    }
  })
}


export { AlertProvider, useAlertDispatch, useAlertState, initiateAlert }