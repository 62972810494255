import React from "react";
import { IconButton, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useQueryDispatch, useQueryState } from "../../../context/query-context";
import StyledActiveField from "../styledComponents/StyledActiveField";
import { Box } from "@mui/material";
import ArrowTooltip from "../../ToolTip/ArrowTooltip";
import { Cube, MemberType, TCubeDimension, TCubeMeasure, TimeDimensionGranularity } from "@cubejs-client/core";
import Menu from "../../NestedMenu/Menu";
import { useFieldContext } from "../../../context/providers/FieldContextProvider";
import { getGranularity } from "../../../utils/time-dimension-utils";
import useMenu from "../../../hooks/useMenu";
import { MdOutlineClose } from "react-icons/md";
import { dataIcons, getDataType } from "../dataIconsUtil";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";

interface Props {
	cube: Cube;
	member: TCubeDimension | TCubeMeasure;
	memberType: MemberType;
	filterActive?: boolean;
	ref?;
}

// eslint-disable-next-line react/display-name
const ActiveField: React.FC<Props> = React.forwardRef(({ cube, member, memberType, filterActive }: Props, ref) => {
	const theme = useTheme();
	const query = useQueryState();
	const dispatch = useQueryDispatch();
	const fieldContext = useFieldContext();

	const granularity = member.type === "time" ? getGranularity(member.name, query.query) : "";

	const dataType = React.useMemo(() => {
		return getDataType(member.type, member.shortTitle);
	}, [member.shortTitle]);

	const textRef = React.useRef<HTMLDivElement>(null);
	const [textOverflow, setTextOverflow] = React.useState(false);
	// recalculate text overflow on resize
	React.useEffect(() => {
		const text = textRef.current;
		if (!text) return;
		const observer = new ResizeObserver(() => {
			if (text.offsetWidth < text.scrollWidth) {
				setTextOverflow(true);
			} else {
				setTextOverflow(false);
			}
		});

		observer.observe(text);
		return () => observer.unobserve(text);
	}, []);

	// more menu
	const menu = useMenu();

	// more menu handlers
	const handleClickFilter = () => {
		if (filterActive) {
			dispatch({ type: "removeFilter", payload: { filter: member.name, runNow: false } });
		} else {
			dispatch({ type: "addFilterField", payload: { member: member.name, type: member.type } });
		}
	};

	const handleClickRemove = () => {
		fieldContext.removeField(member.name, member.type, memberType, false);
	};

	const editGranularity = (granularity: TimeDimensionGranularity) => {
		menu.handleClose();
		dispatch({
			type: "setTimeDimensionGranularity",
			payload: { timeDimension: member.name, granularity: granularity as TimeDimensionGranularity, runNow: false },
		});
	};

	const menuItems = [
		{
			label: "Aggregate",
			onClick: () => {
				/* do nothing */
			},
			options: {
				disabled: memberType === "measures",
				visible: member.type === "time",
				divider: true,
			},
			children: [
				{
					label: "Day",
					onClick: () => editGranularity("day"),
					options: {
						active: granularity === "day",
					},
				},
				{
					label: "Week",
					onClick: () => editGranularity("week"),
					options: {
						active: granularity === "week",
					},
				},
				{
					label: "Month",
					onClick: () => editGranularity("month"),
					options: {
						active: granularity === "month",
					},
				},
				{
					label: "Quarter",
					onClick: () => editGranularity("quarter"),
					options: {
						active: granularity === "quarter",
					},
				},
				{
					label: "Year",
					onClick: () => editGranularity("year"),
					options: {
						active: granularity === "year",
					},
				},
			],
		},
		{
			label: "Filter",
			onClick: handleClickFilter,
			options: {
				active: filterActive,
			},
		},
		{
			label: "Remove",
			onClick: handleClickRemove,
		},
	];

	function getTitle() {
		return (
			<ArrowTooltip title={
				textOverflow ? (
					<>
						<span>{cube.title}</span> <b>{member.shortTitle}</b>
					</>
				) : (
					""
				)
			}>
			<Typography
				ref={textRef}
				className="title-typography"
				sx={{
					fontFamily: "Outfit",
					fontSize: "12px",

					span: {
						opacity: 0.75,
					},

					b: {
						fontWeight: 600,
					},
				}}
			>
				<span>{cube.title}</span> <b>{member.shortTitle}</b>
				<Box component="span" className="title-chip">
					{granularity !== "" && (
						<>
							{granularity}
							<AiOutlineFieldTime />
						</>
					)}
				</Box>
				<Box component="span" className="title-chip">
					{filterActive && (
						<>
							filtering
							<BiFilterAlt />
						</>
					)}
				</Box>
			</Typography>
			</ArrowTooltip>
		);
	}

	const styles = {
		base: {
			display: "flex",
			alignItems: "center",

			"& .drag-handle": {
				width: theme.spacing(2),
				color: theme.palette.secondary.contrastText,
				transform: `translateY(-${theme.spacing(0.5)})`,
				opacity: 0,
				transition: `opacity ${theme.transitions.duration.short}ms ${theme.transitions.easing.sharp}`,
			},

			"& .active-field": {
				maxWidth: `calc(100% - ${theme.spacing(3)})`,
				minWidth: `calc(100% - ${theme.spacing(3)})`,
				display: "flex",
				alignItems: "center",
				flex: 1,
				padding: theme.spacing(0, 0, 0, 1),
				marginBottom: theme.spacing(0.5),
				background: `linear-gradient(90deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.main} 50%, transparent 95%)`,
				backgroundColor: theme.palette.secondary.dark,
				backgroundPositionX: "100%",
				backgroundSize: "200% 100%",
				borderRadius: 1,
				color: alpha(theme.palette.secondary.contrastText, 0.75),
				
				"& .data-type-icon": {
					display: "inline-block",
					marginRight: theme.spacing(1),
					color: memberType === "dimensions" ? alpha(theme.palette.secondary.contrastText, 0.75) : alpha("#F58737", 0.75),
				},
				
				"& .title": {
					WebkitMaskMode: "alpha",
					WebkitMaskImage: textOverflow ? `linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)` : "unset", 
					color: memberType === "dimensions" ? alpha(theme.palette.secondary.contrastText, 0.75) : alpha("#F58737", 0.75),
					overflow: "hidden",
					cursor: "text",
					maxWidth: "100%",
					whiteSpace: "nowrap",
					"& .title-typography": {
						maxWidth: "100%",
					},
					"& .title-chip": {
						"&:empty": {
							display: "none",
						},
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
						gap: theme.spacing(0.5),
						backgroundColor: alpha(theme.palette.secondary.contrastText, 0.05),
						padding: theme.spacing(0, 1),
						borderRadius: theme.spacing(1),
						textTransform: "capitalize",
						marginLeft: theme.spacing(0.5),
						marginRight: theme.spacing(0.5),
						minHeight: "18px",
					},
				},

				"& .action-btns": {
					display: "flex",
					alignItems: "center",
					justifyContent: "end",
					flex: 1,

					"& .icon-btn": {
						width: "34px",
						height: "34px",
						aspectRatio: 1,

						"& svg": {
							opacity: 0.5,
						},

						"&:hover": {
							opacity: 1,
							backgroundColor: alpha(theme.palette.secondary.contrastText, 0.05),
							"& svg": {
								opacity: 1,
							},
						},
					},

					"& .remove-btn": {
						display: "none",
						opacity: 0,
					},

					"& .MuiButtonBase-root": {
						color: "inherit",
					},
				},
			},
		},

		hover: {
			"& .drag-handle": {
				opacity: 0.2,

				"&:hover": {
					opacity: 0.75,
				},
			},

			"& .data-type-icon": {
				color: memberType === "dimensions" ? alpha(theme.palette.secondary.contrastText, 1) : alpha("#F58737", 1),
			},
			
			"& .title": {
				color: memberType === "dimensions" ? alpha(theme.palette.secondary.contrastText, 1) : alpha("#F58737", 1),
			},

			"& .active-field": {
				backgroundPositionX: "0%",
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				transition: `background-position-x ${theme.transitions.duration.complex}ms ${theme.transitions.easing.sharp}`,
			},

			"& .action-btns": {
				"& .icon-btn": {
					opacity: 1,
				},
				"& .remove-btn": {
					display: "inline-flex",
				},
			},
		},
	};

	return (
		<Box
			ref={ref}
			component="div"
			className="active-field-container"
			sx={{
				...styles.base,
				"&:hover, &:active": {
					...styles.hover,
				},
			}}
		>
			<DragIndicatorIcon className="drag-handle" fontSize="small" />
			<Box component="div" className="active-field" onContextMenu={(e) => {
				e.preventDefault();
				menu.handleToggle()
			}}>
				<Box className="data-type-icon">{dataIcons[dataType || "string"]}</Box>
				<Box className="title" >
					{getTitle()}
				</Box>
				<Box className="action-btns">
					<IconButton className="remove-btn icon-btn" onClick={handleClickRemove}>
						<MdOutlineClose className="btn-icon" fontSize="small" />
					</IconButton>
					<IconButton
						ref={menu.anchorRef as React.RefObject<HTMLButtonElement>}
						className="more-btn icon-btn"
						onClick={menu.handleToggle}
					>
						<MoreHorizIcon className="btn-icon" fontSize="small" />
						<Menu menuItems={menuItems} open={menu.open} setOpen={menu.setOpen} anchorEl={menu.anchorRef.current} />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
});

export default ActiveField;
