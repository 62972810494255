import React from "react";
import { QueryOrder, TableColumn, TCubeMemberType, TimeDimensionGranularity } from "@cubejs-client/core";
import { Box, TableCell } from "@mui/material";
import CubeFieldLabel from "../CubeFieldLabel/CubeFieldLabel";
import { useQueryDispatch, useQueryState } from "../../context/query-context";
import { useTheme } from "@mui/material/styles";
import Menu from "../NestedMenu/Menu";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import { getGranularity, processTimeDimensionName } from "../../utils/time-dimension-utils";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { getDataType } from "../SidebarExplore/dataIconsUtil";

export interface Props {
	column: TableColumn;
	width?: string;
	sortDirection: QueryOrder | false;
	isMeasure: boolean;
	title: string;
	isDashboardPanel: boolean;
	setQueryOrder?: (member: string, direction: QueryOrder) => void;
	index: number;
}

const ReportHeaderCell: React.FC<Props> = ({
	column,
	width,
	sortDirection,
	isMeasure,
	title,
	isDashboardPanel,
	setQueryOrder,
	index,
}: Props): React.ReactElement => {
	const theme = useTheme();
	const query = useQueryState();
	const dispatchQuery = useQueryDispatch();
	const fieldContext = useFieldContext();

	const [open, setOpen] = React.useState(false);
	const handleToggleMenu = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const inActiveFields = React.useMemo(() => {
		let active = false;
		// for loop
		for (let i = 0; i < fieldContext.fields.length; i++) {
			const field = fieldContext.fields[i];
			const columnKey = processTimeDimensionName(column.key);
			if (field.memberName.toLocaleLowerCase().includes(columnKey.toLocaleLowerCase())) {
				active = true;
				break;
			}
		}

		if (isDashboardPanel) {
			active = true;
		}
		return active;
	}, [fieldContext.fields]);

	const ref = React.useRef<HTMLDivElement>(null);

	const dataType = React.useMemo(() => {
		return getDataType(column.type as string, column.shortTitle);
	}, [column.shortTitle]);

	React.useEffect(() => {
		if (!ref.current) return;
		function handleStickyChange() {
			console.log("sticky change");
		}
		ref.current.addEventListener("sticky-change", handleStickyChange);
		return () => {
			ref.current && ref.current.removeEventListener("sticky-change", handleStickyChange);
		};
	}, [ref.current]);

	const handleRemoveColumn = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		fieldContext.removeField(column.key, String(column.type), isMeasure ? "measures" : "dimensions");
	};

	const onClickedSort = (newSortDirection: QueryOrder) => {
		const dimensionName = processTimeDimensionName(column.key);
		if (setQueryOrder) {
			setQueryOrder(dimensionName, newSortDirection);
		} else {
			dispatchQuery({
				type: "setOrder",
				payload: { order: dimensionName, runNow: true, orderDirection: newSortDirection },
			});
		}
	};

	const handleMoveLeft = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		fieldContext.reorderField(index - 1, index - 2, undefined, true);
	};
	const handleMoveRight = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		fieldContext.reorderField(index - 1, index, undefined,true);
	};

	const addFilter = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		dispatchQuery({ type: "addFilterField", payload: { member: column.key, type: column.type as TCubeMemberType } });
	};

	const editGranularity = (granularity: TimeDimensionGranularity) => {
		dispatchQuery({
			type: "setTimeDimensionGranularity",
			payload: { timeDimension: column.key, granularity: granularity as TimeDimensionGranularity, runNow: true },
		});
	};

	const granularity = column.type === "time" ? getGranularity(processTimeDimensionName(column.key), query.query) : "";
	function renderContextMenu() {
		const menuItems = [
			{
				label: "Aggregate",
				onClick: () => {
					/* do nothing */
				},
				options: {
					visible: column.type === "time",
					divider: true,
				},
				children: [
					{
						label: "Day",
						onClick: () => editGranularity("day"),
						options: {
							active: granularity === "day",
						},
					},
					{
						label: "Week",
						onClick: () => editGranularity("week"),
						options: {
							active: granularity === "week",
						},
					},
					{
						label: "Month",
						onClick: () => editGranularity("month"),
						options: {
							active: granularity === "month",
						},
					},
					{
						label: "Quarter",
						onClick: () => editGranularity("quarter"),
						options: {
							active: granularity === "quarter",
						},
					},
					{
						label: "Year",
						onClick: () => editGranularity("year"),
						options: {
							active: granularity === "year",
						},
					},
				],
			},
			{
				label: "Filter",
				onClick: addFilter,
				options: {
					visible: !isDashboardPanel,
					divider: true,
				},
			},
			{
				label: "Sort A-Z",
				onClick: () => onClickedSort("asc"),
				options: {
					disabled: sortDirection != false && sortDirection != "desc",
				},
			},
			{
				label: "Sort Z-A",
				onClick: () => onClickedSort("desc"),
				options: {
					disabled: sortDirection != false && sortDirection != "asc",
					divider: true,
				},
			},
			{
				label: "Move column left",
				onClick: handleMoveLeft,
				options: {
					disabled:
						index === 1 ||
						!fieldContext.validateReorder(index - 1, index - 2, isMeasure ? "measures" : "dimensions"),
					visible: !isDashboardPanel,
				},
			},
			{
				label: "Move column right",
				onClick: handleMoveRight,
				options: {
					disabled:
						index === fieldContext?.fields.length ||
						!fieldContext.validateReorder(index - 1, index, isMeasure ? "measures" : "dimensions"),
					visible: !isDashboardPanel,
					divider: true,
				},
			},
			{
				label: "Remove",
				onClick: handleRemoveColumn,
				options: {
					visible: !isDashboardPanel,
				},
			},
		];

		return (
			<>
				<Menu menuItems={menuItems} open={open} setOpen={setOpen} anchorEl={ref.current} arrow={false} />
			</>
		);
	}

	let clsName = "";
	if (column.key === "idxColumn") {
		clsName += " idxCell";
	}
	if (isMeasure) {
		clsName += " is-measure";
	}

	const children = (
		<Box
			sx={{
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "space-between",
				gap: theme.spacing(1),
				"& .MuiButtonBase-root": {
					opacity: open ? 1 : 0,
				},
				"&:hover": {
					"& .MuiButtonBase-root": {
						opacity: 1,
					},
				},
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", opacity: inActiveFields ? 1 : 0.5 }}>
				<CubeFieldLabel
					cubeTitle={title}
					showCubeTitle={!(column.meta && column.meta.duplicateTitle)}
					memberTitle={column.shortTitle + (granularity ? ` (${granularity})` : "")}
				/>
				{sortDirection !== false && (
					<ArrowDownwardRoundedIcon
						fontSize="small"
						sx={{
							opacity: 0.65,
							transform: `rotate(${sortDirection === "asc" ? 180 : 0}deg) scale(0.8)`,
						}}
					/>
				)}
			</Box>
		</Box>
	);

	return (
		<>
			<TableCell
				key={column.key}
				ref={ref}
				sx={{
					verticalAlign: "bottom",
					maxWidth: column.key === 'idxColumn' ? "25px" : dataType === "text" ? "120px" : "70px",
					minWidth: column.key === 'idxColumn' ? "25px" : dataType === "text" ? "120px" : "70px",
					backgroundColor: 'rgba(94, 94, 94, 1)',
					transition: `background-color 0.3s ${theme.transitions.easing.sharp}`,
					color: "white",
					cursor: inActiveFields && column.key !== "idxColumn" ? "pointer" : "default",
					position: "sticky",
					"&:first-of-type": {
						borderRadius: "8px 0 0 0",
						"&:before": {
							display: "none"
						}
					},
					"&:last-of-type": {
						borderRadius: "0 8px 0 0"
					},
					"&:after": {
						content: '""',
						position: "absolute",
						bottom: 0,
						left: 0,
						width: "100%",
						height: "2px",
						backgroundColor: isMeasure ? "#F58737" : theme.palette.primary.light,
						backgroundSize: "200% 200%",
						backgroundPositionY: "0%",
						pointerEvents: "none",
						zIndex: 1,
						transition: `all 0s ${theme.transitions.easing.sharp}`,
						transform: "scaleX(0)",
						transformOrigin: "left",
					},
					"&:hover": {
						backgroundColor: 'rgba(34, 34, 34, 0.85)',
						"&:after": {
							transition: `transform 0.3s ${theme.transitions.easing.sharp}`,
							transform: inActiveFields && column.key !== "idxColumn" ? "scaleX(1)" : "scaleX(0)",
						},
					},
					"&:before": {
						content: '""',
						// boxShadow: `inset 0px 0px 0px ${theme.palette.divider}`,
						// position: "absolute",
						// top: 0,
						// left: 0,
						// width: "100%",
						// height: "100%",
						// background: `linear-gradient(180deg, ${theme.palette.background.paper} 0px, #D9D9D9 100%)`,
						// backgroundSize: `100% calc(var(--windowHeight) / ${ref.current?.clientHeight} * 100%)`,
						// backgroundPosition: `bottom calc((var(--tableContainerHeight) - ${ref.current?.clientHeight}px) * -1) right 0px`,
						// zIndex: -1,
						// opacity: 0,
						backgroundColor: '#3f3f3f',
						height: "100%",
						position: "absolute",
						width: "1px",
						left: 0,
						top: 0,
					},
				}}
				onClick={handleToggleMenu}
			>
				{children}
				{inActiveFields && column.key !== "idxColumn" && renderContextMenu()}
			</TableCell>
		</>
	);
};

export default ReportHeaderCell;
