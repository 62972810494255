import { Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import animations from "../../theme/animations";

export interface path {
	label: string;
	path?: string;
	color?: string;
}

type Props = {
	path: path;
	index: number;
};

const weights = ["600", "400", "100"];
const BreadCrumb = ({ path, index }: Props) => {
	index = weights.length - 1 < index ? weights.length - 1 : index;
	const theme = useTheme();
    const navigate = useNavigate();

	return (
		<Box
            // onClick={() => path.path && navigate(path.path)}
			sx={{
				position: "relative",
				mixBlendMode: "multiply",
				"&:after": {
					content: '""',
					position: "absolute",
					bottom: 5,
					left: 0,
					width: "0",
					height: "1px",
					border: "1px solid white",
					backgroundColor: path.color ? path.color : "black",
					transition: "width 0s linear, opacity 0.5s ease-in-out",
				},
				// "&:hover": {
                //     opacity: path.path ? 0.8 : 1,
                //     cursor: path.path ? "pointer" : "select",
				// 	"&:after": {
				// 		width: path.path ? "100%" : "0",
				// 		transition: `width 1.5s ${theme.transitions.easing.sharp}`,
				// 	},
				// },
                // "&:active": {
                //     opacity: 1,
                // },
                animation: animations.fadeRight,
                animationDelay: `${index * 0.2}s`,
			}}
		>
			<Typography variant="h4" sx={{ fontWeight: weights[index], color: path.color }}>
				{path.label}
			</Typography>
		</Box>
	);
};

export default BreadCrumb;
