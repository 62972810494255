import React from "react";
import { formatRelative } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SavedReport } from "../../utils/saved-report-utils";
import { getColorFromContext } from "../../utils/dashboard-utils";
import { useAuthState } from "../../context/auth-context";
import BasicConfirmationDialog from "../dialogs/BasicConfirmationDialog";
import API from "../../api";
import { SchemaContextEnum } from "../../utils/cube-utils";
import { useTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import ArrowTooltip from "../ToolTip/ArrowTooltip";
import ConfirmModal from "../Modals/ConfirmModal";

interface Props {
	reportsData: SavedReport[] | null;
	setReportsData: (x: unknown) => void;
}

const NamedReportsList: React.FC<Props> = ({ reportsData, setReportsData }: Props) => {
	const navigate = useNavigate();
	const [sortOption, setSortOption] = React.useState("last_changed");
	const [sortDirection, setSortDirection] = React.useState(true);
	const { env } = useParams() as Record<string, string>;
	const [callbackArgs, setCallbackArgs] = React.useState<string[]>([]);
	// const [dialogOpen, setDialogOpen] = React.useState(false);
	const { user } = useAuthState();
	const theme = useTheme();

	const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
	function handleClickDelete(ownerId, key) {
		setCallbackArgs([ownerId, key]);
		// setDialogOpen(true);
		setConfirmModalOpen(true);
	}

	function handleDelete(ownerId, key) {
		// console.debug(`ReportsGridList > handleDelete > ownerId=${ownerId}, key=${key}`);
		const uriEncodedKey = encodeURIComponent(key);
		API.delete(`/api/report/env_name/${env}/key/${uriEncodedKey}`)
			.then((response) => {
				setReportsData(response.data);
				handleSort(sortOption, false)
			})
			.catch((error) => {
				console.error(`error=${error}`);
			});
	}

	const handleSort = (sortOption, toggleDirection=true) => {
		if (sortOption == "last_changed" || sortOption == "creation_time") {
			reportsData?.sort((a, b) =>
				new Date(a[sortOption]) < new Date(b[sortOption]) ? (!sortDirection && 1) || -1 : (!sortDirection && -1) || 1
			);
		} else {
			reportsData?.sort((a, b) =>
				a[sortOption].toLowerCase() > b[sortOption].toLowerCase()
					? (sortDirection && 1) || -1
					: (sortDirection && -1) || 1
			);
		}
		if (toggleDirection) setSortDirection(!sortDirection);
	};

	const styles = {
		"& .named-report-list-thead": {
			"& tr": {
				"& .named-report-list-th": {
					fontWeight: 600,
				},
			},
		},
		"& .named-report-list-tbody": {
			"& tr": {
				cursor: "pointer",
				"&:hover td": {
					backgroundColor: blue[50],
					color: theme.palette.primary.main,
				},
			},
		},
	};

	if (!user) {
		return <></>;
	}

	return (
		<>
			<ConfirmModal open={confirmModalOpen} setOpen={setConfirmModalOpen} onAccept={() => handleDelete(callbackArgs[0], callbackArgs[1])} title={`Delete Report?`} description="This will permanently delete the report!"/>
			<Table className="named-report-list-table" stickyHeader sx={styles}>
				<TableHead className="named-report-list-thead">
					<TableRow>
						<TableCell className="named-report-list-th" component="th">
							Name
							<TableSortLabel
								active={sortOption == "name"}
								direction={sortDirection === false ? "asc" : "desc"}
								onClick={() => {
									handleSort("name");
									setSortOption("name");
								}}
							/>
						</TableCell>

						<TableCell className="named-report-list-th" component="th">
							Last Modified
							<TableSortLabel
								active={sortOption == "last_changed"}
								direction={sortDirection === false ? "asc" : "desc"}
								onClick={() => {
									handleSort("last_changed");
									setSortOption("last_changed");
								}}
							/>
						</TableCell>

						<TableCell className="named-report-list-th" component="th">
							Created
							<TableSortLabel
								active={sortOption == "creation_time"}
								direction={sortDirection === false ? "asc" : "desc"}
								onClick={() => {
									setSortOption("creation_time");
									handleSort("creation_time");
								}}
							/>
						</TableCell>
						<TableCell className="named-report-list-th" component="th">
							Delete
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className="named-report-list-tbody">
					{reportsData &&
						reportsData.map((report) => {
                          const reportContext = SchemaContextEnum[report.context_name];
                          return (
                            <TableRow
                                  className="named-report-list-tr"
                                  key={report.name}
                                  onClick={(e) => {
                                      const target = e.target as Element;
                                      if (!target.className.toLowerCase().includes("button")) {
                                          const reportContext = SchemaContextEnum[report.context_name];
                                          const url = `/${env}/reports/${reportContext}/${report.subcontext_enum}/key/${report.key}`;
                                          navigate(url, {
                                              state: { savedReport: report },
                                          });
                                      }
                                  }}
                              >
                                  <TableCell>
                                    <Link
                                      to={`/${env}/reports/${reportContext}/${report.subcontext_enum}/key/${report.key}`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div style={{ display: "flex" }}>
                                          <Typography className="href-underline" color="text.primary" sx={{ fontWeight: 500 }} variant="body1">
                                              <ArrowTooltip title={report["context_name"]}>
                                                  <span
                                                      style={{
                                                          width: "14px",
                                                          height: "14px",
                                                          backgroundColor: getColorFromContext(theme, report["context_name"]),
                                                          borderRadius: "100%",
                                                          display: "inline-block",
                                                          marginRight: "1ch",
                                                      }}
                                                  />
                                              </ArrowTooltip>
                                              {report.name}
                                          </Typography>
                                      </div>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{formatRelative(new Date(report.last_changed), new Date())}</TableCell>
                                  <TableCell>{formatRelative(new Date(report.creation_time), new Date())}</TableCell>
                                  <TableCell>
                                      <Button
                                          size="small"
                                          color="primary"
                                          disabled={parseInt(report.owner_id) !== user.userId}
                                          onClick={() => {
                                              handleClickDelete(report.owner_id, report.key);
                                          }}
                                      >
                                          Delete
                                      </Button>
                                  </TableCell>
                              </TableRow>
                          );
                        })}
				</TableBody>
			</Table>
		</>
	);
};

export default NamedReportsList;
