import { useRef, useState } from "react";

const useMenu = () => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement | HTMLButtonElement>(null);

    function handleClose() {
        setOpen(() => false);
    }
    
    function handleOpen() {
        setOpen(() => true);
    }

    function handleToggle() {
        setOpen(() => !open);
    }

    return { anchorRef, handleClose, handleOpen, handleToggle, open, setOpen };
}

export default useMenu;