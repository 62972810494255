import React from "react";
import { TextField } from "@mui/material";
import Modal from "./Modal";
import { AxiosError, AxiosResponse } from "axios";
import { SavedReport } from "../../utils/saved-report-utils";
import { useQueryState } from "../../context/query-context";
import API from "../../api";
import { useParams } from "react-router-dom";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { useFieldContext } from "../../context/providers/FieldContextProvider";
import PrimaryButton from "../Base/PrimaryButton";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onClose: () => void;
	handleSuccess: (responseData: SavedReport) => void;
	handleFailure: (error: AxiosError) => void;
	report?: SavedReport;
};

const SaveAsModal = ({ open, setOpen, onClose, handleSuccess, handleFailure, report }: Props) => {
	const { query } = useQueryState();
	const { appContextName } = useCubeMetaState();
	const { env, exploreFrom } = useParams() as Record<string, string>;
	const { fields } = useFieldContext();

	const initialName = "";

	const [nameInput, setNameInput] = React.useState(initialName);

	const handleChangeName = (evt) => {
		setNameInput(evt.target.value);
	};

	const handleClose = () => {
		setNameInput(initialName);
		onClose();
	};

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");

	const handleSaveAs = () => {
		handleClose();

		const payload = {
			query_string: query,
			description: "",
			name: nameInput,
			context_name: appContextName,
			subcontext_enum: parseInt(exploreFrom),
			fields_order: fields,
		};

		if (!env) {
			return;
		}

		setLoading(true);

		API.post(`/api/report/env_name/${env}`, payload)
			.then((response: AxiosResponse) => {
				handleSuccess(response.data);
				setLoading(false);
			})
			.catch((error: AxiosError) => {
				console.error(`SavedReportDialog > create > error=`, error);
				handleFailure(error);
				setError(error.message);
				setLoading(false);
			});
	};

	const props = {
		open,
		setOpen,
		title: "Save Report As",
		description: "A new copy of the current report will be created.  Please provide a name.",
		form: (
			<>
				<TextField
					value={nameInput}
					onChange={handleChangeName}
					required
					autoFocus
					id="name"
					label="Name"
					type="text"
					inputProps={{ maxLength: 50 }}
					sx={{ width: "50%", minWidth: "16vw" }}
					variant="standard"
					margin="dense"
					error={error !== ""}
					helperText={error}
				/>
			</>
		),
		actions: (
			<>
				<PrimaryButton onClick={() => setOpen(false)} color="secondary" variant="text">
					Cancel
				</PrimaryButton>
				<PrimaryButton
					color="primary"
					disabled={!nameInput}
					onClick={handleSaveAs}
					variant="text"
					loading={loading}
					loadingText="Saving"
				>
					Create
				</PrimaryButton>
			</>
		),
		onKeyPress: (e: React.KeyboardEvent) => {
			if (e.key === "Enter" && nameInput) {
				handleSaveAs();
			}
		},
	};

	return <Modal {...props} />;
};

export default SaveAsModal;
