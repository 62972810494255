import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  // pointerEvents: "none",
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0,0,0,0.8)",
    // backdropFilter: "blur(5px)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.8)",
    // backdropFilter: "blur(5px)",
  },
}));

export default ArrowTooltip;