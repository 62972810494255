import { Box, IconButton } from "@material-ui/core";
import Typography from "@mui/material/Typography"
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import React from "react";
import useStyles from "./style";

interface Props {
  toggleFiltersOpen: () => void
  filtersOpen: boolean
  numFilters: number
}

const QueryFiltersAreaTitle: React.FC<Props> = (
  {
    toggleFiltersOpen,
    filtersOpen,
    numFilters,
  }: Props) => {
  const classes = useStyles();
  return (
        <Box onClick={toggleFiltersOpen} className={classes.titleRow}>
          <IconButton size="small">
            {filtersOpen ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
          <Typography variant="subtitle1" sx={{ fontSize: '16px', alignSelf: 'center' }} >
            Filters {!filtersOpen && numFilters ? ` (${numFilters})` : ``}
          </Typography>
          {/*{!filtersOpen && numFilters*/}
          {/*  // ? <Typography sx={{ fontSize: '10px', paddingLeft: '3px', paddingTop: '2px', color: '#f254ff' paddingLeft: '3px'}} className={classes.numFiltersSuperText}> ({numFilters})</Typography>*/}
          {/*  ? <Typography sx={{ }} > ({numFilters})</Typography>*/}
          {/*  : <></>}*/}
        </Box>
  )
}

export default QueryFiltersAreaTitle;