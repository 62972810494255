import React, {useState} from "react";
import { useStyles } from "../AuthForm";
import { LinearProgress } from "@material-ui/core";
import logoImg from "../../img/logo.png";

interface Props {
  handleSubmit: (username: string, password: string) => void,
  isValidating: boolean,
}

const LoginForm = (
  {
    handleSubmit,
    isValidating,
  }: Props): React.ReactElement => {

  const classes = useStyles()

  const [usernameInput, setUsernameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  function onSubmit() {
    handleSubmit(usernameInput, passwordInput)
  }

  return <div className={classes.card}>
    <img src={logoImg} style={{width: '50%', marginBottom: '1rem'}} alt={"logo"}/>
    <div className={classes.form}>
      <input className={classes.inputField}
             inputMode="text"
             type="username"
             value={usernameInput}
             onChange={e => setUsernameInput(e.target.value)}
             onKeyDown={(e: React.KeyboardEvent) => {if (e.key === "Enter") onSubmit()}}
             placeholder="username"
             disabled={isValidating}
      />
      <input className={classes.inputField}
             type="password"
             value={passwordInput}
             onChange={e => setPasswordInput(e.target.value)}
             onKeyDown={(e: React.KeyboardEvent) => {if (e.key === "Enter") onSubmit() }}
             placeholder="password"
             disabled={isValidating}
      />
      { isValidating
        ? <LinearProgress />
        : <button className={classes.button} onClick={onSubmit}>Sign In</button>}
    </div>
  </div>
}

export default LoginForm