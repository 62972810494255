import {createTheme} from "@mui/material";
import palette from "./colors";

export const theme = {
  palette: {
    ...palette,
    transparent: {
      main: "rgba(0,0,0,0.5)",
    },
    background: {
      paper: "white",
      default: "white",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Outfit",
    body1: {
      fontFamily: "Roboto",
    },
    body2: {
      fontFamily: "Roboto",
    },
    caption: {
      fontFamily: "Roboto",
    },
    h5: {
      fontWeight: 700,
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.175, 0.885, 0.32, 1.27)",
      sharp: "cubic-bezier(0.075, 0.82, 0.165, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    }
  },
  components: {
  },
} as const;


export default createTheme(theme);
