import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import { alpha } from "@mui/system";
import { grey } from "@mui/material/colors";

const StyledInput = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
	// overflow: "hidden",
	borderRadius: "8px",
    width: "100%",
	"& .MuiOutlinedInput-root": {
		borderRadius: "inherit",
		color: alpha(theme.palette.secondary.contrastText, 0.75),
		fontSize: "14px",

		"& .MuiIconButton-root": {
			padding: "0px",
			fontSize: "1rem",
			color: grey[800],

			"&:hover": {
				"& .MuiSvgIcon-root": {
					color: grey[500],
				},
			},
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: alpha(theme.palette.secondary.contrastText, 0.05),
		},
		"&:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: alpha(theme.palette.secondary.contrastText, 0.25),
			},
		},
	},
	"& input": {
		padding: "8px 0 8px 8px",
	},
}));

export default StyledInput;