import React from "react";
import {useParams} from "react-router-dom";
import useStyles from "./style";
import {DashboardProvider} from "../../context/dashboard-context";
import DashboardFilters from "../../components/DashboardFilters/DashboardFilters";
import DashboardMain from "../../components/DashboardMain/DashboardMain";
import {DashboardDef, DashboardsConfig, PinnedFilterDef} from "../../utils/dashboard-utils";
import {CircularProgress, Divider} from "@material-ui/core";
import {useCubeMetaState} from "../../context/cube-meta-context";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {formatRelative} from "date-fns";
import { useAuthState } from "../../context/auth-context";
import {ReportsEnvironmentConfig} from "../../utils/auth-utils";
import {BinaryFilter, UnaryFilter} from "@cubejs-client/core";
import {MetaLike, SchemaContextName, validateQuery} from "../../utils/cube-utils";


interface Props {
  currentEnvironment: ReportsEnvironmentConfig
  appContextName: SchemaContextName
  metaContextName: SchemaContextName
}
const DashboardsPage: React.FC<Props> = (
  {
    currentEnvironment,
    appContextName,
    metaContextName,
  }: Props): React.ReactElement => {
  const classes = useStyles();
  const { cubesMetaLike } = useCubeMetaState();
  const { dashboardKey } = useParams() as Record<string, string>;
  const [lastSynced, setLastSynced] = React.useState<string | undefined>(undefined);
  const [visibleFilters, setVisibleFilters] = React.useState<PinnedFilterDef[] | undefined>(undefined);
  const [initialFilters, setInitialFilters] = React.useState<(BinaryFilter | UnaryFilter)[] | undefined>(undefined);
  const [dashboardDef, setDashboardDef] = React.useState<DashboardDef | undefined>(undefined);
  const isContextReady = DashboardsConfig[dashboardKey].contextName === appContextName;

  React.useEffect(() => {
    if (cubesMetaLike && isContextReady) {
      const validatedDashboardDef = validateDashboardDef(DashboardsConfig[dashboardKey]);
      setDashboardDef(validatedDashboardDef);
      const lastSyncedDate = currentEnvironment.last_synced;
      const formattedLastSynced = lastSyncedDate ? formatRelative(new Date(lastSyncedDate), new Date()) : "--";
      setLastSynced(formattedLastSynced);
    }
  }, [cubesMetaLike, isContextReady, dashboardKey]);

  React.useEffect(() => {
    if (dashboardDef) {
      const visibleFilters = dashboardDef.pinnedFilters
        .filter(f => f.visibilityCondition ? f.visibilityCondition(currentEnvironment) : true);
      setVisibleFilters(visibleFilters);
      const initialFilters = visibleFilters.filter(f => !!f.defaultFilter).map(f => f.defaultFilter!);
      setInitialFilters(initialFilters);
    }
  }, [dashboardDef && dashboardDef.key]);

  if (appContextName !== metaContextName) {
    // console.warn(`DashboardsPage > metaContextName (${metaContextName}) and appContextName (${appContextName}) not synced.  Returning loader`);
    return <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size='1rem'/>
    </div>
  }


  function validateDashboardDef(def: DashboardDef): DashboardDef {
    const panels = def.panels.map(rowPanels => (
      rowPanels.map(panel => (
        Object.assign(panel, {
          baseQuery: validateQuery(panel.baseQuery, cubesMetaLike as MetaLike)
        })
      ))
    ));
    return Object.assign(def, { panels });
  }

  function renderDashboardHeader(title: string) {
      return <Box style={{display: 'flex', alignItems: 'flex-end'}}>
        <div className={classes.titleRoot}>
          <Typography
            variant='h4'
            color='textPrimary'
            style={{display: 'inline-block', marginRight: '0.5rem'}}
          >
            <span style={{color: "#aaa"}}>{"Dashboards > "}</span>{title}
          </Typography>
        </div>
        <Typography
          variant='subtitle1'
          color='textSecondary'
          gutterBottom={false}
          sx={{display: 'inline-block', marginLeft: 'auto', fontSize: '14px'}}
        >
          Synced {lastSynced}
        </Typography>
      </Box>
  }

  if (dashboardDef && initialFilters && visibleFilters) {
    return (
      <DashboardProvider initialDashboardFilters={initialFilters}>
        <div className={classes.root}>
          {renderDashboardHeader(dashboardDef.title)}
          <Divider style={{margin: '0.5rem 0'}}/>
          <DashboardFilters
            pinnedFilters={visibleFilters}
          />
          <DashboardMain
            dashboardKey={dashboardKey}
            dashboardDef={dashboardDef}
          />
        </div>
      </DashboardProvider>
    );
  } else {
    return <></>
  }
};

export default DashboardsPage;
