import React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../../context/auth-context";
import StyledSideBar from "./styledComponents/StyledSideBar";
import Logo from "./components/Logo";
import Navigation from "./components/Navigation";
import UserControls from "./components/UserControls";
import SelectEnvironment from "./components/SelectEnvironment";
import EnvEditModal from "../Modals/EnvEditModal";
import { ReportsEnvironmentConfig } from "../../utils/auth-utils";

const Sidebar = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatchAuth = useAuthDispatch();
	const { isAuthenticated, currentEnvironment, allowedEnvironments } = useAuthState();
	const [open, setOpen] = React.useState(false);
	const [editEnv, setEditEnv] = React.useState<ReportsEnvironmentConfig | null>(null);

	const openMenu = React.useCallback((event?: React.MouseEvent) => {
		if (!event) return;
		if ((event?.clientX <= 5 || event === undefined) && !open) {
			setOpen(true);
		}
	}, [open, setOpen]);

	const closeMenu = React.useCallback((event?: React.MouseEvent) => {
		if (event && event.clientX > 0 && open) {
			setOpen(false);
		}
	}, [open, setOpen]);

	if (!allowedEnvironments || !currentEnvironment || !isAuthenticated) {
		return <></>;
	}

	return (
		<div id="Sidebar-Navigation-Container" onMouseMove={openMenu} onMouseLeave={closeMenu}>
			<StyledSideBar variant="permanent" anchor="left" open={open}>
				<Logo open={open} theme={theme} />
				<Navigation open={open} openMenu={() => openMenu()} closeMenu={() => closeMenu()} theme={theme} />
				<SelectEnvironment open={open} setOpen={setOpen} setEditEnv={setEditEnv}/>
				<EnvEditModal editEnv={editEnv} open={editEnv !== null} setOpen={(open) => {
					if (!open) {
						setEditEnv(null)
					}
				}}/>
				<UserControls open={open} />
			</StyledSideBar>
		</div>
	);
};

export default Sidebar;
