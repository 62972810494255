import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'inherit',
  },
  sectionUserActions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  loadingContainer: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    top: '20%'
  },

  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6, 6, 0, 6),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    marginLeft: "64px", // quick fix for the sidebar width
  },
}));

export default useStyles