import * as React from "react";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import {TCubeDimension} from "@cubejs-client/core";
import {CubeContext} from "@cubejs-client/react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@mui/material/FormControl";
import {CircularProgress, InputAdornment} from "@mui/material";
import useStyles from "./style";


interface Props {
  cubeDimension: TCubeDimension
  values: string[]
  handleSetValue: (x: string[]) => void
}

const MultipleSelectCheckmarks: React.FunctionComponent<Props> = (
  {
    cubeDimension,
    values,
    handleSetValue,
  }: Props) => {
  const classes = useStyles();
  const {cubejsApi} = React.useContext(CubeContext);
  const [options, setOptions] = React.useState<string[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const loading = options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const query = {
        dimensions: [cubeDimension.name],
        // limit: 500,
      };
      const resultSet = await cubejsApi.load(query);
      const rawData = resultSet.rawData()
        .filter(row => !!(row[cubeDimension.name]))
        .map(row => row[cubeDimension.name]);

      if (active) {
        setOptions(rawData as string[]);
      }
    })();

    return () => {
      active = false;
    };

  }, [loading]);

  const handleSelectAllClicked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleSetValue(event.target.checked ? options : []);
  };

  const handleChange = (option: string, checked: boolean): void => {
    // console.log(`handleChange (${values.length} values) > option=${option}, checked=${checked}`);
    if (checked) {
      values = values.concat([option]);
    } else {
      values = values.filter(s => s !== option);
    }
    handleSetValue(values);
  };

  const handleChangeSearchText = (event): void => {
    setSearchText(event.target.value);
  }

  const handleClickedClearSearch = (): void => {
    setSearchText("");
  }

  function renderProgressLoader(): React.ReactElement {
    return <>
      <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size='1rem'/>
      </div>
    </>
  }

  return (
    <>
      <FormControl fullWidth>
        <TextField
          autoFocus
          size="small"
          variant="outlined"
          onChange={handleChangeSearchText}
          placeholder="Search"
          value={searchText}
          sx={{ marginBottom: '8px', marginTop: '4px' }}
          InputProps={{
            sx: { fontSize: "15px", fontFamily: "Outfit", fontWeight: 500, backgroundColor: 'white' },
            startAdornment: (
              <InputAdornment position="start" sx={{ }} >
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ display: searchText.length ? 'default' : 'none', cursor: 'pointer' }}
                onClick={handleClickedClearSearch}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Box sx={{ overflow: 'auto', maxHeight: 250, padding: '8px 12px 12px 12px' }}>
          <FormControlLabel
            label={<Typography sx={{ fontSize: "14px", fontFamily: "Outfit", fontWeight: 600 }}>Select/Deselect All</Typography>}
            classes={{ root: classes.formControlLabelRoot }}
            control={
              <Checkbox
                sx={{ padding: '5px' }}
                checked={values.length === options.length}
                indeterminate={!!(values.length && values.length !== options.length)}
                onChange={handleSelectAllClicked}
              />
            }
          />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {loading ? renderProgressLoader() :
            options.filter(option => option.toLowerCase().includes(searchText.toLowerCase())).map(option => (
                <FormControlLabel
                  key={option}
                  classes={{ root: classes.formControlLabelRoot }}
                  sx={{ fontSize: '12px' }}
                  label={
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontFamily: "Outfit",
                        fontWeight: 500,
                        lineHeight: 1,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      {option}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      sx={{ padding: '5px' }}
                      checked={values.includes(option)}
                      onChange={(event, checked) => handleChange(option, checked)}
                    />
                  }
                />
            ))}
        </Box>
      </Box>
    </>
  );
}

export default MultipleSelectCheckmarks;