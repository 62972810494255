import * as React from "react";
import {Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {defaultOperatorForType, filterOperatorsForType} from "../../utils/filters";
import {TCubeMemberType} from "@cubejs-client/core";
import MenuItem from "@mui/material/MenuItem";
import {FilterOperatorName} from "../../utils/utils";
import Typography from "@mui/material/Typography";



interface Props {
  handleChange: (value) => void
  memberType: TCubeMemberType
  value: FilterOperatorName
}

export const FilterOperatorSelect: React.FunctionComponent<Props> = ({
  handleChange,
  memberType,
  value,
}: Props) => {
  const availableOperators = filterOperatorsForType(memberType);

  return (
    <FormControl fullWidth className="thinScrollbar">
      <Select
        value={!value ? defaultOperatorForType[memberType] : value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        size="small"
        sx={{
          height: '32px',
          fontSize: '14px',
          backgroundColor: 'white',
        }}
        className="thinScrollbar"
      >
        {availableOperators.map((operator, idx) => (
          <MenuItem key={idx} value={operator.name}>
            <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>{operator.title}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};
