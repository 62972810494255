import { createStyles, makeStyles, alpha } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // minHeight: "100%",
      position: "relative",
      marginTop: 6,
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: "85%",
      paddingBottom: '1rem',
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: alpha("#888", 0.5),
        borderRadius: '6px',
      },

      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          "&:hover": {
            background: alpha("#555", 0.5),
          }
        }
      }
    },
  })
);

export default useStyles;
