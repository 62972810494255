import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ReportsUser, useAuthDispatch, useAuthState } from "../../context/auth-context";
import { useAlertDispatch } from "../../context/alert-context";
import { deleteUserValues, ReportsEnvironmentConfig, USER_ROLE_NAME_ADMIN } from "../../utils/auth-utils";
import { DashboardDef, DashboardsConfig } from "../../utils/dashboard-utils";
import { SchemaContextDefinition, SchemaContextEnum } from "../../utils/cube-utils";
import { SavedReport } from "../../utils/saved-report-utils";
import API from "../../api";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import NamedReportsList from "../../components/NamedReportsList/NamedReportsList";
import SplitButton, { SplitButtonOption } from "../../components/SplitButton/SplitButton";
import theme from "../../theme/theme";
import animations from "../../theme/animations";
import useSetSchemaContextDefinitions from "../../hooks/useSetSchemaContextDefinitions";
import useStyles from "./style";
import { useCubeMetaState } from "../../context/cube-meta-context";
import { IS_LOCAL_DEV } from "../../config/frontendConfig";


const LandingPage: React.FC = () => {
	const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams() as Record<'env', string>;
  const envName = params.env;
  const dispatchAuth = useAuthDispatch();
  const dispatchAlert  = useAlertDispatch();
	const authState = useAuthState();
	const cubeMetaState = useCubeMetaState();
	const currentEnvironment = authState.currentEnvironment as ReportsEnvironmentConfig;
	const user = authState.user as ReportsUser;

	const [reportsData, setReportsData] = React.useState<SavedReport[] | undefined>(undefined);

	const isDashboardVisible = React.useCallback((dashboardDef: DashboardDef): boolean => {
		const dashboardContextConfig = currentEnvironment.contexts_config[dashboardDef.contextName];
		const dashboardConfig = currentEnvironment.dashboards_config[dashboardDef.key];
		return dashboardConfig
			&& (dashboardConfig.visible || user.roleName === USER_ROLE_NAME_ADMIN)
			&& dashboardContextConfig
			&& dashboardContextConfig.enabled
			&& (dashboardContextConfig.visible || user.roleName === USER_ROLE_NAME_ADMIN)
			&& dashboardContextConfig.dependencies_met;
	}, [currentEnvironment, user, USER_ROLE_NAME_ADMIN]);


	useSetSchemaContextDefinitions();

	/* get reports list */
	React.useEffect(() => {
		API.get(`/api/reports/env_name/${envName}`)
			.then((response) => {
				setReportsData(response.data);
			})
			.catch((error) => {
				console.error(`LandingPage.tsx > useEffect > get reports API error=${error}`);
				if (error.response && error.response.status === 401) {
					deleteUserValues();
					dispatchAuth({ type: "setIsNotAuthenticated" });
					dispatchAlert({
						type: "setAlert",
						payload: {
							alert: { message: "Authentication failed", severity: "error" },
						},
					});
				} else {
					dispatchAlert({
						type: "setAlert",
						payload: {
							alert: { message: "Failed to load data, try reloading page", severity: "warning" },
						},
					});
				}
			});
	}, []);


	const renderSavedReports = React.useCallback(() => {
		if (reportsData && reportsData.length) {
			return <NamedReportsList reportsData={reportsData} setReportsData={setReportsData} />
		} else {
			return (
				<div style={{ marginTop: "0.8rem" }}>
					<Typography>No reports saved yet!</Typography>
				</div>
			);
		}
	}, [reportsData]);

	const [isDashboardsVisible, setIsDashboardsVisible] = React.useState(false);

	React.useEffect(() => {
		const dashboardKeys = Object.keys(DashboardsConfig);
		for (let i = 0; i < dashboardKeys.length; i++) {
			const key = dashboardKeys[i];
			const dashboard = DashboardsConfig[key];
			if (isDashboardVisible(dashboard)) {
				setIsDashboardsVisible(true);
				break;
			}
		}
	}, []);

	const visibleContextNames = Object.entries(currentEnvironment.contexts_config)
		.filter(([key, context]) => (
			context.enabled
			&& context.dependencies_met
			&& (context.visible || user.roleName === USER_ROLE_NAME_ADMIN)
		))
		.map(([key, context]) => key);

	const schemaContextDefinitions = cubeMetaState.schemaContextDefinitions as SchemaContextDefinition[];
	const exploreContextStartingPoints = (schemaContextDefinitions as SchemaContextDefinition[]).reduce(addOnClickToSplitButton, []);
	function addOnClickToSplitButton(res: SplitButtonOption[], contextDef: SchemaContextDefinition) {
		return res.concat(contextDef.explore_queries.map(queryDef => Object.assign(queryDef, {
			onClick: () => navigate(`/${envName}/explore/${SchemaContextEnum[contextDef.name]}/${queryDef.enumInt}`),
		})));
	}

	return (
		<Box className={classes.root}>
			{isDashboardsVisible && (
				<>
					<div key="landing-page-section-dashboards" style={{ marginBottom: "0.5rem", flex: 0, display: "flex", alignItems: "center", gap: "1rem" }}>
						<Typography variant="h5" sx={{ display: "inline-block", animation: animations.fadeRight }}>
							Dashboards
						</Typography>
						<Typography variant="subtitle2" sx={{px: 2, py: 0.5, backgroundColor: theme.palette.primary.main, borderRadius: "1rem", color: theme.palette.primary.contrastText, fontSize: "12px"}}>
							Beta
						</Typography>
					</div>
					<div
						style={{
							paddingTop: "0.25rem",
							marginBottom: theme.spacing(4)
						}}
					>
						<Grid container spacing={2}>
							{Object.keys(DashboardsConfig).map((key, index) => {
								const dashboard = DashboardsConfig[key];
								// const isVisible = currentEnvironment.contexts_config[dashboard.contextName].visible;
								const isVisible = isDashboardVisible(dashboard);
								// if (isVisible || (dashboard.title === "DEV TESTING" && IS_LOCAL_DEV)) return <DashboardCard dashboard={dashboard} key={index} />;
								if (isVisible) return <DashboardCard dashboard={dashboard} key={index} />;
							})}
						</Grid>
					</div>
				</>
			)}

			<div key="landing-page-section-reports-list" style={{ marginBottom: "0.5rem", flex: 0, display: "flex" }}>
				<Typography variant="h5" sx={{ display: "inline-block" }}>
					Reports
				</Typography>
				<div className={classes.grow} />
			</div>

			<Grid container spacing={1} rowSpacing={1} sx={{ marginBottom: theme.spacing(4), zIndex: 3 }}>
				{schemaContextDefinitions
					.filter(contextDef => visibleContextNames.includes(contextDef.name))
					.map(contextDef => (
						<Grid key={contextDef.name} item>
							<SplitButton
								key={contextDef.name}
								label={`${contextDef.label}`}
								bgColor={contextDef.colorPalette}
								options={contextDef.explore_queries
									.map(queryDef => Object.assign(queryDef, {
										onClick: () => navigate(
											`/${envName}/explore/${SchemaContextEnum[contextDef.name]}/${queryDef.enumInt}`
										)})
									)}
							/>
						</Grid>
					))
				}
			</Grid>

			<Box
				sx={{
					overflow: "auto",
					marginTop: "0.25rem",
					flexGrow: 1,
					// animation: reportsData ? animations.fadeIn : 'none',
					"&::-webkit-scrollbar-track": {
						background: "transparent",
					},
					"&::-webkit-scrollbar": {
						width: "6px",
						height: "6px",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "rgba(136, 136, 136, 0.5)",
						borderRadius: "6px",

						"&:hover": {
							background: "rgba(85, 85, 85, 0.5)",
						}
					},
				}}
			>
				{reportsData ? (
					renderSavedReports()
				) : (
					<div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
						<CircularProgress size="2rem" />
					</div>
				)}
			</Box>
		</Box>
	);
};

export default LandingPage;
