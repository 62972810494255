// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
    height: 100%;
    margin: 0;
    overflow: auto;
}

.thinScrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

.thinScrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

.thinScrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

.thinScrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;IACI,YAAY;IACZ,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,WAAW;EACb;;AAEF;IACI,uBAAuB;EACzB;;AAEF;IACI,gBAAgB;IAChB,kBAAkB;EACpB;;AAEF;IACI,gBAAgB;EAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\nhtml, body, #root {\n    height: 100%;\n    margin: 0;\n    overflow: auto;\n}\n\n.thinScrollbar::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n  }\n\n.thinScrollbar::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n.thinScrollbar::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 6px;\n  }\n\n.thinScrollbar::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
