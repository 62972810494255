import React, { useRef, useState, useEffect, useCallback, RefObject } from "react";
import DateButton from "./DateButton";
import { AiOutlineCalendar } from "react-icons/ai";
import { Box, Typography } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { useQueryDispatch, useQueryState } from "../../context/query-context";
import { getDescription } from "./util";
import CustomMenu from "./CustomMenu/CustomMenu";
import { ExploreQueryDefinition } from "../../utils/cube-utils";
import useMenu from "../../hooks/useMenu";
import { format } from "date-fns";
import { useCubeMetaState } from "../../context/cube-meta-context";

type Props = {
	timeDimension: string | undefined;
	explore: ExploreQueryDefinition | undefined;
	fixed: boolean;
};

const DateFilter = ({ timeDimension, explore, fixed }: Props) => {
	if (!timeDimension || !explore) return null;

	const theme = useTheme();
	const dispatch = useQueryDispatch();
	const { cubesMetaLike } = useCubeMetaState();
	const { isLoading } = useQueryState();

	// base state of component
	const relativeFilters = ["last year", "this year", "next year", "next 3 years"];
	const [options, setOptions] = useState<string[]>(relativeFilters); // currently available filters

	const [customText, setCustomText] = useState<string>("Custom");
	// selected indicates which relative filter option is selected and dateRange indicates the custom date range
	const [selected, setSelected] = useState<{ value: string, runNow: boolean }>({ value: "today", runNow: false }); // note this is custom is the date range is to be custom and all if there should be no date range
	const highlighted = useRef<string>("today");
	const [dateRange, setDateRange] = useState<[string, string]>(["today", "tomorrow"]);

	// initialize fixed date filter
	useEffect(() => {
		if (!explore === undefined || !fixed) return;
		const timeDimensions = explore.query.timeDimensions;
		if (!timeDimensions) return;
		const td = timeDimensions.find((td) => td.dimension === timeDimension);
		const dateRange = (td?.dateRange as string) || selected.value; // defaults to selected if date range is not specified
		// // find which base option to select
		const filterTabs = explore.fixedTimeDimension && explore.fixedTimeDimension.filterTabs;
		let curOptions = options;
		if (filterTabs) {
			setOptions(filterTabs);
			curOptions = filterTabs;
		}
		for (const option in curOptions) {
			if (curOptions[option].toLowerCase() === dateRange.toLowerCase()) {
				setSelected({ value: curOptions[option], runNow: false });
				highlight(curOptions[option]);
				break;
			}
		}
	}, [explore && explore.key]);

	// when selected or dateRange changes, update the date range
	useEffect(() => {
		if (selected.value === "all") {
			dispatch({ type: "setTimeDimensionDateRange", payload: { timeDimension, range: "", runNow: selected.runNow } });
			return;
		} else if (selected.value === "custom") {
			dispatch({ type: "setTimeDimensionDateRange", payload: { timeDimension, range: dateRange, runNow: selected.runNow } });
			return;
		}
		dispatch({ type: "setTimeDimensionDateRange", payload: { timeDimension, range: selected.value, runNow: selected.runNow } });
	}, [selected, dateRange]);

	// when selected or date range changes change the description
	const renderDescription = useCallback(
		() => {
			const description = getDescription(timeDimension, cubesMetaLike);
			return <>{description}</>;
		},
		[cubesMetaLike, timeDimension]
	);

	const componentRef = useRef<HTMLDivElement>(null);
	const highlighterRef = useRef<HTMLDivElement>(null);

	function highlight(option: string) {
		highlighted.current = option;
	}

	function handleClick(option: string) {
		setSelected({ value: option, runNow: true });
		highlight(option);
	}

	// all date buttons get these same props
	const defaultDateButtonProps = {
		selected: selected.value,
		highlighterRef,
		pickerRef: componentRef,
		highlighted: highlighted.current,
	};

	const customMenu = useMenu(); // state for custom menu popper

	React.useEffect(() => {
		// console.log("selected", selected, highlighted.current, customText);
		if (selected.value === "custom") {
			setCustomText(`${format(new Date(dateRange[0]), "MMM dd, yyyy")} - ${format(new Date(dateRange[1]), "MMM dd, yyyy")}`);
		} else if (selected.value !== customText) {
			setCustomText("custom");
		}
	}, [selected.value]);

	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", opacity: isLoading? 0.75 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}>
				<Typography
					variant="subtitle2"
					sx={{
						fontWeight: 200,
						"& b": { textTransform: "Capitalize", fontWeight: 600 },
						"& em": { textTransform: "capitalize", fontStyle: "normal", fontWeight: 400 },
					}}
				>
					{renderDescription()}
				</Typography>
				<Box
					className="date-filter-picker"
					ref={componentRef}
					sx={{
						// backgroundColor: theme.palette.secondary.dark,
						borderRadius: 1,
						overflow: "hidden",
						position: "relative",
						width: "fit-content",
						whiteSpace: "nowrap",
						border: "1px solid " + theme.palette.secondary.main,
					}}
				>
					<Box
						className="highlighter"
						ref={highlighterRef}
						sx={{
							zIndex: 5,
							backgroundColor: theme.palette.primary.main,
							position: "absolute",
							height: "100%",
							mixBlendMode: "screen",
							transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp}`,
							pointerEvents: "none",
							opacity: isLoading ? 0.25 : 1,
						}}
					/>
					<>
						{fixed ? (
							<span
								ref={customMenu.anchorRef}
								style={{
									display: "inline-block",
									borderRight: "1px solid " + theme.palette.secondary.main,
								}}
							>
								<DateButton
									onClick={() => {
										// console.log("click custom", highlighted.current);
										// previouslyHighlighted.current = highlighted.current;
										customMenu.handleToggle();
										// highlight("custom");
									}}
									sx={{borderRadius: "4px 0 0 4px !important"}}
									option={"custom"}
									{...defaultDateButtonProps}
								>
									<AiOutlineCalendar /> {customText}
								</DateButton>
								<CustomMenu
									open={customMenu.open}
									setOpen={customMenu.setOpen}
									anchorEl={customMenu.anchorRef as RefObject<HTMLDivElement>}
									selected={selected.value}
									setSelected={(newVal: string) => setSelected({ value: newVal, runNow: true })}
									setDateRange={setDateRange}
									highlight={highlight}
									setCustomText={setCustomText}
								/>
							</span>
						) : null}
					</>
					{options.map((option, index) => (
						<DateButton
							key={index}
							onClick={() => {
								customMenu.handleClose();
								handleClick(option);
							}}
							sx={{borderRadius: 0}}
							option={option}
							{...defaultDateButtonProps}
						>
							{option}
						</DateButton>
					))}
					<DateButton
						onClick={() => {
							handleClick("all");
						}}
						option={"all"}
						{...defaultDateButtonProps}
					>
						all
					</DateButton>
				</Box>
			</Box>
		</>
	);
};

function areEqual(prevProps: Props, nextProps: Props) {
	const result =
		prevProps.timeDimension === nextProps.timeDimension &&
		prevProps.fixed === nextProps.fixed &&
		prevProps.explore?.enumInt === nextProps.explore?.enumInt;
	return result;
}
export default React.memo(DateFilter, areEqual);
