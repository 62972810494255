import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import StyledMenuWrapper from "../../StyledMenuWrapper/StyledMenuWrapper";
import { Format, tickFormatter, tooltipFormatter, numberFormatter } from "./panelUtils";

interface Props extends TooltipProps<ValueType, NameType> {
	format: Format;
	dateFormatOverride?: string;
	pieChartTotal?: number;
}

function formatLabel(value, format: string, dateFormatOverride?: string) {
	if (format === "string") return value;

	return tickFormatter(value, format, dateFormatOverride);
}

const CustomTooltip = ({ active, payload, label, format, dateFormatOverride, pieChartTotal = -1 }: Props) => {
	if (!(active && payload && payload.length)) return null;

	const total = useMemo(() => {
		return payload.reduce((acc, item) => {
			if (!item.value) return acc + 0;
			return acc + parseInt(item.value as string);
		}, 0);
	}, [payload]);

	function getPercentage(value) {
		const int = parseInt(value as string)
		if (pieChartTotal === 0) return "invalid"
		return numberFormatter((int / pieChartTotal) * 100, "percentage")
	}

	function getItemName(item) {
		const seriesNameObj = item.payload && item.payload.seriesNames
			&& item.payload.seriesNames.find(s => s.key === item.dataKey);
		if (seriesNameObj) {
			if (seriesNameObj.yValues.length > 1) {
				return seriesNameObj.yValues[0];
			} else {
				return seriesNameObj.title;
			}
		}

		return item.name;
	}

	return (
		<StyledMenuWrapper>
			<Box sx={{isolation:  "isolate"}}>
				<Typography variant="subtitle2">{formatLabel(label, format.x, dateFormatOverride)}</Typography>
				{label !== undefined && <Divider />}
				<List dense>
					{payload.filter(item => item.value && item.value > 0).map((item) => {
						return (
							<ListItem key={item.name} sx={{}}>
								<Typography
									variant="caption"
									sx={{
										marginRight: "1ch",
										fontWeight: "600",
										position: "relative",
										"&:before": {
											content: '""',
											position: "absolute",
											width: "10px",
											height: "100%",
											backgroundColor: pieChartTotal > 0 ? item.payload.fill : item.color,
											top: 0,
											left: "-15px",
											borderRadius: 1,
											zIndex: -1,
										},
									}}
								>
									{`${getItemName(item)}: `}
								</Typography>
								<Typography variant="caption">{tooltipFormatter(item.value, format.y)}</Typography>
								{pieChartTotal > 0 && (
									<Typography variant="caption" sx={{fontWeight: '600', marginLeft: '1ch'}}>
										{` - ${getPercentage(item.value)}%`}
									</Typography>
								)}
							</ListItem>
						);
					})}
				</List>
				{["currency", "number"].includes(format.y) && payload.length > 1 && (
					<>
						<Divider />
						<Typography variant="caption" sx={{ fontWeight: "600", marginTop: "1ch" }}>
							{`Total: ${tooltipFormatter(total, format.y)}`}
						</Typography>
					</>
				)}
			</Box>
		</StyledMenuWrapper>
	);
};

export const reTooltipProps = {
	wrapperStyle: {
		outline: "none",
	},
	// position: {
	//     y: 0,
	// },
	// animationDuration: 0
};

export default CustomTooltip;
