import { Query, TimeDimensionGranularity } from "@cubejs-client/core";

export function processTimeDimensionName(memberName: string): string {
	// count the number of periods in the member name
	let periods = 0;
	for (let i = 0; i < memberName.length; i++) {
		if (memberName[i] === ".") {
			periods++;
		}
	}

	// if there is one period we can return the member name as is
	if (periods === 1) {
		return memberName;
	}

	// if there are two periods we need to check it is a time dimension
	if (periods == 2) {
		memberName = memberName.replace(/\.([^.]*)$/, "");
		return memberName;
	}

	// if there are more than two periods something is wrong
	if (periods > 2) {
		console.error(`Member name ${memberName} is not a valid time dimension`);
	}

    return "";
}

export function getGranularity(memberName: string, query: Query): TimeDimensionGranularity {
    const granularity = "day"; // default is day
    const timeDimensions = query.timeDimensions || []; // get time dimensions from query

    // find the time dimension that matches the member name
    const timeDimension = timeDimensions.find((td) => td.dimension === memberName); 

    // if the time dimension exists return the granularity
    if (timeDimension) {
        if (timeDimension.granularity) {
            return timeDimension.granularity;
        }
    } else {
        // console.warn(`Time dimension ${memberName} does not exist in query as a time dimension so granularity is returning day`);
    }

    return granularity;
}
