import API from "../api";
import {useAuthDispatch, useAuthState} from "../context/auth-context";
import React from "react";


/**
 * Get allowed_environments from server, set in component state.
 */
export default function useSetAllowedEnvironments(): void {
	const dispatchAuth = useAuthDispatch();
	const { user } = useAuthState();

  React.useEffect(() => {
		function setAllowedEnvironments(userId: number) {
			API.get(`/api/user/${userId}/allowed_environments`)
				.then(response => {
					dispatchAuth({type: 'setAllowedEnvironments', payload: {
							allowedEnvironments: response.data.allowed_environments
						}});
				})
				.catch(error => {
					console.error(`useSetAllowedEnvironments > get user allowed environments, error=`, error);
				});
		}

		if (user) {
			setAllowedEnvironments(user.userId);
		}
  }, [user && user.userId])
}