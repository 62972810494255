import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import {
	ReportsEnvironmentConfig,
	USER_ROLE_NAME_ADMIN,
} from "../../utils/auth-utils";
import {
	TextField,
	FormControlLabel,
	Switch,
	FormGroup,
	Typography,
	Tab,
	Tabs,
	Box,
	Button,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import PrimaryButton from "../Base/PrimaryButton";
import { useTheme } from "@mui/material/styles";
import { formatRelative } from "date-fns";
import { ReportsUser, useAuthState } from "../../context/auth-context";
import { isEqual, isObject, isEmpty, merge } from "lodash";
import { getColorFromContext } from "../../utils/dashboard-utils";
import { SchemaContextName } from "../../utils/cube-utils";
import API from "../../api";
import { AxiosError, AxiosResponse } from "axios";
import { useQueryParam, NumberParam } from "use-query-params";
import { ExpandMore } from "@mui/icons-material";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getDealthingUrl } from "../../utils/utils";

// used as a base for env data because sometimes these context configs arn't fully populated
const defaultEnv = {
	contextConfig: {
		default: { enabled: false, visible: false, dependencies_met: false },
		"critical-dates": {
			enabled: false,
			visible: false,
			dependencies_met: false,
		},
		portfolio: { enabled: false, visible: false, dependencies_met: false },
		global: { enabled: false, visible: false, dependencies_met: false },
		rent: { enabled: false, visible: false, dependencies_met: false },
		projects: { enabled: false, visible: false, dependencies_met: false },
	},
	dashboardConfig: {
		"critical-dates": { visible: false },
		"portfolio-rent": { visible: false },
		property: { visible: false },
		abstraction: { visible: false },
	},
};

type Props = {
	editEnv: ReportsEnvironmentConfig | null;
	open: boolean;
	setOpen: (open: boolean) => void;
};

const EnvEditModal = ({ editEnv, open, setOpen }: Props) => {
	// utility hooks
	const theme = useTheme();
	const authState = useAuthState();
	const user = authState.user as ReportsUser;

	// select env
	const [_, setIsSelectEnvOpen] = useQueryParam("select-env", NumberParam);

	// ui
	const [selectedTab, setSelectedTab] = useState(0);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	// form
	const [
		formDefaultData,
		setFormDefaultData,
	] = useState<ReportsEnvironmentConfig | null>(null);
	const [formData, setFormData] = useState(editEnv);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const handleEnvChange = (path, value) => {
		setFormData((prevState) => {
			if (prevState) {
				let newState = { ...prevState };
				let lastKey = path[path.length - 1];
				let statePointer = newState;
				path.slice(0, -1).forEach((key) => {
					statePointer[key] = { ...statePointer[key] };
					statePointer = statePointer[key];
				});
				statePointer[lastKey] = value;
				return newState;
			}
			return null;
		});
	};

	// action handeling
	const [actionState, setActionState] = useState({
		results: "",
		error: false,
	});
	const [lastCommand, setLastCommand] = useState("");

	const actions = {
		init_input_dbs: () => requestAction("init_input_dbs"),
		init_shared_input_db: () => requestAction("init_shared_input_db"),
		update_dbs: () => requestAction("update_dbs"),
		test: () => requestAction("test"),
		force_sync: () => requestAction("force_sync"),
	} as const;

	function requestAction(action: string) {
		if (!editEnv || !editEnv["env_name"]) return;
		setActionState({ ...actionState, error: false });
		setLastCommand(action);
		setLoading(true);
		API.post(`/api/env_configs/run_command`, {
			env_name: editEnv["env_name"],
			command: action,
		})
			.then((response: AxiosResponse) => {
				let error = false;
				if (!response.data.success) error = true;
				setActionState({
					...actionState,
					results: response.data.message,
					error: error,
				});
				setLoading(false);
			})
			.catch((error: AxiosError) => {
				setActionState({
					...actionState,
					error: true,
					results:
						error.response?.data.error ||
						"An unexpected error occurred.",
				});
				setLoading(false);
			});
	}

	// confirm modal
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [differences, setDifferences] = useState({});

	function confirmSave() {
		if (!editEnv || !editEnv["env_name"]) return;
		setLoading(true);
		API.patch(`/api/env_configs/env_name/${editEnv["env_name"]}`, formData)
			.then((response: AxiosResponse) => {
				console.log(response.data);
				setLoading(false);
				setOpen(false);
				setIsSelectEnvOpen(1);
				window.location.reload();
			})
			.catch((error: AxiosError) => {
				setError(error.message);
				setLoading(false);
			});
		setConfirmModalOpen(false);
	}

	const confirmModalProps = {
		open: confirmModalOpen,
		setOpen: setConfirmModalOpen,
		title: "Confirm Changes",
		description: "The following changes will be saved:",
		form: <div>{formatDifferences(differences)}</div>,
		actions: (
			<>
				<PrimaryButton
					onClick={() => setConfirmModalOpen(false)}
					color="secondary"
					variant="text"
				>
					Cancel
				</PrimaryButton>
				<PrimaryButton
					color="primary"
					variant="text"
					onClick={confirmSave}
				>
					Confirm
				</PrimaryButton>
			</>
		),
	};

	// initialize form data
	useEffect(() => {
		if (editEnv) {
			let updatedEnv = { ...editEnv };
			// merge default configs with the db env configs (prioritizing db configs)
			updatedEnv.contexts_config = merge(
				defaultEnv.contextConfig,
				editEnv.contexts_config
			);
			updatedEnv.dashboards_config = merge(
				defaultEnv.dashboardConfig,
				editEnv.dashboards_config
			);
			setFormDefaultData(updatedEnv);
			setFormData(updatedEnv);
			setActionState({ results: "", error: false });
			setLastCommand("");
		}
	}, [editEnv]);

	// frontend permissions check
	if (!editEnv || user.roleName !== USER_ROLE_NAME_ADMIN) {
		setOpen(false);
		return <></>;
	}

	function handleClickSave() {
		const diffs = calculateDifferences(formData, formDefaultData);
		setDifferences(diffs);
		setConfirmModalOpen(true);
	}

	function renderForm() {
		if (!editEnv) return <></>;
		return (
			<>
				<Box>
					<Typography
						variant="caption"
						color="#777"
						sx={{
							fontSize: "12px",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{editEnv["env_name"] + "  - "}
						{" " + editEnv["last_synced"] ? (
							<>
								Synced{" "}
								{formatRelative(
									new Date(editEnv["last_synced"]),
									new Date()
								)}
							</>
						) : (
							<>Not synced</>
						)}
					</Typography>
					<Typography
						variant="caption"
						color={theme.palette.error.main}
						sx={{
							fontSize: "12px",
						}}
					>
						{error}
					</Typography>
				</Box>
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					aria-label="environment tabs"
				>
					<Tab label="General" />
					<Tab label="Contexts Config" />
					<Tab label="Dashboards Config" />
					<Tab label="Actions" />
				</Tabs>
				<Box sx={{ padding: theme.spacing(2) }}>
					{selectedTab === 0 && formData && (
						<div>
							<TextField
								value={formData.display_name}
								onChange={(e) =>
									handleEnvChange(
										["display_name"],
										e.target.value
									)
								}
								required
								id="display_name"
								label="Display Name"
								type="text"
								sx={{ width: "50%", minWidth: "16vw" }}
								variant="standard"
								margin="dense"
							/>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={
												formData.included_in_daily_stream
											}
											onChange={(e) =>
												handleEnvChange(
													[
														"included_in_daily_stream",
													],
													e.target.checked
												)
											}
										/>
									}
									label="Included in Daily Stream"
								/>
								<FormControlLabel
									control={
										<Switch
											checked={
												formData.visible_to_end_users
											}
											onChange={(e) =>
												handleEnvChange(
													["visible_to_end_users"],
													e.target.checked
												)
											}
										/>
									}
									label="Visible to End Users"
								/>
							</FormGroup>
						</div>
					)}
					{selectedTab === 1 && formData && (
						<Grid container spacing={2}>
							{Object.keys(editEnv["contexts_config"]).map(
								(contextKey, index) => (
									<Grid item xs={4} key={contextKey}>
										<Typography
											variant="subtitle1"
											sx={{ textTransform: "capitalize" }}
										>
											<span
												style={{
													width: "12px",
													height: "12px",
													backgroundColor: getColorFromContext(
														theme,
														contextKey as SchemaContextName
													),
													borderRadius: "100%",
													display: "inline-block",
													marginRight: "1ch",
												}}
											/>
											{contextKey}
										</Typography>
										<FormGroup>
											<FormControlLabel
												control={
													<Switch
														checked={
															formData[
																"contexts_config"
															][contextKey]
																?.enabled
														}
														onChange={(e) => {
															handleEnvChange(
																[
																	"contexts_config",
																	contextKey,
																	"enabled",
																],
																e.target.checked
															);
															if (
																!e.target
																	.checked
															)
																handleEnvChange(
																	[
																		"contexts_config",
																		contextKey,
																		"visible",
																	],
																	e.target
																		.checked
																);
														}}
													/>
												}
												label="Enabled"
											/>
											<FormControlLabel
												control={
													<Switch
														checked={
															formData
																.contexts_config[
																contextKey
															]?.visible
														}
														onChange={(e) =>
															handleEnvChange(
																[
																	"contexts_config",
																	contextKey,
																	"visible",
																],
																e.target.checked
															)
														}
														disabled={
															!formData[
																"contexts_config"
															][contextKey]
																?.enabled
														}
													/>
												}
												label="Visible"
											/>
										</FormGroup>
									</Grid>
								)
							)}
						</Grid>
					)}
					{selectedTab === 2 && formData && (
						<Grid container spacing={2}>
							{Object.keys(formData["dashboards_config"]).map(
								(dashboardKey, index) => (
									<Grid item xs={4} key={dashboardKey}>
										<Typography
											variant="subtitle1"
											sx={{ textTransform: "capitalize" }}
										>
											{dashboardKey}
										</Typography>
										<FormGroup>
											<FormControlLabel
												control={
													<Switch
														checked={
															formData
																.dashboards_config[
																dashboardKey
															]?.visible
														}
														onChange={(e) =>
															handleEnvChange(
																[
																	"dashboards_config",
																	dashboardKey,
																	"visible",
																],
																e.target.checked
															)
														}
													/>
												}
												label="Visible"
											/>
										</FormGroup>
									</Grid>
								)
							)}
						</Grid>
					)}
					{selectedTab === 3 && formData && (
						<>
							<Box
								sx={{
									opacity: loading ? 0.5 : 1,
									pointerEvents: loading ? "none" : "auto",
								}}
							>
								<Box>
									<Typography
										variant="subtitle1"
										color="textSecondary"
									>
										Environment Sync:
									</Typography>
									<Button
										variant="outlined"
										color="secondary"
										sx={{ marginRight: theme.spacing(1) }}
										onClick={actions.force_sync}
									>
										1. Dealthing Force Sync
									</Button>
									<Button
										variant="outlined"
										color="secondary"
										onClick={actions.update_dbs}
									>
										2. Update Athena DBs
									</Button>
								</Box>
								<Box>
									<Typography
										variant="subtitle1"
										color="textSecondary"
										sx={{ marginTop: theme.spacing(1) }}
									>
										Environment Setup or Re-Initalize:
									</Typography>
									<Button
										variant="outlined"
										color="secondary"
										onClick={actions.init_input_dbs}
										sx={{ marginRight: theme.spacing(1) }}
									>
										1. Initialize Input DBs
									</Button>
									<Button
										variant="outlined"
										color="secondary"
										onClick={actions.update_dbs}
									>
										2. Update Athena DBs
									</Button>
								</Box>
								<Box>
									<Typography
										variant="subtitle1"
										color="textSecondary"
										sx={{ marginTop: theme.spacing(1) }}
									>
										New AWS Environment or New Streams:
									</Typography>
									<Button
										variant="outlined"
										color="secondary"
										onClick={actions.init_shared_input_db}
									>
										Initialize Shared Input DB
									</Button>
								</Box>
								<Box>
									<Typography
										variant="subtitle1"
										color="textSecondary"
										sx={{ marginTop: theme.spacing(1) }}
									>
										Other:
									</Typography>
									<Button
										variant="outlined"
										color="secondary"
										onClick={actions.test}
									>
										Test Remote Command
									</Button>
								</Box>
							</Box>

							<Typography
								variant="subtitle1"
								color="textSecondary"
								sx={{ marginTop: theme.spacing(2) }}
							>
								Results:
							</Typography>
							<Box
								sx={{
									borderTop: `1px solid ${theme.palette.divider}`,
									py: theme.spacing(1),
								}}
							>
								<Typography variant="body1">
									{loading ? "loading..." : ""}
								</Typography>
								{actionState.results && !loading && (
									<>
										<Typography
											variant="body1"
											sx={{ fontWeight: "bold" }}
											color={
												actionState.error
													? theme.palette.error.main
													: theme.palette.info.main
											}
										>
											{actionState.error
												? `${lastCommand} - Error:`
												: `${lastCommand} - Success:`}
										</Typography>
										<Accordion
											sx={{
												padding: 0,
												backgroundColor:
													theme.palette.divider,
												maxWidth: "100%",
												minWidth: "unset !important",
											}}
											TransitionProps={{
												timeout: 0
											}}
										>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-label="Expand"
												id="info"
											>
												<Typography
													sx={{
														display: "flex",
														alignItems: "center",
														gap: theme.spacing(1),
													}}
												>
													<AiOutlineInfoCircle />
													Info
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography
													variant="body1"
													color={
														actionState.error
															? theme.palette
																	.error.main
															: ""
													}
												>
													{actionState.results ||
														"No results."}
												</Typography>
											</AccordionDetails>
										</Accordion>
									</>
								)}
							</Box>
						</>
					)}
				</Box>
			</>
		);
	}

	function renderActions() {
		if (selectedTab === 3) {
			return null;
		}

		return (
			<>
				<PrimaryButton
					onClick={() => setOpen(false)}
					color="secondary"
					variant="text"
				>
					Cancel
				</PrimaryButton>
				<PrimaryButton
					color="primary"
					variant="text"
					disabled={isEqual(formDefaultData, formData)}
					onClick={handleClickSave}
					loading={loading}
					loadingText="Saving"
				>
					Save
				</PrimaryButton>
			</>
		);
	}

	const modalProps = {
		open,
		setOpen: setOpen,
		title: `${editEnv["display_name"]}`,
		description: "",
		form: renderForm(),
		actions: renderActions(),
	};

	return (
		<>
			<Modal {...modalProps} />
			<Modal {...confirmModalProps} />
		</>
	);
};

export default EnvEditModal;

function calculateDifferences(obj1, obj2) {
	const changes = {};
	for (const [key, value] of Object.entries(obj1)) {
		if (obj2.hasOwnProperty(key)) {
			if (isObject(value) && isObject(obj2[key])) {
				const nestedDiff = calculateDifferences(value, obj2[key]);
				if (!isEmpty(nestedDiff)) {
					changes[key] = nestedDiff;
				}
			} else if (!isEqual(value, obj2[key])) {
				changes[key] = [value, obj2[key]];
			}
		} else {
			changes[key] = [value, undefined];
		}
	}

	for (const [key, value] of Object.entries(obj2)) {
		if (!obj1.hasOwnProperty(key)) {
			changes[key] = [undefined, value];
		}
	}

	return changes;
}

const formatDifferences = (
	differences: Record<string, any>,
	depth: number = 0
): JSX.Element[] => {
	return Object.entries(differences).map(([key, value]) => {
		if (isObject(value) && !Array.isArray(value)) {
			return (
				<div key={key} style={{ marginLeft: `${depth * 20}px` }}>
					<Typography variant="body2">
						<strong>{key}:</strong>
					</Typography>
					{formatDifferences(value, depth + 1)}
				</div>
			);
		} else {
			return (
				<div key={key} style={{ marginLeft: `${depth * 20}px` }}>
					<Typography variant="body2">
						<strong>{key}:</strong>
						{(value as any[])[0] === undefined
							? `initialized to ${JSON.stringify(
									(value as any[])[1]
							  )}`
							: `${JSON.stringify(
									(value as any[])[1]
							  )} → ${JSON.stringify((value as any[])[0])}`}
					</Typography>
				</div>
			);
		}
	});
};
