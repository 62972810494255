import { Box, Button, Fade, Popper, Typography } from "@mui/material";
import React, { RefObject } from "react";
import StyledMenuWrapper from "../../StyledMenuWrapper/StyledMenuWrapper";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "../calendar.css";
import { subYears, addYears, format, addDays } from "date-fns";
import RelativeDate from "../../../utils/relative-dates";
import { ClickAwayListener } from "@material-ui/core";
import useMenu from "../../../hooks/useMenu";
import { menuItemType } from "../../NestedMenu/menuItemType";
import { alpha, useTheme } from "@mui/material/styles";
import animations from "../../../theme/animations";
import Select from "./Select";
import DateInput from "./DateInput";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	anchorEl: RefObject<HTMLDivElement>;
	selected: string;
	setSelected: (selected: string) => void;
	setDateRange: React.Dispatch<React.SetStateAction<[string, string]>>;
	highlight: (option: string) => void;
	setCustomText: (text: string) => void;
};

const CustomMenu = ({ open, setOpen, anchorEl, selected, setSelected, setDateRange, highlight, setCustomText }: Props) => {
	const theme = useTheme();
	const [hasChanged, setHasChanged] = React.useState(false);
	const [range, setRange] = React.useState({
		selection: {
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	});

	// React.useEffect(() => {
	// 	if (selected === "custom") return;
	// 	if (selected === "all") {
	// 		setRange({
	// 			selection: {
	// 				startDate: new Date(),
	// 				endDate: new Date(),
	// 				key: "selection",
	// 			},
	// 		});
	// 	} else {
	// 		const { startDay, endDay } = new RelativeDate(selected).getRange();
	// 		setRange({
	// 			selection: {
	// 				startDate: startDay,
	// 				endDate: endDay,
	// 				key: "selection",
	// 			},
	// 		});
	// 	}
	// }, [selected]);

	// close all menus when clicking outside
	React.useEffect(() => {
		if (!open) {
			optionsMenu.handleClose();
			unitMenu.handleClose();
		}
	}, [open]);

	const options = {
		last: {
			units: true,
			unit: true,
			between: false,
		},
		this: {
			units: false,
			unit: true,
			between: false,
		},
		next: {
			units: true,
			unit: true,
			between: false,
		},
		between: {
			units: false,
			unit: false,
			between: true,
		},
	};

	const [relativeString, setRelativeString] = React.useState<string>("");
	const [option, setOption] = React.useState<"last" | "this" | "next" | "between">("last");
	const optionsMenu = useMenu();
	const optionItems: menuItemType[] = React.useMemo(() => {
		const items: menuItemType[] = [];
		for (const key in options) {
			const upper = key.charAt(0).toUpperCase() + key.slice(1);
			items.push({
				label: upper,
				onClick: () => {
					setOption(key as "last" | "this" | "next" | "between");
					optionsMenu.handleClose();
				},
				options: {
					active: key === option,
				}
			});
		}
		return items;
	}, [option]);

	const unitOptions = ["days", "weeks", "months", "quarters", "years"];
	const [unit, setUnit] = React.useState<string>("days");
	React.useEffect(() => {
		// if unit ends with s and option is this then remove the s
		if (unit.endsWith("s") && option === "this") {
			setUnit(unit.slice(0, -1));
			if (unit === "days") {
				setUnit("week");
			}
		} else if (!unit.endsWith("s") && option !== "this") {
			setUnit(unit + "s");
		}
	}, [option, unit]);
	const unitMenu = useMenu();
	const unitItems: menuItemType[] = React.useMemo(() => {
		const items: menuItemType[] = [];
		for (const i in unitOptions) {
			let u = unitOptions[i];
			if (option === "this") {
				u = u.slice(0, -1);
			}
			const upper = u.charAt(0).toUpperCase() + u.slice(1);
			if (option === "this" && u === "day") {
				continue;
			}
			items.push({
				label: upper,
				onClick: () => {
					setUnit(u as "days" | "weeks" | "months" | "quarters" | "years");
					unitMenu.handleClose();
				},
				options: {
					active: u === unit,
				}
			});
		}
		return items;
	}, [unit, option]);

	const [units, setUnits] = React.useState<string>("");

	React.useEffect(() => {
		// we use a fixed date range for between
		if (option === "between") {
			return;
		}
		const optionDefinition = options[option];
		let string = `${option}`;
		if (optionDefinition.units) {
			if (units) {
				string += ` ${units}`;
			} else {
				// console.warn("custom relative date: units is required");
				return;
			}
		}
		if (optionDefinition.unit) {
			if (unit) {
				string += ` ${unit}`;
			} else {
				// console.log("unit", unit);
				// console.warn("custom relative date: unit is required");
				return;
			}
		}

		try {
			const { startDay, endDay } = new RelativeDate(string).getRange();
			setRange({
				selection: {
					startDate: startDay,
					endDate: endDay,
					key: "selection",
				},
			});
		} catch (e) {
			console.warn("custom relative date: invalid date string", e);
			return;
		}

		setHasChanged(true);
		setRelativeString(string);
	}, [option, units, unit]);

	function getMaxDate() {
		if (option === "last") {
			return new Date();
		}
		return addYears(new Date(), 5);
	}

	function getMinDate() {
		if (option === "next") {
			return new Date();
		}
		return subYears(new Date(), 5);
	}

	return (
		<Popper open={open} anchorEl={anchorEl.current} placement="bottom-start">
			<ClickAwayListener
				onClickAway={() => {
					setOpen(false);
				}}
			>
				<Fade in timeout={100} mountOnEnter unmountOnExit>
					<StyledMenuWrapper style={{transformOrigin: 'top'}}>
						<Box sx={{ maxWidth: 366, minWidth: 366}}>
							{/* <p>{relativeString}</p> */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									maxWidth: "100%",
									"& input": {
										backgroundColor: theme.palette.secondary.main,
										border: "1px solid transparent",
										borderRadius: 1,
										color: theme.palette.secondary.contrastText,
										padding: "0.5rem 1rem",
										flexGrow: 1,
										width: "2%",
										height: "24.5px",
										"&:focus, &:focus-visible": {
											border: `1px solid ${theme.palette.primary.main}`,
											outline: "none",
										},

										textAlign: "center",
									},

									"& .dateInput": {
										textAlign: "left",
									},
								}}
							>
								<Select menu={optionsMenu} menuItems={optionItems} selected={option} />
								<Box
									sx={{
										backgroundColor: alpha(theme.palette.secondary.contrastText, 0.1),
										padding: theme.spacing(1),
										borderRadius: 1,
										display: "flex",
										justifyContent: "space-between",
										animation: animations.fadeIn,
										gap: theme.spacing(1),
									}}
									key={option}
								>
									{options[option].between && (
										<>
											<DateInput placeHolder="Start Date" range={range} setRange={setRange} />
											<DateInput placeHolder="End Date" range={range} setRange={setRange} />
										</>
									)}

									{options[option].units && (
										<input
											placeholder="Enter a Value"
											type="text"
											value={units}
											autoFocus
											onChange={(e) => {
												const value = e.target.value;
												if (parseInt(value)) {
													setUnits(`${parseInt(value)}`);
												} else if (value === "") {
													setUnits(value);
												}
											}}
										/>
									)}
									{options[option].unit && (
										<Select menu={unitMenu} menuItems={unitItems} selected={unit} />
									)}
								</Box>
							</Box>
							<Box
								key={option}
								sx={{
									"& .rdrDefinedRangesWrapper": { display: "none" },
									button: {
										pointerEvents: option !== "between" ? "none" : "auto",
									},
									animation: animations.fadeIn,
									"& .rdrMonthsVertical": {
										WebkitMaskImage: `linear-gradient(black 85%, transparent 100%)`,
										maskMode: "alpha",
									}
								}}
							>
								<Box
									sx={{
										position: "absolute",
										background: `linear-gradient(180deg, ${alpha(
											theme.palette.secondary.main,
											0.25
										)} 0%, transparent 100%)`,
										width: "calc(100% + 4px)",
										height: "20%",
										pointerEvents: "none",
										left: -2,
										mixBlendMode: "overlay",
									}}
								/>
								<Box
									sx={{
										display: option === "between" ? "none" : "flex",
										// backgroundColor: alpha(theme.palette.primary.main, 0.25),
										justifyContent: "center",
										gap: theme.spacing(1),
										paddingLeft: theme.spacing(5),
										paddingRight: theme.spacing(5),
										paddingTop: theme.spacing(1),
										// paddingBottom: theme.spacing(1),
									}}
								>
									<Typography variant="subtitle2">
										{format(range.selection.startDate, "MMM dd, yyyy")}
									</Typography>
									<Typography variant="subtitle2">-</Typography>
									<Typography variant="subtitle2">
										{format(range.selection.endDate, "MMM dd, yyyy")}
									</Typography>
								</Box>
								<DateRangePicker
									// locale={enGB}
									onChange={(item) => {
										setHasChanged(true);
										setRange({ ...range, ...item });
									}}
									months={1}
									direction="vertical"
									scroll={{ enabled: true }}
									maxDate={getMaxDate()}
									minDate={getMinDate()}
									ranges={[range.selection]}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
								}}
							>
								<Button
									sx={{ width: "100%" }}
									color="error"
									size="small"
									onClick={() => {
										setOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									size="small"
									disabled={!hasChanged}
									disableElevation
									onClick={() => {
										if (option === "between") {
											setDateRange([
												format(range.selection.startDate, "yyyy-MM-dd"),
												format(range.selection.endDate, "yyyy-MM-dd"),
											]);
											setSelected("custom");
											setCustomText("date range");
										} else {
											setSelected(relativeString);
											setCustomText(relativeString);
										}
										highlight("custom");
										setOpen(false);
									}}
								>
									Apply
								</Button>
							</Box>
						</Box>
					</StyledMenuWrapper>
				</Fade>
			</ClickAwayListener>
		</Popper>
	);
};

export default CustomMenu;
