import React from "react";
import { useAuthDispatch } from "../../../context/auth-context";
import { deleteUserValues } from "../../../utils/auth-utils";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../context/auth-context";
import {
	IconButton,
	ListItemText,
	Avatar,
} from "@mui/material";
import Menu from "../../../components/NestedMenu/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import useMenu from "../../../hooks/useMenu";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

interface Props {
	open: boolean;
}
const UserControls: React.FC<Props> = ({ open }: Props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { user } = useAuthState();
	const dispatchAuth = useAuthDispatch();

	// popover menu
	// const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	// const [menuOpen, setMenuOpen] = React.useState(false);
	const menu = useMenu();
	const buttonRef = React.useRef<HTMLButtonElement>(null);

	React.useEffect(() => {
		if (!open && menu.open) {
			// setAnchorEl(null);
			// setMenuOpen(false);
			menu.handleClose();
		}
	}, [open]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		console.log("handle click", menu)
		// if (event.currentTarget.tagName === "DIV" && open) {
		// 	return;
		// }
		// setAnchorEl(event.currentTarget);
		menu.handleToggle();
	};

	function logout() {
		deleteUserValues();
		dispatchAuth({ type: "setIsNotAuthenticated" });
		navigate(`/auth`);
	}

	const name = React.useMemo(() => {
		let name = ""
		let username = ""
		let monogram = ""
		if (!user) return

		if (user.firstName) {
			name = user.firstName
			monogram = user.firstName[0]

			if (user.lastName) {
				name += (" " + user.lastName)
				monogram += user.lastName[0]
			}

			if (user.username) {
				username = user.username
			}
		} else if (user.username) {
			name = user.username
			monogram = user.username[0]
		}

		monogram = monogram.toUpperCase()

		if (name === username) {
			username = ""
		}

		return {name, username, monogram}
	}, [user])

	return (
		<>
			<div
				onClick={(e) => handleClick(e)}
				style={{
					display: "flex",
					alignItems: "center",
					backgroundColor: open ? "rgba(12, 12, 12, 0.2)" : "transparent",
					transition: "backgroundColor 225ms, borderTop 255ms, cubic-bezier(.075,.82,.165,1) 0ms",
					borderTop: open ? "1px solid rgba(92, 92, 92, 0.5)" : "1px solid transparent",
				}}
				>
				<Avatar
					ref = {menu.anchorRef as React.RefObject<HTMLDivElement>}
					sx={{
						cursor: open ? "default" : "pointer",
						bgcolor: "#171717",
						fontWeight: "800",
						borderRadius: "4px !important",
						width: 40,
						height: 40,
						fontSize: "0.8rem",
						transform: "translateX(25%)",
						mr: open ? 3 : 0,
						transition: "all 225ms cubic-bezier(.075,.82,.165,1) 0ms",
						color: "white",
					}}
				>
					{name && name.monogram}
				</Avatar>
				<ListItemText
					sx={{
						color: "white",
						width: open ? "auto" : 0,
						opacity: open ? 1 : 0,
						transition: "all 225ms cubic-bezier(.075,.82,.165,1) 0ms;",
						overflow: "hidden",
						textTransform: "capitalize",
						"& .MuiListItemText-secondary": {
							color: "rgba(238, 238, 238, 0.5)",
						},
					}}
					primary={name && name.name}
					secondary={name && name.username}
					ref={buttonRef}
				/>
				<IconButton
					ref={buttonRef}
					onClick={(e) => handleClick(e)}
					disableRipple={open ? false : true}
					disabled={open ? false : true}
					size="large"
					>
					<MoreVertIcon
						sx={{
							color: "rgba(238, 238, 238, 0.5)",
							"&:hover": {
								color: "rgba(238, 238, 238, 1)",
							},
							opacity: open ? 1 : 0,
							transition: "all 225ms cubic-bezier(.075,.82,.165,1) 0ms;",
						}}
					/>
				</IconButton>
			</div>
			{/* logout menu using material ui */}
			<Menu
			menuItems={[{
				label: "Logout",
				onClick: () => logout(),
				options: {
					endIcon: <LogoutRoundedIcon  style={{fontSize: '16px'}}/>,
					color: theme.palette.error.main,
				}
			}]}
			open={menu.open}
			setOpen={menu.setOpen}
			anchorEl={open ? buttonRef.current : menu.anchorRef.current }
			placement="top-start"
			canHide={false}
			/>
		</>
	);
};

export default UserControls;
