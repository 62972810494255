import React, { useEffect } from "react";
import Wrapper from "./Wrapper";
import { Box, List } from "@mui/material";
import Header from "../Header";
import { useCubeMetaState } from "../../../../context/cube-meta-context";
import { useQueryState } from "../../../../context/query-context";
import {
	resolveCubeFromMember,
} from "../../../../utils/cube-utils";
import {BinaryFilter, TCubeMember, UnaryFilter} from "@cubejs-client/core";
import { useTheme } from "@mui/material/styles";
import ActiveField from "../ActiveField";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Field, useFieldContext } from "../../../../context/providers/FieldContextProvider";
import useFadedScroll from "../../../../hooks/useFadedScroll";

interface Props {
	dimensions: Field[];
	measures: Field[];
}

const ActiveFields: React.FC<Props> = (
	{
		dimensions,
		measures,
	}: Props) => {
	const theme = useTheme();
	const { cubesMetaLike } = useCubeMetaState();
	const { query } = useQueryState();
	const fieldContext = useFieldContext();

	if (!cubesMetaLike) {
		return <></>;
	}
	const { cubesMap } = cubesMetaLike;

	const filterActive = (member: TCubeMember) => {
		return query.filters && query.filters.map((f: BinaryFilter | UnaryFilter) => f.member).includes(member.name);
	};

	const [overflow, setOverflow] = React.useState(false);
	const innerRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		// get the height of the inner div
		const innerHeight = innerRef.current?.clientHeight;
		// get the height of the outer div
		const outerHeight = fade.ref.current?.clientHeight;

		if (innerHeight === undefined || outerHeight === undefined) return;

		if (innerHeight > outerHeight) {
			setOverflow(true);
		} else {
			setOverflow(false);
		}
	}, [fieldContext.fields])

	const fade = useFadedScroll(80);

	return (
		<Wrapper>
			<Box sx={{ marginLeft: "64px", paddingRight: theme.spacing(1) }}>
				<Box sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) }}>
					<Header>
						<div>
							active <span>fields</span>
						</div>
					</Header>
				</Box>
				<div
					className="scroll-container"
					style={{
						maxHeight: "30vh",
						overflow: "auto",
						marginBottom: theme.spacing(1),
						paddingRight: theme.spacing(1),
						...(overflow ? fade.fadeStyles : {})
						
					}}
					onScroll={fade.onScroll}
					ref={fade.ref}
				>
					<div ref={innerRef}>
						<DragDropContext
							onDragEnd={(result) => {
								if (result.destination === null) {
									return;
								}
								fieldContext.reorderField(result.source.index, result.destination.index);
							}}
						>
							<Droppable droppableId="active-members-dimensions-list">
								{(provided) => (
									<List
										{...provided.droppableProps}
										ref={provided.innerRef}
										component="div"
										disablePadding
										sx={{
											maxWidth: "100%",
										}}
									>
										{dimensions.map((field, id) => {
											const member = fieldContext.getMember(field.memberName);
											const cube = resolveCubeFromMember(field.memberName, cubesMap);
											if (!member || !cube) {
												return null;
											}
											return (
												<Draggable key={field.memberName} draggableId={field.memberName} index={id}>
													{(provided) => (
														<div {...provided.draggableProps} {...provided.dragHandleProps}>
															<ActiveField
																ref={provided.innerRef}
																cube={cube}
																member={member}
																memberType={field.type}
																filterActive={filterActive(member)}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</List>
								)}
							</Droppable>
						</DragDropContext>
						<DragDropContext
							onDragEnd={(result) => {
								if (result.destination === null) {
									return;
								}
								fieldContext.reorderField(result.source.index, result.destination.index,  "measures", false);
							}}
						>
							{measures.length > 0 && (
								<Box sx={{ marginLeft: theme.spacing(3), marginBottom: theme.spacing(2) }}>
								</Box>
							)}
							<Droppable droppableId="active-members-dimensions-list">
								{(provided) => (
									<List
										{...provided.droppableProps}
										ref={provided.innerRef}
										component="div"
										disablePadding
										sx={{
											maxWidth: "100%",
										}}
									>
										{measures.map((field, id) => {
											const member = fieldContext.getMember(field.memberName);
											const cube = resolveCubeFromMember(field.memberName, cubesMap);
											if (!member || !cube) {
												return null;
											}
											return (
												<Draggable key={field.memberName} draggableId={field.memberName} index={id}>
													{(provided) => (
														<div {...provided.draggableProps} {...provided.dragHandleProps}>
															<ActiveField
																ref={provided.innerRef}
																cube={cube}
																member={member}
																memberType={field.type}
																filterActive={filterActive(member)}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</List>
								)}
							</Droppable>
						</DragDropContext>
						{/* {overflow && <br></br>} */}
					</div>
				</div>
			</Box>
		</Wrapper>
	);
};

// https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
function arraysEqual(a, b) {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}

function areEqual(prevProps, nextProps) {
	const result = (arraysEqual(prevProps.dimensions, nextProps.dimensions) && arraysEqual(prevProps.measures, nextProps.measures) && prevProps.overflow === nextProps.overflow);
	return result;
}

export default React.memo(ActiveFields, areEqual);
