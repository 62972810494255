import {
  MemberType,
  Meta,
  TCubeDimension,
  TCubeMeasure,
  TimeDimension,
  Query,
  TCubeMemberType,
  TCubeSegment,
  TQueryOrderArray,
  Filter, TCubeMember, Cube, BinaryFilter, UnaryFilter
} from "@cubejs-client/core";
import { Field } from "../context/providers/FieldContextProvider";

/***
 * Names of schema contexts - must always match names in context config file in backend
 */
export type SchemaContextName = "default" | "critical-dates" | "portfolio" | "global" | "rent" | "projects"

export interface ExploreQueryDefinition {
  enumInt: number
  key: string
  label: string
  visibleCubes: VisibleCube[]
  fixedTimeDimension?: { dimension: string, filterTabs?: string[] }
  fieldsOrder: string[]
  query: Query
}

export interface VisibleCube {
	name: string
	dimensions: string[] | boolean
	measures: string[] | boolean
}

export type SchemaContextDefinition = {
  name: SchemaContextName
  label: string
  color: string
  colorPalette: string
  hidden_cubes: string[]
  explore_queries: ExploreQueryDefinition[]
  visible_by_default: boolean
  enabled_by_default: boolean
}

export enum SchemaContextEnum {
    "default",
    "critical-dates",
    "portfolio",
    "global",
    "rent",
    "projects",
}

export interface MetaLike extends Meta {
  cubes: Cube[]
  cubesMap: Record<string, Cube>
  resolveCubeMember: (cubes: Cube[], memberName: string, memberType: MemberType) => TCubeDimension | TCubeMeasure | undefined
}

export function resolveQueryMember(cubesMeta: MetaLike, memberName: string, memberType?: MemberType):  TCubeDimension | TCubeMeasure | TCubeSegment | undefined {
  const cubeName = memberName.split('.')[0];
  const cube = cubesMeta.cubesMap[cubeName];
  if (cube) {
    if (memberType) {
      return cube[memberType].find(m  => (m as TCubeMember).name === memberName);
    } else {
      return cube["dimensions"].find((m: TCubeDimension) => m.name === memberName)
        ||  cube["measures"].find((m: TCubeMeasure) => m.name === memberName)
        ||  cube["segments"].find((m: TCubeSegment) => m.name === memberName);
    }
  }
}

export function resolveMemberAndType(cubes: Cube[], memberName: string): { member: TCubeMember | undefined, memberType: MemberType | undefined } {
  const measuresMember = resolveCubeMember(cubes, memberName, "measures");
  const dimensionsMember = resolveCubeMember(cubes, memberName, "dimensions");
  return {
    member: measuresMember ? measuresMember : dimensionsMember,
    memberType: measuresMember ? "measures" : dimensionsMember ? "dimensions" : undefined,
  }
}

export function resolveCubeMember(cubes: Cube[], memberName: string, memberType?: MemberType):  TCubeDimension | TCubeMeasure | undefined {
  const cube = cubes.find(c => c.name === memberName.split('.')[0])
  if (!cube) {
    return;
  }
  if (memberType === "dimensions") {
    return (cube["dimensions"]).find((m: TCubeDimension) => m.name === memberName);
  }
  if (memberType === "measures") {
    return (cube["measures"]).find((m: TCubeMeasure) => m.name === memberName);
  }
  return cube["dimensions"].find((m: TCubeDimension) => m.name === memberName)
    ||  cube["measures"].find((m: TCubeMeasure) => m.name === memberName);
}

export function createCubesMap(cubes: Cube[]): Record<string, Cube> {
  const cubesMap = {}
  cubes.forEach(c => {
    cubesMap[c.name] = c;
  })
  return cubesMap;
}

export function resolveCubeFromMember(memberName: string, cubesMap: Record<string, Cube>): Cube {
  const cubeName = memberName.split('.')[0]
  return cubesMap[cubeName]
}

export function validateQuery(query: Query, meta: MetaLike): Query {
  const dimensions = query.dimensions && query.dimensions.filter(m => !!resolveQueryMember(meta, m, "dimensions"));
  const measures = query.measures && query.measures.filter(m => !!resolveQueryMember(meta, m, "measures"));
  const segments = query.segments && query.segments.filter(m => !!resolveQueryMember(meta, m, "segments"));
  const filters = query.filters && query.filters.filter((f: BinaryFilter | UnaryFilter) => f.member && !!resolveQueryMember(meta, f.member));
  const order = query.order && (query.order as TQueryOrderArray).filter(oArray => !!resolveQueryMember(meta, oArray[0]));

  return {
    ...query,
    dimensions: dimensions || undefined,
    measures: measures || undefined,
    segments: segments || undefined,
    filters: filters || undefined,
    order: order || undefined,
  }
}