import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { IoIosWarning } from "react-icons/io";
import { APP_MAINTENANCE_MODE } from "../../config/frontendConfig";

const MaintenancePopup = () => {
    const theme = useTheme();
	const [open, setOpen] = React.useState(true);

	function onClose() {
		setOpen(false);
	}

    if (!APP_MAINTENANCE_MODE) {
        return null;
    }

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle sx={{display: 'flex', alignItems: "center", gap: theme.spacing(2), color: theme.palette.error.main}}>
				<IoIosWarning />
				{"Planned Maintenance In Progress"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					DealSumm Reports is currently undergoing scheduled
					maintenance. We apologize for any inconvenience and thank
					you for your patience.
					<br />
					<br />
					You are free to continue using the application, but you may
					experience issues, such as reports not loading, or the
					application becoming unresponsive.
				</DialogContentText>
			</DialogContent>
			<Button onClick={onClose} color="primary">
				Close
			</Button>
		</Dialog>
	);
};

export default MaintenancePopup;
