import React from "react";
import {useParams} from "react-router-dom";
import {SchemaContextEnum, SchemaContextName} from "../utils/cube-utils";
import {useCubeMetaDispatch} from "../context/cube-meta-context";


/**
 * Listens for changes in "contextNumeric", the URL param
 * On change, finds the matching context in "SchemaContextEnum" then sets "appContextName".
 */
export default function useSetSchemaContext(): void {
	const dispatchMeta = useCubeMetaDispatch();
	const { contextNumeric } = useParams() as Record<string, string>;

  React.useEffect(() => {
		// console.debug(`useSetSchemaContext > useEffect > contextNumeric changed (=${contextNumeric})`);
		if (contextNumeric) {
			const appContextName = SchemaContextEnum[parseInt(contextNumeric)] as SchemaContextName;
			dispatchMeta({type: "setAppContextName", payload: { appContextName }});
		} else {
			dispatchMeta({type: "setAppContextName", payload: { appContextName: null }});
		}
  }, [contextNumeric])
}
