import React from "react";
import { LineChart as ReLineChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Line } from "recharts";
import CustomTooltip, { reTooltipProps } from "./CustomTooltip";
import { PanelProps, tickFormatter } from "./panelUtils";
import { darken, useTheme } from "@mui/material/styles";
import { calculateFill } from "../../../utils/dashboard-utils";
// import useChartZoom from "./hooks/useChartZoom";

const LineChart = ({ seriesNames, data, format, colorPaletteIdx, height, dateFormatOverride }: PanelProps) => {
	const theme = useTheme();

	// const zoom = useChartZoom(data);

	return (
		<>
			{/*{zoom.renderButton()}*/}
			<ResponsiveContainer width="100%" height={400}>
				{/*<ReLineChart data={zoom.data} {...zoom.chartEventProps}>*/}
				<ReLineChart data={data}>
					<defs>
						{seriesNames.map(({ key, title, yValues }, idx) => {
							return (
								<linearGradient key={idx} id={`color${idx}`} x1="0" y1="0" x2="0" y2="1">
									{/*<stop offset="5%" stopColor={calculateFill(theme, idx, seriesNames.length)} stopOpacity={0.8} />*/}
									{/*<stop offset="95%" stopColor={calculateFill(theme, idx, seriesNames.length)} stopOpacity={0} />*/}
									<stop offset="5%" stopColor={calculateFill(idx, seriesNames.length, colorPaletteIdx)} stopOpacity={0.8} />
									<stop offset="95%" stopColor={calculateFill(idx, seriesNames.length, colorPaletteIdx)} stopOpacity={0} />
								</linearGradient>
							);
						})}
					</defs>
					<XAxis dataKey="x" tickFormatter={(value) => tickFormatter(value, format.x, dateFormatOverride)} />
					<YAxis tickFormatter={(value) => tickFormatter(value, format.y, dateFormatOverride)} />
					<Tooltip
						content={
							<CustomTooltip
								payload={data.map((d) => Object.assign(d, { seriesNames }))}
								format={format}
								dateFormatOverride={dateFormatOverride}
							/>
						}
						{...reTooltipProps}
						position={{ y: 0 }}
						animationDuration={0}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					{seriesNames.map(({ key, title, yValues }, idx) => {
						return (
							<Line
								key={idx}
								dataKey={key}
								// stroke={calculateFill(theme, idx, seriesNames.length)}
								stroke={calculateFill(idx, seriesNames.length, colorPaletteIdx)}
								fill={`url(#color${idx})`}
								type="monotone"
								dot={false}
								animationDuration={300}
								animationEasing="ease-in-out"
							/>
						);
					})}
					{/*{zoom.renderReferenceArea()}*/}
				</ReLineChart>
			</ResponsiveContainer>
		</>
	);
};

export default LineChart;
