import React from "react";
import { Button, Typography } from "@mui/material";
import Modal from "./Modal";
import PrimaryButton from "../Base/PrimaryButton";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onAccept: () => void;
	title?: string;
	description?: string;
	form?: React.ReactNode;
};

const ConfirmModal = ({ open, setOpen, title, description, form, onAccept }: Props) => {

	const handleConfirm = () => {
		onAccept();
		setOpen(false);
	}

    const confirmRef = React.useRef<HTMLButtonElement>(null);
	const props = {
		open,
		setOpen,
		title: title ? title : "Confirm",
		description: description ? description : "",
		form: form ? form : <></>,
		actions: (
			<>
				<PrimaryButton onClick={() => setOpen(false)} color="primary">
					No
				</PrimaryButton>
				<PrimaryButton color="error" onClick={handleConfirm} variant="text">
					Yes
				</PrimaryButton>
			</>
		),
        onKeyPress: (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                confirmRef.current?.focus();
            }
        },
	};
	return <Modal {...props} />;
};

export default ConfirmModal;
