import React from "react";
import { alpha, keyframes } from "@mui/system";
import { styled } from "@mui/system";

const StyledMenuWrapper = styled("div")(({ theme }) => ({
	backgroundColor: "rgba(25, 25, 25, 0.65)",
	position: "relative",
	color: "white",
	borderRadius: "4px",
	padding: "2px",
	border: "1px solid rgba(100, 100, 100, 1)",
	boxShadow: "0px 0px 4px rgba(56, 56, 56, 0.5)",
	marginLeft: "2px",
	minWidth: "150px",
	backdropFilter: "blur(30px)",
	opacity: 0.75,
	overflowY: "auto",
	overflowX: 'hidden',
	animation: `${animateOpen} 100ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards`,
	// transformOrigin: "top",
	maxHeight: "60vh",
	"&::-webkit-scrollbar-track": {
		background: "transparent",
	},
	"&::-webkit-scrollbar": {
		width: "6px",
		height: "6px",
	},
	"&::-webkit-scrollbar-thumb": {
		background: alpha(theme.palette.secondary.contrastText, 0.25),
		borderRadius: "6px",
    "&:hover": {
      background: alpha(theme.palette.secondary.contrastText, 0.5),
    }
	},
}));

const animateOpen = keyframes`
	0% {
		transform: scaleY(0.5);
		}
	100% {
		transform: scaleY(1);
	}`;

export default StyledMenuWrapper;
