import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../context/auth-context";
import useSetAllowedEnvironments from "../hooks/useSetAllowedEnvironments";
import useSetSchemaContextDefinitions from "../hooks/useSetSchemaContextDefinitions";
import {deleteUserValues, getStoredUserValues, UserAuthenticationDetails, setUserValues} from "../utils/auth-utils"
import API from "../api"


const PrivateRoute: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAuthDispatch();
  const { isAuthenticated } = useAuthState();

  useSetAllowedEnvironments();
  useSetSchemaContextDefinitions();

  React.useEffect(() => {
    const userAuth: UserAuthenticationDetails = getStoredUserValues()

    // if (isAuthenticated) {
    //   setUserValues(userAuth)
    //   dispatch({type: 'setIsAuthenticated', payload: {user: userAuth}})
    //   return
    // }

    function authenticationFailure() {
      deleteUserValues()
      dispatch({type: 'setIsNotAuthenticated'})
      navigate('/auth', {replace: true})
    }

    if (userAuth.jwtToken) {
      API.get(`/api/login/token/${userAuth.jwtToken}`)
      .then(response => {
        if (response.status === 200) {
          setUserValues(userAuth)
          dispatch({type: 'setIsAuthenticated', payload: {user: userAuth}})
        } else {
          authenticationFailure()
        }
      })
      .catch(error => {
        authenticationFailure()
      })
    } else {
      authenticationFailure()
    }
  }, [])

  return <Outlet />
}

export default PrivateRoute;